import { useGetAll } from "@mb-pro-ui/utils";
import { Checkbox } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { AllEntities } from "../utils";
import { Authority } from "../types";
import AuthorityCreateModal from "./AuthorityCreateModal";

const AllAuthorities = () => {
  const { formatMessage } = useIntl();
  const queryResult = useGetAll<Authority>("alarm/authorities", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
    filter: {
      deleted: {
        is: "false",
      },
    },
  });

  const columns = useMemo(
    (): Column<Authority>[] => [
      {
        id: "name",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (authority) => authority.name,
      },
      {
        id: "phone",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Phone",
        }),
        accessor: (authority) => authority.phone || "",
      },
      {
        id: "mobile",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Mobile",
        }),
        accessor: (authority) => authority.mobile || "",
      },
      {
        id: "remark",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Remark",
        }),
        accessor: (guard) => guard.remark || "",
      },
      {
        id: "active",
        width: 40,
        Header: formatMessage({
          defaultMessage: "Active",
        }),
        accessor: (installer) => installer.active,
        Cell: ({ value: isAlert }: Cell<Authority, boolean>) => (
          <Checkbox
            checked={isAlert}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
      },
    ],
    [formatMessage]
  );

  const title = <FormattedMessage defaultMessage="Authorities" />;

  return (
    <AllEntities
      columns={columns}
      title={title}
      queryResult={queryResult}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <AuthorityCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllAuthorities;

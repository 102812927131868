import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FilterViewProps } from "../../table";
import { AutocompleteFilter } from "../../table/filters";
import { EquipmentType } from "../types";

import {
  EntityWithDescription,
  filterOptionsFromDescription,
  idAsLabel,
  sortByDescription,
} from "../../../utils/FilterUtils";

const renderOption = (v: EntityWithDescription) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.description}
  </Typography>
);

const EquipmentTypeAutocompleteFilter = (props: FilterViewProps) => {
  const { data } = useGetAll<EquipmentType>("alarm/equipment-types", {
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: false,
    select: (data) => [...data.sort(sortByDescription)],
  });

  const { formatMessage } = useIntl();

  return (
    <AutocompleteFilter
      {...props}
      options={data}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      noOptionsText={formatMessage({
        defaultMessage: "No such equipment type",
        description: "Autocomplete Filter",
      })}
      label={formatMessage({
        defaultMessage: "Equipment type",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Equipment types: Name",
        description: "Autocomplete Filter",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default EquipmentTypeAutocompleteFilter;

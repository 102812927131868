function isEqualImpl(a: any, b: any, aPath: any[], bPath: any[]): boolean {
  if ((a ?? "") === (b ?? "")) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  const ai = aPath.indexOf(a);
  if (ai !== -1) {
    return bPath[ai] === b;
  } else if (bPath.includes(b)) {
    return false;
  }

  aPath = [...aPath, a];
  bPath = [...bPath, b];

  if (Array.isArray(a) && Array.isArray(b)) {
    return (
      a === b ||
      (Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((item, i) => isEqualImpl(item, b[i], aPath, bPath)))
    );
  }

  if (typeof a === "object" && typeof b === "object" && a && b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length === bKeys.length) {
      for (const key of aKeys) {
        if (!isEqualImpl(a[key], b[key], aPath, bPath)) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

// Deep equals with circular reference support
export default function isEqual(a: any, b: any): boolean {
  return isEqualImpl(a, b, [], []);
}

import { useParams } from "react-router";

import { Box, SvgIcon, Typography } from "@mui/material";

import { SnackbarState, SystemSettingsPageProps } from "../types";
import {
  Category as _Category,
  CodeTable as _CodeTable,
  Event as _Event,
} from "@mb-pro-ui/utils/types/alarm";

import { useLocale } from "../../../locales/LocaleProvider";
import { Lang } from "../../types";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";

import { Page, SubPage } from "@mb-pro-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemo, useState } from "react";

import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { localDescr } from "../../../utils/LocaleUtils";
import { Form } from "react-final-form";
import {
  AllEntities,
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { TextField } from "@mb-pro-ui/components/form";
import { Maybe } from "@mb-pro-ui/utils/types";
import { Column } from "react-table";
import { UseQueryResult } from "react-query";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import EventTypeCreateModal from "./EventTypeCreateModal";
import EventTypeUpdateModal from "./EventTypeUpdateModal";
import { ActionType } from "../utils/types";
import { ReactComponent as CodeTableIcon } from "../../../icons/system_settings/CodeTable.svg";

type Event = Omit<_Event, "code-table" | "category"> & { category: _Category };

type CodeTable = Omit<
  Pick<
    _CodeTable,
    "id" | "type" | "descr" | "events" | "remark" | "localized-description"
  >,
  "events"
> & {
  events: Maybe<Event[]>;
};

interface FormValues extends Pick<CodeTable, "id" | "remark" | "descr"> {}

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Code table editor"
        description="System settings page sidebar code table editor option"
      />
    ),
    icon: <SvgIcon component={CodeTableIcon} />,
  },
];

export const postLoadFormat = ({
  id,
  descr,
  remark,
}: CodeTable): FormValues => ({
  id,
  descr,
  remark,
});

const CodeTableUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const { waitFor } = useBackendSchemas();
  const {
    data: codeTable,
    refetch,
    isLoading,
    ...rest
  } = useGetOne<CodeTable>("alarm/code-tables", id, {
    include: { events: { category: {} } },
    fields: {
      "code-tables": ["+events"],
      events: ["-code-table"],
    },
  });

  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const descr = codeTable?.descr
    ? localDescr(codeTable?.descr, Lang[locale])
    : "";
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/code-tables/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    refetch,
    setSnackbarState,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/code-tables",
    {
      replaceTo: "/alarm/settings/code-tables",
      setSnackbarState,
    }
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([...diff("code-tables", codeTable, values, { schemas })]);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  const columns = useMemo(
    (): Column<Event>[] => [
      {
        id: "code",
        width: 40,
        Header: formatMessage({
          defaultMessage: "Code",
        }),
        accessor: (event) => event.code,
      },
      {
        id: "descr",
        Header: formatMessage({
          defaultMessage: "Description",
        }),
        accessor: (event) => localDescr(event.descr, Lang[locale]),
      },
      {
        id: "category",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Category",
        }),
        accessor: (event) =>
          event.category.descr
            ? localDescr(event.category.descr, Lang[locale])
            : "",
      },
    ],
    [formatMessage, locale]
  );

  return (
    <Page
      backLink="/alarm/settings/code-tables"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      {...systemSettingsPageProps}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box>
          <Form
            onSubmit={onSubmit}
            initialValues={codeTable && postLoadFormat(codeTable)}
            render={({ handleSubmit, submitting, pristine, form }) => {
              return (
                <SubPage
                  prefix={prefix}
                  sx={{ minWidth: "500px" }}
                  innerSx={{ height: "auto" }}
                >
                  <StyledForm>
                    <TextField
                      name={`descr.[${Lang[locale]}]`}
                      label={formatMessage(
                        { defaultMessage: "Name ({locale})" },
                        { locale }
                      )}
                      disabled={submitting || isLoading || isDeleting}
                      required
                      requiredError={formatMessage({
                        defaultMessage: "Required",
                      })}
                    />
                    <TextField
                      name="remark"
                      label={formatMessage({ defaultMessage: "Remark" })}
                      disabled={submitting || isLoading || isDeleting}
                      parse={(v) => v}
                    />
                    <UpdateFormActions
                      onSave={handleSubmit}
                      onReset={() => form.reset()}
                      onDelete={() => setDeleteDialogOpen(true)}
                      disabledDelete={submitting}
                      disabledReset={submitting || pristine}
                      disabledSave={submitting || pristine}
                      isLoading={isLoading}
                      isDeleting={isDeleting}
                      isSaving={submitting}
                    />
                  </StyledForm>
                </SubPage>
              );
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            marginLeft: "10px",
          }}
        >
          <AllEntities
            columns={columns}
            queryResult={
              {
                data: codeTable?.events ?? [],
                refetch,
                isLoading,
                ...rest,
              } as UseQueryResult<Event[], JsonapiError>
            }
            actionType={ActionType.POPUP}
            title={formatMessage({ defaultMessage: "Event types" })}
            renderForm={({
              isFormOpen,
              onFormClose,
              refetch,
              setSnackbarState,
            }) => {
              return (
                <EventTypeCreateModal
                  open={isFormOpen}
                  onFormClose={onFormClose}
                  refetch={refetch}
                  setSnackbarState={setSnackbarState}
                  mutationVariables={{ "code-table": id }}
                />
              );
            }}
            renderUpdateForm={({
              isFormOpen,
              onFormClose,
              refetch,
              setSnackbarState,
              selectedId,
            }) => {
              return (
                <EventTypeUpdateModal
                  open={isFormOpen}
                  onFormClose={onFormClose}
                  setSnackbarState={setSnackbarState}
                  refetch={refetch}
                  id={selectedId}
                  mutationVariables={{ "code-table": id }}
                />
              );
            }}
          />
        </Box>
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={descr}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default CodeTableUpdatePage;

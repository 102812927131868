import { FormattedMessage, useIntl } from "react-intl";
import { useGetAll } from "@mb-pro-ui/utils";
import { Installer } from "../types";
import { Column } from "react-table";
import { useMemo } from "react";
import InstallerCreateModal from "./InstallerCreateModal";
import { AllEntities } from "../utils";

const AllInstallers = () => {
  const { formatMessage } = useIntl();

  const queryResult = useGetAll<Installer>("alarm/installers", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
    filter: {
      deleted: {
        is: "false",
      },
    },
  });

  const columns = useMemo(
    (): Column<Installer>[] => [
      {
        id: "name",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (installer) => installer.name,
      },
      {
        id: "company",
        Header: formatMessage({
          defaultMessage: "Company",
        }),
        accessor: (installer) => installer.company,
      },
      {
        id: "address",
        Header: formatMessage({
          defaultMessage: "Address",
        }),
        accessor: (installer) => installer.address || "",
      },
      {
        id: "phone",
        Header: formatMessage({
          defaultMessage: "Phone",
        }),
        accessor: (installer) => installer.phone || "",
      },
      {
        id: "mobile",
        Header: formatMessage({
          defaultMessage: "Mobile",
        }),
        accessor: (installer) => installer.mobile || "",
      },
      {
        id: "email",
        Header: formatMessage({
          defaultMessage: "Email",
        }),
        accessor: (installer) => installer.email || "",
      },
    ],
    [formatMessage]
  );

  const title = <FormattedMessage defaultMessage="Installers" />;

  return (
    <AllEntities
      columns={columns}
      title={title}
      queryResult={queryResult}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => {
        return (
          <InstallerCreateModal
            open={isFormOpen}
            onFormClose={onFormClose}
            refetch={refetch}
            setSnackbarState={setSnackbarState}
          />
        );
      }}
    />
  );
};

export default AllInstallers;

import React, { SyntheticEvent, useEffect, useState } from "react";

import { Box, IconButton, List, ListItem, Typography } from "@mui/material";

import { FormDialog } from "@mb-pro-ui/components";

import {
  FieldArray as FinalFormFieldArray,
  useFieldArray,
} from "react-final-form-arrays";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ActionPlansSubForm, {
  ActionPlanSubFormDialog,
} from "./actionPlan/ActionPlanSubform";
import Widget from "../utils/Widget";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";
import { Form, useFormState } from "react-final-form";
import { StyledTextField } from "./StyledComponents";

import { AnyObject } from "final-form";
import { useIntl } from "react-intl";
import range from "lodash/range";

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface ActionPlanDialogProps {
  name: string;
  url: string;
}

const ActionPlanDialog = ({ name, url }: ActionPlanDialogProps) => {
  let submit: Submit = null;

  const history = useHistory();

  const { fields } = useFieldArray(name);
  const { push } = fields;

  const { formatMessage } = useIntl();

  const onSubmit = (vals: { description: string }) => {
    push({
      acknowledgement: false,
      "acknowledgement-delay": null,
      "call-storno": false,
      "call-storno-delay": null,
      color: null,
      "event-storno": false,
      "event-storno-delay": null,
      "group-code": 0,
      rules: null,
      subactions: null,
      type: "action-groups",
      ...vals,
    });
  };

  const onActionPlanDialogAction = (
    _: {},
    action: "close" | "cancel" | "confirm"
  ) => {
    switch (action) {
      case "close":
        history.replace(url);
        break;
      case "cancel":
        history.replace(url);
        break;
      case "confirm":
        if (submit) {
          submit();
          history.replace(url);
        }
        break;
    }
  };

  return (
    <FormDialog
      title={formatMessage({
        defaultMessage: "New action plan",
      })}
      open={true}
      onDialogAction={onActionPlanDialogAction}
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => {
          submit = handleSubmit;
          return (
            <div>
              <StyledTextField
                name="description"
                label={formatMessage({
                  defaultMessage: "Description",
                })}
              />
            </div>
          );
        }}
      </Form>
    </FormDialog>
  );
};

interface ActionPlansWidgetProps {
  label: string;
  name: string;
  readOnly?: boolean;
}

const ActionPlansWidget = ({
  label,
  name,
  readOnly,
}: ActionPlansWidgetProps) => {
  const [opens, setOpens] = useState<number[]>([]);

  const [deletedItems, setDeletedItems] = useState<any[]>([]);

  const { fields } = useFieldArray(name);

  const { path, url } = useRouteMatch();

  const { submitting } = useFormState();

  const toggleOpen = (index: number) => {
    setOpens(
      opens.includes(index)
        ? [...opens.filter((i) => i !== index)]
        : [...opens, index]
    );
  };

  const onDelete = (index: number, values: any, ord: number) => {
    fields.remove(index);
    if (values.id) {
      setDeletedItems([...deletedItems, { ...values, ind: ord }]);
    }
    if (opens.includes(index)) setOpens([...opens.filter((i) => i !== index)]);
  };

  useEffect(() => {
    if (submitting) {
      setDeletedItems([]);
    }
  }, [setDeletedItems, submitting]);

  return (
    <>
      <Route path={`${path}/new`}>
        <ActionPlanDialog name={name} url={url} />
      </Route>
      <Route path={`${path}/${name}/:groupCode/:value/new`}>
        <ActionPlanSubFormDialog url={url} />
      </Route>
      <Box
        paddingY={0}
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 1,
          minHeight: "40px",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: "5px",
            marginRight: "auto",
          }}
        >
          {label}
        </Typography>
        {readOnly ? null : (
          <Link to={`${url}/new`}>
            <IconButton
              size="large"
              sx={{
                color: "common.white",
                padding: 1,
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Link>
        )}
      </Box>

      <FinalFormFieldArray name={name}>
        {({ fields }) => {
          const newFields = fields.value ? [...fields.value] : [];

          const arrayLength = (fields?.length ?? 0) + deletedItems.length;
          return (
            <Widget
              placeholderMessage="Nincsenek feladatlisták"
              children={
                fields.value.length > 0 || deletedItems.length > 0 ? (
                  <List>
                    {range(arrayLength).map((i) => {
                      if (deletedItems.some((item) => item.ind === i)) {
                        const deletedItem = deletedItems.find(
                          (item) => item.ind === i
                        );
                        return (
                          <React.Fragment key={`actiongroup.${i}`}>
                            <ListItem
                              sx={{
                                display: "flex",
                              }}
                            >
                              <IconButton
                                size="large"
                                sx={{
                                  padding: 1,
                                }}
                              >
                                <ArrowRightIcon sx={{ color: "grey.300" }} />
                              </IconButton>
                              <Typography
                                sx={{ textDecoration: "line-through" }}
                              >
                                {deletedItem.description}
                              </Typography>
                            </ListItem>
                          </React.Fragment>
                        );
                      } else {
                        const fieldValue = newFields.shift();

                        const fieldsCount =
                          arrayLength -
                          newFields.length -
                          1 -
                          deletedItems.length;

                        return (
                          <React.Fragment key={`actiongroup.${i}`}>
                            <ListItem
                              sx={{
                                display: "flex",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  toggleOpen(fieldsCount);
                                }}
                                size="large"
                                sx={{
                                  padding: 1,
                                }}
                              >
                                {opens.includes(fieldsCount) ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowRightIcon />
                                )}
                              </IconButton>
                              <Typography>
                                {fields.value[fieldsCount].description}
                              </Typography>
                              {readOnly ? null : (
                                <IconButton
                                  onClick={() =>
                                    onDelete(fieldsCount, fieldValue, i)
                                  }
                                  size="large"
                                  sx={{
                                    color: "error.main",
                                    marginLeft: "auto",
                                    padding: 1,
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </ListItem>

                            {opens.includes(fieldsCount) ? (
                              <ActionPlansSubForm
                                name={name}
                                i={fieldsCount}
                                groupCode={
                                  fields.value[fieldsCount]["group-code"]
                                }
                                readOnly={readOnly}
                              />
                            ) : null}
                          </React.Fragment>
                        );
                      }
                    })}
                  </List>
                ) : null
              }
              sx={{ marginBottom: "10px" }}
            />
          );
        }}
      </FinalFormFieldArray>
    </>
  );
};

export default ActionPlansWidget;

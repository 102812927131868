import { useEffect, useMemo, useRef } from "react";
import { Manager, Socket } from "socket.io-client";
import { useNamespace } from "./SocketIOProvider";

type AnyEventHandler = (event: string, ...args: unknown[]) => void;

export function useAnyEvent(
  ...uargs: [namespace: string, callback: AnyEventHandler] | [callback: AnyEventHandler]
): void {
  const [namespace, callback] = uargs.length === 1 ? [undefined, uargs[0]] : uargs;
  // Reason for using useRef here:
  //  don't have to resubscribe to the socket
  //  every time the callback changes (eg. inline arrow function)
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const socket = useNamespace(namespace);

  useEffect(() => {
    function socketHandler(this: Socket, ...args: [string, ...unknown[]]) {
      callbackRef.current.apply(this, args);
    }

    if (socket) {
      socket.onAny(socketHandler);
      return () => {
        socket.offAny(socketHandler);
      };
    }
  }, [socket]);
}

type EventHandler = (...args: unknown[]) => void;

export function useEvent(
  ...uargs:
    | [event: string, namespace: string, callback: EventHandler]
    | [event: string, callback: EventHandler]
): void {
  const [event, namespace, callback] = uargs.length === 2 ? [uargs[0], undefined, uargs[1]] : uargs;

  // Reason for using useRef here:
  //  don't have to resubscribe to the socket
  //  every time the callback changes (eg. inline arrow function)
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const socket = useNamespace(namespace);

  useEffect(() => {
    function socketHandler(this: Socket, ...args: unknown[]) {
      callbackRef.current.apply(this, args);
    }

    if (socket) {
      socket.on(event, socketHandler);
      return () => {
        socket.off(event, socketHandler);
      };
    }
  }, [socket, event]);
}

export function useEvents(
  ...uargs:
    | [events: string[] | undefined, namespace: string, callback: AnyEventHandler]
    | [events: string[] | undefined, callback: AnyEventHandler]
): void {
  const [events, namespace, callback] =
    uargs.length === 2 ? [uargs[0], undefined, uargs[1]] : uargs;

  // Reason for using useRef here:
  //  don't have to resubscribe to the socket
  //  every time the callback changes (eg. inline arrow function)
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const socket = useNamespace(namespace);

  const boundHandlers = useRef<Record<string, EventHandler>>({});

  useEffect(() => {
    function getHandler(event: string) {
      if (!boundHandlers.current[event]) {
        boundHandlers.current[event] = (...args: unknown[]) => {
          callbackRef.current(event, ...args);
        };
      }
      return boundHandlers.current[event];
    }

    if (socket && events && events.length > 0) {
      for (const event of events) {
        socket.on(event, getHandler(event));
      }

      const registeredEvents = events.slice();
      return () => {
        for (const event of registeredEvents) {
          socket.off(event, getHandler(event));
        }
      };
    }
  }, [socket, events]);
}

type MangerEvent = Parameters<InstanceType<typeof Manager>["on"]>[0];

export function useManagerEvent(
  ...uargs:
    | [event: MangerEvent, namespace: string, callback: EventHandler]
    | [event: MangerEvent, callback: EventHandler]
): void {
  const [event, namespace, callback] = uargs.length === 2 ? [uargs[0], undefined, uargs[1]] : uargs;

  // Reason for using useRef here:
  //  don't have to resubscribe to the socket
  //  every time the callback changes (eg. inline arrow function)
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const socket = useNamespace(namespace);

  useEffect(() => {
    function socketHandler(this: Socket, ...args: unknown[]) {
      callbackRef.current.apply(this, args);
    }

    if (socket) {
      socket.io.on(event, socketHandler);
      return () => {
        socket.io.off(event, socketHandler);
      };
    }
  }, [socket, event]);
}

type BoundEventEmitter = {
  (...args: any[]): void;
  enabled: boolean;
};

export function useEmit(event: string, namespace?: string): BoundEventEmitter {
  const socket = useNamespace(namespace);
  const eventRef = useRef(event);
  eventRef.current = event;

  return useMemo(
    () =>
      Object.assign(
        socket
          ? (...args: any[]) => socket.emit(eventRef.current, ...args)
          : () => {
              throw new Error("Cannot emit event with disabled SocketIOProvider");
            },
        { enabled: !!socket }
      ),
    [socket]
  );
}

let cache: number;

const scrollbarWidth = () => {
  if (!cache !== undefined && !isNaN(cache)) {
    return cache;
  }

  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  cache = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return cache;
};

export default scrollbarWidth;

import { useEffect, useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { Box, styled } from "@mui/system";
import { Divider, IconButton, SvgIcon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import FilterHeader from "./FilterHeader";
import { Filter, FilterView } from "../filters/types";

const collapsedHeaderHeight = 54 + 24 + 1; // collapsed filter height + filterheader header height + border divider

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.info.main,
  "&:hover": {
    backgroundColor: "unset",
  },
}));

export interface AdvancedFiltersProps {
  tableFilters: FilterView[];
  onFilterChange?: (filters: Filter[]) => void;
}

const AdvancedFilters = ({
  tableFilters,
  onFilterChange,
}: AdvancedFiltersProps) => {
  const [filterHeader, filterHeaderRef] = useState<HTMLDivElement | null>(null);
  const [filters, setFilters] = useState<Filter[]>(() => []);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedHeaderHeight, setExpandedHeaderHeight] = useState(
    collapsedHeaderHeight
  );

  useLayoutEffect(() => {
    filterHeader &&
      setExpandedHeaderHeight(filterHeader.getBoundingClientRect().height);
  }, [filterHeader]);

  useResizeObserver(filterHeader, (e) =>
    setExpandedHeaderHeight(e.borderBoxSize[0].blockSize)
  );

  useEffect(() => {
    onFilterChange?.(filters);
  }, [filters, onFilterChange]);

  const filterIsExpandable = expandedHeaderHeight > collapsedHeaderHeight;

  return (
    <>
      <div
        style={{
          height: `${
            isExpanded ? expandedHeaderHeight : collapsedHeaderHeight
          }px`,
          transition: "height 0.3s ease-in-out",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <FilterHeader
          filterViews={tableFilters}
          filters={filters}
          setFilters={setFilters}
          ref={filterHeaderRef}
          sx={{
            "@media (min-width: 600px)": {
              paddingRight: filterIsExpandable ? "87px" : "47px",
            },
            "@media (min-width: 0px) and (orientation: landscape)": {
              paddingRight: filterIsExpandable ? "87px" : "47px",
            },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 12,
            height: `${collapsedHeaderHeight}px`,
            display: "flex",
            alignItems: "center",
            paddingRight: "24px",
          }}
        >
          <StyledIconButton
            size="small"
            disableRipple
            onClick={() => setFilters([])}
            disabled={filters.length === 0}
          >
            <SvgIcon fontSize={"small"}>
              <ClearIcon />
            </SvgIcon>
          </StyledIconButton>
          {filterIsExpandable && (
            <StyledIconButton
              size="small"
              disableRipple
              onClick={() => setIsExpanded((previous) => !previous)}
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(360deg)",
                transition: "transform 0.2s linear",
              }}
            >
              <ExpandMoreIcon color="primary" />
            </StyledIconButton>
          )}
        </Box>
      </div>
      <Divider />
    </>
  );
};

export default AdvancedFilters;

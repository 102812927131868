import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderOptionState,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popper, { PopperProps } from "@mui/material/Popper";
import styled from "@mui/system/styled";
import parse from "autosuggest-highlight/parse";
import { HTMLAttributes, useCallback, useMemo } from "react";
import TextField from "./TextField";

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput"> {
  label?: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  required?: boolean;
  stringify?: (v: T) => string;
}

interface HighLightedItemProps extends HTMLAttributes<HTMLLIElement> {
  primary: {
    text: string;
    highlight: boolean;
  }[];
  secondary?: {
    text: string;
    highlight: boolean;
  }[];
}

const HighlightablePart = styled("span", {
  shouldForwardProp: (propName) => propName !== "highlight",
})<{ highlight?: boolean }>((props) => [props.highlight ? { fontWeight: 700 } : false]);

const HighLightedItem = ({ primary, secondary, ...props }: HighLightedItemProps) => (
  <ListItem {...props}>
    <ListItemText
      primary={
        <>
          {primary.map(({ highlight, text }, index) => (
            <HighlightablePart key={index} {...{ highlight, children: text }} />
          ))}
        </>
      }
      secondary={
        <>
          {secondary?.map(({ highlight, text }, index) => (
            <HighlightablePart key={index} {...{ highlight, children: text }} />
          ))}
        </>
      }
    />
  </ListItem>
);

const handleRenderOption = <T,>(
  props: HTMLAttributes<HTMLLIElement>,
  option: T,
  { inputValue }: AutocompleteRenderOptionState,
  getOptionLabel: (labelOption: T) => string,
  stringify?: (v: T) => string
) => {
  const [primary, secondary] = (stringify || getOptionLabel)(option).split("\n");

  const index = primary?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1;

  const primaryParts = parse(
    primary ?? "",
    index !== -1 && inputValue ? [[index, index + inputValue.length]] : []
  );

  let secondaryParts;

  if (secondary) {
    const secIndex = secondary.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1;

    secondaryParts = parse(
      secondary,
      secIndex !== -1 && inputValue ? [[secIndex, secIndex + inputValue.length]] : []
    );
  }

  return <HighLightedItem primary={primaryParts} secondary={secondaryParts} {...props} />;
};

const AutocompletePopper = (props: PopperProps) => (
  <Popper
    {...props}
    style={{ ...props.style, width: "auto", minWidth: props.style?.width, maxWidth: "fit-content" }}
    placement="bottom-start"
  />
);

const defaultGetOptionLabel = (v: any) => v as string;

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  error,
  label,
  helperText,
  readOnly,
  fullWidth,
  getOptionLabel = defaultGetOptionLabel,
  stringify,
  required,
  disableClearable,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & { required?: boolean }) => (
  <MuiAutocomplete
    renderInput={({ fullWidth: _, InputLabelProps, ...params }) => (
      <TextField
        {...params}
        fullWidth={fullWidth}
        label={label}
        error={error}
        helperText={helperText}
        readOnly={readOnly}
        required={required}
      />
    )}
    open={readOnly ? false : undefined}
    PopperComponent={AutocompletePopper}
    disableClearable={(disableClearable || required || readOnly) as DisableClearable}
    getOptionLabel={getOptionLabel}
    renderOption={useCallback(
      (props, option, optionState) =>
        handleRenderOption<T>(props, option, optionState, getOptionLabel, stringify),
      [getOptionLabel, stringify]
    )}
    filterOptions={useMemo(
      () => (stringify ? createFilterOptions({ stringify }) : undefined),
      [stringify]
    )}
    {...rest}
  />
);

export default Autocomplete;

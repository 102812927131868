import {
  Intervention as _Intervention,
  Cdec as _Cdec,
  Subtask as _Subtask,
  TaskGroup as _TaskGroup,
  Session as _Session,
  Log as _Log,
  NotifiablePerson,
  Customer as _Customer,
  Zone as _Zone,
  Partition as _Partition,
  IntervalRule as _IntervalRule,
  Category as _Category,
  Operator as _Operator,
  Employee as _Employee,
} from "@mb-pro-ui/utils/types/alarm";
import _CustomerCustomFields from "@mb-pro-ui/utils/types/alarm/customer-custom-fields";
import _ActionGroup from "@mb-pro-ui/utils/types/alarm/action-groups";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import checkFields from "../../utils/checkFields";

export type Intervention = Omit<
  _Intervention,
  | "start-event"
  | "session"
  | "customer"
  | "task-groups"
  | "logs"
  | "fresh-cdec-count"
> & {
  "start-event": Maybe<ID>;
  session: Maybe<Session>;
  customer: Maybe<Customer>;
  "task-groups": Maybe<TaskGroup[]>;
  logs: Log[];
};

export type Customer = Pick<
  _Customer,
  | "id"
  | "name"
  | "account"
  | "address"
  | "phone"
  | "mobile"
  | "unitid"
  | "passwd"
  | "active"
  | "remarks"
  | "custom"
  | "location-name"
> & {
  "notifiable-persons": Maybe<NotifiablePerson[]>;
  zones: Maybe<Zone[]>;
  partitions: Maybe<Partition[]>;
  "interval-rules": Maybe<IntervalRule[]>;
  "custom-fields": Maybe<CustomerCustomFields>;
} & (
    | {
        "has-valid-base-coords": true;
        "base-latitude": number;
        "base-longitude": number;
        "base-coord-update-ts": string;
      }
    | {
        "has-valid-base-coords": false;
        "base-latitude": null;
        "base-longitude": null;
        "base-coord-update-ts": null;
      }
  ) &
  (
    | {
        "has-valid-move-coords": true;
        latitude: number;
        longitude: number;
        "coord-update-ts": string;
      }
    | {
        "has-valid-move-coords": false;
        latitude: null;
        longitude: null;
        "coord-update-ts": null;
      }
  );

export const interventionIncludes = {
  customer: {
    "custom-fields": {},
    "interval-rules": {
      "date-code": {},
      "event-category": {},
    },
    partitions: {},
    "notifiable-persons": {},
    zones: {
      "sensor-type": {},
      "zone-type": {},
    },
  },
  session: { operator: { employee: {} } },
  "task-groups": {
    subtasks: {},
    "action-group": {},
  },
  logs: { session: { operator: { employee: {} } } },
};

export const getInterventionFields = (variant: "-all" | "" = "") => ({
  [`interventions${variant}`]: checkFields<Intervention>()([
    "start-time",
    "close-time",
    "customer",
    "session",
    "start-event",
    "task-groups",
    "logs",
    "mod-time",
  ]),
  customers: checkFields<Customer>()([
    "name",
    "account",
    "address",
    "phone",
    "mobile",
    "unitid",
    "passwd",
    "active",
    "latitude",
    "longitude",
    "base-latitude",
    "base-longitude",
    "coord-update-ts",
    "base-coord-update-ts",
    "remarks",
    "custom",
    "notifiable-persons",
    "zones",
    "partitions",
    "interval-rules",
    "custom-fields",
    "has-valid-base-coords",
    "has-valid-move-coords",
    "location-name",
  ]),
  sessions: checkFields<Session>()(["operator"]),
  [`log${variant}`]: checkFields<Log>()([
    "time",
    "text",
    "automatic",
    "session",
  ]),
  "customer-custom-fields": checkFields<CustomerCustomFields>()(["fields"]),
  categories: checkFields<Category>()(["localized-description"]),
  "date-codes": checkFields<IntervalRule["date-code"]>()([
    "localized-description",
  ]),
  "sensor-types": checkFields<Zone["sensor-type"]>()(["localized-description"]),
  "zone-types": checkFields<Zone["zone-type"]>()(["localized-description"]),
  operators: checkFields<Operator>()([
    "login",
    "deleted",
    "active",
    "employee",
  ]),
  employees: checkFields<Operator["employee"]>()(["name"]),
  [`subtasks${variant}`]: checkFields<Subtask>()([
    "description",
    "channel",
    "code",
    "person",
    "address",
    "status",
  ]),
  "action-groups": checkFields<ActionGroup>()(["description"]),
  [`task-groups${variant}`]: checkFields<TaskGroup>()([
    "status",
    "action-group",
    "subtasks",
  ]),
});

export type CustomerCustomFields = Omit<_CustomerCustomFields, "name">;

export type TaskGroup = Omit<
  _TaskGroup,
  "subtasks" | "action-group" | "intervention"
> & {
  subtasks: Maybe<Subtask[]>;
  "action-group": Maybe<Pick<ActionGroup, "description">>;
};

export type Subtask = Omit<
  _Subtask,
  "start-time" | "close-time" | "task-group"
>;

export type ActionGroup = Pick<_ActionGroup, "description">;

export type Cdec = Pick<
  _Cdec,
  | "id"
  | "type"
  | "localized-description"
  | "category-alert"
  | "color"
  | "arrived"
  | "sent"
  | "event-code"
  | "account"
  | "unitid"
  | "partition-number"
  | "zone-number"
  | "active"
> & { customer: ID } & (
    | {
        "has-valid-coords": true;
        latitude: number;
        longitude: number;
      }
    | {
        "has-valid-coords": false;
        latitude: null;
        longitude: null;
      }
  );

export type Session = Pick<_Session, "id" | "type"> & { operator: Operator };

export type Operator = Pick<
  _Operator,
  "id" | "login" | "deleted" | "active"
> & {
  employee: Maybe<Pick<_Employee, "name">>;
};

export type Log = Omit<_Log, "session" | "intervention"> & {
  session: Maybe<Session>;
};

export type ObjectWithLocalizedDescr = {
  id: ID;
  "localized-description": Maybe<string>;
};

export type Zone = Omit<_Zone, "sensor-type" | "zone-type"> & {
  "sensor-type": Maybe<ObjectWithLocalizedDescr>;
  "zone-type": Maybe<ObjectWithLocalizedDescr>;
};

export type IntervalRule = Omit<
  _IntervalRule,
  "date-code" | "event-category"
> & {
  "date-code": ObjectWithLocalizedDescr;
  "event-category": Maybe<Category>;
};

export type Category = Pick<_Category, "id" | "localized-description">;

export type Partition = _Partition;

import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Hidden,
  CircularProgress,
  ButtonBase,
  Theme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, Button } from "@mb-pro-ui/components";
import { useGetOne, useUpdate } from "@mb-pro-ui/utils";

import { useLocale } from "../locales/LocaleProvider";
import { isHttpError, useAuth } from "@mb-pro-ui/utils";

import logo from "../icons/logo.svg";
import { Box, styled, useTheme } from "@mui/system";

const StyledForm = styled("form")(({ theme }) => ({
  width: "70%",
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

type LoginLocationState = { from?: { pathname: string } } | undefined;

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation<LoginLocationState>();
  const theme = useTheme();
  const auth = useAuth();
  const { locale, locales, setLocale } = useLocale();
  const {
    data: user,
    isLoading: isUserInfoLoading,
    refetch: getUserInfo,
  } = useGetOne("admin/users", "me", {
    enabled: false,
  });
  const { isLoading: isSigninInProgress, isError, error } = auth.signin;
  const { mutateAsync, isLoading: isUserUpdateInProgress } = useUpdate(
    "admin/users"
  );

  const from = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("from")) {
      return JSON.parse(searchParams.get("from") ?? '"/home"');
    }
    return location.state?.from ?? { pathname: "/home" };
  }, [location.state, location.search]);

  useEffect(() => {
    document.title = "Login - Monitoringbook Pro";
  }, []);

  useEffect(() => {
    if (auth.user) {
      getUserInfo();
    }
  }, [auth.user, getUserInfo]);

  useEffect(() => {
    if (user && auth.user) {
      (async function () {
        if (user && auth.user) {
          try {
            await mutateAsync({
              id: user.id,
              "ui-language": locale,
            });
          } catch (error) {
            console.error(error);
          } finally {
            const pathname = typeof from === "string" ? from : from.pathname;
            if (pathname.match(/^\/home/)) {
              history.replace(from);
            } else {
              const url = new URL(pathname, window.location.origin);
              if (typeof from !== "string") {
                if (from.search !== undefined) {
                  url.search = from.search;
                }
                if (from.hash !== undefined) {
                  url.hash = from.hash;
                }
              }
              window.location.replace(url.toString());
            }
          }
        }
      })();
    }
  }, [user, auth.user, history, from, locale, mutateAsync]);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      auth.signin.mutate({ username: username, password });
    },
    [auth.signin, username, password]
  );

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const errorMessage = useMemo(() => {
    if (error && isHttpError(error) && error.status === 401) {
      return formatMessage({
        defaultMessage: "Invalid username or password",
        description: "LoginPage invalid username or password",
      });
    } else if (error) {
      return formatMessage(
        {
          defaultMessage: "Could not log in: {message}",
          description: "LoginPage could not login error message",
        },
        { message: error.message }
      );
    } else {
      return "\u00a0";
    }
  }, [error, formatMessage]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "100vh",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          padding: "5%",
          position: "relative",
          [theme.breakpoints.up("md")]: {
            borderRadius: "20px",
          },
        }}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              alignSelf: "flex-end",
              color: theme.palette.text.secondary,
              position: "absolute",
              top: "30px",
              right: "30px",
              [theme.breakpoints.down("sm")]: {
                top: "10px",
                right: "10px",
              },
            }}
          >
            {locales.map((lang) => (
              <ButtonBase
                sx={{
                  "&:not(:first-of-type):before": {
                    content: '"/"',
                    paddingLeft: "0.2rem",
                    paddingRight: "0.2rem",
                    textDecoration: "initial",
                  },
                }}
                key={lang}
                onClick={() => setLocale(lang)}
                disabled={locale === lang}
              >
                <Typography
                  sx={{
                    ...(locale === lang && {
                      fontWeight: "bold",
                    }),
                  }}
                >
                  {lang}
                </Typography>
              </ButtonBase>
            ))}
          </Box>
          <img src={logo} alt="" width="64px" height="73px"></img>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              "& > span": {
                color: theme.palette.primary.main,
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.5rem",
                marginBottom: "20px",
              },
              "@media (max-width:350px)": {
                display: "none",
              },
            }}
            variant="h1"
          >
            <span>Monitoringbook</span> Pro
          </Typography>
          <Hidden smDown>
            <Typography sx={{ marginBottom: "4rem" }} variant="subtitle1">
              <FormattedMessage
                defaultMessage="Please login below to proceed"
                description="LoginPage subtitle"
              />
            </Typography>
          </Hidden>
          <StyledForm autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              alignContent="center"
              alignItems="center"
            >
              <TextField
                sx={{
                  margin: "0.2rem",
                  width: "100%",
                }}
                type="text"
                placeholder={formatMessage({
                  defaultMessage: "Username",
                  description: "LoginPage username",
                })}
                value={username}
                onChange={handleUsernameChange}
                autoFocus
                required
                error={isError}
                autoComplete="username"
              ></TextField>
              <TextField
                sx={{
                  margin: "0.2rem",
                  width: "100%",
                }}
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder={formatMessage({
                  defaultMessage: "Password",
                  description: "LoginPage password",
                })}
                onChange={handlePasswordChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        data-testid="toggle-visibility"
                        size="large"
                      >
                        {showPassword ? (
                          <Visibility color="primary" />
                        ) : (
                          <VisibilityOff color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isError}
                helperText={isError ? errorMessage : "\u00a0"}
                autoComplete="current-password"
              />
              {isSigninInProgress ||
              isUserInfoLoading ||
              isUserUpdateInProgress ? (
                <CircularProgress
                  data-testid="loading-spinner"
                  sx={{
                    marginTop: "4rem",
                  }}
                  size={42} // height of Button size="large"
                />
              ) : (
                <Button
                  sx={{
                    marginTop: "4rem",
                    paddingRight: "4.5rem",
                    paddingLeft: "4.5rem",
                    fontWeight: (theme: Theme) =>
                      theme.typography.fontWeightLight,
                  }}
                  size="large"
                  type="submit"
                >
                  <FormattedMessage
                    defaultMessage="Login"
                    description="LoginPage login button"
                  />
                </Button>
              )}
            </Grid>
          </StyledForm>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LoginPage;

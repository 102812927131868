import { SxProps } from "@mui/material";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@mui/material/FormControlLabel";

export interface CheckboxProps extends MuiCheckboxProps {
  label?: string | number | React.ReactElement;
  FormControlLabelProps?: Partial<Omit<MuiFormControlLabelProps, "label">>;
  labelSx?: SxProps;
}

const Checkbox = ({
  label,
  disabled,
  FormControlLabelProps,
  readOnly,
  sx = [],
  labelSx = [],
  ...rest
}: CheckboxProps) => {
  const control = (
    <MuiCheckbox
      disableRipple
      color="primary"
      disabled={disabled || readOnly}
      readOnly={readOnly}
      sx={[
        !!readOnly && {
          "&.Mui-disabled": {
            color: (theme) => theme.palette.primary.main,
          },
        },
        !label && {
          margin: 8,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );

  return label ? (
    <MuiFormControlLabel
      control={control}
      disabled={disabled}
      sx={[
        {
          margin: 1,
          minWidth: 160,
          "&.Mui-disabled .MuiFormControlLabel-label": {
            color: "text.primary",
          },
        },
        ...(Array.isArray(labelSx) ? labelSx : [labelSx]),
      ]}
      label={label}
      {...FormControlLabelProps}
    />
  ) : (
    control
  );
};

export default Checkbox;

import { Link, LinkProps } from "react-router-dom";
import { styled } from "@mui/system";

const ColoredRouterLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "blink",
})<LinkProps & { blink?: boolean }>(({ theme, color, blink }) => {
  const _color = color || theme.palette.primary.main;
  return {
    textDecoration: "none",
    color: _color,
    "&:hover": {
      textDecoration: `underline ${_color}`,
      animation: "unset",
    },
    "@keyframes blink": {
      "0%, 100%": {
        color: "inherit",
      },
      "50%": {
        color: _color,
      },
    },
    ...(blink && {
      animation: "blink 1s ease-in-out infinite",
    }),
  };
});

export default ColoredRouterLink;

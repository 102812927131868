import { useApi } from "@mb-pro-ui/utils";
import {
  useEffect,
  useRef,
  useState,
} from "react";
import { FormDialog } from "@mb-pro-ui/components";
import { Form } from "react-final-form";
import {
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { EmailTemplate } from "@mb-pro-ui/utils/types/alarm";
import { useMutation } from "react-query";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import { ModalFormProps } from "../settings/types";

export interface EmailTemplateSendDialogProps extends ModalFormProps {
  template: EmailTemplate;
}

interface PrepareEmailRequest {
  createAttachments?: boolean | "missing-only";
}

interface PrepareEmailRecipient {
  name: string;
  email: string;
  active: boolean;
}

interface PrepareEmailAttachment {
  filename: string;
  status?: "success" | "pending" | "in_progress" | "failure";
}

interface PrepareEmailResponse {
  ready: boolean;
  subject: string;
  body: string;
  recipients: PrepareEmailRecipient[];
  "bcc-recipients": PrepareEmailRecipient[];
  attachments: PrepareEmailAttachment[];
}

function isEmailStillBeingPrepared(response: PrepareEmailResponse) {
  if (response.ready || response.attachments === null) return false;
  for (let i = 0; i < response.attachments.length; i++) {
    const s = response.attachments[i].status;
    if (!s || s === "pending" || s === "in_progress") return true;
  }
  return false;
}

const EmailTemplateSendDialog = ({
  template,
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: EmailTemplateSendDialogProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const api = useApi();
  const prepareEmail = (request?: PrepareEmailRequest) => {
    return api<any>(`/actions/alarm/prepare-email/${template.id}`, {
      method: "post",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
      body: request ? JSON.stringify({ request }) : undefined,
    });
  };
  const sendEmail = () => {
    return api<any>(`/actions/alarm/send-prepared-email/${template.id}`, {
      method: "post",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
    });
  };

  const {
    mutate: doPrepareEmail,
    status,
    data: response,
  } = useMutation<PrepareEmailResponse, Error, PrepareEmailRequest>(prepareEmail);

  const {
    mutate: doSendEmail,
  } = useMutation<PrepareEmailResponse, Error, void>(sendEmail);

  const [displayed, setDisplayed] = useState<PrepareEmailResponse>();

  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (status === "idle") {
      doPrepareEmail({ createAttachments: true });
    } else if (status === "loading") {
      //loading
    } else {
      setDisplayed(response);
      if (!response || isEmailStillBeingPrepared(response)) {
        const timer = setTimeout(() => {
          doPrepareEmail({ createAttachments: false });
        }, 5 * 1000);
        refreshTimerRef.current = timer;
      } else {
      }
    }
  }, [status, response, doPrepareEmail]);

  const onSubmit = () => {
      doSendEmail();
      refetch();
      setSnackbarState({
          message: formatMessage({ defaultMessage: "Sending email in progress..." }),
          autoHideDuration: 3000,
          action: <Link sx={{color: theme.palette.primary.contrastText}} href="/alarm/exports/sent-emails"><FormattedMessage defaultMessage="View"/></Link>
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <FormDialog
            title={formatMessage({
              defaultMessage: "Prepare email",
            })}
            open={open}
            confirmDisabled={!displayed || !displayed.ready}
            warning={true}
            confirmLabel={formatMessage({defaultMessage: "Send"})}
            onDialogAction={(_event, action) => {
              if (action === 'confirm') {
                  handleSubmit();
              }
              onFormClose();
            }}
          >
            <form>
              {displayed && (
                <>
                  <List dense disablePadding>
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormattedMessage defaultMessage="Recipients" />
                        }
                        secondary={displayed.recipients.map((r) => (
                          <Typography key={r.email}>
                            {r.name} ({r.email})
                          </Typography>
                        ))}
                      />
                    </ListItem>
                    {displayed["bcc-recipients"] &&
                      displayed["bcc-recipients"].length > 0 && (
                        <ListItem>
                          <ListItemText
                            primary={
                              <FormattedMessage defaultMessage="BCC recipients" />
                            }
                            secondary={displayed["bcc-recipients"].map((r) => (
                              <Typography key={r.email}>
                                {r.name} ({r.email})
                              </Typography>
                            ))}
                          />
                        </ListItem>
                      )}
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <FormattedMessage defaultMessage="Subject" />
                            :&nbsp;
                            <b>{displayed.subject}</b>
                          </>
                        }
                        secondary={
                          <div
                            dangerouslySetInnerHTML={{ __html: displayed.body }}
                          ></div>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormattedMessage defaultMessage="Attachments" />
                        }
                        secondary={
                          <List dense disablePadding>
                            {displayed.attachments.map((a) => (
                              <>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    {a.status === "success" && (
                                      <CheckCircleOutlineOutlinedIcon
                                        color="success"
                                        fontSize="inherit"
                                      />
                                    )}
                                    {(!a.status ||
                                      a.status === "pending" ||
                                      a.status === "in_progress") && (
                                      <CircularProgress
                                        data-testid="loading-spinner"
                                        sx={{
                                          marginTop: "4px",
                                        }}
                                        size={16} // height of Button size="large"
                                      />
                                    )}
                                    {a.status === "failure" && (
                                      <BootstrapTooltip
                                        title={formatMessage({
                                          defaultMessage: "Export failed",
                                          description: "Failed export message",
                                        })}
                                        backgroundColor={
                                          theme.palette.error.light
                                        }
                                      >
                                        <ErrorOutlineOutlinedIcon
                                          color="error"
                                          fontSize="inherit"
                                        />
                                      </BootstrapTooltip>
                                    )}
                                  </ListItemIcon>
                                  <ListItemText secondary={a.filename} />
                                </ListItem>
                              </>
                            ))}
                          </List>
                        }
                      />
                    </ListItem>
                  </List>
                </>
              )}
              {!displayed && (
                <CircularProgress
                  data-testid="loading-spinner"
                  sx={{
                    marginTop: "4rem",
                  }}
                  size={42} // height of Button size="large"
                />
              )}
            </form>
          </FormDialog>
        );
      }}
    </Form>
  );
};

export default EmailTemplateSendDialog;

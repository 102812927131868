import { Filters, useGetAll } from "@mb-pro-ui/utils";
import { Link, useTheme, TableRowProps, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Cell, Column, Row } from "react-table";

import { Report, User } from "@mb-pro-ui/utils/types/admin";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingIcon from '@mui/icons-material/Pending';

import moment from "moment";
import { EnhancedTable } from "@mb-pro-ui/components/table";

const RowAction = (row: Row<Report>, _url: string) => {
  const { formatMessage } = useIntl();

  if (row.original.result) {
    return (
      <>
        <Link href={row.original.result}>
          <FileDownloadOutlinedIcon fontSize="inherit" />
          {formatMessage({
            defaultMessage: "Download",
          })}
        </Link>
      </>
    );
  }

  return null;
};

interface ReportGroup extends Report {
  groupName: string;
  count: number;
}
const ReportTableRowProps = (row: Row<Report>): Partial<TableRowProps> => {
  const g = row.original as ReportGroup;
  if (g && g.groupName) {
    return { selected: true, style: { fontSizeAdjust: 1.5 } };
  } else {
    return {};
  }
};

export interface ReportQueryRef {
    refetch?: () => void;
}

export interface ReportTableProps {
    prefix?: JSX.Element;
    filter?: Filters;
    queryRef?: React.MutableRefObject<ReportQueryRef | undefined>;
}

const ReportTable = (props:ReportTableProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { data: reports = [], status: queryStatus, refetch } = useGetAll<Report>(
    "admin/reports",
    {
      include: {
        user: {},
      },
      page: {
        limit: 1000,
      },
      sort: ["-created"],
      filter: props.filter,
    }
  );

  if (props.queryRef) {
      props.queryRef.current = { refetch };
  }

  const groupedReports = useMemo(() => {
    const result: Report[] = [];

    let last_rg: ReportGroup;
    reports.forEach((r) => {
      const cd = moment(r.created).format(
        formatMessage({
          defaultMessage: "MM/DD/YYYY",
        })
      );

      if (last_rg?.groupName !== cd) {
        const rg: ReportGroup = {
          ...r,
          groupName: cd,
          count: 0,
          description: null,
          user: null,
          status: null,
          result: null,
          "report-type": null,
        };
        result.push(rg);
        last_rg = rg;
      }
      last_rg.count++;
      result.push(r);
    });

    return result;
  }, [reports, formatMessage]);

  const columns = useMemo(
    (): Column<Report>[] => [
      {
        id: "name",
        width: 400,
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (report) => report.description,
        Cell: ({ row, value }: Cell<Report>) => {
          const group = row.original as ReportGroup;
          if (group?.groupName) {
            return (
              <b>
                {group.groupName} ({group.count})
              </b>
            );
          }

          return (
            <ListItem>
              <ListItemIcon sx={{minWidth: "20px"}}>
              {row.original.description &&
                row.original.status === "success" && (
                  <CheckCircleOutlineOutlinedIcon
                    color="success"
                    fontSize="inherit"
                  />
                )}
              {row.original.description &&
                (row.original.status === "pending" || row.original.status === "in_progress") && (
                  <PendingIcon
                    color="primary"
                    fontSize="inherit"
                  />
                )}
              {row.original.description && row.original.status === "failure" && (
                <BootstrapTooltip
                  title={formatMessage({
                    defaultMessage: "Export failed",
                    description: "Failed export message",
                  })}
                  backgroundColor={theme.palette.error.light}
                >
                  <ErrorOutlineOutlinedIcon color="error" fontSize="inherit" />
                </BootstrapTooltip>
              )}
              </ListItemIcon>
              <ListItemText>{value}</ListItemText>
            </ListItem>
          );
        },
      },
      {
        id: "operator",
        Header: formatMessage({
          defaultMessage: "Operator",
        }),
        accessor: (report) => {
          if (!report.user) return "";
          const user = report.user as User;          
          if (user) {
            return user.username
          }          
          return String(report.user);
        },
        minWidth: 60,
        maxWidth: 150,
      },
      {
        id: "report-created",
        Header: formatMessage({
          defaultMessage: "Report created",
        }),
        accessor: (report) =>
          report.description
            ? moment(report.created).format(
                formatMessage({
                  defaultMessage: "MM/DD/YYYY HH:mm",
                })
              )
            : "",
        minWidth: 60,
        maxWidth: 120,
      },
    ],
    [formatMessage, theme]
  );

  return (
    <EnhancedTable
      data={groupedReports}
      columns={columns}
      queryStatus={queryStatus}
      prefix={props.prefix}
      action={RowAction}
      actionCellWidth={200}
      getRowProps={ReportTableRowProps}
    />
  );
};

export default ReportTable;

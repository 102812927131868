import { FieldState, FieldValidator } from "final-form";
import { ReactNode, useMemo } from "react";

export function isEqual(a: unknown, b: unknown): boolean {
  if ((a ?? "") === (b ?? "")) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return arrayIsEqual(a, b);
  }

  if (typeof a === "object" && typeof b === "object" && a && b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length === bKeys.length) {
      for (const key of aKeys) {
        if (!isEqual((a as any)[key], (b as any)[key])) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

function arrayIsEqual(a: any[], b: any[]): boolean {
  return (
    a === b ||
    (Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((item, i) => isEqual(item, b[i])))
  );
}

export const useComposedValidate = <T = unknown>(
  required: boolean | undefined,
  requiredError: ReactNode,
  validate: FieldValidator<T> | undefined
) =>
  useMemo(() => {
    if (!required) {
      return validate;
    }

    return (value: T, allValues: object, meta?: FieldState<T>) =>
      !!value || (value as unknown) === 0 ? validate?.(value, allValues, meta) : requiredError;
  }, [validate, required, requiredError]);

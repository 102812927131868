import { useBatchedChanges } from "@mb-pro-ui/utils";
import { ModalFormProps } from "../types";
import { useIntl } from "react-intl";
import { TypeCategories } from "@mb-pro-ui/utils/types";

const useUseBatchedChanges = (
  category: TypeCategories,
  {
    refetch,
    setSnackbarState,
    onSuccess,
  }: Pick<ModalFormProps, "refetch" | "setSnackbarState" | "onSuccess">
) => {
  const { formatMessage } = useIntl();

  return useBatchedChanges(category, {
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully saved" }),
      });
      refetch();
      onSuccess?.();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Failed to save" }),
        error: true,
      });
    },
  });
};

export default useUseBatchedChanges;

import { useCallback } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { useApi, useBackendSchema } from "../query";
import { ID, Type } from "../types";
import { JsonapiError } from "./types";

type UseDeleteOptions<T> = UseMutationOptions<T, JsonapiError, ID>;

export default function useDelete<T extends string>(
  fullType: Type<T>,
  options?: UseDeleteOptions<T>
) {
  const api = useApi();
  const schema = useBackendSchema(fullType);

  const deleteItem = useCallback(
    async (id: ID) => {
      if (!schema) {
        throw new Error("Backend schemas are not available");
      }

      return api<T>(`/${fullType}/${id}`, {
        method: "delete",
        headers: {
          Accept: "application/vnd.api+json, application/json",
        },
      });
    },
    [api, fullType, schema]
  );

  return useMutation<T, JsonapiError, ID>(deleteItem, options);
}

import { useGetAll } from "@mb-pro-ui/utils";
import { Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { EmailTemplate, RegularReportInterval } from "@mb-pro-ui/utils/types/alarm";
import { AllEntities, Snackbar } from "../settings/utils";
import EmailTemplateCreateModal from "./EmailTemplateCreateModal";
import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from "@mui/system";
import EmailTemplateSendDialog from "./EmailTemplateSendDialog";
import { SnackbarState } from "../settings/types";

function timingLabel(t:EmailTemplate) {
    const interval = t["max-interval"] as RegularReportInterval;
    if (interval) {
      if (t["max-offset"] && t["max-offset"] !== '00:00:00') {
        return `${interval["localized-description"]} (${t["max-offset"]})`
      } else {
        return interval["localized-description"];
      }
    } else {
        return t["max-offset"];
    }
}

const AllEmailTemplates = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [prepareEmail, setPrepareEmail] = useState<EmailTemplate>();

  const queryResult = useGetAll<EmailTemplate>("alarm/email-templates", {
    page: {
      limit: 10000,
    },
    sort: ["-active", "id"],
    fields: {
        "email-templates": ["+max-interval", "+max-offset","+last-sent-ts", "+last-sent-status", "+last-interval-end"],
    },
    include: {
        "customer-group": {},
        "max-interval": {}
    }
  });

  const columns = useMemo(
    (): Column<EmailTemplate>[] => [
      {
        id: "description",
        width: 300,
        Header: formatMessage({
          defaultMessage: "Description",
        }),
        accessor: (template) => template.description,
        Cell: ({ row, value }: Cell<EmailTemplate>) => {
          const t = row.original;          
          let sx = t.active ? undefined : {color: theme.palette.grey[500], fontStyle: "italic"};
          return <Typography sx={sx}>{value}</Typography>
        },
      },
      {
        id: "timing",
        width: 140,
        Header: formatMessage({
          defaultMessage: "Timing",
        }),
        accessor: timingLabel,
      },
      {
        id: "last-interval-end",
        width: 150,
        Header: formatMessage({
          defaultMessage: "Last interval",
        }),
        accessor: (template) => {
          const t = template["last-interval-end"];
          if (t) {
            return moment(t).format(
              formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
            );
          } else {
            return "";
          }
        },        
        Cell: ({ row, value }: Cell<EmailTemplate>) => {
          const t = row.original;          
          const end = t["last-interval-end"];
          const sent = t["last-sent-ts"];
          let sx = t.active ? ((end && (!sent || sent < end)) ? {color: "warning.main"} : undefined) : 
            {color: theme.palette.grey[500], fontStyle: "italic"};

          return <Typography sx={sx}>{value}</Typography>
        },
      },
      {
        id: "state",
        width: 200,
        Header: formatMessage({
          defaultMessage: "Last delivery",
        }),
        accessor: (template) => {

          //"pending" | "in_progress" | "success" | "failure";
          const s = template["last-sent-status"];
          let ss;
          if (s === 'pending') {
            ss = formatMessage({defaultMessage: "Pending"});
          } else if (s === 'in_progress') {
            ss = formatMessage({defaultMessage: "In progress"});
          } else if (s === 'success') {
            ss = formatMessage({defaultMessage: "Success"});
          } else if (s === 'failure') {
            ss = formatMessage({defaultMessage: "Failure"});
          } else {
            ss = "";
          }

          const t = template["last-sent-ts"];
          if (s && t) {
            const ts = moment(t).format(
              formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
            );

            return `${ss} ${ts}`;
          } else {
            return "";
          }
        },
      },

      {
        id: "primary-actions",
        width: 80,
        Cell: ({ row }: Cell<EmailTemplate>) => {
          const color = "warning.main";
          return (<Button
          disabled={!row.original.active}
          size="small"
          aria-label="close"
          color="warning"
          onClick={() => {setPrepareEmail(row.original)}}
          sx={{color}}
          style={{ height: "24px", borderRadius: 0 }}
          startIcon={<SendIcon fontSize="small" />}
        ><FormattedMessage defaultMessage="Send" description="Email template" /></Button>);         
        },
      },
    ],
    [theme, formatMessage]
  );

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  return (
    <>
    <AllEntities
      columns={columns}
      title={<FormattedMessage defaultMessage="Email templates" />}
      queryResult={queryResult}            
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <EmailTemplateCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
    {prepareEmail && 
    <EmailTemplateSendDialog template={prepareEmail} open={!!prepareEmail} 
      onFormClose={() => {setPrepareEmail(undefined)}}
      refetch={queryResult.refetch}
      setSnackbarState={setSnackbarState}
    />}
          
    <Snackbar onClose={snackbarOnClose} state={snackbarState} />

    </>
  );
};

export default AllEmailTemplates;

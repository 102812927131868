import { SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import without from "lodash/without";

import {
  Customer,
  Intervention as _Intervention,
} from "@mb-pro-ui/utils/types/alarm";
import { useGetAll, Filters } from "@mb-pro-ui/utils";

import { IconButton, Link, Snackbar, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

import useImport from "../../utils/useImport";

const HeaderActions = ({ filters }: { filters: Filters }) => {
  const { formatMessage } = useIntl();

  const { data: customers } = useGetAll<Customer>("alarm/customers");

  const [
    createReport,
    snackBarMessage,
    setSnackBarMessage,
    reportURL,
    setReportURL,
  ] = useImport({ formatMessage });

  let selectedCustomerIds: string[] | undefined;
  let selectedCustomerAccounts: string[] | undefined;

  if (Array.isArray(filters?.customer?.in)) {
    selectedCustomerIds = filters.customer.in;
  } else if (Array.isArray(filters?.customer?.nin)) {
    selectedCustomerIds =
      filters.customer.nin &&
      without(
        customers?.map((customer) => customer.id),
        ...filters.customer.nin
      );
  }

  if (selectedCustomerIds !== undefined) {
    const ids = selectedCustomerIds;
    selectedCustomerAccounts = customers
      ?.filter((customer) => ids.indexOf(customer.id) !== -1)
      .map((customer) => customer.account);
  }

  const handleSnackbarClose = (
    event: Event | SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "timeout") {
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Please try again",
          description: "Interventions table export try agin message",
        })
      );
    } else {
      setSnackBarMessage(null);
    }
  };

  const action = (
    <>
      {reportURL && (
        <Link
          href={reportURL}
          target="_blank"
          rel="noopener"
          onClick={() => {
            setSnackBarMessage(null);
          }}
          sx={{ mr: 1 }}
        >
          {formatMessage({ defaultMessage: "Download" })}
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const allCustomersString = formatMessage({
    defaultMessage: "All Customers",
    description:
      "Interventions table interventions-export report description all customers",
  });

  return (
    <>
      <IconButton
        onClick={() => {
          setReportURL(undefined);
          createReport({
            description: formatMessage(
              {
                defaultMessage: "Intervention query (Customers: {customers})",
                description:
                  "Interventions table interventions-export report description",
              },
              {
                customers:
                  selectedCustomerAccounts?.join(", ") ?? allCustomersString,
              }
            ),
            "report-type": "interventions-export",
            options: {
              filters: filters,
            },
          });
          setSnackBarMessage(
            formatMessage({
              defaultMessage: "Exporting...",
              description:
                "Interventions table currently exporting snackbar message",
            })
          );
        }}
        size="small"
        sx={{
          color: "primary.contrastText",
        }}
      >
        <Tooltip
          title={formatMessage({
            defaultMessage: "Export",
          })}
          placement="bottom-start"
        >
          <DownloadIcon fontSize="inherit" />
        </Tooltip>
      </IconButton>
      <Snackbar
        open={!!snackBarMessage}
        onClose={handleSnackbarClose}
        autoHideDuration={reportURL ? null : 10000}
        message={snackBarMessage}
        action={action}
      />
    </>
  );
};

export default HeaderActions;

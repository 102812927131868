import { Fragment } from "react";
import { useIntl } from "react-intl";
import DOMPurify from "dompurify";
import { Typography, Box, Divider } from "@mui/material";

import { Intervention } from "./types";
import Widget from "../utils/Widget";

export const RemarksWidget = ({
  intervention,
}: {
  intervention?: Intervention;
}) => {
  const { formatMessage } = useIntl();
  const isOpen = intervention?.["close-time"] === null;
  const remarks = intervention?.customer?.remarks ?? "";

  const structuredFields = intervention?.customer?.custom;
  const customFields = intervention?.customer?.["custom-fields"]?.fields;

  return (
    <Widget
      title={formatMessage({
        defaultMessage: "Customer remarks",
        description: "Intervention page Notes widget title",
      })}
      postfix={
        !isOpen && (
          <Typography sx={{ color: "primary.contrastText" }}>
            {formatMessage({ defaultMessage: "Closed intervention" })}
          </Typography>
        )
      }
      placeholderMessage={formatMessage({
        defaultMessage: "No Remarks",
        description: "Intervention page remarks widget empty message",
      })}
      sx={{ height: "100%" }}
    >
      {structuredFields || remarks ? (
        <Box sx={{ overflow: "auto" }}>
          {customFields && (
            <Box padding={2}>
              {customFields.map(({ name }, i) => (
                <Fragment key={`${name}${i}`}>
                  <Typography sx={{ color: "primary.light" }}>
                    {name}
                  </Typography>
                  <Typography sx={{ marginBottom: "8px" }}>
                    {structuredFields?.[name] ?? "-"}
                  </Typography>
                </Fragment>
              ))}
            </Box>
          )}
          {structuredFields && remarks ? <Divider /> : null}
          {remarks && (
            <Box padding={2}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(remarks),
                }}
              />
            </Box>
          )}
        </Box>
      ) : null}
    </Widget>
  );
};

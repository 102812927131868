import { styled, TableCell, TableRow, Typography } from "@mui/material";
import { memo } from "react";
import { Row, TableInstance, TableRowProps } from "react-table";
import { areEqual, ListChildComponentProps } from "react-window";
import getCustomColumnProps from "./getCustomColumnProps";

export const BodyCellContentWrapper = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1.5, 0, 2),
}));

const TableBodyCell = styled(TableCell)({
  padding: 0,
  color: "inherit",
  borderBottom: 0,
});

const TableBodyRow = styled(TableRow)(({ theme }) => ({
  padding: 0,
  cursor: "default",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
  outline: `1px solid ${theme.palette.divider}`,
})) as typeof TableRow;

export interface TableRowContext<Type extends object> {
  rows: Row<Type>[];
  prepareRow: TableInstance<Type>["prepareRow"];
  getCustomRowProps?: (row: Row<Type>) => Omit<Partial<TableRowProps>, "key">;
  onRowClick?: (row: Row<Type>) => void;
}

const RenderTableBodyRow = <Type extends object>({
  index,
  style,
  data: { rows, prepareRow, getCustomRowProps, onRowClick },
}: ListChildComponentProps<TableRowContext<Type>>) => {
  if (index > rows.length - 1) {
    return <TableRow id="extraEmptyRow" component="div" />;
  }

  const row = rows[index];
  prepareRow(row);

  return (
    <TableBodyRow
      {...row.getRowProps([{ style }, getCustomRowProps?.(row) ?? {}])}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      component="div"
    >
      {row.cells.map((cell) => (
        <TableBodyCell
          {...cell.getCellProps(getCustomColumnProps(cell.column))} // includes a `key` prop
          component="div"
        >
          <BodyCellContentWrapper noWrap overflow={cell.column.id === "fix" ? "visible" : "hidden"}>
            {cell.render("Cell")}
          </BodyCellContentWrapper>
        </TableBodyCell>
      ))}
    </TableBodyRow>
  );
};

export default memo(RenderTableBodyRow, areEqual) as typeof RenderTableBodyRow;

import { useIntl } from "react-intl";
import { FilterViewProps } from "../types";
import TextFilter from "./base/TextFilter";

const LogTextFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({
    defaultMessage: "Log Search",
    description: "Interventions table log text filter label",
  });
  return (
    <TextFilter
      {...props}
      label={label}
      helperText={formatMessage({
        defaultMessage: "You can enter multiple terms separated by commas.",
        description: "Log text filter helper text",
      })}
    />
  );
};

export default LogTextFilter;

import { SvgIcon, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { useParams, Redirect } from "react-router-dom";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { Page, SubPage } from "@mb-pro-ui/components";
import { SounderType as _SounderType } from "@mb-pro-ui/utils/types/alarm";
import { Form } from "react-final-form";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { TextField } from "@mb-pro-ui/components/form";

type SounderType = _SounderType;

interface FormValues extends Pick<SounderType, "id" | "description"> {}

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Sounder type editor"
        description="System settings page sidebar sounder type editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

const postLoadFormat = ({ id, description }: SounderType): FormValues => ({
  id,
  description,
});

const SounderTypeUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: sounderType,
    refetch,
    isLoading,
    isError,
  } = useGetOne<SounderType>("alarm/sounder-types", id);

  const { waitFor } = useBackendSchemas();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    setSnackbarState,
    refetch,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/sounder-types",
    {
      refetch,
      setSnackbarState,
    }
  );

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };
  const descr = sounderType?.description;
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/sounder-types/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff(
        "sounder-types",
        sounderType as unknown as Record<string, unknown>,
        values,
        { schemas }
      ),
    ]);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  if (isError) {
    return <Redirect to="/alarm/settings/equipment-types" />;
  }

  return (
    <Page
      backLink="/alarm/settings/sounder-types"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={sounderType && postLoadFormat(sounderType)}
          render={({ handleSubmit, submitting, pristine, form }) => {
            return (
              <SubPage
                prefix={prefix}
                sx={{ minWidth: "500px" }}
                innerSx={{ height: "auto" }}
              >
                <StyledForm onSubmit={handleSubmit}>
                  <TextField
                    name={`description`}
                    label={formatMessage({ defaultMessage: "Name" })}
                    required
                    requiredError={formatMessage({
                      defaultMessage: "Required",
                    })}
                    disabled={submitting || isLoading || isDeleting}
                  />
                  <UpdateFormActions
                    onSave={handleSubmit}
                    onReset={() => form.reset()}
                    onDelete={handleDeleteClick}
                    disabledDelete={submitting}
                    disabledReset={submitting || pristine}
                    disabledSave={submitting || pristine}
                    isLoading={isLoading}
                    isDeleting={isDeleting}
                  />
                </StyledForm>
              </SubPage>
            );
          }}
        />
      </Box>
      <DeleteDialog
        deleteResource={del}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        resourceId={id}
        resourceName={descr ?? ""}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default SounderTypeUpdatePage;

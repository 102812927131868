import { FilterViewProps } from "../../types";
import {
  BoxProps,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  FormGroup,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { utils } from "@mb-pro-ui/utils";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { Box } from "@mui/system";

interface CheckBoxState {
  [key: string]: boolean;
}

interface StyledBoxProps extends BoxProps {
  emphasize: boolean;
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "emphasize",
})<StyledBoxProps>(({ theme, emphasize }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: "100%",
  height: "100%",
  position: "absolute",
  borderRadius: "4px",
  left: 0,
  top: 0,
  "&:hover": {
    transition: theme.transitions.create(["border"]),
    borderColor: emphasize
      ? theme.palette.info.main
      : theme.palette.primary.main,
  },
  ...(emphasize && {
    transition: theme.transitions.create(["border"]),
    borderColor: theme.palette.info.main,
    borderWidth: "2px",
    "&:hover fieldset": {
      borderColor: theme.palette.info.main,
    },
    "&.Mui-focused fieldset": {
      transition: theme.transitions.create(["border"]),
      borderColor: theme.palette.info.main,
    },
  }),
}));

const BooleanFilter = ({
  setFilter,
  filterId,
  filterValue,
  setUrlSearchParam,
  urlSearchParam,
  options,
}: FilterViewProps & { options: [string, string] }) => {
  const [state, setState] = useState<CheckBoxState>({
    [options[0]]: urlSearchParam === "true" ? true : false,
    [options[1]]: urlSearchParam === "false" ? true : false,
  });

  const debouncedState = utils.useDebounce(state, 500);
  const first = debouncedState?.[options[0]];
  const second = debouncedState?.[options[1]];
  const emphasize = !!filterValue;

  useEffect(() => {
    if (first && second) {
      setState((prev) => {
        Object.keys(prev).forEach((key) => (prev[key] = false));
        return { ...prev };
      });
      setFilter(filterId, null);
      setUrlSearchParam(filterId, "");
      return;
    }

    if (first) {
      setFilter(filterId, "true");
      setUrlSearchParam(filterId, "true");
      return;
    }

    if (second) {
      setFilter(filterId, "false");
      setUrlSearchParam(filterId, "false");
      return;
    }

    setFilter(filterId, null);
    setUrlSearchParam(filterId, "");
  }, [setFilter, filterId, debouncedState, first, second, setUrlSearchParam]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const checked = event.target.name;
      const unchecked = options[0] === checked ? options[1] : options[0];
      setState({ [checked]: true, [unchecked]: false });
    } else {
      setState({ [options[0]]: false, [options[1]]: false });
    }
  };

  return (
    <FormGroup
      sx={{
        position: "relative",
        flexDirection: "row",
        justifyContent: "space-between",
        alignSelf: "center",
        m: 1,
        px: "14px",
        py: "7px",
        minWidth: "200px",
        backgroundColor: (theme) => theme.palette.common.white,
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option}
          sx={{
            color: (theme) => theme.palette.primary.main,
            [`& .${checkboxClasses.root}`]: { p: 0, mx: 1 },
            "&:hover ~ #styledBox": {
              borderColor: emphasize ? "info.main" : "primary.main",
            },
          }}
          control={
            <Checkbox
              sx={{ px: 1 }}
              checked={state[option] ?? false}
              name={option}
              onChange={handleChange}
            />
          }
          label={option}
        />
      ))}
      <StyledBox id="styledBox" emphasize={emphasize} />
      <LibraryAddCheckIcon color="primary" />
    </FormGroup>
  );
};

export default BooleanFilter;

import { Page, SubPage } from "@mb-pro-ui/components";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { Box, SvgIcon, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect, useParams } from "react-router";
import { useLocale } from "../../../locales/LocaleProvider";

import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { Lang } from "../../types";
import { localDescr } from "../../../utils/LocaleUtils";
import { Form } from "react-final-form";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import {
  Event as _Event,
  Category as _Category,
} from "@mb-pro-ui/utils/types/alarm";
import { TextField } from "@mb-pro-ui/components/form";
import EventCategoryAutocomplete from "./EventCategoryAutocomplete";

type Event = Omit<_Event, "category"> & {
  category: _Category;
};

interface FormValues extends Pick<Event, "id" | "category" | "descr"> {}

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Event editor"
        description="System settings page sidebar event category editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

const postLoadFormat = (values: Event): FormValues => ({
  id: values.id,
  category: values.category,
  descr: values.descr,
});

const preSaveFormat = (values: FormValues) => ({
  ...values,
  category: values.category.id,
});

const EventTypeUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: event,
    refetch,
    isLoading,
    isError,
  } = useGetOne<Event>("alarm/events", id, {
    include: { category: {} },
  });

  const { locale } = useLocale();
  const { waitFor } = useBackendSchemas();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    setSnackbarState,
    refetch,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete("alarm/events", {
    refetch,
    setSnackbarState,
  });

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const descr = event?.descr ? localDescr(event?.descr, Lang[locale]) : "";
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/events/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbNameMap, breadcrumbName]
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([...diff("events", event, preSaveFormat(values), { schemas })]);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  if (isError) {
    return <Redirect to="/alarm/settings/events" />;
  }

  return (
    <Page
      backLink="/alarm/settings/events"
      sidebarOptions={sidebarOptions}
      breadcrumbs
      breadcrumbNameMap={finalBreadcrumbNameMap}
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={event && postLoadFormat(event)}
          render={({ handleSubmit, submitting, pristine, form }) => {
            return (
              <SubPage
                prefix={prefix}
                sx={{ minWidth: "500px" }}
                innerSx={{ height: "auto" }}
              >
                <StyledForm onSubmit={handleSubmit}>
                  <EventCategoryAutocomplete
                    disabled={submitting || isLoading}
                    required
                  />
                  <TextField
                    name={`descr.[${Lang[locale]}]`}
                    label={formatMessage(
                      { defaultMessage: "Name ({locale})" },
                      { locale }
                    )}
                    disabled={submitting || isLoading || isDeleting}
                    parse={(v) => v}
                  />
                  <UpdateFormActions
                    onSave={handleSubmit}
                    onReset={() => form.reset()}
                    onDelete={handleDeleteClick}
                    disabledDelete={submitting}
                    disabledReset={submitting || pristine}
                    disabledSave={submitting || pristine}
                    isLoading={isLoading}
                    isDeleting={isDeleting}
                  />
                </StyledForm>
              </SubPage>
            );
          }}
        />
      </Box>
      <DeleteDialog
        deleteResource={del}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        resourceId={id}
        resourceName={formatMessage(
          { defaultMessage: "{name}" },
          { name: event?.descr ? localDescr(event.descr, Lang[locale]) : "" }
        )}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default EventTypeUpdatePage;

import { styled } from "@mui/system";
import { Typography, TypographyProps } from "@mui/material";

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "blink",
})<TypographyProps<"span", { component?: "span" }> & { blink?: boolean }>(
  ({ theme, color, blink }) => {
    const _color = color || theme.palette.primary.main;
    return {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: _color,
      "@keyframes blinkStyledText": {
        "0%, 100%": {
          color: "inherit",
        },
        "50%": {
          color: _color,
        },
      },
      ...(blink && {
        animation: "blinkLinkStyledText 1s ease-in-out infinite",
      }),
    };
  }
);

export default StyledText;

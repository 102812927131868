import { Category as _Category, Sound } from "@mb-pro-ui/utils/types/alarm";
import { Lang } from "../../types";
import { Select } from "@mb-pro-ui/components/form";
import AudioButton from "../../audio/AudioButton";
import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material";
import { useLocale } from "../../../locales/LocaleProvider";

const SoundSelect = ({
  selectedId,
}: {
  selectedId: string | undefined | null;
}) => {
  const { formatMessage } = useIntl();
  const { data: sounds } = useGetAll<Sound>("alarm/sounds");
  const { locale } = useLocale();
  const theme = useTheme();

  return (
    <>
      <Select<"id", Sound>
        name={`sound`}
        label={formatMessage({ defaultMessage: "Sound" })}
        items={
          sounds
            ? [
                {
                  // provide a fake id to generate a valid key prop
                  id: "none",
                  descr: {
                    "en-gb": "None",
                    "hu-hu": "Egyik sem",
                    "de-de": "Keiner",
                    "sk-sk": "žiadne",
                  },
                } as unknown as Sound,
                ...sounds,
              ]
            : []
        }
        parse={(value: string) =>
          //if fake id (must provide a valid one) then Form should remove sound property
          value === "none" ? (null as unknown as string) : value
        }
        renderOption={({ option: sound }) =>
          sound.descr[Lang[locale]] ?? sound.id
        }
        valueKey={"id"}
      />
      <AudioButton
        url={
          selectedId &&
          sounds &&
          sounds.find((sound) => sound.id === selectedId)?.data
        }
        sx={{
          minWidth: "60px",
          height: "unset",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "3px 3px 0px 0px",
          margin: "8px",
        }}
      />
    </>
  );
};

export default SoundSelect;

import { Typography, Checkbox } from "@mui/material";

import { Row, Column, Cell } from "react-table";

import {
  Autocomplete,
  RichEditor,
  Checkbox as MBPCheckbox,
} from "@mb-pro-ui/components/form";

import { useGetAll } from "@mb-pro-ui/utils";

import Widget from "../utils/Widget";

import { Event, Notification, SMSConfig, EmailConfig } from "./types";
import { Box, useTheme, styled } from "@mui/system";
import { FormSpy } from "react-final-form";
import { StyledTextField } from "../form/StyledComponents";
import { NotifiablePerson } from "@mb-pro-ui/utils/types/alarm";
import { useRouteMatch } from "react-router-dom";
import { useLocale } from "../../locales/LocaleProvider";
import { useIntl } from "react-intl";

import { Lang } from "../types";

import LinkButton from "../utils/LinkButton";
import { useMemo } from "react";

import SimpleFieldArray from "../form/SimpleFieldArray";
import FormTable from "../form/FormTable";
import { isEqualById } from "../utils/autocompleteUtils";

const StyledSpan = styled("span")({
  backgroundColor: "black",
  color: "white",
});

interface UrlParams {
  by: "new" | "byId" | "byNum";
  id: string;
  num: string;
}

const AutomaticNotificationsDialog = () => {
  const {
    params: { id },
  } = useRouteMatch<UrlParams>();

  const { data: events } = useGetAll<Event>("alarm/events", {
    refetchOnWindowFocus: false,
    page: { limit: 100 }, //TODO
  });

  const theme = useTheme();
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const { data: notifiablesPersons } = useGetAll<NotifiablePerson>(
    "alarm/notifiable-persons",
    {
      refetchOnWindowFocus: false,
      filter: { customer: { eq: id } },
    }
  );

  const { data: SMSConfigs } = useGetAll<SMSConfig>("alarm/sms-configs", {
    refetchOnWindowFocus: false,
  });

  const { data: emailConfigs } = useGetAll<EmailConfig>("alarm/email-configs", {
    refetchOnWindowFocus: false,
  });

  return events && notifiablesPersons && SMSConfigs && emailConfigs ? (
    <>
      <Autocomplete
        name="event-code"
        label={formatMessage({
          defaultMessage: "Event code",
        })}
        options={events.map((event) => event.code)}
        fullWidth
        getOptionLabel={(option) =>
          `${option} - ${
            events.find((e) => e.code === option)?.descr[Lang[locale]] ?? ""
          }`
        }
        isEqual={isEqualById}
        sx={{ width: "100%" }}
        required
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <StyledTextField
          name="partition"
          label={formatMessage({ defaultMessage: "Partition" })}
          sx={{ marginLeft: 0 }}
          parse={(x) => x}
        />
        <StyledTextField
          name="zone"
          label={formatMessage({ defaultMessage: "Zone" })}
          parse={(x) => x}
        />
        <MBPCheckbox
          name="on-storno"
          label={formatMessage({
            defaultMessage: "On cancellation",
          })}
        />
      </Box>
      <FormSpy subscription={{ values: true }}>
        {({ values }) =>
          values["sms"] ? (
            <MBPCheckbox
              name="bill-to-customer"
              label={formatMessage({
                defaultMessage: "Bill to customer",
              })}
              sx={{ marginLeft: "-16px" }}
            />
          ) : null
        }
      </FormSpy>
      <Autocomplete
        name="sms"
        label={formatMessage({
          defaultMessage: "SMS service",
        })}
        options={SMSConfigs}
        fullWidth
        getOptionLabel={(option) => option.name ?? ""}
        isEqual={isEqualById}
        sx={{
          width: "100%",
        }}
      />
      <FormSpy>
        {({ values }) =>
          values["sms"] ? (
            <Box sx={{ marginBottom: "16px" }}>
              <Box sx={{ marginTop: "8px", marginBottom: "8px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "60%",
                  }}
                >
                  <Typography>
                    {`${formatMessage({
                      defaultMessage: "Global rate limit",
                    })}:`}
                  </Typography>
                  <Typography sx={{ marginLeft: "8px" }}>
                    100 01:00:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "60%",
                  }}
                >
                  <Typography>
                    {`${formatMessage({
                      defaultMessage: "Per-customer rate limit",
                    })}: `}
                  </Typography>
                  <Typography sx={{ marginLeft: "8px" }}>
                    20 24:00:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "60%",
                  }}
                >
                  <Typography>
                    {`${formatMessage({
                      defaultMessage: "Source address",
                    })}: 
          `}
                  </Typography>
                  <Typography sx={{ marginLeft: "8px" }}>
                    +36209000859
                  </Typography>
                </Box>
              </Box>
              <Autocomplete
                name="sms-recipients"
                label={formatMessage({
                  defaultMessage: "Sms recipients",
                })}
                options={notifiablesPersons?.filter((p) => p.mobile) ?? []}
                getOptionLabel={(option) => `${option.name} <${option.mobile}>`}
                isEqual={isEqualById}
                fullWidth
                multiple
              />
              <RichEditor
                name="sms-message"
                toolbarHidden={true}
                editorStyle={{
                  minHeight: "50px",
                  backgroundColor: theme.palette.grey[300],
                }}
              />
            </Box>
          ) : null
        }
      </FormSpy>
      <Autocomplete
        name="email"
        label={formatMessage({
          defaultMessage: "Email service",
        })}
        options={emailConfigs}
        fullWidth
        getOptionLabel={(option) => option.name ?? ""}
        isEqual={isEqualById}
        sx={{
          width: "100%",
        }}
      />
      <FormSpy>
        {({ values }) =>
          values["email"] ? (
            <Box sx={{ marginBottom: "16px" }}>
              <Autocomplete
                name="email-recipients"
                label={formatMessage({
                  defaultMessage: "Email recipients",
                })}
                options={notifiablesPersons?.filter((p) => p.email) ?? []}
                getOptionLabel={(option) => `${option.name} <${option.email}>`}
                isEqual={isEqualById}
                fullWidth
                multiple
                sx={{ marginBottom: "8px" }}
              />
              <RichEditor
                name="email-message"
                toolbar={{
                  options: ["inline", "colorPicker", "fontSize", "remove"],
                  inline: {
                    options: ["bold", "italic"],
                  },
                }}
                editorStyle={{
                  minHeight: "50px",
                  backgroundColor: theme.palette.grey[300],
                }}
              />
            </Box>
          ) : null
        }
      </FormSpy>
      <div>
        <Typography>
          {formatMessage({
            defaultMessage:
              "Template parameters in addition to the usual event description parameters:",
          })}
        </Typography>
        <Box>
          <Typography>
            <StyledSpan>{"%{account}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "customer identifier",
            })}
          </Typography>
          <Typography>
            <StyledSpan>{"%{name}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "customer name",
            })}
          </Typography>
          <Typography>
            <StyledSpan>{"%{address}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "customer address",
            })}
          </Typography>
          <Typography>
            <StyledSpan>{"%{unitid}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "customer Helios ID",
            })}
          </Typography>
          <Typography>
            <StyledSpan>{"%{event}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "event code",
            })}
          </Typography>
          <Typography>
            <StyledSpan>{"%{descr}"}</StyledSpan>{" "}
            {formatMessage({
              defaultMessage: "event description from the code table",
            })}
          </Typography>
        </Box>
      </div>
    </>
  ) : null;
};

const DialogRenderer = () => {
  const { formatMessage } = useIntl();

  const { data: autoNotifications, isLoading: areAutoNotificationsLoading } =
    useGetAll<any>("alarm/auto-notifications", {
      refetchOnWindowFocus: false,
    });

  if (areAutoNotificationsLoading) {
    return <Typography>Loading...</Typography>;
  }

  return autoNotifications ? (
    <>
      <StyledTextField
        name="name"
        label={formatMessage({ defaultMessage: "Name" })}
        parse={(x) => x}
        readOnly={true}
      />
      <Autocomplete
        name="notification"
        label={formatMessage({ defaultMessage: "Notification template" })}
        fullWidth
        options={autoNotifications}
        getOptionLabel={(option) => option.name ?? "-"}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
      />
    </>
  ) : (
    <></>
  );
};

const ItemRenderer = (fieldValue: any) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography
        sx={{
          color: "primary.light",
          fontSize: "0.8rem",
        }}
      >
        {formatMessage({
          defaultMessage: "Name",
        })}
      </Typography>
      <Typography
        sx={{
          marginBottom: "2%",
        }}
      >
        {fieldValue.name ?? "-"}
      </Typography>
      <Typography
        sx={{
          color: "primary.light",
          fontSize: "0.8rem",
        }}
      >
        {formatMessage({ defaultMessage: "Notification template" })}
      </Typography>
      <Typography
        sx={{
          marginBottom: "2%",
        }}
      >
        {fieldValue.notification ? fieldValue.notification.name : "-"}
      </Typography>
    </>
  );
};

interface AutomaticNotificationFormProps {
  readOnly?: boolean;
}

const AutomaticNotificationForm = ({
  readOnly,
}: AutomaticNotificationFormProps) => {
  const action = (row: Row<Notification>, url: string) =>
    readOnly ? (
      <></>
    ) : (
      <LinkButton row={row} url={`${url}/notifications/byNum/${row.index}`} />
    );

  const { formatMessage } = useIntl();

  const localeToString = (locale: string) => {
    switch (locale) {
      case "hu_hu":
        return "Magyar";
      case "en_gb":
        return "English";
      case "de_de":
        return "Deutsch";
      case "sk_sk":
        return "Slovenčina";
    }
  };

  const columns = useMemo(
    (): Column<Notification>[] => [
      {
        id: "event-code",
        Header: formatMessage({ defaultMessage: "Event code" }),
        accessor: (p) => p["event-code"],
        width: 50,
      },
      {
        id: "partition",
        Header: formatMessage({ defaultMessage: "Partition/Zone" }),
        accessor: (p) =>
          `${p.partition ? p.partition : "-"} / ${p.zone ? p.zone : "-"}`,
        width: 60,
      },

      {
        id: "on-cancellation",
        Header: formatMessage({
          defaultMessage: "On cancellation",
          description: "Customer editor,automatic notification ",
        }),
        accessor: (p) => p["on-storno"],
        Cell: ({ value }: Cell<Notification, boolean>) => (
          <Checkbox
            checked={value}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
        width: 60,
      },
      {
        id: "sms",
        Header: formatMessage({ defaultMessage: "SMS" }),
        accessor: (p) => !!p.sms,
        Cell: ({ value }: Cell<Notification, boolean>) => (
          <Checkbox
            checked={value}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
        width: 40,
      },
      {
        id: "email",
        Header: formatMessage({ defaultMessage: "Email" }),
        accessor: (p) => !!p.email,
        Cell: ({ value }: Cell<Notification, boolean>) => (
          <Checkbox
            checked={value}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
        width: 40,
      },
    ],
    [formatMessage]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "4px",
          marginRight: "4px",
          marginTop: "4px",
          width: "calc(33.33% - 10px)",
        }}
      >
        <Widget
          title={formatMessage({
            defaultMessage: "Automatic notification",
            description:
              "Customer editor,automatic notification page 1st card title ",
          })}
          children={
            <Autocomplete
              name="notification-config.locale"
              label={formatMessage({
                defaultMessage: "Language",
              })}
              options={Object.values(Lang).map((lang) =>
                lang.replace("-", "_")
              )}
              fullWidth
              getOptionLabel={(option) => localeToString(option) ?? ""}
              sx={{
                marginLeft: "8px",
                width: "calc(100% - 16px)",
              }}
              readOnly={readOnly}
            />
          }
          sx={{ marginBottom: "8px" }}
        />
        <SimpleFieldArray
          name="notifiable-persons"
          formDialogRenderer={DialogRenderer}
          label={formatMessage({ defaultMessage: "Notification templates" })}
          itemRenderer={ItemRenderer}
          hasAdd={false}
          hasDelete={false}
          readOnly={readOnly}
        />
      </Box>
      <Box
        sx={{
          marginLeft: "4px",
          marginRight: "4px",
          marginTop: "4px",
          width: "calc(66.66% - 10px)",
          height: "100%",
        }}
      >
        <FormTable
          name="notifications"
          columns={columns}
          action={action}
          dialogNewTitle={formatMessage({
            defaultMessage: "Edit notification",
          })}
          dialogEditTitle={formatMessage({
            defaultMessage: "New notification",
          })}
          dialog={<AutomaticNotificationsDialog />}
          initialValues={{ "email-recipients": [], "sms-recipients": [] }}
        />
      </Box>
    </>
  );
};

export default AutomaticNotificationForm;

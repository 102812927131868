import { styled } from "@mui/system";

const LinkStyleText = styled("span", {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "blink",
})<{ blink?: boolean }>(({ theme, color, blink }) => {
  const _color = color || theme.palette.primary.main;
  return {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: _color,
    "&:hover": {
      cursor: "pointer",
      textDecoration: `underline ${_color}`,
      animation: "unset",
    },
    "@keyframes blinkLinkStyleText": {
      "0%, 100%": {
        color: "inherit",
      },
      "50%": {
        color: _color,
      },
    },
    ...(blink && {
      animation: "blinkLinkStyleText 1s ease-in-out infinite",
    }),
  };
});

export default LinkStyleText;

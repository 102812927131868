import { ModalFormProps } from "../types";
import { Button } from "@mb-pro-ui/components";
import { TextField } from "@mb-pro-ui/components/form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { EquipmentType as _EquipmentType } from "@mb-pro-ui/utils/types/alarm";
import { useUseCreate } from "../utils";

type FormValues = {
  description: string;
};

const EquipmentTypeCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: ModalFormProps) => {
  const { mutate: create } = useUseCreate<_EquipmentType>(
    "alarm/equipment-types",
    {
      refetch,
      setSnackbarState,
      onFormClose,
    }
  );

  const { formatMessage } = useIntl();

  const onSubmit = (values: FormValues) => {
    create(values);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create Equipment Type" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <TextField
                  name="description"
                  label={formatMessage({ defaultMessage: "Name" })}
                  required
                  requiredError={formatMessage({
                    defaultMessage: "Required",
                  })}
                  disabled={submitting}
                  highlightDirty={false}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button type="submit" disabled={submitting || pristine}>
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default EquipmentTypeCreateModal;

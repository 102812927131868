import { useStorageState } from "@mb-pro-ui/utils";
import isEmpty from "lodash/isEmpty";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import errorAlert from "../../sound/sound-serious-error.wav";

export interface AudioPlayer {
  play: (url: string) => HTMLAudioElement;
  pause: (url: string) => void;
  volume: number;
  setVolume: Dispatch<SetStateAction<number>>;
  playNotification: (url: string) => void;
  isNotificationOn: boolean;
  setIsNotificationOn: Dispatch<SetStateAction<boolean>>;
  playAlert: (url: string, id: string) => void;
  stopAlert: (url: string, id: string) => void;
  stopAllAlerts: () => void;
  playErrorAlert: (url: string) => void;
  stopErrorAlert: (url: string) => void;
  stopAllErrorAlert: () => void;
  isErrorAlertOn: boolean;
  audioContextState: string;
  errorAlertDelay: number;
  errorAlertCounter: number;
}

const AudioPlayerContext = React.createContext<AudioPlayer | undefined>(
  undefined
);

const playingSourceAndIdsByUrl: Map<
  string,
  { ids: string[]; source: AudioBufferSourceNode | null }
> = new Map();

const audioBufferCache: Map<string, AudioBuffer> = new Map();

const audioElementByUrl: Map<string, HTMLAudioElement> = new Map();

let audioContext = new AudioContext();
let gainNode = audioContext.createGain();

const errorAlertDelay = 30000;
const errorAlertID = "errorAlertID";

function AudioPlayerProvider({ children }: PropsWithChildren<{}>) {
  const [audioContextState, setAudioContextState] = useState(
    audioContext.state
  );
  const [isNotificationOn, setIsNotificationOn] = useStorageState<boolean>(
    "allowNotification",
    false
  );
  const [volume, setVolume] = useStorageState<number>("volume", 1);

  const [errorAlertRegister, setErrorAlertRegister] = useState<string[]>([]);
  const [errorAlertCounter, setErrorAlertCounter] = useState(
    errorAlertDelay / 1000
  );
  const isErrorAlertOn = !isEmpty(errorAlertRegister);

  const resumeAudio = useCallback(() => {
    if (audioContext.state === "suspended") {
      audioContext.resume().then(() => {
        setAudioContextState(audioContext.state);
      });
    } else {
      setAudioContextState(audioContext.state);
    }
  }, [setAudioContextState]);

  const stopAllAlerts = useCallback(() => {
    for (const [, sourceAndIds] of playingSourceAndIdsByUrl) {
      const source = sourceAndIds.source;
      source?.stop();
    }
    playingSourceAndIdsByUrl.clear();

    setErrorAlertRegister([]);
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === "stop-alerts") {
        stopAllAlerts();
      }
    },
    [stopAllAlerts]
  );

  useEffect(() => {
    gainNode.gain.value = volume;
  }, [volume]);

  useEffect(() => {
    window.addEventListener("touchend", resumeAudio, false);
    window.addEventListener("click", resumeAudio, false);
    window.addEventListener("storage", handleStorageChange, false);

    return function cleanUp() {
      window.removeEventListener("touchend", resumeAudio, false);
      window.removeEventListener("click", resumeAudio, false);
      window.removeEventListener("storage", handleStorageChange, false);
    };
  }, [resumeAudio, handleStorageChange]);

  const unlockAudio = useCallback(() => {
    if (audioContext.state === "suspended") {
      audioContext.resume().then(() => {
        setAudioContextState(audioContext.state);
      });
    } else if (audioContext.state === "closed") {
      audioContext = new AudioContext();
      gainNode = audioContext.createGain();
      playingSourceAndIdsByUrl.clear();
      for (const [, audio] of audioElementByUrl) {
        audio.pause();
      }
      audioElementByUrl.clear();
    }
  }, [setAudioContextState]);

  const playAlert = useCallback(
    (url: string, id: string) => {
      unlockAudio();

      const playingSourceAndIds = playingSourceAndIdsByUrl.get(url);

      if (playingSourceAndIds) {
        const ids = playingSourceAndIds.ids;
        ids.push(id);
        return;
      } else {
        playingSourceAndIdsByUrl.set(url, {
          ids: [id],
          source: null,
        });
      }

      getAudioBuffer(url)
        .then((audioBuffer) => {
          if (audioBuffer) {
            const sourceAndIds = playingSourceAndIdsByUrl.get(url);
            if (sourceAndIds) {
              let source = audioContext.createBufferSource();
              source.buffer = audioBuffer;
              source.loop = true;
              source.connect(gainNode).connect(audioContext.destination);
              source.onended = () => source.disconnect();
              source.start();
              sourceAndIds.source = source;
            }
          }
        })
        .catch((error) => {
          console.error(error);
          playingSourceAndIdsByUrl.delete(url);
        });
    },
    [unlockAudio]
  );

  const playErrorAlert = useCallback((id: string) => {
    setErrorAlertRegister((previous) =>
      previous.includes(id) ? previous : [...previous, id]
    );
  }, []);

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    if (isErrorAlertOn) {
      timeoutID = setTimeout(
        () => playAlert(errorAlert, errorAlertID),
        errorAlertDelay
      );
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [isErrorAlertOn, playAlert]);

  useEffect(() => {
    let intervalID: NodeJS.Timeout;

    if (isErrorAlertOn) {
      intervalID = setInterval(() => {
        setErrorAlertCounter((counter) =>
          counter > 0 ? counter - 1 : counter
        );
      }, 1000);
    }

    return () => {
      clearInterval(intervalID);
      setErrorAlertCounter(errorAlertDelay / 1000);
    };
  }, [isErrorAlertOn]);

  const stopErrorAlert = useCallback((id: string) => {
    setErrorAlertRegister((previous) =>
      previous.filter((registeredID) => registeredID !== id)
    );
  }, []);

  const stopAllErrorAlert = useCallback(() => {
    setErrorAlertRegister([]);
  }, []);

  useEffect(() => {
    if (!isErrorAlertOn) {
      stopAlert(errorAlert, errorAlertID);
    }
  }, [isErrorAlertOn]);

  const play = useCallback(
    (url: string) => {
      unlockAudio();

      const cachedAudioElement = audioElementByUrl.get(url);

      if (!cachedAudioElement) {
        const audioElement = new Audio(url);
        // const source = audioContext.createMediaElementSource(audioElement);
        // source.connect(gainNode).connect(audioContext.destination);
        audioElement.play();
        audioElementByUrl.set(url, audioElement);
        return audioElement;
      } else {
        cachedAudioElement.play();
        return cachedAudioElement;
      }
    },
    [unlockAudio]
  );

  const pause = useCallback((url: string) => {
    const audioElement = audioElementByUrl.get(url);
    if (audioElement) {
      audioElement.pause();
    }
  }, []);

  const playNotification = useCallback(
    (url: string) => {
      if (isNotificationOn) {
        unlockAudio();

        getAudioBuffer(url).then((audioBuffer) => {
          let source = audioContext.createBufferSource();
          source.buffer = audioBuffer;
          source.loop = false;
          source.connect(gainNode).connect(audioContext.destination);
          source.onended = () => source.disconnect();
          source.start();
        });
      }
    },
    [unlockAudio, isNotificationOn]
  );

  const value = useMemo(
    () => ({
      play,
      pause,
      volume: volume,
      setVolume,
      playNotification,
      isNotificationOn,
      setIsNotificationOn,
      playAlert,
      stopAlert,
      stopAllAlerts,
      playErrorAlert,
      stopErrorAlert,
      audioContextState,
      isErrorAlertOn,
      errorAlertDelay,
      stopAllErrorAlert,
      errorAlertCounter,
    }),
    [
      audioContextState,
      isNotificationOn,
      play,
      playAlert,
      playErrorAlert,
      playNotification,
      setIsNotificationOn,
      setVolume,
      volume,
      isErrorAlertOn,
      stopErrorAlert,
      stopAllErrorAlert,
      pause,
      stopAllAlerts,
      errorAlertCounter,
    ]
  );

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
    </AudioPlayerContext.Provider>
  );
}

function useAudio() {
  const audioPlayerContext = React.useContext(AudioPlayerContext);
  if (audioPlayerContext === undefined) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return audioPlayerContext;
}

const stopAlert = (url: string, id: string) => {
  const playingSourceAndIds = playingSourceAndIdsByUrl.get(url);
  if (playingSourceAndIds) {
    const ids = playingSourceAndIds.ids;
    const playingSource = playingSourceAndIds.source;
    ids.splice(ids.indexOf(id), 1);
    if (ids.length === 0) {
      playingSource?.stop();
      playingSourceAndIdsByUrl.delete(url);
    }
  }
};

const getAudioBuffer = (url: string): Promise<AudioBuffer> => {
  const audioBuffer = audioBufferCache.get(url);
  if (audioBuffer) {
    return Promise.resolve(audioBuffer);
  }

  return downloadAudio(url, 0).then((arrayBuffer) =>
    audioContext
      .decodeAudioData(arrayBuffer)
      .then((audioBuffer) => {
        audioBufferCache.set(url, audioBuffer);
        return audioBuffer;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  );
};

const downloadAudio = (
  url: string,
  iteration: number
): Promise<ArrayBuffer> => {
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.statusText || response.status));
      } else {
        return response.arrayBuffer();
      }
    })
    .catch((error) => {
      if (iteration < 8) {
        return delay(backoffDuration(iteration)).then(() =>
          downloadAudio(url, iteration + 1)
        );
      }
      throw error;
    });
};

function delay(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration * 1000));
}

function backoffDuration(iteration: number) {
  return Math.min(Math.pow(2, iteration), 5) + Math.random();
}

getAudioBuffer(errorAlert);

export { AudioPlayerProvider, useAudio };

import { useIntl } from "react-intl";

import { useGetAll } from "@mb-pro-ui/utils";
import { useMemo } from "react";
import { CustomerGroup, EmailRecipient } from "@mb-pro-ui/utils/types/alarm";

import { StyledTextField } from "../form/StyledComponents";
import { Autocomplete, Checkbox } from "@mb-pro-ui/components/form";
import { FormRenderProps } from "react-final-form";
import { isEqualById } from "../utils/autocompleteUtils";

export type EmailRecipientFormValues = Partial<EmailRecipient> & {};

export const EmailRecipientForm = (
  _props: FormRenderProps<EmailRecipientFormValues>
) => {
  const { formatMessage } = useIntl();

  const { data: customerGroups, isLoading: areCustomerGroupsLoading } =
    useGetAll<CustomerGroup>("alarm/customer-groups", {
      refetchOnWindowFocus: false,
    });

  const [customerGroupOptions] = useMemo(() => {
    const options = [] as CustomerGroup[];
    customerGroups?.forEach((c) => {
      options.push(c);
    });
    return [options];
  }, [customerGroups]);

  return (
    <>
      <StyledTextField
        name="name"
        label={formatMessage({
          defaultMessage: "Name",
        })}
      />
      <StyledTextField
        name="email"
        label={formatMessage({
          defaultMessage: "Email",
        })}
      />
      <Autocomplete
        name="customer-group"
        label={formatMessage({
          defaultMessage: "Customer group",
        })}
        fullWidth
        isEqual={isEqualById}
        disabled={areCustomerGroupsLoading}
        options={customerGroupOptions}
        getOptionLabel={(option) => option?.description || ""}
        sx={{ mx: 1 }}
      />

      <Checkbox
        name={"active"}
        label={formatMessage({ defaultMessage: "Active" })}
        labelSx={{
          alignSelf: "flex-start",
        }}
      />
    </>
  );
};

export default EmailRecipientForm;

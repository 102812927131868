import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "@mb-pro-ui/components";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { ModalFormProps } from "../types";
import { Checkbox, TextField } from "@mb-pro-ui/components/form";
import { Maybe } from "@mb-pro-ui/utils/types";
import { Authority as _Authority } from "@mb-pro-ui/utils/types/alarm";
import { useUseCreate } from "../utils";

interface FormValues {
  name: string;
  phone: Maybe<string>;
  mobile: Maybe<string>;
  remark: Maybe<string>;
  active: Maybe<boolean>;
}

const AuthorityCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: ModalFormProps) => {
  const { formatMessage } = useIntl();

  const { mutate: create } = useUseCreate<_Authority>("alarm/authorities", {
    refetch,
    setSnackbarState,
    onFormClose,
  });

  const onSubmit = async (values: FormValues) => {
    create(values as { [key: string]: any });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create Authoriy" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <TextField
                  name="name"
                  label={formatMessage({ defaultMessage: "Name" })}
                  required
                  requiredError={formatMessage({ defaultMessage: "Required" })}
                  disabled={submitting}
                  highlightDirty={false}
                />
                <TextField
                  name="phone"
                  type="tel"
                  label={formatMessage({ defaultMessage: "Phone" })}
                  disabled={submitting}
                  highlightDirty={false}
                />
                <TextField
                  name="mobile"
                  type="tel"
                  label={formatMessage({ defaultMessage: "Mobile" })}
                  disabled={submitting}
                  highlightDirty={false}
                />
                <TextField
                  name="remark"
                  label={formatMessage({ defaultMessage: "Remark" })}
                  disabled={submitting}
                  highlightDirty={false}
                />
                <Checkbox
                  name="active"
                  label={formatMessage({ defaultMessage: "Active" })}
                  highlightDirty={false}
                  labelSx={{
                    alignSelf: "flex-start",
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button type="submit" disabled={submitting || pristine}>
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default AuthorityCreateModal;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { ModalFormProps } from "../types";
import {
  Category as _Category,
  CodeTable as _CodeTable,
  Event as _Event,
} from "@mb-pro-ui/utils/types/alarm";

import { useLocale } from "../../../locales/LocaleProvider";

import { Button } from "@mb-pro-ui/components";
import { useIntl } from "react-intl";

import { Form } from "react-final-form";
import { TextField } from "@mb-pro-ui/components/form";
import EventCategoryAutocomplete from "./EventCategoryAutocomplete";
import { Lang } from "../../types";
import { Maybe } from "@mb-pro-ui/utils/types";
import { useUseCreate } from "../utils";
import { useApi } from "@mb-pro-ui/utils";
import { ApiResponseMany } from "@mb-pro-ui/utils/jsonapi/types";
import CodeTableAutocomplete from "./CodeTableAutocomplete";

type CodeTable = Omit<_CodeTable, "events"> & {
  events: _Event[];
};

interface FormValues {
  code: string;
  descr: Maybe<string>;
  category: _Category;
  "code-table": Maybe<CodeTable>;
}

const preSaveFormat = (values: FormValues, locale: keyof typeof Lang) => {
  return {
    code: values.code,
    category: values.category.id,
    ...(values.descr && { descr: { [`${Lang[locale]}`]: values.descr } }),
    ...(values["code-table"] && { "code-table": values["code-table"].id }),
  };
};

const parseCode = (value: string) => {
  if (value.length <= 4) return value;
  return value.slice(0, 4);
};

const EventTypeCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
  mutationVariables,
}: ModalFormProps) => {
  const { mutate: create } = useUseCreate<_Event>("alarm/events", {
    refetch,
    setSnackbarState,
    onFormClose,
  });

  const api = useApi();

  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  const onSubmit = (values: FormValues) => {
    create({
      ...preSaveFormat(values, locale),
      ...mutationVariables,
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create Event Type" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={async (values: FormValues) => {
          const ctid =
            values["code-table"]?.id || mutationVariables?.["code-table"];
          const code = values["code"];
          if (ctid && code) {
            const response = await api<ApiResponseMany>(
              `/alarm/events?${new URLSearchParams(
                `filter[code-table][eq]=${ctid}&filter[code][eq]=${code}&fields[events]=id`
              )}`
            );

            if (response.data.length > 0) {
              return Promise.resolve({
                code: formatMessage({
                  defaultMessage: "Occupied event code",
                }),
              });
            }
          }
        }}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <TextField
                  name="code"
                  label={formatMessage({ defaultMessage: "Event code" })}
                  required
                  requiredError={formatMessage({
                    defaultMessage: "Required",
                  })}
                  parse={parseCode}
                  disabled={submitting}
                  placeholder="00FF"
                  highlightDirty={false}
                />
                <EventCategoryAutocomplete required highlightDirty={false} />
                {!mutationVariables?.hasOwnProperty("code-table") && (
                  <CodeTableAutocomplete disabled={submitting} />
                )}
                <TextField
                  name="descr"
                  label={formatMessage(
                    { defaultMessage: "Name ({locale})" },
                    { locale }
                  )}
                  disabled={submitting}
                  highlightDirty={false}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" type="button" onClick={onFormClose}>
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button type="submit" disabled={submitting || pristine}>
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default EventTypeCreateModal;

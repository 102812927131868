import { ReactNode } from "react";
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  SxProps,
  Theme,
} from "@mui/material";

import EmptyPlaceholder from "@mb-pro-ui/components/EmptyPlaceholder";

interface WidgetProps {
  title?: ReactNode;
  postfix?: ReactNode;
  children?: ReactNode;
  className?: string;
  placeholder?: boolean;
  placeholderMessage?: string;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

const Widget = ({
  children,
  className,
  title,
  postfix,
  placeholder,
  placeholderMessage,
  isLoading,
  sx,
}: WidgetProps) => {
  return (
    <Paper
      className={className}
      sx={{ display: "flex", flexDirection: "column", overflow: "auto", ...sx }}
    >
      {title && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "40px",
          }}
          paddingY={1}
        >
          <Typography>{title}</Typography>
          {postfix && postfix}
        </Box>
      )}
      {isLoading ? (
        <Box
          sx={{
            height: (theme) =>
              `calc(100% - ${
                theme.typography.subtitle2.fontSize
              } - ${theme.spacing(3)})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !children && (placeholder || placeholderMessage) ? (
        <EmptyPlaceholder
          message={placeholderMessage}
          sx={{
            height: (theme) =>
              `calc(100% - ${
                theme.typography.subtitle2.fontSize
              } - ${theme.spacing(3)})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
          }}
        />
      ) : (
        children
      )}
    </Paper>
  );
};

export default Widget;

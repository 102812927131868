import { useGetAll } from "@mb-pro-ui/utils";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { MapProfile } from "../types";
import { Lang } from "../../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { localDescr } from "../../../utils/LocaleUtils";
import { Maybe } from "@mb-pro-ui/utils/types";
import { Icon } from "@mb-pro-ui/utils/types/alarm";
import StyledAvatar from "../StyledAvatar";
import { AllEntities } from "../utils";
import MapProfileCreateModal from "./MapProfileCreateModal";

const AllMapProfiles = () => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const queryResult = useGetAll<MapProfile>("alarm/map-profiles", {
    include: {
      icon: {},
      "base-icon": {},
      "alarm-icon": {},
    },
    page: {
      limit: 10000,
    },
    sort: ["id"],
  });

  const columns = useMemo(
    (): Column<MapProfile>[] => [
      {
        id: "id",
        Header: formatMessage({
          defaultMessage: "ID",
        }),
        accessor: (mapProfile) => mapProfile.id,
        width: 70,
      },
      {
        id: "descr",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (mapProfile) => localDescr(mapProfile.descr, Lang[locale]),
        width: 140,
      },
      {
        id: "icon",
        Header: formatMessage({
          defaultMessage: "Position Icon",
        }),
        accessor: (mapProfile) => mapProfile.icon,
        Cell: ({ value: MbProIcon }: Cell<MapProfile, Maybe<Icon>>) => {
          return MbProIcon?.icon ? <StyledAvatar src={MbProIcon.icon} /> : "";
        },
        width: 70,
      },
      {
        id: "base-icon",
        Header: formatMessage({
          defaultMessage: "Base Icon",
        }),
        accessor: (mapProfile) => mapProfile["base-icon"],
        Cell: ({ value: MbProIcon }: Cell<MapProfile, Maybe<Icon>>) => {
          return MbProIcon?.icon ? <StyledAvatar src={MbProIcon.icon} /> : "";
        },
        width: 70,
      },
      {
        id: "alarm-icon",
        Header: formatMessage({
          defaultMessage: "Alarm Icon",
        }),
        accessor: (mapProfile) => mapProfile["alarm-icon"],
        Cell: ({ value: MbProIcon }: Cell<MapProfile, Maybe<Icon>>) => {
          return MbProIcon?.icon ? <StyledAvatar src={MbProIcon.icon} /> : "";
        },
        width: 70,
      },
    ],
    [formatMessage, locale]
  );

  const title = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      <FormattedMessage defaultMessage="Map profiles" />
    </Typography>
  );

  return (
    <AllEntities
      columns={columns}
      queryResult={queryResult}
      title={title}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <MapProfileCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllMapProfiles;

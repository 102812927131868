import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { SvgIcon, Typography, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as ContactsIcon } from "../../icons/export/Contacts.svg";
import { useParams } from "react-router-dom";
import { useDelete, useGetOne, useUpdate } from "@mb-pro-ui/utils";
import { useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { useHistory } from "react-router";
import { Form } from "react-final-form";

import { SnackbarState, SystemSettingsPageProps } from "../settings/types";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
} from "../settings/utils";
import { CustomerGroup, EmailRecipient } from "@mb-pro-ui/utils/types/alarm";
import EmailRecipientForm, {
  EmailRecipientFormValues,
} from "./EmailRecipientForm";

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Email recpient editor"
        description="Sidebar option"
      />
    ),
    icon: <SvgIcon component={ContactsIcon} />,
  },
];

const EmailRecipientUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: recipient,
    isLoading,
    refetch,
  } = useGetOne<EmailRecipient>("alarm/email-recipients", id, {
    enabled: id !== "new",
    include: {
      "customer-group": {},
    },
  });

  const name = recipient?.name;
  const breadcrumbName = name || id;

  const history = useHistory();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/exports/email-recipients/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: update } = useUpdate("alarm/email-recipients", {
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully saved" }),
      });
      refetch();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Failed to save" }),
        error: true,
      });
    },
  });

  const { mutate: del, isLoading: isDeleting } = useDelete(
    "alarm/email-recipients",
    {
      onSuccess: () => {
        history.replace("/alarm/exports/email-recipients");
      },
      onError: () => {
        setSnackbarState({
          message: formatMessage({ defaultMessage: "Unsuccessful delete" }),
          error: true,
        });
        setDeleteDialogOpen(false);
      },
    }
  );

  const onSubmit = (values: EmailRecipientFormValues) => {
    const { "customer-group": customerGroup, ...rest } = values;
    const updateArg = {
      id,
      "customer-group": (customerGroup as CustomerGroup)?.id,
      ...rest,
    };
    update(updateArg);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{name}</Typography>;

  return (
    <Page
      backLink="/alarm/exports/email-recipients"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={recipient}
          render={(props) => (
            <SubPage
              prefix={prefix}
              innerSx={{ height: "auto", padding: "8px", minWidth: "400px" }}
            >
              <StyledForm>
                <EmailRecipientForm {...props} />
                <UpdateFormActions
                  onSave={props.handleSubmit}
                  onReset={() => props.form.reset()}
                  onDelete={() => setDeleteDialogOpen(true)}
                  disabledDelete={props.submitting}
                  disabledReset={props.submitting || props.pristine}
                  disabledSave={props.submitting || props.pristine}
                  isLoading={isLoading}
                  isDeleting={isDeleting}
                />
              </StyledForm>
            </SubPage>
          )}
        />
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={recipient?.name ?? ""}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default EmailRecipientUpdatePage;

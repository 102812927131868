import { ModuleMenu } from "./ModuleMenu";
import { Header, MainMenu, SettingsMenu } from "@mb-pro-ui/components";
import { useAuth, useGetOne } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import VolumeButton from "../audio/VolumeButton";
import { useMenuLinks } from "../../hooks";
import { Switch, Route } from "react-router";
import { DispatcherRoutes, ModuleID } from "../types";
import { User } from "@mb-pro-ui/utils/types/admin";

export const AppHeader = () => {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const links = useMenuLinks();

  const { data: user } = useGetOne<User>("admin/users", "me");

  return (
    <Header
      position="static"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <Switch>
        <Route path="/alarm">
          <MainMenu
            links={links}
            routes={[
              ...Object.keys(DispatcherRoutes).map(
                (key) => DispatcherRoutes[key as keyof typeof DispatcherRoutes]
              ),
            ]}
          />
          <VolumeButton />
          <ModuleMenu currentModuleID={ModuleID.Dispatcher} />
        </Route>
      </Switch>
      <SettingsMenu
        settingsItemProps={{ component: "a", href: "/admin" }}
        onClickLogout={() => auth.signout.mutate()}
        settingsMessage={formatMessage({
          defaultMessage: "Settings",
          description: "App header settings button label",
        })}
        logoutMessage={formatMessage({
          defaultMessage: "Logout",
          description: "App header logout button label",
        })}
        userTitle={formatMessage({
          defaultMessage: "Logged In",
        })}
        userName={user?.username ?? ""}
      />
    </Header>
  );
};

export default AppHeader;

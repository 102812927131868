import { useCallback, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  Snackbar,
  SvgIcon,
  Typography,
} from "@mui/material";

import { matchPath, useHistory, useLocation } from "react-router";
import { Link, useRouteMatch } from "react-router-dom";

import SaveOutlined from "@mui/icons-material/SaveOutlined";

import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { ReactComponent as OneDayQuery } from "../../icons/customer/OneDayQueryGrey.svg";
import { ReactComponent as SevenDayQuery } from "../../icons/customer/SevenDayQueryGrey.svg";
import { ReactComponent as ThirtyDayQuery } from "../../icons/customer/ThirtyDayQueryGrey.svg";

import PrintIcon from "@mui/icons-material/Print";

import DeleteDialog from "./dialogs/DeleteDialog";
import CloningDialog from "./dialogs/CloningDialog";
import { useIntl } from "react-intl";
import {
  StyledDivider,
  StyledIconButton,
  StyledTooltip,
} from "../utils/StyledHeaderComponents";

import { getMenuOptions } from "../../components/customer/utils";

import FormPrompt from "../form/FormPrompt";
import { useForm } from "react-final-form";
import { AndroidSwitch, FormDialog } from "@mb-pro-ui/components";
import { useProfile } from "./profile/ProfileProvider";
import PrintDialog from "./dialogs/PrintDialog";
import moment from "moment";

interface CustomerFormHeaderProps {
  account: string;
  id: string;
  formRef: React.RefObject<HTMLFormElement>;
  formDirty: boolean;
  formValid: boolean;
  readOnly?: boolean;
  hasExpertMode: boolean;
}

const CustomerFormHeader = ({
  formRef,
  account,
  id,
  formDirty,
  formValid,
  readOnly,
  hasExpertMode,
}: CustomerFormHeaderProps) => {
  const { path, url } = useRouteMatch();
  const location = useLocation();

  const history = useHistory();

  const { reset, getState } = useForm();

  const { setExpertMode, expertMode, profile } = useProfile();

  const [cloningDialogOpen, setCloningDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const [errorSnackbar, setErrorSnackbar] = useState<null | string>(null);

  const { formatMessage } = useIntl();

  const onDialogAction = (_: {}, action: "close" | "cancel" | "confirm") => {
    switch (action) {
      case "close":
        setResetDialogOpen(false);
        break;
      case "cancel":
        setResetDialogOpen(false);
        break;
      case "confirm":
        setResetDialogOpen(false);
        reset();
        if (setExpertMode) setExpertMode(false);
        break;
    }
  };

  const onModeChange = () => {
    if (expertMode && getState().dirty) {
      setResetDialogOpen(true);
    } else {
      if (setExpertMode) {
        setExpertMode(!expertMode);
      }

      if (
        expertMode &&
        !getMenuOptions(profile, false, formatMessage).some(
          (option) =>
            option.link === location.pathname.replace(url, "") ||
            option.subPages?.some(
              (opt) => opt.link === location.pathname.replace(url, "")
            )
        )
      ) {
        history.replace(url);
      }
    }
  };

  const getOperatorPageQuery = useCallback(
    (days: number) =>
      `/alarm/events?customer=${id}&arrived=${encodeURIComponent(
        [moment().subtract(days, "days").format(), moment().format()].join(",")
      )}`,
    [id]
  );

  return (
    <>
      {resetDialogOpen ? (
        <FormDialog
          title={formatMessage({ defaultMessage: "Attention!" })}
          open={resetDialogOpen}
          onDialogAction={onDialogAction}
        >
          <Typography>
            {formatMessage({
              defaultMessage:
                "There are unsaved changes. Are you sure want to leave expert mode?",
            })}
          </Typography>
        </FormDialog>
      ) : null}
      {printDialogOpen ? (
        <PrintDialog
          printDialogOpen={printDialogOpen}
          setPrintDialogOpen={setPrintDialogOpen}
        />
      ) : null}
      <FormPrompt
        title={formatMessage({ defaultMessage: "Attention!" })}
        when={formDirty}
        whenForward={(e, action) => {
          if (
            matchPath(e.pathname, {
              path: `${path}/*/:resource`,
              exact: true,
            })?.isExact ||
            action === "REPLACE"
          ) {
            return true;
          }
          return false;
        }}
        onConfirm={() => {
          reset();
          return true;
        }}
      />
      <Snackbar
        open={!!errorSnackbar}
        message={errorSnackbar}
        onClose={() => setErrorSnackbar(null)}
        sx={{
          "& > div": {
            backgroundColor: "error.main",
          },
        }}
      />
      <CloningDialog
        open={cloningDialogOpen}
        onDialogAction={() => setCloningDialogOpen(false)}
        handleError={setErrorSnackbar}
      />
      <DeleteDialog
        account={account}
        open={deleteDialogOpen}
        onDialogAction={() => setDeleteDialogOpen(false)}
      />
      {hasExpertMode && (
        <FormControlLabel
          control={
            <AndroidSwitch checked={expertMode} onChange={onModeChange} />
          }
          label={formatMessage({ defaultMessage: "Expert mode" })}
        />
      )}
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Events from the last 24 hours",
        })}
      >
        <Link to={getOperatorPageQuery(1)}>
          <StyledIconButton>
            <SvgIcon
              color="action"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={OneDayQuery}
            />
          </StyledIconButton>
        </Link>
      </StyledTooltip>
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Events from the last 7 days",
        })}
      >
        <Link to={getOperatorPageQuery(7)}>
          <StyledIconButton>
            <SvgIcon
              color="action"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={SevenDayQuery}
            />
          </StyledIconButton>
        </Link>
      </StyledTooltip>
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Events from the last 30 days",
        })}
      >
        <Link to={getOperatorPageQuery(30)}>
          <StyledIconButton>
            <SvgIcon
              color="action"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={ThirtyDayQuery}
            />
          </StyledIconButton>
        </Link>
      </StyledTooltip>
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Print",
        })}
      >
        <StyledIconButton onClick={() => setPrintDialogOpen(true)}>
          <SvgIcon
            color="action"
            sx={{ "& .cls-1": { fill: "inherit" } }}
            component={PrintIcon}
          />
        </StyledIconButton>
      </StyledTooltip>
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      {readOnly ? (
        <Typography>
          {formatMessage({ defaultMessage: "Readonly mode" })}
        </Typography>
      ) : (
        <>
          <StyledTooltip
            title={formatMessage({
              defaultMessage: "Delete customer...",
            })}
          >
            <StyledIconButton onClick={() => setDeleteDialogOpen(true)}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
            </StyledIconButton>
          </StyledTooltip>
          <StyledTooltip
            title={formatMessage({
              defaultMessage: "Clone customer...",
            })}
          >
            <StyledIconButton onClick={() => setCloningDialogOpen(true)}>
              <SvgIcon>
                <FileCopyIcon />
              </SvgIcon>
            </StyledIconButton>
          </StyledTooltip>
          <StyledTooltip
            title={formatMessage({
              defaultMessage: "Save customer...",
            })}
          >
            <StyledIconButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new CustomEvent("submit", {
                    bubbles: true,
                    cancelable: true,
                  })
                );
              }}
            >
              {formDirty && formValid ? (
                <IconButton sx={{ backgroundColor: "warning.light" }}>
                  <SvgIcon>
                    <SaveOutlined />
                  </SvgIcon>
                </IconButton>
              ) : (
                <IconButton>
                  <SvgIcon>
                    <SaveOutlined sx={{ color: "grey.400" }} />
                  </SvgIcon>
                </IconButton>
              )}
            </StyledIconButton>
          </StyledTooltip>
        </>
      )}
    </>
  );
};

export default CustomerFormHeader;

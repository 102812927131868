import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl, defineMessage as $d, FormattedMessage } from "react-intl";
import { Filter as TableFilter, FilterViewProps } from "../components/table";
import { Filters, useGetAll } from "@mb-pro-ui/utils";
import { Page, SubPage } from "@mb-pro-ui/components";

import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import AutoSizer from "react-virtualized-auto-sizer";
import {
  boolFilter,
  commaSeparatedValuesFilter,
  dateRangeFilter,
  eqInNeqNinFilter,
  FilterOp,
  patternFilter,
} from "../utils/FilterUtils";
import {
  CustomerAutocompleteFilter,
  DateRangeFilter,
  EventCategoryAutocompleteFilter,
  SimpleBooleanFilter,
  TextFilter,
} from "../components/table/filters";
import { MessageDescriptor } from "@formatjs/intl";

import InstallerAutocompleteFilter from "../components/customer/filters/InstallerAutocompleteFilter";
import EquipmentTypeAutocompleteFilter from "../components/customer/filters/EquipmentTypeAutocompleteFilter";
import ObjectTypeAutocompleteFilter from "../components/customer/filters/ObjectTypeAutocompleteFilter";
import { Cdec } from "../components/operator/types";
import CdecModal from "../components/operator/cdec/CdecModal";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { ReactComponent as EventsMenuIcon } from "../icons/EventsMenu.svg";
import {
  Box,
  IconButton,
  Link,
  Snackbar,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import useImport from "../utils/useImport";
import AdvancedFilters from "../components/table/AdvancedFilters/AdvancedFilters";
import {
  createContainer,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
  VictoryVoronoiContainerProps,
  VictoryZoomContainerProps,
} from "victory";
import { getTimeTick } from "../utils/date";

import ListIcon from "@mui/icons-material/List";
import { StyledDivider } from "../components/utils/StyledHeaderComponents";

import ShowChartIcon from "@mui/icons-material/ShowChart";

import flatten from "lodash/flattenDeep";
import CustomLabel from "./chart/CustomLabel";

const VictoryZoomVoronoiContainer = createContainer<
  VictoryZoomContainerProps,
  VictoryVoronoiContainerProps
>("zoom", "voronoi");

const colors = ["#ff0000", "#8fce00", "#2986cc", "#6a329f", "#6a329f"];

const filterSpec: { [id: string]: FilterOp } = {
  account: patternFilter,
  arrived: dateRangeFilter,
  "event-code": commaSeparatedValuesFilter,
  "event-category": eqInNeqNinFilter,
  customer: eqInNeqNinFilter,
  "contract-number": patternFilter,
  "billing-id": patternFilter,

  active: boolFilter,
  installer: eqInNeqNinFilter,
  "equipment-type": eqInNeqNinFilter,
  "object-type": eqInNeqNinFilter,
  "category-alert": boolFilter,
};

const mapToServerFilters = (filters: TableFilter[]): Filters => {
  const result: Filters = {};
  if (filters !== null) {
    filters.forEach((filter) => {
      if (filter.id in filterSpec) {
        result[filter.id] = filterSpec[filter.id](filter);
      }
    });
  }
  return result;
};

const SimpleTextFilter = (msg: MessageDescriptor) => {
  const SimpleTextFilter = (props: FilterViewProps) => {
    const { formatMessage } = useIntl();
    return <TextFilter {...props} label={formatMessage(msg)} />;
  };
  return SimpleTextFilter;
};

const EventCodeFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();
  return (
    <TextFilter
      {...props}
      label={formatMessage({ defaultMessage: "Event Code" })}
    />
  );
};

const tableFilters = [
  {
    id: "account",
    Filter: SimpleTextFilter($d({ defaultMessage: "Account" })),
  },
  { id: "arrived", Filter: DateRangeFilter },
  { id: "event-code", Filter: EventCodeFilter },
  { id: "event-category", Filter: EventCategoryAutocompleteFilter },
  { id: "customer", Filter: CustomerAutocompleteFilter },

  {
    id: "contract-number",
    Filter: SimpleTextFilter($d({ defaultMessage: "Contract Number" })),
  },
  {
    id: "billing-id",
    Filter: SimpleTextFilter($d({ defaultMessage: "Billing ID" })),
  },

  {
    id: "active",
    Filter: SimpleBooleanFilter(
      $d({ defaultMessage: "Active" }),
      $d({ defaultMessage: "Inactive" })
    ),
  },
  { id: "installer", Filter: InstallerAutocompleteFilter },
  { id: "equipment-type", Filter: EquipmentTypeAutocompleteFilter },
  { id: "object-type", Filter: ObjectTypeAutocompleteFilter },
  {
    id: "category-alert",
    Filter: SimpleBooleanFilter(
      $d({ defaultMessage: "Alert" }),
      $d({ defaultMessage: "Non Alert" })
    ),
  },
];

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: <FormattedMessage defaultMessage="Event query" />,
    icon: (
      <SvgIcon component={EventsMenuIcon} viewBox="0 0 50 50" color="inherit" />
    ),
  },
];

interface CdecAll {
  id: string;
  arrived: string;
  event: any;
  "chart-data": { [key: string]: number | null };
  customer: { type: "customers"; id: string; account: string; name: string };
  "event-code": string;
  type: `${"alarm/" | ""}cdec-all`;
}

export const EventQueryChart = ({ url }: { url: string }) => {
  const { formatMessage, locale } = useIntl();

  const { search } = useLocation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);
  const [filters, setFilters] = useState<Filters>({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const stableResults = Boolean(filters["arrived"]?.["lte"]);
  const limit = filters && Object.keys(filters).length > 0 ? 1000 : 100;

  const { data, status } = useGetAll<CdecAll>("alarm/cdec-all", {
    include: {
      customer: {},
      event: {},
    },
    fields: {
      customers: ["account", "name"],
      "cdec-all": ["customer", "arrived", "chart-data", "event-code", "event"],
    },
    sort: ["-id"],
    keepPreviousData: true,
    filter: { "chart-data": { null: "false" }, ...filters } as Filters,
    refetchOnWindowFocus: !stableResults,
    page: {
      limit,
    },
  });

  const [graphData, setGraphData] = useState<any>(null);

  /*
Example Graphdata
{   Ügyfél id
    205: {
        Eseménykód
        "6005":{
            Mező
            "DoorOpen":[{x:"2022-03-08T14:59:57.733Z",y:11}]
        }
    }
    206: {}
    209: {}
}
*/

  useEffect(() => {
    if (data) {
      setGraphData(
        data.reduce((prev, curr) => {
          if (prev[curr.customer.id]) {
            if (prev[curr.customer.id][curr["event-code"]]) {
              return {
                ...prev,
                [curr.customer.id]: {
                  ...prev[curr.customer.id],
                  [curr["event-code"]]: Object.keys(curr["chart-data"]).reduce(
                    (eventPrev, eventCurr) => {
                      if (eventPrev[eventCurr]) {
                        return {
                          ...eventPrev,
                          [eventCurr]: [
                            ...eventPrev[eventCurr],
                            {
                              x: new Date(curr.arrived),
                              y: curr["chart-data"][eventCurr],
                            },
                          ],
                        };
                      } else {
                        return {
                          ...eventPrev,
                          [eventPrev[eventCurr]]: [
                            {
                              x: new Date(curr.arrived),
                              y: curr["chart-data"][eventCurr],
                            },
                          ],
                        };
                      }
                    },
                    prev[curr.customer.id][curr["event-code"]]
                  ),
                },
              };
            } else {
              return {
                ...prev,
                [curr.customer.id]: {
                  ...prev[curr.customer.id],
                  [curr["event-code"]]: {},
                },
              };
            }
          } else {
            return {
              ...prev,
              [curr.customer.id]: {
                [curr["event-code"]]: Object.keys(curr["chart-data"]).reduce(
                  (eventPrev, eventCurr) => {
                    return {
                      ...eventPrev,
                      [eventCurr]: [
                        {
                          x: new Date(curr.arrived),
                          y: curr["chart-data"][eventCurr],
                        },
                      ],
                    };
                  },
                  {} as any
                ),
              },
            };
          }
        }, {} as any)
      );
    }
  }, [data]);

  const [createReport, snackBarMessage, setSnackBarMessage, reportURL] =
    useImport({ formatMessage });

  const handleFilterChange = useCallback(
    (filters: TableFilter[]) => {
      setFilters(mapToServerFilters(filters));
    },
    [setFilters]
  );

  const handleCdecModalClose = useCallback(() => {
    setSelectedCdec(null);
    setModalOpen(false);
  }, []);

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);
  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const prefix = useMemo(() => {
    const message =
      status === "success"
        ? formatMessage(
            { defaultMessage: "Query Results: {count}{mark}" },
            {
              count: data?.length,
              mark: data?.length === limit ? "+" : "",
            }
          )
        : formatMessage(
            { defaultMessage: "Query Status: {status}" },
            { status }
          );
    return <Typography color="primary.contrastText">{message}</Typography>;
  }, [data, status, limit, formatMessage]);

  const downloadAction = (
    <>
      {reportURL && (
        <Link
          href={reportURL}
          target="_blank"
          rel="noopener"
          onClick={() => {
            setSnackBarMessage(null);
          }}
          sx={{ mr: 1 }}
        >
          {formatMessage({ defaultMessage: "Download" })}
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setSnackBarMessage(null)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={!!snackBarMessage}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={downloadAction}
      />
      <Page
        sx={{ overflow: "hidden" }}
        sidebarOptions={sidebarOptions}
        sidebarOpen={sidebarOpen}
        onSidebarOpen={handleSidebarOpen}
        onSidebarClose={handleSidebarClose}
      >
        <SubPage
          showHeader={true}
          prefix={<>{prefix}</>}
          postfix={
            <Box sx={{ display: "flex" }}>
              <IconButton
                size="small"
                sx={{
                  color: "primary.contrastText",
                }}
              >
                <Tooltip
                  title={formatMessage({
                    defaultMessage: "Show chart",
                  })}
                  placement="bottom-start"
                >
                  <ShowChartIcon sx={{ color: "white" }} />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                sx={{
                  color: "primary.contrastText",
                }}
              >
                <RouterLink to={`${url}${search}`}>
                  <Tooltip
                    title={formatMessage({
                      defaultMessage: "Show list",
                    })}
                    placement="bottom-start"
                  >
                    <ListIcon sx={{ color: "white" }} />
                  </Tooltip>
                </RouterLink>
              </IconButton>
              <StyledDivider orientation="vertical" variant="middle" flexItem />
              <IconButton
                sx={{
                  color: "primary.contrastText",
                }}
                onClick={() => {
                  createReport({
                    description: formatMessage({
                      defaultMessage: "Cdec export",
                    }),
                    "report-type": "cdec-export",
                    options: {
                      filters: filters,
                    },
                  });
                  setSnackBarMessage(
                    formatMessage({
                      defaultMessage: "Exporting...",
                      description:
                        "Interventions table currently exporting snackbar message",
                    })
                  );
                }}
              >
                <Tooltip
                  title={formatMessage({
                    defaultMessage: "Export",
                  })}
                  placement="bottom-start"
                >
                  <DownloadIcon />
                </Tooltip>
              </IconButton>
            </Box>
          }
          innerSx={{ display: "flex", flexDirection: "column" }}
        >
          <AdvancedFilters
            tableFilters={tableFilters}
            onFilterChange={handleFilterChange}
          />
          <Box sx={{ flex: "1" }}>
            <AutoSizer>
              {({ width, height }) => (
                <VictoryChart
                  containerComponent={
                    <VictoryZoomVoronoiContainer
                      zoomDimension="x"
                      responsive={false}
                      labels={({ datum }) =>
                        `${(datum.x as Date).toLocaleTimeString()}: ${datum.y}`
                      }
                      radius={25}
                    />
                  }
                  theme={limit === 100 ? undefined : VictoryTheme.material}
                  scale={{ x: "time" }}
                  width={width}
                  height={height}
                >
                  {data && graphData && limit !== 100 && (
                    <VictoryLegend
                      x={width - 220}
                      y={20}
                      centerTitle
                      orientation="vertical"
                      rowGutter={{ top: -5, bottom: 0 }}
                      labelComponent={<CustomLabel />}
                      style={{
                        title: { fontSize: 20 },
                      }}
                      data={flatten(
                        Object.keys(graphData).map((customerId, i) => [
                          {
                            name:
                              data.find(
                                (cdec) => cdec.customer.id === customerId
                              )?.customer.name ?? "-",
                            symbol: { fill: "none" },
                          },
                          Object.keys(graphData[customerId]).map(
                            (eventCode, j) =>
                              Object.keys(graphData[customerId][eventCode]).map(
                                (field, k) => ({
                                  name: field,
                                  symbol: { fill: colors[(i + j + k) % 5] },
                                  labels: { fill: "grey" },
                                })
                              )
                          ),
                        ])
                      )}
                    />
                  )}
                  <VictoryAxis
                    scale="time"
                    tickCount={3}
                    tickFormat={(e) => getTimeTick(e, locale)}
                  />
                  {limit !== 100 ? (
                    graphData ? (
                      Object.keys(graphData).map((key, i) =>
                        Object.keys(graphData[key]).map((eventKey, j) =>
                          Object.keys(graphData[key][eventKey]).map(
                            (fieldKey, k) => (
                              <VictoryLine
                                data={graphData[key][eventKey][fieldKey]}
                                standalone={false}
                                style={{
                                  data: { stroke: colors[(i + j + k) % 5] },
                                }}
                              />
                            )
                          )
                        )
                      )
                    ) : null
                  ) : (
                    <VictoryLabel
                      x={width / 2 - 50}
                      y={height / 2 - 50}
                      text={formatMessage({ defaultMessage: "Missing filter" })}
                      style={{ fill: "red", fontSize: 25 }}
                    />
                  )}
                  <VictoryAxis dependentAxis />
                </VictoryChart>
              )}
            </AutoSizer>
          </Box>
        </SubPage>
        {selectedCdec && (
          <CdecModal
            cdec={selectedCdec}
            open={modalOpen}
            onClose={handleCdecModalClose}
          />
        )}
      </Page>
    </>
  );
};

export default EventQueryChart;

import { Breadcrumbs as MuiBreadcrumbs, breadcrumbsClasses, Typography } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import { ReactNode, useMemo } from "react";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export interface BreadcrumbsProps {
  breadcrumbNameMap?: { [key: string]: ReactNode } | ((key: string) => ReactNode);
}

const Breadcrumbs = ({ breadcrumbNameMap }: BreadcrumbsProps) => {
  const location = useLocation();

  const pathnames = useMemo(
    () => location.pathname.split("/").filter((x) => x),
    [location.pathname]
  );

  return (
    <MuiBreadcrumbs
      sx={{
        [`& .${breadcrumbsClasses.li}`]: {
          margin: 0,
        },
        [`& .${breadcrumbsClasses.ol}`]: {
          maxHeight: "24px",
        },
        [`& .${breadcrumbsClasses.separator}`]: {
          marginTop: 0,
          color: "primary.main",
        },
        "& li": {
          display: "flex",
        },
      }}
      maxItems={3}
      separator=">"
    >
      {pathnames.map((_, index) => {
        const first = index === 0;
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const name =
          typeof breadcrumbNameMap === "function" ? breadcrumbNameMap(to) : breadcrumbNameMap?.[to];

        if (first || !name) {
          return null;
        }

        return last ? (
          <Typography color="text.primary" key={to}>
            {name}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="primary.main" to={to} key={to}>
            {name}
          </LinkRouter>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;

import { Cdec as _Cdec } from "@mb-pro-ui/utils/types/alarm";
import { useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { ID } from "@mb-pro-ui/utils/types";
import BootstrapTooltip from "./BootstrapTooltip";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

export type Cdec = Omit<_Cdec, "intervention"> & {
  intervention: Exclude<_Cdec["intervention"], ID>;
};

const EventIcon = ({ cdec }: { cdec: Cdec }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  if (cdec["category-alert"]) {
    if (cdec.intervention) {
      if (cdec.intervention["close-time"]) {
        return (
          <BootstrapTooltip
            backgroundColor={theme.palette.info.main}
            title={formatMessage({
              defaultMessage: "Closed",
            })}
          >
            <DoneOutlinedIcon
              sx={{
                color: (theme) => theme.palette.info.main,
              }}
              fontSize="inherit"
            />
          </BootstrapTooltip>
        );
      } else {
        return (
          <BootstrapTooltip
            title={formatMessage({
              defaultMessage: "Intervention",
              description: "Under intervention tooltip on operator page",
            })}
          >
            <RemoveRedEyeOutlinedIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
              fontSize="inherit"
            />
          </BootstrapTooltip>
        );
      }
    } else {
      return (
        <BootstrapTooltip
          backgroundColor={cdec.color}
          title={
            cdec.active
              ? formatMessage({
                  defaultMessage: "Alert Event",
                })
              : formatMessage({
                  defaultMessage: "Alert Event (Inactive)",
                })
          }
        >
          <PriorityHighOutlinedIcon
            sx={{
              color: cdec.color,
            }}
            fontSize="inherit"
          />
        </BootstrapTooltip>
      );
    }
  } else {
    return (
      <BootstrapTooltip
        title={formatMessage({
          defaultMessage: "Notification",
        })}
      >
        <NotificationsNoneOutlinedIcon color="primary" fontSize="inherit" />
      </BootstrapTooltip>
    );
  }
};

export default EventIcon;

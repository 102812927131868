import { useMemo } from "react";

import { StyledTextField } from "../form/StyledComponents";

import { Column, Row } from "react-table";

import { useIntl } from "react-intl";
import FormTable from "../form/FormTable";
import { NotifiablePerson } from "./types";
import LinkButton from "../utils/LinkButton";

export const NotifiablesDialogRenderer = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledTextField
        name="name"
        label={formatMessage({ defaultMessage: "Name" })}
        required
      />
      <StyledTextField
        name="role"
        label={formatMessage({
          defaultMessage: "Role",
          description: "Customer editor, notifiables page role",
        })}
      />
      <StyledTextField
        name="phone"
        label={formatMessage({
          defaultMessage: "Phone",
        })}
      />
      <StyledTextField
        name="mobile"
        label={formatMessage({
          defaultMessage: "Mobile",
        })}
      />
      <StyledTextField
        name="email"
        label={formatMessage({
          defaultMessage: "Email",
        })}
      />
    </>
  );
};

interface NotifiablesFormProps {
  readOnly?: boolean;
}

const NotifiablesForm = ({ readOnly }: NotifiablesFormProps) => {
  const { formatMessage } = useIntl();

  const action = (row: Row<NotifiablePerson>, url: string) =>
    readOnly ? (
      <></>
    ) : (
      <LinkButton
        row={row}
        url={`${url}/notifiable-persons/byNum/${row.index}`}
      />
    );

  const columns = useMemo(
    (): Column<NotifiablePerson>[] => [
      {
        id: "name",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (p) => p.name,
      },
      {
        id: "role",
        Header: formatMessage({
          defaultMessage: "Role",
        }),
        accessor: (p) => p.role,
      },
      {
        id: "phone",
        Header: formatMessage({
          defaultMessage: "Phone",
        }),
        accessor: (p) => p.phone,
      },
      {
        id: "mobile",
        Header: formatMessage({
          defaultMessage: "Mobile",
        }),
        accessor: (p) => p.mobile,
      },
      {
        id: "email",
        Header: formatMessage({
          defaultMessage: "Email",
        }),
        accessor: (p) => p.email,
      },
    ],
    [formatMessage]
  );

  return (
    <FormTable
      name="notifiable-persons"
      columns={columns}
      action={action}
      dialogNewTitle={formatMessage({
        defaultMessage: "New notifiable",
      })}
      dialogEditTitle={formatMessage({
        defaultMessage: "Edit notifiable",
      })}
      dialog={<NotifiablesDialogRenderer />}
      readOnly={readOnly}
    />
  );
};

export default NotifiablesForm;

import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { Box, SvgIcon, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as EmailTemplatesIcon } from "../../icons/export/EmailTemplates.svg";

import { useParams } from "react-router-dom";
import { useDelete, useGetOne, useUpdate } from "@mb-pro-ui/utils";
import { useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { useHistory } from "react-router";
import { Form } from "react-final-form";

import { SnackbarState, SystemSettingsPageProps } from "../settings/types";
import { DeleteDialog, Snackbar, UpdateFormActions } from "../settings/utils";
import { EmailTemplate } from "@mb-pro-ui/utils/types/alarm";
import {
  EmailTemplateForm,
  EmailTemplateFormValues,
} from "./EmailTemplateForm";

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Email template"
        description="Sidebar option"
      />
    ),
    icon: <SvgIcon component={EmailTemplatesIcon} />,
  },
];

const EmailTemplateUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: template,
    isLoading,
    refetch,
  } = useGetOne<EmailTemplate>("alarm/email-templates", id, {
    enabled: id !== "new",
    fields: {
      "email-templates": [
        "+last-sent-ts",
        "+last-sent-status",
        "+recipients",
        "+bcc-recipients",
        "+attached-regular-reports",
      ],
    },
    include: {},
  });

  const templateInitialValue = useMemo(() => {
    const result: EmailTemplateFormValues =
      isLoading || !template ? {} : { ...template };
    if (!template?.["attached-regular-reports"])
      result["attached-regular-reports"] = [];
    if (!template?.["bcc-recipients"]) result["bcc-recipients"] = [];
    if (!template?.recipients) result.recipients = [];

    return result;
  }, [template, isLoading]);

  const name = template?.description;
  const breadcrumbName = name || id;

  const history = useHistory();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/exports/email-templates/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: update } = useUpdate("alarm/email-templates", {
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully saved" }),
      });
      refetch();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Failed to save" }),
        error: true,
      });
    },
  });

  const { mutate: del, isLoading: isDeleting } = useDelete(
    "alarm/email-templates",
    {
      onSuccess: () => {
        history.replace("/alarm/exports/email-templates");
      },
      onError: () => {
        setSnackbarState({
          message: formatMessage({ defaultMessage: "Unsuccessful delete" }),
          error: true,
        });
        setDeleteDialogOpen(false);
      },
    }
  );

  const onSubmit = (values: EmailTemplateFormValues) => {
    const updateArg = {
      id,
      description: values.description || null,
      active: values.active || false,
      subject: values.subject || null,
      body: values.body || null,
      "customer-group": (values["customer-group"] as string) || null,
      recipients: values.recipients as string[],
      "bcc-recipients": values["bcc-recipients"] as string[],
      "attached-regular-reports": values[
        "attached-regular-reports"
      ] as string[],
    };

    update(updateArg);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{name}</Typography>;

  return (
    <Page
      backLink="/alarm/exports/email-templates"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      {!isLoading && (
        <Box>
          <Form
            onSubmit={onSubmit}
            initialValues={templateInitialValue}
            render={(props) => (
              <SubPage
                prefix={prefix}
                innerSx={{
                  padding: "8px",
                }}
              >
                <EmailTemplateForm {...props} />
                <UpdateFormActions
                  onSave={props.handleSubmit}
                  onReset={() => props.form.reset()}
                  onDelete={() => setDeleteDialogOpen(true)}
                  disabledDelete={props.submitting}
                  disabledReset={props.submitting || props.pristine}
                  disabledSave={props.submitting || props.pristine}
                  isLoading={isLoading}
                  isDeleting={isDeleting}
                />
              </SubPage>
            )}
          />
        </Box>
      )}
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={template?.description ?? ""}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default EmailTemplateUpdatePage;

import { useIntl } from "react-intl";
import get from "lodash/get";
import { SvgIcon } from "@mui/material";

import { useGetOne } from "@mb-pro-ui/utils";
import { User as _User, Org } from "@mb-pro-ui/utils/types/admin";

import { Module, ModuleID } from "../components/types";

import ozoneIcon from "../icons/ozonewhite.png";

import { ReactComponent as DispatcherIcon } from "../icons/module/Dispatcher.svg";
import { ReactComponent as VehicleIcon } from "../icons/module/Vehicle.svg";
import { ReactComponent as PatrolIcon } from "../icons/module/Patrol.svg";
import { ReactComponent as StockIcon } from "../icons/module/Stock.svg";
import { ReactComponent as ConsumptionIcon } from "../icons/module/Consumption.svg";
import { ReactComponent as IndoorPositioningIcon } from "../icons/module/IndoorPositioning.svg";
import { ReactComponent as TelemedicinaIcon } from "../icons/module/Telemedicina.svg";
import { ReactComponent as TemperatureIcon } from "../icons/module/Temperature.svg";
import { ReactComponent as VideoIcon } from "../icons/module/Video.svg";

type User = Pick<
  _User,
  | "id"
  | "type"
  | "admin"
  | "alarm-operator"
  | "gps-user"
  | "ozone-admin"
  | "org"
  | "view-flags"
> & { org: Org };

export interface ImageProps {
  width: string;
  height: string;
}

const isModuleHidden = (
  user: User | undefined,
  path: string,
  defaultEnabled = true
) =>
  !get(
    user,
    `view-flags.${path}`,
    get(user, `org.view-flags.${path}`, defaultEnabled)
  );

function useModules() {
  const { formatMessage } = useIntl();

  const {
    data: user,
    isLoading,
    error,
  } = useGetOne<User>("admin/users", "me", {
    include: { org: {} },
    fields: {
      users: [
        "admin",
        "alarm-operator",
        "gps-user",
        "ozone-admin",
        "view-flags",
        "org",
      ],
    },
    notifyOnChangeProps: "tracked",
  });

  const enableNewModules = true;
  const disableForSecuriForum = false;

  const modules: Module[] = [
    {
      id: ModuleID.Dispatcher,
      name: formatMessage({
        defaultMessage: "Alarm Monitoring",
        description: "Module name Alarm Monitoring",
      }),
      path: "/alarm/operator",
      icon: (props) => <SvgIcon component={DispatcherIcon} {...props} />,
      active: !!user?.["alarm-operator"],
      info: "Dispatcher module",
    },
    {
      id: ModuleID.Patrol,
      name: formatMessage({
        defaultMessage: "Patrol Monitoring",
        description: "Module name Patrol Monitoring",
      }),
      icon: (props) => (
        <SvgIcon component={PatrolIcon} viewBox="-2 0 22 24" {...props} />
      ),
      path: "/alarm-patrol",
      active: !!user?.["alarm-operator"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Patrol Monitoring",
        description: "Module name Patrol Monitoring",
      }),
    },
    {
      id: ModuleID.Vehicle,
      name: formatMessage({
        defaultMessage: "Vehicle Monitoring",
        description: "Module name Vehicle Monitoring",
      }),
      icon: (props) => <SvgIcon component={VehicleIcon} {...props} />,
      path: "/gps",
      active: !!user?.["gps-user"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Vehicle Monitoring",
        description: "Module name Vehicle Monitoring",
      }),
    },
    {
      id: ModuleID.IndoorPositioning,
      name: formatMessage({
        defaultMessage: "Indoor Positioning",
        description: "Module name Indoor Positioning",
      }),
      icon: (props) => <SvgIcon component={IndoorPositioningIcon} {...props} />,
      path: "/indoorPositioning",
      active: user?.["admin"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Indoor Positioning",
        description: "Module name Indoor Positioning",
      }),
    },
    {
      id: ModuleID.Video,
      name: formatMessage({
        defaultMessage: "Remote Video Controll",
        description: "Module name Remote Video Controll",
      }),
      icon: (props) => <SvgIcon component={VideoIcon} {...props} />,
      path: "/video",
      active: enableNewModules && user?.["admin"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Remote Video Controll",
        description: "Module name Remote Video Controll",
      }),
    },
    {
      id: ModuleID.Telemedicina,
      name: formatMessage({
        defaultMessage: "Telemedicina",
        description: "Module name Telemedicina",
      }),
      icon: (props) => <SvgIcon component={TelemedicinaIcon} {...props} />,
      path: "/telemedicina",
      active: enableNewModules && user?.["admin"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Telemedicina",
        description: "Module name Telemedicina",
      }),
    },
    {
      id: ModuleID.Temperature,
      name: formatMessage({
        defaultMessage: "Temperature Monitoring",
        description: "Module name Temperature Monitoring",
      }),
      icon: (props) => <SvgIcon component={TemperatureIcon} {...props} />,
      path: "/temperature",
      active: enableNewModules && user?.["admin"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Temperature Monitoring",
        description: "Module name Temperature Monitoring",
      }),
    },
    {
      id: ModuleID.Consumption,
      name: formatMessage({
        defaultMessage: "Consumption Monitoring",
        description: "Module name Consumption Monitoring",
      }),
      icon: (props) => <SvgIcon component={ConsumptionIcon} {...props} />,
      path: "/consumption",
      active: enableNewModules && user?.["admin"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Consumption Monitoring",
        description: "Module name Consumption Monitoring",
      }),
    },
    {
      id: ModuleID.Stock,
      name: formatMessage({
        defaultMessage: "Stock Monitoring",
        description: "Module name Stock Monitoring",
      }),
      icon: (props) => <SvgIcon component={StockIcon} {...props} />,
      path: "/alarm/stock-monitoring",
      active: !!user?.["alarm-operator"] && disableForSecuriForum,
      info: formatMessage({
        defaultMessage: "Stock Monitoring",
        description: "Module name Stock Monitoring",
      }),
    },
    {
      id: ModuleID.Ozone,
      name: formatMessage({
        defaultMessage: "Ozone White",
        description: "Ozone White",
      }),
      img: (width = "100px", height = "100px") => (
        <img src={ozoneIcon} alt="" width={width} height={height} />
      ),
      path: "/ozone",
      active:
        !!user?.["ozone-admin"] || (user?.["admin"] && disableForSecuriForum),
      hidden: isModuleHidden(user, "categories.ozone", false),
      info: formatMessage({
        defaultMessage: "Ozone White",
        description: "Ozone White",
      }),
    },
  ];

  modules.sort((a, b) => {
    let h1 = a.active ? 1 : 0;
    let h2 = b.active ? 1 : 0;
    if (h1 < h2) return 1;
    if (h2 < h1) return -1;
    return 0;
  });

  const activeModules = modules.filter(
    ({ active, hidden }) => active && !hidden
  );

  const inactiveModules = modules.filter(
    ({ active, hidden }) => !active && !hidden
  );

  return { activeModules, inactiveModules, isLoading, error };
}

export default useModules;

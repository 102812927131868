import {
  TextField,
  TextFieldProps,
  NumericField,
} from "@mb-pro-ui/components/form";
import { TextField as MBPTextField } from "@mb-pro-ui/components";

import { FormHeader, FormHeaderProps } from "@mb-pro-ui/components";

import { styled } from "@mui/system";

export const StyledFormheader = styled((props: FormHeaderProps) => (
  <FormHeader {...props} />
))(({ theme }) => ({
  marginTop: "85px",
  backgroundColor: theme.palette.primary.main,
  marginBottom: "52px",
}));

export const StyledTextField = styled((props: TextFieldProps) => (
  <TextField {...props} />
))({
  marginBottom: "8px",
  marginTop: "8px",
  width: "calc(100% - 16px);",
});

export const StyledNumericField = styled((props: TextFieldProps) => (
  <NumericField {...props} />
))({
  marginBottom: "8px",
  marginTop: "8px",
  width: "calc(100% - 16px);",
});

export const StyledMBPTextField = styled((props: TextFieldProps) => (
  <MBPTextField {...props} />
))({
  marginBottom: "8px",
  marginTop: "8px",
  width: "calc(100% - 16px);",
});

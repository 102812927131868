import { useMemo, useRef } from "react";
import { FeatureGroup, Pane, Tooltip } from "react-leaflet";
import { LeafletEventHandlerFnMap, Map as LeafletMap } from "leaflet";
import { Maybe } from "@mb-pro-ui/utils/types";

import MarkerClusterGroup, {
  ClusterGroupEventHandlerFnMap,
} from "./MarkerClusterGroup";
import BaseMap from "./BaseMap";
import DivIconMarker, { DivIconMarkerProps } from "./DivIconMarker";
import { defaultIcons, MarkerIcon } from "./icons";
import Marker from "./Marker";
import { Icon, MapProfile, MarkerInfo, MarkerProps } from "./types";

export interface MapProps {
  clusteredMarkers?: MarkerProps[];
  unclusteredMarkers?: MarkerProps[];
  onGroupClick?: (info: MarkerInfo[]) => void;
  onMapObjectClick?: (info: MarkerInfo) => void;
}

const COLORS = {
  activeTrack: "#B71C1CCC",
  inactiveTrack: "#000B",
  alarmAlarm: "#B71C1CCC",
  alarmNormal: "#000B",
};

export function intToColor(color: number) {
  const a = (color >> 24) & 0xff;
  const r = (color >> 16) & 0xff;
  const g = (color >> 8) & 0xff;
  const b = color & 0xff;
  return `rgba(${r},${g},${b},${a / 255})`;
}

function colorToInt(r: number, g: number, b: number, a: number = 1) {
  return (
    ((Math.round(a * 255) & 0xff) << 24) |
    ((r & 0xff) << 16) |
    ((g & 0xff) << 8) |
    (b & 0xff)
  );
}

export const defaultAlarmProfile: MapProfile = {
  "alarm-icon": defaultIcons.siren,
  "base-icon": defaultIcons.house,
  icon: defaultIcons.siren,
  "track-icon": defaultIcons.flag,
  "line-icon": defaultIcons.shoeprint,
  "line-color": colorToInt(0, 0, 0),
  "line-style": null,
  "line-arrow": false,
  "line-icon-rotated": true,
  "line-icon-spacing": 20,
  "line-thickness": 0,
};

const MbMarker = ({
  id,
  position,
  type,
  mapProfile,
  tracking,
  hidden,
  label,
  ...props
}: Omit<MarkerProps, "noGroup"> & { hidden?: boolean } & Omit<
    DivIconMarkerProps,
    "iconSource" | "icon" | "iconOptions"
  >) => {
  if (!mapProfile) {
    mapProfile = defaultAlarmProfile;
  }

  const alarm = type === "alarm@alarm";
  let icon: Maybe<Icon> = null;
  switch (type) {
    case "track":
    case "alarm@icon":
      icon = mapProfile.icon;
      break;
    case "alarm@alarm":
      icon = mapProfile["alarm-icon"];
      break;
    case "alarm@base":
      icon = mapProfile["base-icon"];
      break;
    case "alarm@track":
      icon = mapProfile["track-icon"];
      break;
  }

  return (
    <DivIconMarker
      id={id}
      position={position}
      iconSource={icon}
      icon={
        <MarkerIcon
          icon={icon}
          alarm={alarm}
          color={
            type === "track"
              ? tracking
                ? COLORS.activeTrack
                : COLORS.inactiveTrack
              : alarm
              ? COLORS.alarmAlarm
              : COLORS.alarmNormal
          }
          iconColor={intToColor(mapProfile["line-color"]) || "#000"}
        />
      }
      iconOptions={{
        className:
          (alarm ? "marker alarm-marker" : "marker") +
          (hidden ? " hidden" : ""),
        iconSize: [40, 80],
        iconAnchor: [40, 80],
        tooltipAnchor: [30, -50],
      }}
      type={type}
      mapProfile={mapProfile}
      {...props}
    >
      {label ? (
        <Tooltip direction="right" permanent>
          {label}
        </Tooltip>
      ) : null}
    </DivIconMarker>
  );
};

const MbMap = ({
  clusteredMarkers,
  unclusteredMarkers,
  onGroupClick: onGroupClick_,
  onMapObjectClick: onMapObjectClick_,
}: MapProps) => {
  const mapRef = useRef<LeafletMap>();
  const onGroupClick = useRef(onGroupClick_);
  const onMapObjectClick = useRef(onMapObjectClick_);
  onGroupClick.current = onGroupClick_;
  onMapObjectClick.current = onMapObjectClick_;

  const clusterGroupEventHandlers: ClusterGroupEventHandlerFnMap = useMemo(
    () => ({
      clusterclick(event) {
        if (!onGroupClick.current) {
          return;
        }

        if (mapRef.current?.getZoom() !== mapRef.current?.getMaxZoom()) {
          return;
        }

        const groupedMarkers = event.propagatedFrom.getAllChildMarkers();
        if (
          groupedMarkers.every(
            (marker) => marker.getLatLng().distanceTo(event.latlng) >= 80
          )
        ) {
          return;
        }

        onGroupClick.current(
          groupedMarkers.map(
            ({ options: { id, type, iconSource: icon, mapProfile, obj } }) => ({
              id,
              type,
              icon,
              mapProfile,
              obj,
            })
          )
        );

        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();
      },
    }),
    []
  );

  const markerEventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      click(event) {
        if (!onMapObjectClick.current) {
          return;
        }

        const {
          options: { id, type, iconSource: icon, mapProfile, obj },
        }: Marker = event.target;

        onMapObjectClick.current({ id, type, icon, mapProfile, obj });
      },
    }),
    []
  );

  return (
    <BaseMap
      whenCreated={(map) => {
        mapRef.current = map;
      }}
    >
      {Array.isArray(clusteredMarkers) && clusteredMarkers.length > 0 && (
        <Pane name="clusterPane" style={{ zIndex: 610 }}>
          <MarkerClusterGroup
            spiderfyOnMaxZoom={false}
            showCoverageOnHover={true}
            zoomToBoundsOnClick
            eventHandlers={clusterGroupEventHandlers}
          >
            {clusteredMarkers.map(({ id, ...props }) => (
              <MbMarker
                key={id}
                id={id}
                {...props}
                eventHandlers={markerEventHandlers}
              />
            ))}
            {unclusteredMarkers?.map(({ id, ...props }) => (
              <MbMarker
                key={id}
                id={id}
                {...props}
                hidden
                eventHandlers={markerEventHandlers}
              />
            ))}
          </MarkerClusterGroup>
        </Pane>
      )}
      {Array.isArray(unclusteredMarkers) && unclusteredMarkers.length > 0 && (
        <FeatureGroup>
          {unclusteredMarkers.map(({ id, ...props }) => (
            <MbMarker
              key={id}
              id={id}
              {...props}
              eventHandlers={markerEventHandlers}
            />
          ))}
        </FeatureGroup>
      )}
    </BaseMap>
  );
};

export default MbMap;

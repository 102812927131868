import {
  Button,
  Icon,
  Popper,
  ClickAwayListener,
  Autocomplete,
  Box,
  InputBase,
  autocompleteClasses,
  Typography,
  PopperProps,
  AutocompleteCloseReason,
  Divider,
  lighten,
  alpha,
  FilterOptionsState,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, {
  MouseEvent,
  useState,
  ChangeEvent,
  ReactNode,
  HTMLAttributes,
  useCallback,
  useEffect,
  SyntheticEvent,
  useRef,
  memo,
} from "react";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import { areEqual, FixedSizeList, ListChildComponentProps } from "react-window";
import { FilterViewProps } from "../../types";
import Grow from "../../../../utils/Grow";
import MbProFilterButton from "../../../components/MbProFilterButton";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import isEmpty from "lodash/isEmpty";
import without from "lodash/without";
import { isEqualById } from "../../../../utils/autocompleteUtils";

const StyledInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-root": {
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    borderBottom: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
    width: "100%",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: `1px solid ${theme.palette.grey[400]}`,
      fontSize: 14,
      "&:focus": {
        boxShadow: `0px 0px 0px 3px ${lighten(
          theme.palette.primary.main,
          0.5
        )}`,
        borderColor: `${theme.palette.primary.main}`,
      },
    },
  },
}));

const CustomPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: `0 8px 24px ${alpha(theme.palette.grey[500], 0.2)}`,
  borderRadius: 6,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: `${theme.palette.text.primary}`,
  backgroundColor: theme.palette.background.paper,
}));

const CustomAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
    },
  },
}));

const OptionContainer = styled("div")({
  overflow: "hidden",
  flex: "1 1 80%",
  display: "flex",
});

const DividerMemo = memo(Divider);

const Row = memo((props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];

  return (
    <div style={style}>
      {dataSet}
      <DividerMemo />
    </div>
  );
}, areEqual);

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;
  const itemCount = (children as React.ReactChild[]).length;
  const itemSize = React.useContext(VirtualizationContext);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={children}
          width="100%"
          height={(itemSize + 1) * 5} // item + divider
          outerElementType={OuterElementType}
          itemSize={itemSize}
          overscanCount={3}
          itemCount={itemCount}
        >
          {Row}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const PopperComponent = (props: PopperProps) => {
  const { disablePortal, anchorEl, open, ...other } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  return <CustomAutocompletePopper {...other} style={{ width: "100%" }} />;
};

interface CustomAutocompleteProps<OptionType> {
  options: OptionType[] | undefined;
  getOptionLabel: (option: OptionType) => string;
  renderOption?: (option: OptionType) => ReactNode;
  inputPlaceholder?: string;
  label?: string;
  getOptionDisabled?: (option: OptionType) => boolean;
  filterOptions?: (
    options: OptionType[],
    state: FilterOptionsState<OptionType>
  ) => OptionType[];
  showSelected?: boolean;
  noOptionsText?: string;
  virtualizedRowHeight?: number;
  sortBySelection?: boolean;
  headerActions?: ReactNode;
  handleClose?: () => void;
}

const PopperContainer = styled("div")({
  width: "350px",
});

const DoneBox = styled(Box)({
  width: 17,
  height: 17,
  mr: "5px",
  ml: "-2px",
});

const CloseBox = styled(Box)({
  opacity: 0.6,
  width: 18,
  height: 18,
});

interface IdAware {
  id: string;
}

interface IdAndAccountAware extends IdAware {
  account: string;
}

function isAccountAware(
  option: IdAware | IdAndAccountAware
): option is IdAndAccountAware {
  return (option as IdAndAccountAware).account !== undefined;
}

const VirtualizationContext = React.createContext(50);

const AutocompleteFilter = <OptionType extends IdAware | IdAndAccountAware>({
  options,
  renderOption,
  getOptionLabel,
  label,
  filterId,
  filterValue,
  setFilter,
  urlSearchParam,
  setUrlSearchParam,
  getOptionDisabled,
  filterOptions,
  inputPlaceholder,
  showSelected,
  noOptionsText,
  virtualizedRowHeight,
  sortBySelection = true,
  headerActions,
  handleClose,
}: CustomAutocompleteProps<OptionType> & FilterViewProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [values, setValues] = useState<OptionType[]>([]);
  const [pendingValues, setPendingValues] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { formatMessage } = useIntl();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setPendingValues(values);
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setValues(pendingValues);
    setInputValue("");
    handleClose?.();
    setAnchorEl(null);
  };

  const handleInputChange = (
    event: SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (reason === "input") {
      setInputValue(value);
    }
  };

  const handleSelectAllClick = () => {
    if (options) {
      if ((pendingValues && pendingValues.length) === options.length) {
        setPendingValues([]);
      } else {
        setPendingValues(options);
      }
    }
  };

  useEffect(() => {
    if (isEmpty(filterValue)) {
      setPendingValues([]);
      setValues([]);
    }
  }, [filterValue]);

  useEffect(() => {
    if (options && values) {
      if (options.length === values.length) {
        setPendingValues([]);
        setValues([]);
      }
    }
  }, [values, options]);

  const consolidatedRef = useRef(false);

  useEffect(() => {
    if (options && consolidatedRef.current) {
      const filterValues = values.map(getOptionLabel);

      if (filterValues.length > options.length / 2) {
        const withoutFilterValues = without(
          options.map(getOptionLabel),
          ...filterValues
        );

        setFilter(filterId, withoutFilterValues, true);
        setUrlSearchParam(
          filterId,
          !isEmpty(withoutFilterValues)
            ? "-" + withoutFilterValues.toString()
            : ""
        );
      } else {
        setFilter(filterId, filterValues);
        setUrlSearchParam(
          filterId,
          values.map((value) => getOptionLabel(value)).toString()
        );
      }
    }
  }, [values, setFilter, filterId, getOptionLabel, options, setUrlSearchParam]);

  useEffect(() => {
    if (options && options.length > 0) {
      let paramsArray;

      if (urlSearchParam?.startsWith("-")) {
        paramsArray = without(
          options.map(getOptionLabel),
          ...urlSearchParam.substring(1).split(",")
        );
      } else {
        paramsArray = urlSearchParam?.split(",");
      }

      paramsArray
        ? setValues(
            paramsArray
              .map((param) =>
                options.find((option) => param === getOptionLabel(option))
              )
              .filter(Boolean) as OptionType[]
          )
        : setValues([]);

      consolidatedRef.current = true;
    }
  }, [options, urlSearchParam, getOptionLabel]);

  const open = Boolean(anchorEl);

  const sort = useCallback(
    (options: OptionType[]) =>
      options.slice().sort((a, b) => {
        if (values) {
          let ai = values.findIndex((value) => value.id === a.id);
          ai =
            ai === -1
              ? values.length +
                options.findIndex((option) => option.id === a.id)
              : ai;
          let bi = values.findIndex((value) => value.id === b.id);
          bi =
            bi === -1
              ? values.length +
                options.findIndex((option) => option.id === b.id)
              : bi;
          return ai - bi;
        } else {
          return 0;
        }
      }),

    [values]
  );

  return (
    <>
      <MbProFilterButton
        variant="outlined"
        endIcon={
          <Icon fontSize="inherit">
            <DoneAllIcon />
          </Icon>
        }
        onClick={handleClick}
        active={open}
        emphasize={!isEmpty(filterValue)}
      >
        <Typography>
          {label ?? "Select"}{" "}
          {values && values.length > 0 ? `(${values.length})` : ""}
        </Typography>
      </MbProFilterButton>
      <CustomPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handlePopupClose}>
          <PopperContainer key={"popperContainer"}>
            <Box
              sx={{
                borderBottom: (theme) =>
                  `1px solid ${lighten(theme.palette.divider, 0.5)}`,
                padding: "8px 10px",
                display: "flex",
              }}
            >
              {headerActions}
              <Tooltip
                title={formatMessage({
                  defaultMessage: "Select All",
                  description:
                    "Interventions table customer filter select all tooltip",
                })}
              >
                <IconButton onClick={handleSelectAllClick}>
                  <DoneAllIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Grow />
              <Button onClick={handlePopupClose}>
                {formatMessage({
                  defaultMessage: "Search",
                  description:
                    "Intervention table customer filter search button label",
                })}
              </Button>
            </Box>
            {showSelected && pendingValues && pendingValues.length > 0 && (
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `1px solid ${lighten(theme.palette.divider, 0.5)}`,
                  padding: "8px 10px",
                  maxHeight: (theme) => theme.spacing(12),
                  overflow: "auto",
                }}
              >
                {pendingValues.map((option) => (
                  <Chip
                    label={
                      isAccountAware(option)
                        ? option.account
                        : getOptionLabel(option)
                    }
                    key={option.id}
                    size="small"
                    variant={"outlined"}
                    color="primary"
                    avatar={<Avatar>U</Avatar>}
                    onDelete={() => {
                      setPendingValues((pValues) =>
                        pValues?.filter((value) => value.id !== option.id)
                      );
                    }}
                  />
                ))}
              </Box>
            )}
            <VirtualizationContext.Provider value={virtualizedRowHeight ?? 50}>
              <Autocomplete
                value={pendingValues ?? []}
                options={
                  options ? (sortBySelection ? sort(options) : options) : []
                }
                isOptionEqualToValue={isEqualById}
                getOptionDisabled={getOptionDisabled}
                open
                multiple
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    (event as React.KeyboardEvent).key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setPendingValues(newValue);
                }}
                noOptionsText={noOptionsText}
                onClose={(
                  event: ChangeEvent<{}>,
                  reason: AutocompleteCloseReason
                ) => {
                  if (reason === "escape") {
                    handlePopupClose();
                  }
                }}
                renderInput={(params) => (
                  <StyledInput
                    placeholder={inputPlaceholder}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                  />
                )}
                renderOption={useCallback(
                  (props, option, { selected }) => {
                    return (
                      <li {...props} style={{ width: "100%" }}>
                        <DoneBox
                          component={DoneIcon}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                        <OptionContainer>
                          {renderOption
                            ? renderOption(option)
                            : getOptionLabel(option)}
                        </OptionContainer>
                        <CloseBox
                          component={CloseIcon}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                      </li>
                    );
                  },
                  [renderOption, getOptionLabel]
                )}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                getOptionLabel={getOptionLabel}
                renderTags={() => null}
                disableCloseOnSelect
                PopperComponent={PopperComponent}
                ListboxComponent={
                  virtualizedRowHeight ? ListboxComponent : undefined
                }
                filterOptions={filterOptions}
              />
            </VirtualizationContext.Provider>
          </PopperContainer>
        </ClickAwayListener>
      </CustomPopper>
    </>
  );
};

export default AutocompleteFilter;

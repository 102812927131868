import { useMemo } from "react";

import { Column, Row } from "react-table";

import { Typography } from "@mui/material";

import { Autocomplete, Checkbox } from "@mb-pro-ui/components/form";

import { StyledNumericField, StyledTextField } from "../form/StyledComponents";

import { useGetAll } from "@mb-pro-ui/utils";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Category, Event, IntervalRule } from "./types";

import { useIntl } from "react-intl";
import LinkButton from "../utils/LinkButton";

import { Lang } from "../types";
import { useLocale } from "../../locales/LocaleProvider";
import FormTable from "../form/FormTable";
import { FormSpy } from "react-final-form";
import { isEqualById } from "../utils/autocompleteUtils";

interface IntervalRulesDialogProps {
  events: Event[] | undefined;
  areEventsLoading: boolean;
  expectationTypes: any[];
}

const IntervalRulesDialog = ({
  events,
  areEventsLoading,
  expectationTypes,
}: IntervalRulesDialogProps) => {
  const { formatMessage } = useIntl();

  const { locale } = useLocale();

  const { data: categories, isLoading: areCategoriesLoading } =
    useGetAll<Category>("alarm/categories", {
      refetchOnWindowFocus: false,
    });

  if (areEventsLoading && areCategoriesLoading) {
    return <Typography>Loading...</Typography>;
  }

  return events && categories ? (
    <>
      <Autocomplete
        name="expectation-type"
        label={formatMessage({
          defaultMessage: "Expectation type",
        })}
        options={[...Array(4).keys()]}
        getOptionLabel={(option) => expectationTypes[option] ?? ""}
        fullWidth
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
        required
      />
      <Checkbox
        name="active"
        label={formatMessage({ defaultMessage: "Active" })}
      />
      <StyledTextField
        name="number"
        label={formatMessage({ defaultMessage: "Number" })}
        inputProps={{ maxLength: 3 }}
      />
      <FormSpy subscription={{ values: true }}>
        {({ values }) =>
          values["expectation-type"] === 0 ||
          values["expectation-type"] === 1 ? (
            <>
              <Autocomplete
                name="event-category"
                label={formatMessage({ defaultMessage: "Event category" })}
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.descr?.[Lang[locale]] ?? ""}
                isEqual={isEqualById}
                sx={{
                  marginLeft: "8px",
                  width: "calc(100% - 16px)",
                }}
                required
              />
              <StyledTextField
                name="event-zone"
                label={formatMessage({ defaultMessage: "Zone/User/RFID" })}
              />
            </>
          ) : (
            <></>
          )
        }
      </FormSpy>
      <StyledTextField
        name="event-partition"
        label={formatMessage({ defaultMessage: "Partition" })}
      />
      <FormSpy subscription={{ values: true }}>
        {({ values }) =>
          values["expectation-type"] === 0 ||
          values["expectation-type"] === 1 ? (
            <StyledNumericField
              name="min-event-count"
              label={formatMessage({ defaultMessage: "Min event count" })}
              InputProps={{ inputProps: { step: 1 } }}
            />
          ) : (
            <></>
          )
        }
      </FormSpy>

      <StyledTextField
        name="interval-start-time"
        label={formatMessage({
          defaultMessage: "Interval start time",
        })}
      />
      <StyledTextField
        name="interval-length"
        label={formatMessage({ defaultMessage: "Interval length" })}
      />
      <StyledNumericField
        name="rep-count"
        label={formatMessage({ defaultMessage: "Repetitions" })}
        InputProps={{ inputProps: { step: 1 } }}
      />
      <StyledTextField
        name="rep-interval"
        label={formatMessage({
          defaultMessage: "Repetition delay",
        })}
      />
      <Autocomplete
        name="alert-event-code"
        label={formatMessage({
          defaultMessage: "Alert event code",
          description: "Ügyfélkezelő egyedi riasztási esemény kód",
        })}
        options={events.map(({ code }) => code)}
        fullWidth
        getOptionLabel={(option) =>
          `${option ?? ""} - ${
            events.find((e) => e.code === option)?.descr[Lang[locale]] ?? ""
          }`
        }
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
      />
    </>
  ) : (
    <></>
  );
};

interface IntervalRulesProps {
  readOnly?: boolean;
}

const IntervalRules = ({ readOnly }: IntervalRulesProps) => {
  const action = (row: Row<IntervalRule>, url: string) =>
    readOnly ? (
      <></>
    ) : (
      <LinkButton row={row} url={`${url}/interval-rules/byNum/${row.index}`} />
    );

  const { data: events, isLoading: areEventsLoading } = useGetAll<Event>(
    "alarm/events",
    {
      refetchOnWindowFocus: false,
      page: { limit: 500 }, //TODO
    }
  );

  const { locale } = useLocale();

  const { formatMessage } = useIntl();

  const expectationTypes = useMemo(
    () => [
      formatMessage({ defaultMessage: "Expect event" }),
      formatMessage({ defaultMessage: "Expect no event" }),
      formatMessage({ defaultMessage: "Expect armed" }),
      formatMessage({ defaultMessage: "Expect not armed" }),
    ],
    [formatMessage]
  );

  const columns = useMemo(
    (): Column<IntervalRule>[] => [
      {
        id: "number",
        Header: formatMessage({
          defaultMessage: "Number",
        }),
        accessor: (p) => p.number,
        width: "28",
      },
      {
        id: "active",
        Header: formatMessage({
          defaultMessage: "Active",
        }),
        accessor: (p) =>
          p.active ? (
            <CheckIcon
              sx={{
                color: "success.main",
              }}
            />
          ) : (
            <CloseIcon
              sx={{
                color: "error.main",
              }}
            />
          ),
        width: "20",
      },
      {
        id: "date-code",
        Header: formatMessage({ defaultMessage: "Date code" }),
        accessor: (p) => p["date-code"]?.descr[Lang[locale]] ?? "",
        width: "30",
      },
      {
        id: "event-category",
        Header: formatMessage({ defaultMessage: "Event category" }),
        accessor: (p) => p["event-category"]?.descr[Lang[locale]] ?? "",
        width: "40",
      },
      {
        id: "expectation-type",
        Header: formatMessage({ defaultMessage: "Expectation type" }),
        accessor: (p) => expectationTypes[p["expectation-type"]] ?? "",
        width: "40",
      },
      {
        id: "event-partition",
        Header: formatMessage({ defaultMessage: "Partition/Zone" }),
        accessor: (p) =>
          `${p["event-partition"] ?? ""}${
            p["event-partition"] && p["event-zone"] ? "/" : ""
          }${p["event-zone"] ?? ""}`,
        width: "30",
      },
      {
        id: "interval-start-time",
        Header: formatMessage({
          defaultMessage: "Interval start time",
        }),
        accessor: (p) => p["interval-start-time"] ?? "",
        width: "30",
      },
      {
        id: "interval-length",
        Header: formatMessage({ defaultMessage: "Interval length" }),
        accessor: (p) => p["interval-length"] ?? "",
        width: "30",
      },
      {
        id: "rep-count",
        Header: formatMessage({ defaultMessage: "Repetitions" }),
        accessor: (p) => p["rep-count"] ?? "",
        width: "28",
      },
      {
        id: "rep-interval",
        Header: formatMessage({ defaultMessage: "Repetition delay" }),
        accessor: (p) => p["rep-interval"] ?? "",
        width: "40",
      },
    ],
    [formatMessage, locale, expectationTypes]
  );

  return (
    <FormTable<IntervalRule>
      name="interval-rules"
      columns={columns}
      action={action}
      dialogNewTitle={formatMessage({ defaultMessage: "New rule" })}
      dialogEditTitle={formatMessage({ defaultMessage: "Edit rule" })}
      dialog={
        <IntervalRulesDialog
          events={events}
          areEventsLoading={areEventsLoading}
          expectationTypes={expectationTypes}
        />
      }
    />
  );
};

export default IntervalRules;

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  PropsWithChildren,
} from "react";
import { IntlProvider } from "react-intl";

import { useStorage } from "@mb-pro-ui/utils";
import en_messages from "./en.json";
import hu_messages from "./hu.json";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import "moment/locale/hu";
import "moment/locale/en-gb";

import {
  IntlErrorCode,
  MessageDescriptor,
  MissingTranslationError,
} from "@formatjs/intl";

const defaultLocale = "en";

const localizedMessages = {
  en: en_messages,
  hu: hu_messages,
};

export type LocaleName = keyof typeof localizedMessages;
const locales = Object.keys(localizedMessages) as LocaleName[];

function mapLocale(locale: unknown): LocaleName {
  if (typeof locale !== "string") {
    return defaultLocale;
  }

  const validLocales = Object.keys(localizedMessages);
  if (validLocales.includes(locale)) {
    return locale as LocaleName;
  }

  const splitLocale = locale.split("-")[0].toLocaleLowerCase();
  if (validLocales.includes(splitLocale)) {
    return splitLocale as LocaleName;
  }

  return defaultLocale;
}

export interface Locale {
  locale: LocaleName;
  locales: LocaleName[];
  setLocale: (locale: string) => void;
}

const LocaleContext = createContext<Locale | undefined>(undefined);

export const useLocale = () => {
  const value = useContext(LocaleContext);
  if (value === undefined) {
    throw new Error("useLocale must be used inside LocaleProvider");
  }
  return value;
};

function checkMissingTranslations() {
  for (const [locale, messages] of Object.entries(localizedMessages)) {
    const not_translated_prefix = `[${locale}]: `;
    for (const [id, str] of Object.entries(messages)) {
      if (str.startsWith(not_translated_prefix)) {
        console.log(`Missing translation: [${locale}] id=[${id}]: ${str}`);
      }
    }
  }
}

function printTranslationSuggestion(d: MessageDescriptor): any {
  const msg =
    typeof d.defaultMessage === "string"
      ? d.defaultMessage
      : (d.defaultMessage as any[])
          .map((m) => {
            if (m.value) {
              return m.type === 0 ? m.value : "{" + m.value + "}";
            }
            return "";
          })
          .join("");
  return '"' + d.id + '": "' + msg + '",';
}

function onLocaleError(err: any) {
  if (err.code === IntlErrorCode.MISSING_TRANSLATION) {
    const e = err as MissingTranslationError;
    if (e.descriptor) {
      console.log(
        "Missing translation: ",
        printTranslationSuggestion(e.descriptor)
      );
      return;
    }
  }
  console.error(err);
}

export default function LocaleProvider({ children }: PropsWithChildren<{}>) {
  const [_locale, _setLocale] = useStorage<unknown>(
    "locale",
    navigator.language
  );

  const locale = mapLocale(_locale);
  const setLocale = useCallback(
    (locale) => {
      _setLocale(mapLocale(locale));
    },
    [_setLocale]
  );

  const value = useMemo(() => {
    checkMissingTranslations();
    return { locale, setLocale, locales };
  }, [locale, setLocale]);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={locale}
        messages={localizedMessages[locale]}
        defaultLocale={defaultLocale}
        onError={onLocaleError}
      >
        <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
          {children}
        </LocalizationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

import { MenuItemProps, SxProps } from "@mui/material";
import FormControl, { FormControlProps as MuiFormControlProps } from "@mui/material/FormControl";
import FormHelperText, {
  FormHelperTextProps as MuiFormHelperTextProps,
} from "@mui/material/FormHelperText";
import InputLabel, { InputLabelProps as MuiInputLabelProps } from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import React, { useMemo } from "react";

export interface InjectedOptionProps<T> {
  option: T;
  index: number;
}

export interface SelectProps<
  V extends string,
  T extends { [k in V]: string | number | object } = { [k in V]: string | number | object }
> extends Omit<MuiSelectProps, "children"> {
  label?: string;
  items?: T[];
  valueKey: V;
  helperText?: string;
  error?: boolean;
  renderOption?:
    | ((props: InjectedOptionProps<T>) => JSX.Element | JSX.Element[] | string)
    | React.ReactElement<InjectedOptionProps<T>>;
  FormControlProps?: Partial<MuiFormControlProps>;
  InputLabelProps?: Partial<MuiInputLabelProps>;
  FormHelperTextProps?: Partial<MuiFormHelperTextProps>;
  getMenuItemProps?: (option: T, index: number) => MenuItemProps;
  outerSx?: SxProps;
}

const Select = <
  V extends string,
  T extends { [k in V]: string | number } = { [k in V]: string | number }
>({
  items,
  valueKey = "value" as V,
  label,
  labelId,
  helperText,
  error,
  className,
  FormControlProps,
  InputLabelProps,
  FormHelperTextProps,
  renderOption,
  fullWidth,
  getMenuItemProps,
  outerSx,
  ...rest
}: SelectProps<V, T>) => {
  const renderItem = useMemo((): ((option: T, index: number) => JSX.Element) => {
    if (typeof renderOption === "function") {
      return (option: T, index: number) => (
        <MenuItem
          value={option[valueKey]}
          key={option[valueKey]}
          {...getMenuItemProps?.(option, index)}
        >
          {renderOption({
            option,
            index,
          })}
        </MenuItem>
      );
    }

    if (!renderOption) {
      return (option: T, index: number) => (
        <MenuItem
          value={option[valueKey]}
          key={option[valueKey]}
          {...getMenuItemProps?.(option, index)}
        >
          {option[valueKey]}
        </MenuItem>
      );
    }

    return (option: T, index: number) => (
      <MenuItem
        value={option[valueKey]}
        key={option[valueKey]}
        {...getMenuItemProps?.(option, index)}
      >
        {React.cloneElement(renderOption, { option, index })}
      </MenuItem>
    );
  }, [renderOption, valueKey, getMenuItemProps]);

  return (
    <FormControl
      variant="filled"
      className={className}
      fullWidth
      {...FormControlProps}
      sx={outerSx}
    >
      {label && (
        <InputLabel id={labelId} shrink error={error} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <MuiSelect labelId={labelId} fullWidth {...rest}>
        {items?.map(renderItem)}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;

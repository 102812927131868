import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

import { Page } from "@mb-pro-ui/components";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { SvgIcon } from "@mui/material";

import { CodeTable as _CodeTable } from "@mb-pro-ui/utils/types/alarm";

import {
  AllCodeTables,
  AllEventCategories,
  AllEventTypes,
  AllInstallers,
  AllGuards,
  AllAuthorities,
  ObjectTypeUpdatePage,
  AllObjectTypes,
  IconUpdatePage,
  AllIcons,
  AllMapProfiles,
  MapProfileUpdatePage,
  SensorTypeUpdatePage,
  AllSensorTypes,
  ZoneTypeUpdatePage,
  EquipmentTypeUpdatePage,
  BatteryTypeUpdatePage,
  SounderTypeUpdatePage,
  AllZoneTypes,
  AllEquipmentTypes,
  AllBatteryTypes,
  AllSounderTypes,
  CodeTableUpdatePage,
  EventCategoryUpdatePage,
  EventTypeUpdatePage,
  InstallerUpdatePage,
  GuardUpdatePage,
  AuthorityUpdatePage,
} from "../components/settings";

import { ReactComponent as AlarmIcon } from "../icons/system_settings/Alarm.svg";
import { ReactComponent as AuthorityIcon } from "../icons/system_settings/Authority.svg";
import { ReactComponent as CodeTableIcon } from "../icons/system_settings/CodeTable.svg";
import { ReactComponent as EquipmentIcon } from "../icons/system_settings/Equipment.svg";
import { ReactComponent as GuardIcon } from "../icons/system_settings/Guard.svg";
import { ReactComponent as IconIcon } from "../icons/system_settings/Icon.svg";
import { ReactComponent as InstallerIcon } from "../icons/system_settings/Installer.svg";
import { ReactComponent as MapProfileIcon } from "../icons/system_settings/MapProfile.svg";
import { ReactComponent as ObjectIcon } from "../icons/system_settings/Object.svg";
import { ReactComponent as SensorIcon } from "../icons/system_settings/Sensor.svg";
import { ReactComponent as SounderIcon } from "../icons/system_settings/Sounder.svg";
import { ReactComponent as ZoneTypeIcon } from "../icons/system_settings/Zone.svg";
import { ReactComponent as BatteryIcon } from "../icons/system_settings/Battery.svg";
import { ReactComponent as EventIcon } from "../icons/system_settings/Event.svg";
import { ReactComponent as CategoryIcon } from "../icons/system_settings/Category.svg";

const sidebarOptions: SideBarOption[] = [
  {
    link: "/installers",
    name: <FormattedMessage defaultMessage="Installers" />,
    icon: <SvgIcon component={InstallerIcon} />,
  },
  {
    link: "/guards",
    name: <FormattedMessage defaultMessage="Guards" />,
    icon: <SvgIcon component={GuardIcon} />,
  },
  {
    link: "/authorities",
    name: <FormattedMessage defaultMessage="Authorities" />,
    icon: <SvgIcon component={AuthorityIcon} />,
  },
  {
    link: "/code-tables",
    name: <FormattedMessage defaultMessage="Code tables" />,
    icon: <SvgIcon component={CodeTableIcon} />,
    subPages: [
      {
        link: "/code-tables",
        name: <FormattedMessage defaultMessage="Code tables" />,
        icon: <SvgIcon component={CodeTableIcon} />,
      },
      {
        link: "/event-categories",
        name: <FormattedMessage defaultMessage="Event categories" />,
        icon: <SvgIcon component={CategoryIcon} />,
      },
      {
        link: "/events",
        name: <FormattedMessage defaultMessage="Event types" />,
        icon: <SvgIcon component={EventIcon} />,
      },
    ],
  },
  {
    link: "/object-types",
    name: <FormattedMessage defaultMessage="Object types" />,
    icon: <SvgIcon component={ObjectIcon} />,
  },
  {
    link: "/icons",
    name: <FormattedMessage defaultMessage="Icons" />,
    icon: <SvgIcon component={IconIcon} />,
  },
  {
    link: "/map-profiles",
    name: <FormattedMessage defaultMessage="Map profiles" />,
    icon: <SvgIcon component={MapProfileIcon} />,
  },
  {
    link: "/sensor-types",
    name: <FormattedMessage defaultMessage="Alarm" />,
    icon: <SvgIcon component={AlarmIcon} />,
    subPages: [
      {
        link: "/sensor-types",
        name: <FormattedMessage defaultMessage="Sensor types" />,
        icon: <SvgIcon component={SensorIcon} />,
      },
      {
        link: "/zone-types",
        name: <FormattedMessage defaultMessage="Zone types" />,
        icon: <SvgIcon component={ZoneTypeIcon} />,
      },
      {
        link: "/equipment-types",
        name: <FormattedMessage defaultMessage="Equipment types" />,
        icon: <SvgIcon component={EquipmentIcon} />,
      },
      {
        link: "/battery-types",
        name: <FormattedMessage defaultMessage="Battery types" />,
        icon: <SvgIcon component={BatteryIcon} />,
      },
      {
        link: "/sounder-types",
        name: <FormattedMessage defaultMessage="Sounder types" />,
        icon: <SvgIcon component={SounderIcon} />,
      },
    ],
  },
];

const SystemSettingsPage = () => {
  const { path } = useRouteMatch();
  const matchLandingPage = useRouteMatch({
    path: "/alarm/settings",
    exact: true,
    strict: false,
  });
  const { formatMessage } = useIntl();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const breadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      "/alarm/settings": formatMessage({
        defaultMessage: "System Settings",
      }),
      "/alarm/settings/code-tables": formatMessage({
        defaultMessage: "Code tables",
      }),
      "/alarm/settings/event-categories": formatMessage({
        defaultMessage: "Event categories",
      }),
      "/alarm/settings/events": formatMessage({
        defaultMessage: "Event types",
      }),
      "/alarm/settings/installers": formatMessage({
        defaultMessage: "Installers",
      }),
      "/alarm/settings/guards": formatMessage({
        defaultMessage: "Guards",
      }),
      "/alarm/settings/authorities": formatMessage({
        defaultMessage: "Authorities",
      }),
      "/alarm/settings/object-types": formatMessage({
        defaultMessage: "Object types",
      }),
      "/alarm/settings/icons": formatMessage({
        defaultMessage: "Icons",
      }),
      "/alarm/settings/map-profiles": formatMessage({
        defaultMessage: "Map profiles",
      }),
      "/alarm/settings/sensor-types": formatMessage({
        defaultMessage: "Sensor types",
      }),
      "/alarm/settings/zone-types": formatMessage({
        defaultMessage: "Zone types",
      }),
      "/alarm/settings/equipment-types": formatMessage({
        defaultMessage: "Equipment types",
      }),
      "/alarm/settings/battery-types": formatMessage({
        defaultMessage: "Battery types",
      }),
      "/alarm/settings/sounder-types": formatMessage({
        defaultMessage: "Sounder types",
      }),
    }),
    [formatMessage]
  );

  const onSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const onSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const systemSettingsPageProps = useMemo(
    () => ({
      breadcrumbNameMap,
      sidebarOpen,
      onSidebarClose,
      onSidebarOpen,
    }),
    [breadcrumbNameMap, sidebarOpen, onSidebarClose, onSidebarOpen]
  );

  return (
    <Switch>
      <Route exact path={`${path}/code-tables/:id`}>
        <CodeTableUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/event-categories/:id`}>
        <EventCategoryUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/events/:id`}>
        <EventTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/installers/:id`}>
        <InstallerUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/guards/:id`}>
        <GuardUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/authorities/:id`}>
        <AuthorityUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/object-types/:id`}>
        <ObjectTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/icons/:id`}>
        <IconUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/map-profiles/:id`}>
        <MapProfileUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/sensor-types/:id`}>
        <SensorTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/zone-types/:id`}>
        <ZoneTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/equipment-types/:id`}>
        <EquipmentTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/battery-types/:id`}>
        <BatteryTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route exact path={`${path}/sounder-types/:id`}>
        <SounderTypeUpdatePage {...systemSettingsPageProps} />
      </Route>
      <Route>
        <Page
          sidebarOptions={sidebarOptions}
          breadcrumbs={!matchLandingPage}
          {...systemSettingsPageProps}
        >
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/installers`} />
            </Route>
            <Route exact path={`${path}/code-tables`}>
              <AllCodeTables />
            </Route>
            <Route exact path={`${path}/event-categories`}>
              <AllEventCategories />
            </Route>
            <Route exact path={`${path}/events`}>
              <AllEventTypes />
            </Route>
            <Route exact path={`${path}/installers`}>
              <AllInstallers />
            </Route>
            <Route exact path={`${path}/guards`}>
              <AllGuards />
            </Route>
            <Route exact path={`${path}/authorities`}>
              <AllAuthorities />
            </Route>
            <Route exact path={`${path}/object-types`}>
              <AllObjectTypes />
            </Route>
            <Route exact path={`${path}/icons`}>
              <AllIcons />
            </Route>
            <Route exact path={`${path}/map-profiles`}>
              <AllMapProfiles />
            </Route>
            <Route exact path={`${path}/sensor-types`}>
              <AllSensorTypes />
            </Route>
            <Route exact path={`${path}/zone-types`}>
              <AllZoneTypes />
            </Route>
            <Route exact path={`${path}/equipment-types`}>
              <AllEquipmentTypes />
            </Route>
            <Route exact path={`${path}/battery-types`}>
              <AllBatteryTypes />
            </Route>
            <Route exact path={`${path}/sounder-types`}>
              <AllSounderTypes />
            </Route>
          </Switch>
        </Page>
      </Route>
    </Switch>
  );
};

export default SystemSettingsPage;

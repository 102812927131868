import { useGetAll } from "@mb-pro-ui/utils";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column } from "react-table";
import { ObjectType } from "../types";
import { localDescr } from "../../../utils/LocaleUtils";
import { Lang } from "../../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { Typography } from "@mui/material";
import { AllEntities } from "../utils";
import ObjectTypeCreateModal from "./ObjectTypeCreateModal";

const AllObjectTypes = () => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const queryResult = useGetAll<ObjectType>("alarm/object-types", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
  });

  const columns = useMemo(
    (): Column<ObjectType>[] => [
      {
        id: "id",
        Header: formatMessage({
          defaultMessage: "ID",
        }),
        accessor: (objectType) => objectType.id,
      },
      {
        id: "description",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (objectType) => localDescr(objectType.descr, Lang[locale]),
      },
    ],
    [formatMessage, locale]
  );

  const title = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      <FormattedMessage defaultMessage="Object types" />
    </Typography>
  );

  return (
    <AllEntities
      columns={columns}
      title={title}
      queryResult={queryResult}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => {
        return (
          <ObjectTypeCreateModal
            open={isFormOpen}
            onFormClose={onFormClose}
            refetch={refetch}
            setSnackbarState={setSnackbarState}
          />
        );
      }}
    />
  );
};

export default AllObjectTypes;

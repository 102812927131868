import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterViewProps } from "../../table";
import { AutocompleteFilter } from "../../table/filters";
import { Installer } from "../types";
import {
  idAsLabel,
  nameToString,
  sortByName,
} from "../../../utils/FilterUtils";

const renderOption = (v: Installer) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.name}
  </Typography>
);

const filterOptions = createFilterOptions({
  stringify: nameToString,
  trim: true,
});

const InstallerAutocompleteFilter = (props: FilterViewProps) => {
  const { data } = useGetAll<Installer>("alarm/installers", {
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: false,
    select: (data) => [...data.sort(sortByName)],
  });
  const { formatMessage } = useIntl();

  return (
    <AutocompleteFilter
      {...props}
      options={data}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptions}
      noOptionsText={formatMessage({
        defaultMessage: "No such installer",
        description: "Autocomplete Filter",
      })}
      label={formatMessage({
        defaultMessage: "Installer",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Installers: Name",
        description: "Autocomplete Filter",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default InstallerAutocompleteFilter;

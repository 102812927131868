import { useIntl } from "react-intl";

import {
  Cdec as _Cdec,
  Intervention as _Intervention,
} from "@mb-pro-ui/utils/types/alarm";

import { Box } from "@mui/system";
import { IconButton, SvgIconProps, Typography } from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

import { useAudio } from "./AudioPlayerProvider";
import ProgressMessage from "../utils/ProgressMessage";

const CounterIcon = ({
  style,
  failureCount,
}: SvgIconProps & { failureCount?: number }) => {
  const { stopAllErrorAlert, isErrorAlertOn, errorAlertCounter } = useAudio();
  const { formatMessage } = useIntl();

  return (
    <IconButton
      onClick={() => stopAllErrorAlert()}
      disabled={!isErrorAlertOn}
      disableRipple
      sx={{
        color:
          errorAlertCounter === 0
            ? "warning.main"
            : isErrorAlertOn
            ? "primary.main"
            : "action.disabled",
        "&:hover": {
          backgroundColor: "unset",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "180px",
        }}
      >
        <NotificationImportantIcon style={style} />
        <div>
          <Typography>
            {formatMessage({
              defaultMessage: "Query error",
              description: "Table Component query error with alert message",
            })}
          </Typography>
        </div>
        <ProgressMessage
          message={`${formatMessage({
            defaultMessage: "Retry",
            description: "Table Component querying data in progress message",
          })}${failureCount === undefined ? "" : ` ${failureCount}`}`}
        />
        {isErrorAlertOn &&
          (errorAlertCounter > 0 ? (
            <Typography>
              {formatMessage(
                {
                  defaultMessage: "Alert in {counter} seconds",
                  description: "Cdec Window alert counter message",
                },
                {
                  counter: errorAlertCounter,
                }
              )}
            </Typography>
          ) : (
            <Typography>
              {formatMessage({
                defaultMessage: "Alert!",
                description: "Cdec Window alert message",
              })}
            </Typography>
          ))}
      </Box>
    </IconButton>
  );
};

export default CounterIcon;

import { useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SvgIcon, Typography, Badge, styled, Box } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PrintIcon from "@mui/icons-material/Print";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useBaseUrl, useUserInfo } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";

import { Intervention } from "./types";
import Timer24h from "../../components/operator/interventions/Timer24h";
import InterventionCloseDialog from "./InterventionCloseDialog";
import InterventionTakeoverDialog from "./InterventionTakeoverDialog";
import { useLocale } from "../../locales/LocaleProvider";
import { ReactComponent as CustomerEditIcon } from "../../icons/intervention/CustomerEdit.svg";
import { ReactComponent as EventsDayIcon } from "../../icons/intervention/EventsDay.svg";
import { ReactComponent as EventsWeekIcon } from "../../icons/intervention/EventsWeek.svg";
import { ReactComponent as EventsMonthIcon } from "../../icons/intervention/EventsMonth.svg";
import {
  StyledDivider,
  StyledIconButton,
  StyledTooltip,
  CloseIconButton,
} from "../utils/StyledHeaderComponents";
import { useAcknowledge } from "./AcknowledgeContext";
import BootstrapTooltip from "../utils/BootstrapTooltip";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 20,
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
}));

type InterventionHeaderProps = {
  id: string;
  isOwn: boolean;
  intervention: Intervention | undefined;
  refetchIntervention: () => Promise<any>;
  handleQueryError: (error: JsonapiError) => void;
};

const InterventionHeader = ({
  id,
  isOwn,
  intervention,
  refetchIntervention,
  handleQueryError,
}: InterventionHeaderProps) => {
  const { formatMessage } = useIntl();
  const [closeModalIsOpen, setCloseModalIsOpen] = useState<boolean>(false);
  const [takeoverModalIsOpen, setTakeoverModalIsOpen] =
    useState<boolean>(false);
  const baseUrl = useBaseUrl();
  const [unackCdecs] = useAcknowledge();
  const [sid] = useUserInfo();
  const { locale } = useLocale();

  const isOpen = intervention?.["close-time"] === null;
  let start = intervention?.["start-time"];
  let end = intervention?.["close-time"];
  const customerId = intervention?.customer?.id;

  const handleCloseClick = () => {
    setCloseModalIsOpen(true);
  };

  const handleCloseModalClose = () => {
    setCloseModalIsOpen(false);
  };

  const handleTakeoverClick = () => {
    setTakeoverModalIsOpen(true);
  };

  const handleTakeoverModalClose = () => {
    setTakeoverModalIsOpen(false);
    refetchIntervention();
  };

  const closeButton = (
    <CloseIconButton
      disabled={!intervention}
      onClick={handleCloseClick}
      size="large"
      color={"warning"}
    >
      <DoneOutlineIcon />
    </CloseIconButton>
  );

  return (
    <>
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Customer manager",
          description: "Intervention page customer manager button tooltip",
        })}
      >
        <StyledIconButton
          component={Link}
          to={`/alarm/customers/${customerId}`}
          size="large"
        >
          <SvgIcon>
            <CustomerEditIcon />
          </SvgIcon>
        </StyledIconButton>
      </StyledTooltip>
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Last 1 day events",
          description: "Last 1 day events button tooltip",
        })}
      >
        <StyledIconButton
          component={Link}
          to={`/alarm/events?customer=${
            intervention?.customer?.id
          }&arrived=${encodeURIComponent(
            [moment().subtract(1, "days").format(), moment().format()].join(",")
          )}`}
          size="large"
        >
          <SvgIcon>
            <EventsDayIcon />
          </SvgIcon>
        </StyledIconButton>
      </StyledTooltip>
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Last 7 days events",
          description: "Last 7 days events button tooltip",
        })}
      >
        <StyledIconButton
          component={Link}
          to={`/alarm/events?customer=${
            intervention?.customer?.id
          }&arrived=${encodeURIComponent(
            [moment().subtract(7, "days").format(), moment().format()].join(",")
          )}`}
          size="large"
        >
          <SvgIcon>
            <EventsWeekIcon />
          </SvgIcon>
        </StyledIconButton>
      </StyledTooltip>
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Last 30 days events",
          description: "Last 30 days events button tooltip",
        })}
      >
        <StyledIconButton
          component={Link}
          to={`/alarm/events?customer=${
            intervention?.customer?.id
          }&arrived=${encodeURIComponent(
            [moment().subtract(30, "days").format(), moment().format()].join(
              ","
            )
          )}`}
          size="large"
        >
          <SvgIcon>
            <EventsMonthIcon />
          </SvgIcon>
        </StyledIconButton>
      </StyledTooltip>
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      {isOpen && (
        <Typography
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {intervention ? <Timer24h startTime={start} /> : "00:00:00"}
        </Typography>
      )}
      <StyledTooltip
        title={formatMessage({
          defaultMessage: "Print",
          description: "Intervention Page Print icon tooltip",
        })}
      >
        <StyledIconButton
          href={`${baseUrl}/actions/alarm/intervention-print/${id}?sid=${sid}&lang=${locale}`}
          target="_blank"
          size="large"
        >
          <PrintIcon />
        </StyledIconButton>
      </StyledTooltip>
      <StyledDivider orientation="vertical" variant="middle" flexItem />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "12px",
        }}
      >
        <Typography variant="caption">
          {formatMessage({
            defaultMessage: "Operator",
          })}
        </Typography>
        <Typography
          component="div"
          variant="subtitle2"
          sx={{ color: isOwn ? "primary.main" : "inherit" }}
        >
          {intervention?.session?.operator?.employee?.name ||
            intervention?.session?.operator?.login}
        </Typography>
      </Box>
      {isOpen ? (
        <>
          {isOwn ? (
            <BootstrapTooltip
              title={formatMessage({
                defaultMessage: "Close",
                description: "Close intervention button tooltip",
              })}
              backgroundColor={unackCdecs.length > 0 ? "info" : "warning"}
            >
              {unackCdecs.length > 0 ? (
                <StyledBadge
                  badgeContent={
                    <NewReleasesIcon color="info" fontSize="small" />
                  }
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  {closeButton}
                </StyledBadge>
              ) : (
                closeButton
              )}
            </BootstrapTooltip>
          ) : (
            <StyledTooltip
              title={formatMessage({
                defaultMessage: "Takeover",
                description: "Intervention Page Takeover icon tooltip",
              })}
            >
              <StyledIconButton
                disabled={!intervention}
                onClick={handleTakeoverClick}
                size="large"
              >
                <CompareArrowsIcon />
              </StyledIconButton>
            </StyledTooltip>
          )}
        </>
      ) : end ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: "12px",
          }}
        >
          <Typography variant="caption">
            {formatMessage({
              defaultMessage: "Closed: ",
              description: "Intervention Page intervention closed text",
            })}{" "}
          </Typography>
          <Typography component="div" variant="subtitle2">
            {moment(end).format(
              formatMessage({
                defaultMessage: "DD/MM/YYYY HH:mm:ss",
                description: "Beavatkozás lezárva ekkor",
              })
            )}
          </Typography>
        </Box>
      ) : null}
      <InterventionCloseDialog
        id={id}
        intervention={intervention}
        open={closeModalIsOpen}
        onClose={handleCloseModalClose}
        handleQueryError={handleQueryError}
      />
      <InterventionTakeoverDialog
        id={id}
        intervention={intervention}
        open={takeoverModalIsOpen}
        onClose={handleTakeoverModalClose}
        refetchIntervention={refetchIntervention}
        handleQueryError={handleQueryError}
      />
    </>
  );
};

export default InterventionHeader;

import { useCallback, useMemo, useState } from "react";
import { useIntl, defineMessage as $d } from "react-intl";
import {
  EnhancedTable,
  Filter as TableFilter,
  FilterViewProps,
} from "../components/table";
import { Filters, useGetAll } from "@mb-pro-ui/utils";
import { Page } from "@mb-pro-ui/components";
import {
  boolFilter,
  commaSeparatedValuesFilter,
  dateRangeFilter,
  eqInNeqNinFilter,
  FilterOp,
  patternFilter,
} from "../utils/FilterUtils";
import {
  CustomerAutocompleteFilter,
  DateRangeFilter,
  EventCategoryAutocompleteFilter,
  SimpleBooleanFilter,
  TextFilter,
} from "../components/table/filters";
import {
  Cdec as _Cdec,
  Intervention as _Intervention,
  Customer as _Customer,
} from "@mb-pro-ui/utils/types/alarm";
import Session from "@mb-pro-ui/utils/types/alarm/sessions";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import { MessageDescriptor } from "@formatjs/intl";
import { Cell, Column, Row } from "react-table";
import InstallerAutocompleteFilter from "../components/customer/filters/InstallerAutocompleteFilter";
import EquipmentTypeAutocompleteFilter from "../components/customer/filters/EquipmentTypeAutocompleteFilter";
import ObjectTypeAutocompleteFilter from "../components/customer/filters/ObjectTypeAutocompleteFilter";
import CdecModal from "../components/operator/cdec/CdecModal";
import {
  //Box,
  IconButton,
  Snackbar,
  SvgIcon,
  Link,
  //Tooltip,
  Typography,
} from "@mui/material";
import LinkStyleText from "../components/utils/LinkStyleText";
import moment from "moment";
import ColoredRouterLink from "../components/utils/ColoredRouterLink";
import AdvancedFilters from "../components/table/AdvancedFilters/AdvancedFilters";
import { ReactComponent as InterventionsMenuIcon } from "../icons/InterventionsMenu.svg";

//import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import useImport from "../utils/useImport";
import BootstrapTooltip from "../components/utils/BootstrapTooltip";
import FULL_TIMESTAMP from "../components/utils/FULL_TIMESTAMP";

import EventQueryChart from "./EventQueryChart";

//import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Route, Switch, /*useLocation,*/ useRouteMatch } from "react-router";
import { Link as RouterLink } from "react-router-dom";

//import ListIcon from "@mui/icons-material/List";
//import { StyledDivider } from "../components/utils/StyledHeaderComponents";

const filterSpec: { [id: string]: FilterOp } = {
  arrived: dateRangeFilter,
  "event-code": commaSeparatedValuesFilter,
  "event-category": eqInNeqNinFilter,
  customer: eqInNeqNinFilter,
  "contract-number": patternFilter,
  "billing-id": patternFilter,

  active: boolFilter,
  installer: eqInNeqNinFilter,
  "equipment-type": eqInNeqNinFilter,
  "object-type": eqInNeqNinFilter,
  "category-alert": boolFilter,
};

const mapToServerFilters = (filters: TableFilter[]): Filters => {
  const result: Filters = {};
  if (filters !== null) {
    filters.forEach((filter) => {
      if (filter.id in filterSpec) {
        result[filter.id] = filterSpec[filter.id](filter);
      }
    });
  }
  return result;
};

export type Intervention = Omit<
  _Intervention,
  "start-event" | "session" | "customer"
> & {
  type: `${"alarm/" | ""}${"interventions" | "interventions-all"}`;
  "start-event": Cdec;
  session: Maybe<
    Omit<Session, "operator"> & {
      operator: Exclude<Session["operator"], ID>;
    }
  >;
  customer: Exclude<_Cdec["customer"], ID>;
};

export type Cdec = Omit<
  _Cdec,
  "customer" | "intervention" | "event-category" | "latitude" | "longitude"
> & {
  customer: _Customer;
  intervention: Exclude<Intervention, ID>;
  "event-category": Exclude<_Cdec["event-category"], ID>;
} & (
    | {
        "has-valid-coords": true;
        latitude: number;
        longitude: number;
      }
    | {
        "has-valid-coords": false;
        latitude: null;
        longitude: null;
      }
  );

const SimpleTextFilter = (msg: MessageDescriptor) => {
  const SimpleTextFilter = (props: FilterViewProps) => {
    const { formatMessage } = useIntl();
    return <TextFilter {...props} label={formatMessage(msg)} />;
  };
  return SimpleTextFilter;
};

const EventCodeFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();
  return (
    <TextFilter
      {...props}
      label={formatMessage({ defaultMessage: "Event Code" })}
    />
  );
};

const tableFilters = [
  { id: "customer", Filter: CustomerAutocompleteFilter },
  { id: "arrived", Filter: DateRangeFilter },
  {
    id: "category-alert",
    Filter: SimpleBooleanFilter(
      $d({ defaultMessage: "Alert" }),
      $d({ defaultMessage: "Non Alert" })
    ),
  },
  {
    id: "active",
    Filter: SimpleBooleanFilter(
      $d({ defaultMessage: "Active" }),
      $d({ defaultMessage: "Inactive" })
    ),
  },
  { id: "event-code", Filter: EventCodeFilter },
  { id: "event-category", Filter: EventCategoryAutocompleteFilter },
  {
    id: "contract-number",
    Filter: SimpleTextFilter($d({ defaultMessage: "Contract Number" })),
  },
  {
    id: "billing-id",
    Filter: SimpleTextFilter($d({ defaultMessage: "Billing ID" })),
  },
  { id: "installer", Filter: InstallerAutocompleteFilter },
  { id: "equipment-type", Filter: EquipmentTypeAutocompleteFilter },
  { id: "object-type", Filter: ObjectTypeAutocompleteFilter },
];

export const EventQueryPage = (/*{ url }: { url: string }*/) => {
  const { formatMessage, formatDate } = useIntl();

  //const { search } = useLocation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);
  const [filters, setFilters] = useState<Filters>({});

  const stableResults = Boolean(filters["arrived"]?.["lte"]);
  const limit = filters && Object.keys(filters).length > 0 ? 1000 : 100;

  const { data, status } = useGetAll<Cdec>("alarm/cdec-all", {
    include: {
      customer: {},
      event: {},
    },
    fields: {
      customers: ["account", "name", "active", "address"],
      "cdec-all": [
        "+category-sound",
        "+category-alert",
        "+color",
        "+chart-data",
      ],
    },
    sort: ["-id"],
    filter: filters,
    refetchOnWindowFocus: !stableResults,
    cacheTime: 0,
    page: {
      limit,
    },
  });

  const [, /*createReport*/ snackBarMessage, setSnackBarMessage, reportURL] =
    useImport({ formatMessage });

  const handleFilterChange = useCallback(
    (filters: TableFilter[]) => {
      setFilters(mapToServerFilters(filters));
    },
    [setFilters]
  );

  const handleCdecModalOpen = useCallback((row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setModalOpen(true);
  }, []);

  const handleCdecModalClose = useCallback(() => {
    setSelectedCdec(null);
    setModalOpen(false);
  }, []);

  const columns = useMemo(
    (): Column<Cdec>[] => [
      {
        id: "customer",
        accessor: (cdec) => cdec.customer?.name,
        width: 100,
        Header: formatMessage({
          defaultMessage: "Customer",
          description: "Események táblázat feljéc: ügyfél(customer)",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          const text = value
            ? `${row.original.customer?.account} - ${value}`
            : formatMessage({
                defaultMessage: "Unknown customer",
                description:
                  "Beavatkozásra váró események táblázat ismeretlen ügyfél",
              });
          return row.original.customer?.id ? (
            <ColoredRouterLink
              to={`/alarm/customers/${row.original.customer?.id}`}
            >
              {text}
            </ColoredRouterLink>
          ) : (
            <span>{text}</span>
          );
        },
      },
      {
        id: "arrived",
        accessor: "arrived",
        width: 70,
        Header: formatMessage({
          defaultMessage: "Arrived",
          description: "Események táblázat fejléc érekezési idő",
        }),
        Cell: ({ value }: Cell<Cdec>) => {
          const arrived = moment(value).format(
            formatMessage({
              defaultMessage: "DD/MM/YYYY HH:mm:ss",
              description: "Események táblázat dátum formátum érkezés",
            })
          );
          return <Typography noWrap>{arrived}</Typography>;
        },
      },
      {
        id: "intervention",
        accessor: (cdec) => cdec.intervention?.id,
        width: 60,
        Header: formatMessage({
          defaultMessage: "Intervention",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          const closed = row.original.intervention?.["close-time"]
            ? `${formatMessage({
                defaultMessage: "Closed:",
              })} ${formatDate(
                moment(row.original.intervention["close-time"]).format(),
                FULL_TIMESTAMP
              )}`
            : formatMessage({ defaultMessage: "Open" });

          return value ? (
            <BootstrapTooltip
              title={
                <span style={{ whiteSpace: "pre" }}>
                  {`${formatMessage({
                    defaultMessage: "Operator",
                  })}: ${
                    row.original.intervention?.session?.operator?.login
                  } \n ${closed}`}
                </span>
              }
            >
              <RouterLink to={`/alarm/interventions/${value}`}>
                <SvgIcon>
                  <InterventionsMenuIcon fontSize="inherit" />
                </SvgIcon>
                {formatMessage({
                  defaultMessage: "Intervention",
                })}
              </RouterLink>
            </BootstrapTooltip>
          ) : (
            <Typography component="span">-</Typography>
          );
        },
      },
      {
        id: "description",
        accessor: "localized-description",
        width: 300,
        Header: formatMessage({
          defaultMessage: "Event description",
          description: "Események táblázat fejléc esemény leírás",
        }),
        Cell: ({ value, row }: Cell<Cdec>) =>
          row.original.customer?.id ? (
            <LinkStyleText
              color={row.original.color}
              onClick={() => handleCdecModalOpen(row)}
            >
              {value}
            </LinkStyleText>
          ) : (
            <span>
              {formatMessage({
                defaultMessage: "Unknown event",
              })}
            </span>
          ),
      },
    ],
    [formatMessage, formatDate, handleCdecModalOpen]
  );

  const prefix = useMemo(() => {
    const message =
      status === "success"
        ? `: ${data?.length}${data?.length === limit ? "+" : ""}`
        : "";
    return (
      <Typography color="primary.contrastText">
        {formatMessage({
          defaultMessage: "Events",
        })}
        {message}
      </Typography>
    );
  }, [data, status, limit, formatMessage]);

  const downloadAction = (
    <>
      {reportURL && (
        <Link
          href={reportURL}
          target="_blank"
          rel="noopener"
          onClick={() => {
            setSnackBarMessage(null);
          }}
          sx={{ mr: 1 }}
        >
          {formatMessage({ defaultMessage: "Download" })}
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setSnackBarMessage(null)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={!!snackBarMessage}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={downloadAction}
      />
      <Page sx={{ padding: "12px", overflow: "hidden" }}>
        <EnhancedTable
          columns={columns}
          data={data ?? []}
          queryStatus={status}
          overscanCount={5}
          prefix={prefix}
          // postfix={
          //   <Box sx={{ display: "flex" }}>
          //     <IconButton
          //       sx={{
          //         color: "primary.contrastText",
          //       }}
          //     >
          //       <RouterLink to={`${url}/chart${search}`}>
          //         <Tooltip
          //           title={formatMessage({
          //             defaultMessage: "Show chart",
          //           })}
          //           placement="bottom-start"
          //         >
          //           <ShowChartIcon sx={{ color: "white" }} />
          //         </Tooltip>
          //       </RouterLink>
          //     </IconButton>
          //     <IconButton
          //       sx={{
          //         color: "primary.contrastText",
          //       }}
          //     >
          //       <RouterLink to={`${url}/chart`}>
          //         <Tooltip
          //           title={formatMessage({
          //             defaultMessage: "Show list",
          //           })}
          //           placement="bottom-start"
          //         >
          //           <ListIcon sx={{ color: "white" }} />
          //         </Tooltip>
          //       </RouterLink>
          //     </IconButton>
          //     <StyledDivider orientation="vertical" variant="middle" flexItem />
          //     <IconButton
          //       sx={{
          //         color: "primary.contrastText",
          //       }}
          //       onClick={() => {
          //         createReport({
          //           description: formatMessage({
          //             defaultMessage: "Cdec export",
          //           }),
          //           "report-type": "cdec-export",
          //           options: {
          //             filters: filters,
          //           },
          //         });
          //         setSnackBarMessage(
          //           formatMessage({
          //             defaultMessage: "Exporting...",
          //             description:
          //               "Interventions table currently exporting snackbar message",
          //           })
          //         );
          //       }}
          //     >
          //       <Tooltip
          //         title={formatMessage({
          //           defaultMessage: "Export",
          //         })}
          //         placement="bottom-start"
          //       >
          //         <DownloadIcon />
          //       </Tooltip>
          //     </IconButton>
          //   </Box>
          // }
          filters={
            <AdvancedFilters
              tableFilters={tableFilters}
              onFilterChange={handleFilterChange}
            />
          }
        />

        {selectedCdec && (
          <CdecModal
            cdec={selectedCdec}
            open={modalOpen}
            onClose={handleCdecModalClose}
          />
        )}
      </Page>
    </>
  );
};

const EventPage = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/chart`}>
        <EventQueryChart url={url} />
      </Route>
      <Route path="/">
        <EventQueryPage /*url={url}*/ />
      </Route>
    </Switch>
  );

  // return chartActive ? (
  //   <EventQueryChart setChartActive={setChartActive} />
  // ) : (
  //   <EventQueryPage setChartActive={setChartActive} />
  // );
};

export default EventPage;

import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { EnhancedTable } from "@mb-pro-ui/components/table";

import { Intervention, Zone } from "./types";

const CustomerZones = ({ intervention }: { intervention?: Intervention }) => {
  const { formatMessage } = useIntl();

  return (
    <EnhancedTable
      columns={useMemo(
        (): Column<Zone>[] => [
          {
            id: "number",
            Header: formatMessage({
              defaultMessage: "Number",
              description:
                "Intervention Page customer zones table header number",
            }),
            accessor: "number",
          },
          {
            id: "place",
            Header: formatMessage({
              defaultMessage: "Place",
              description:
                "Intervention Page customer zones table header place",
            }),
            accessor: "place",
          },
          {
            id: "zone-type",
            Header: formatMessage({
              defaultMessage: "Zone type",
              description:
                "Intervention Page customer zones table header zone-type",
            }),
            accessor: (zone) => zone["zone-type"]?.["localized-description"],
          },
          {
            id: "sensor-type",
            Header: formatMessage({
              defaultMessage: "Sensor type",
              description:
                "Intervention Page customer zones table header sensor-type",
            }),
            accessor: (zone) => zone["sensor-type"]?.["localized-description"],
          },
          {
            id: "temporary-inactive",
            Header: formatMessage({
              defaultMessage: "Temporary inactive",
              description:
                "Intervention Page customer zones table header temporary inactive",
            }),
            accessor: "temporary-inactive-ts",
          },
        ],
        [formatMessage]
      )}
      data={intervention?.customer?.zones ?? []}
      prefix={
        <Typography color="primary.contrastText">
          {formatMessage({
            defaultMessage: "Customer Zones",
            description: "Customer zones page subpage header",
          })}
        </Typography>
      }
    />
  );
};

export default CustomerZones;

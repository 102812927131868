import omit from "lodash/omit";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export interface ServiceHealth {
  group?: string;
  label?: string;
  ok?: boolean;
  message?: string;
}

export interface ServiceHealthRegistry {
  register(service: string, opts?: { group?: string; label?: string }): void;
  unregister(service: string): void;
  update(service: string, ok: boolean, message?: string): void;
  updateLabel(service: string, label?: string): void;
  status: Record<string, ServiceHealth>;
}

const ServiceHealthContext = createContext<ServiceHealthRegistry | undefined>(undefined);

export function ServiceHealthProvider({ children }: PropsWithChildren<{}>) {
  const [status, setStatus] = useState<Record<string, ServiceHealth>>({});

  const register = useCallback((service: string, opts?: { group?: string; label?: string }) => {
    setStatus((s) => ({
      ...s,
      [service]: { ...opts },
    }));
  }, []);

  const unregister = useCallback((service: string) => {
    setStatus((s) => omit(s, [service]));
  }, []);

  const update = useCallback((service: string, ok: boolean, message?: string) => {
    setStatus((s) => {
      if (s[service].ok !== ok || s[service].message !== message) {
        return { ...s, [service]: { ...s[service], ok, message } };
      }
      return s;
    });
  }, []);

  const updateLabel = useCallback((service: string, label?: string) => {
    setStatus((s) => {
      if (s[service].label !== label) {
        return { ...s, [service]: { ...s[service], label } };
      }
      return s;
    });
  }, []);

  return (
    <ServiceHealthContext.Provider
      value={useMemo(
        () => ({ register, unregister, update, updateLabel, status }),
        [register, unregister, update, updateLabel, status]
      )}
    >
      {children}
    </ServiceHealthContext.Provider>
  );
}

export function useHealthReporting(
  service: string,
  { group, label }: { group?: string; label?: string }
) {
  const registry = useContext(ServiceHealthContext);
  if (registry === undefined) {
    throw new Error("useHealthReporting must be used within a ServiceHealthProvider");
  }

  const { register, unregister, update, updateLabel } = registry;
  const labelRef = useRef(label);
  labelRef.current = label;

  useEffect(() => {
    register(service, { group, label: labelRef.current });

    return () => unregister(service);
  }, [register, unregister, service, group]);

  useEffect(() => {
    updateLabel(service, label);
  }, [updateLabel, service, label]);

  return useCallback(
    (ok: boolean, message?: string) => update(service, ok, message),
    [update, service]
  );
}

export interface GroupedServiceHealth {
  ok?: boolean;
  services: Record<string, ServiceHealth>;
}

export function useHealthStatus(): {
  isEmpty: boolean;
  ok?: boolean;
  status: Array<{ service: string } & ServiceHealth>;
  groups: Array<{ group: string } & GroupedServiceHealth>;
} {
  const registry = useContext(ServiceHealthContext);
  if (registry === undefined) {
    throw new Error("useHealthStatus must be used within a ServiceHealthProvider");
  }

  const { status } = registry;

  return useMemo(() => {
    const services = Object.keys(status);

    let ok: boolean | undefined;
    const groups: Record<string, GroupedServiceHealth> = {};

    for (const [service, state] of Object.entries(status)) {
      const group = state.group ?? service;
      if (!groups[group]) {
        groups[group] = {
          ok: state.ok,
          services: { [service]: state },
        };
      } else {
        groups[group].services[service] = state;
      }

      if (state.ok === false) {
        ok = false;
        groups[group].ok = false;
      } else if (state.ok === true) {
        if (ok === undefined) {
          ok = true;
        }
        if (groups[group].ok === undefined) {
          groups[group].ok = true;
        }
      }
    }

    return {
      isEmpty: services.length === 0,
      status: Object.keys(status)
        .sort()
        .map((service) => ({
          service,
          ...status[service],
        })),
      ok,
      groups: Object.keys(groups)
        .sort()
        .map((group) => ({
          group,
          ...groups[group],
        })),
    };
  }, [status]);
}

import { useCallback, useRef } from "react";
import { styled } from "@mui/system";
import {
  MapContainer,
  MapContainerProps,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { Map as LeafletMap } from "leaflet";

const mapDefaults = {
  center: { lat: 47.2, lng: 19.5 },
  zoom: 8,
  maxZoom: 19,
};

const invalidateOnResize = (map: LeafletMap) => {
  const observer = new ResizeObserver(() => map.invalidateSize());
  observer.observe(map.getContainer());
  map.on("unload", () => observer.disconnect());
};

export interface BaseMapProps extends Omit<MapContainerProps, "zoomControl"> {
  noTiles?: boolean;
}

const BaseMap = styled(
  ({
    noTiles,
    children,
    center = mapDefaults.center,
    zoom = mapDefaults.zoom,
    maxZoom = mapDefaults.maxZoom,
    whenCreated: whenCreated_,
    ...props
  }: BaseMapProps) => {
    const whenCreated = useRef(whenCreated_);
    whenCreated.current = whenCreated_;

    const handleCreated = useCallback((map: LeafletMap) => {
      invalidateOnResize(map);
      whenCreated.current?.(map);
    }, []);

    return (
      <MapContainer
        center={center}
        zoom={zoom}
        maxZoom={maxZoom}
        zoomControl={false}
        whenCreated={handleCreated}
        {...props}
      >
        {!noTiles && (
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={maxZoom}
            maxNativeZoom={maxZoom}
          />
        )}
        {children}
        <ZoomControl position="bottomright" />
      </MapContainer>
    );
  }
)({ flex: 1 });

export default BaseMap;

import { useIntl } from "react-intl";
import { useGetAll } from "@mb-pro-ui/utils";
import { Customer } from "@mb-pro-ui/utils/types/alarm";
import { AutocompleteFilter } from "..";
import { FilterViewProps } from "../types";
import {
  Typography,
  createFilterOptions,
  IconButton,
  Menu,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  menuClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import clsx from "clsx";

const PREFIX = "MbPro";

const classes = {
  rotate: `${PREFIX}-rotate`,
};

const ChipBox = styled("div")({
  flex: 1,
});

const TextBox = styled("div")({
  display: "flex",
  flex: 3,
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "70%",
  paddingLeft: "10px",
});

interface HeaderActionsProps {
  sortByKey: string | null;
  setSortBy: React.Dispatch<React.SetStateAction<keyof Customer | null>>;
  reverseSort: boolean;
  setReverseSort: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderActions = (props: HeaderActionsProps) => {
  const { sortByKey, setSortBy, reverseSort, setReverseSort } = props;
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReverseButtonClick = () => {
    setReverseSort((previous) => !previous);
  };

  const handleSortButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    nextSortBy: string
  ) => {
    setAnchorEl(null);
    setSortBy(nextSortBy as keyof Customer);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Tooltip
          title={formatMessage({
            defaultMessage: "Sort",
            description: "Intervention table cutomer filter sorting tooltip",
          })}
        >
          <IconButton onClick={handleClick}>
            <SortIcon color={sortByKey ? "info" : "primary"} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleSortMenuClose}
          sx={{
            [`& .${menuClasses.list}`]: { paddingTop: 0, paddingBottom: 0 },
          }}
        >
          <ToggleButtonGroup
            orientation="vertical"
            value={sortByKey}
            exclusive
            onChange={handleSortButtonChange}
          >
            <ToggleButton value="name">
              {formatMessage({
                defaultMessage: "Name",
                description:
                  "Intervention table customer filter sort by name label",
              })}
            </ToggleButton>
            <ToggleButton value="account">
              {formatMessage({
                defaultMessage: "Account",
                description:
                  "Intervention table customer filter sort by account label",
              })}
            </ToggleButton>
            <ToggleButton value="address">
              {formatMessage({
                defaultMessage: "Address",
                description:
                  "Intervention table customer filter sort by address label",
              })}
            </ToggleButton>
            <ToggleButton value="unitid">
              {formatMessage({
                defaultMessage: "HeliosId",
                description:
                  "Intervention table customer filter sort by helios id label",
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        </Menu>
      </div>
      <Tooltip
        title={
          !sortByKey
            ? ""
            : reverseSort
            ? formatMessage({
                defaultMessage: "Descending order",
                description:
                  "Interventions table Customer filter descending order message",
              })
            : formatMessage({
                defaultMessage: "Ascending order",
                description:
                  "Interventions table Customer filter ascending order message",
              })
        }
      >
        <IconButton onClick={handleReverseButtonClick} disabled={!sortByKey}>
          <ArrowDownwardIcon
            color={sortByKey ? "primary" : "inherit"}
            className={clsx({ [classes.rotate]: reverseSort })}
            sx={{
              transform: "rotate(deg)",
              transition: (theme) => theme.transitions.create(["transform"]),
              [`&.${classes.rotate}`]: {
                transform: "rotate(180deg)",
                transition: (theme) => theme.transitions.create(["transform"]),
              },
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

const getOptionLabel = (customer: Customer) => customer.id;

function sortData<T extends { name: string | null }>(
  data: T[],
  sortByKey: keyof T | null,
  reverseSort: boolean
): T[] {
  const sortedData = Array.from(data);

  sortedData.sort((a, b) => {
    const A = a[sortByKey ?? "name"];
    const B = b[sortByKey ?? "name"];
    if (!A) {
      return 1;
    }
    if (!B) {
      return -1;
    }

    if (sortByKey === "unitid") {
      return ((A ?? 0) as number) - ((B ?? 0) as number);
    } else {
      const stringA = String(A).toLowerCase();
      const stringB = String(B).toLowerCase();
      return stringA.localeCompare(stringB);
    }
  });

  if (reverseSort) {
    sortedData.reverse();
  }

  return sortedData;
}

const renderOption = (customer: Customer) => (
  <>
    <ChipBox>
      <Typography color="primary">{customer.account ?? "n/a"}</Typography>
      <Typography color="primary" sx={{ padding: 0, margin: 0 }}>
        {customer.unitid ?? "n/a"}
      </Typography>
    </ChipBox>
    <TextBox>
      <Typography variant={"body2"} noWrap>
        {customer.name}
      </Typography>
      <Typography variant={"caption"} noWrap>
        {customer.address}
      </Typography>
    </TextBox>
  </>
);

const filterOptions = createFilterOptions({
  stringify: (c: Customer) => `${c.name} ${c.unitid} ${c.address} ${c.account}`,
  trim: true,
});

const CustomerAutocompleteFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();
  const [sortByKey, setSortBy] = useState<keyof Customer | null>(null);
  const [reverseSort, setReverseSort] = useState(false);
  const sort = useCallback((data) => sortData(data, sortByKey, reverseSort), [
    reverseSort,
    sortByKey,
  ]);

  const { data } = useGetAll<Customer>("alarm/customers", {
    fields: {
      customers: ["account", "name", "unitid", "address", "active"],
    },
    sort: ["name"],
    notifyOnChangeProps: "tracked",
    select: sort,
    refetchOnWindowFocus: false,
  });

  return (
    <AutocompleteFilter
      {...props}
      label={formatMessage({
        defaultMessage: "Customer",
        description: "Interventions Table Customer Multi Select Filter Label",
      })}
      options={data}
      sortBySelection={!sortByKey}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      inputPlaceholder={formatMessage({
        defaultMessage: "Filter Customers: UnitId, Name, Address, Account",
        description: "Interventions table customer filter input placeholder",
      })}
      noOptionsText={formatMessage({
        defaultMessage: "No such customer",
        description: "Intervention table customer filter no option label",
      })}
      virtualizedRowHeight={64}
      headerActions={
        <HeaderActions
          sortByKey={sortByKey}
          setSortBy={setSortBy}
          reverseSort={reverseSort}
          setReverseSort={setReverseSort}
        />
      }
    />
  );
};

export default CustomerAutocompleteFilter;

import { SyntheticEvent, useCallback, useMemo } from "react";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";

import {
  FieldArray as FinalFormFieldArray,
  useFieldArray,
} from "react-final-form-arrays";
import { EnhancedTable } from "../table";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  lighten,
  useTheme,
} from "@mui/material";

import { EnhancedTableProps } from "../table/EnhancedTable";
import { IconButton, Palette } from "@mui/material";

import { AnyObject, Form } from "react-final-form";
import { Button } from "@mb-pro-ui/components";
import { Box } from "@mui/system";

import AddIcon from "@mui/icons-material/Add";
import { useIntl } from "react-intl";

import isEqual from "lodash/isEqual";

const getRowStyle = (
  index: number,
  value: any[],
  initialValues: any[],
  palette: Palette
) => {
  if (!value[index].id) {
    return {
      style: {
        backgroundColor: lighten(palette.warning.light, 0.6),
      },
    };
  } else {
    const original = initialValues.find(
      (initialVal) => initialVal.id === value[index].id
    );

    if (!isEqual(original, value[index])) {
      return {
        style: {
          backgroundColor: lighten(palette.warning.light, 0.6),
        },
      };
    }
  }
  return {};
};

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface UrlParams {
  by: "new" | "byId" | "byNum";
  id: string;
  num: string;
}

interface FormTableDialogProps<T> {
  name: string;
  url: string;
  dialog?: any;
  dialogNewTitle: string;
  dialogEditTitle: string;
  initialValues?: Partial<T>;
}

const FormTableDialog = <T,>({
  name,
  url,
  dialog,
  dialogNewTitle,
  dialogEditTitle,
  initialValues: propInitialValues,
}: FormTableDialogProps<T>) => {
  let submit: Submit = null;

  const history = useHistory();
  const { formatMessage } = useIntl();

  const {
    params: { by, num },
  } = useRouteMatch<UrlParams>();

  const {
    fields: { push, update, remove, value },
  } = useFieldArray(name);

  const initialValues = useMemo(() => {
    if (by === "byNum") {
      return value[parseInt(num)];
    } else if (by === "byId") {
      return value.find((f) => f.id === parseInt(num));
    } else {
      return undefined;
    }
  }, [by, value, num]);

  const onSubmit = useCallback(
    (vals: T) => {
      if (initialValues) {
        if (by === "byNum") {
          update(parseInt(num), vals);
        }
      } else {
        push(vals);
      }
    },
    [update, push, initialValues, by, num]
  );

  const onDelete = () => {
    if (by === "byNum") {
      remove(parseInt(num));
      history.replace(url);
    }
  };

  const onDialogAction = useCallback(
    (_: {}, action: "close" | "cancel" | "confirm") => {
      switch (action) {
        case "close":
          history.replace(url);
          break;
        case "cancel":
          history.replace(url);
          break;
        case "confirm":
          if (submit) {
            submit();
            history.replace(url);
          }
          break;
      }
    },
    [history, submit, url]
  );

  return (
    <Dialog open={true} onClose={() => onDialogAction({}, "cancel")}>
      <DialogTitle>
        {initialValues ? dialogEditTitle : dialogNewTitle}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues ?? propInitialValues}
      >
        {({ handleSubmit, submitting, pristine, values, invalid }) => {
          submit = handleSubmit;
          return (
            <>
              <DialogContent>{dialog}</DialogContent>
              <DialogActions
                sx={
                  initialValues
                    ? { display: "flex", justifyContent: "space-between" }
                    : {}
                }
              >
                {initialValues ? (
                  <Button
                    mode="warning"
                    onClick={() => onDelete()}
                    disabled={submitting}
                  >
                    {formatMessage({ defaultMessage: "Delete" })}
                  </Button>
                ) : null}
                <Box>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => onDialogAction({}, "close")}
                    disabled={submitting}
                  >
                    {formatMessage({ defaultMessage: "Cancel" })}
                  </Button>
                  <Button
                    type="submit"
                    disabled={submitting || pristine || invalid}
                    onClick={() => onDialogAction(values, "confirm")}
                    sx={{ marginLeft: "32px" }}
                  >
                    {initialValues
                      ? formatMessage({ defaultMessage: "Edit" })
                      : formatMessage({ defaultMessage: "Create" })}
                  </Button>
                </Box>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </Dialog>
  );
};

export interface FormTableProps<T extends object>
  extends Omit<EnhancedTableProps<T, {}>, "data"> {
  name: string;
  dialog?: any;
  dialogNewTitle: string;
  dialogEditTitle: string;
  readOnly?: boolean;
  initialValues?: Partial<T>;
}

const FormTable = <T extends object = {}>({
  name,
  dialog,
  dialogNewTitle,
  dialogEditTitle,
  readOnly,
  initialValues,
  ...tableProps
}: FormTableProps<T>) => {
  const { path, url } = useRouteMatch();

  const { palette } = useTheme();

  const {
    fields: { value },
    meta: { initial },
  } = useFieldArray(name);

  return (
    <>
      {dialog && (
        <Route path={`${path}/:resource/:by/:num`}>
          <FormTableDialog
            name={name}
            url={url}
            dialog={dialog}
            dialogNewTitle={dialogNewTitle}
            dialogEditTitle={dialogEditTitle}
            initialValues={initialValues}
          />
        </Route>
      )}
      <FinalFormFieldArray name={name}>
        {({ fields }) => (
          <EnhancedTable<T>
            data={Array.isArray(fields.value) ? fields.value : []}
            getRowProps={({ index }) =>
              getRowStyle(index, value, initial, palette)
            }
            actionPosition="left"
            postfix={
              !readOnly ? (
                <Box>
                  <Link to={`${url}/${name}/new/0`}>
                    <IconButton
                      sx={{
                        color: "primary.contrastText",
                      }}
                      size="small"
                    >
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Box>
              ) : null
            }
            sx={{
              alignSelf: "stretch",
              height: "100%",
            }}
            {...tableProps}
          />
        )}
      </FinalFormFieldArray>
    </>
  );
};

export default FormTable;

import { SyntheticEvent, useState } from "react";

import { Typography } from "@mui/material";

import { FormDialog } from "@mb-pro-ui/components";

import { AnyObject, Form } from "react-final-form";

import { StyledTextField } from "../../form/StyledComponents";
import { useApi } from "@mb-pro-ui/utils";
import { useHistory, useParams } from "react-router-dom";

import { matchValue } from "../../form/Validators";
import { useIntl } from "react-intl";

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface DeleteDialogProps {
  account: string;
  open: boolean;
  onDialogAction: (event: {}, action: "close" | "cancel" | "confirm") => void;
}

const DeleteDialog = ({
  account,
  open,
  onDialogAction: onDeleteDialogAction,
}: DeleteDialogProps) => {
  const { id } = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const history = useHistory();

  const api = useApi();

  const [isWarning, setIsWarning] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser = (id: number) => {
    return api<any>(`/alarm/customers/${id}`, {
      method: "delete",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
    });
  };

  const onDialogAction = async (
    event: {},
    action: "close" | "cancel" | "confirm",
    valid: boolean
  ) => {
    switch (action) {
      case "confirm":
        if (isWarning) {
          setIsWarning(false);
        } else {
          if (submit && valid) {
            await submit();
            setIsLoading(false);
            history.push("/alarm/customers");
          }
        }
        break;
      case "cancel":
        onDeleteDialogAction(event, action);
        setIsWarning(true);
        break;
      case "close":
        onDeleteDialogAction(event, action);
        setIsWarning(true);
        break;
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await deleteUser(parseInt(id));
  };

  let submit: Submit = null;

  if (isLoading) {
    return (
      <FormDialog
        title={formatMessage({ defaultMessage: "Deleting..." })}
        open={true}
        onDialogAction={() => {}}
        hasCancel={false}
        hasConfirm={false}
      >
        <Typography>Loading...</Typography>
      </FormDialog>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, valid }) => {
        submit = handleSubmit;
        return (
          <FormDialog
            title={formatMessage({ defaultMessage: "Attention!" })}
            open={open}
            onDialogAction={(event, action) =>
              onDialogAction(event, action, valid)
            }
          >
            {isWarning ? (
              <>
                <Typography sx={{ marginTop: "5%" }}>
                  Biztosan törölni akarja, az alábbi ügyfelet?
                </Typography>
                <Typography sx={{ marginTop: "5%" }}>
                  Ügyfél név:{" "}
                  <span style={{ fontWeight: "bold" }}>{account}</span>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    width: "300px",
                    marginLeft: 1,
                  }}
                >
                  A törlés megerősítéséhez adja meg a törlendő ügyfél 4
                  számjegyű azonosítóját!
                </Typography>
                <StyledTextField
                  name="account"
                  label="Azonosító"
                  validate={matchValue(account)}
                  sx={{
                    marginTop: "5%",
                    width: "300px",
                  }}
                  required
                />
              </>
            )}
          </FormDialog>
        );
      }}
    </Form>
  );
};

export default DeleteDialog;

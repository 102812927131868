import { Autocomplete } from "@mb-pro-ui/components/form";

import { StyledTextField } from "../form/StyledComponents";

import Widget from "../utils/Widget";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import HideableFormField from "./profile/HideableFormField";

const contractUnitTypes = [
  "Alarm",
  "Navigator",
  "MedCare",
  "MedCare Plus",
  "Vario MedCare",
  "Patrol",
  "Patrol Plus",
  "Vario Patrol",
  "Elevator",
  "Firesignal",
  "iMachine",
  "Moniphone",
  "Mastershelf",
];

const signalType = ["GPRS", "PSTN", "GPRS & PSTN"];

interface ContractFormProps {
  readOnly?: boolean;
}

const ContractForm = ({ readOnly }: ContractFormProps) => {
  const { formatMessage } = useIntl();

  const appType = [
    formatMessage({
      defaultMessage: "Security",
    }),
    formatMessage({
      defaultMessage: "Vehicle tracking",
    }),
    formatMessage({
      defaultMessage: "Healthcare",
    }),
    formatMessage({
      defaultMessage: "Person and tool tracking",
    }),
    formatMessage({
      defaultMessage: "Elevator",
    }),
    formatMessage({
      defaultMessage: "Fire and gas detection",
    }),
    formatMessage({
      defaultMessage: "Pilot",
    }),
    formatMessage({
      defaultMessage: "Private test",
    }),
    formatMessage({
      defaultMessage: "Other",
    }),
  ];

  const serviceType = [
    formatMessage({
      defaultMessage: "Self-care",
    }),
    formatMessage({
      defaultMessage: "With notification",
    }),
    formatMessage({
      defaultMessage: "With patrol/delivery",
    }),
  ];

  const contractBillingType = [
    formatMessage({
      defaultMessage: "Invoiced",
    }),
    "VIP",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "8px",
        marginTop: "8px",
        maxHeight: "100%",
        width: "calc(66.66% - 10px)",
      }}
    >
      <Widget
        title={formatMessage({
          defaultMessage: "Contract info",
        })}
        children={
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <HideableFormField name="contract-number">
                <StyledTextField
                  name="contract-number"
                  label={formatMessage({
                    defaultMessage: "Contract number",
                  })}
                  parse={(x) => x}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="billing-id">
                <StyledTextField
                  name="billing-id"
                  label={formatMessage({
                    defaultMessage: "Billing ID",
                  })}
                  parse={(x) => x}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="contract-app-type">
                <Autocomplete
                  name="contract-app-type"
                  label={formatMessage({
                    defaultMessage: "Application type",
                  })}
                  options={[...Array(9).keys()].map((i) => i + 1)}
                  getOptionLabel={(option) => appType[option - 1] ?? ""}
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="contract-service-type">
                <Autocomplete
                  name="contract-service-type"
                  label={formatMessage({
                    defaultMessage: "Service type",
                  })}
                  options={[...Array(3).keys()].map((i) => i + 1)}
                  getOptionLabel={(option) => serviceType[option - 1] ?? ""}
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                />
              </HideableFormField>
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <HideableFormField name="contract-signal-type">
                <Autocomplete
                  name="contract-signal-type"
                  label={formatMessage({
                    defaultMessage: "Signal type",
                  })}
                  options={[...Array(3).keys()].map((i) => i + 1)}
                  getOptionLabel={(option) => signalType[option - 1] ?? ""}
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="contract-unit-type">
                <Autocomplete
                  name="contract-unit-type"
                  label={formatMessage({
                    defaultMessage: "Device type",
                  })}
                  options={[...Array(13).keys()].map((i) => i + 1)}
                  getOptionLabel={(option) =>
                    contractUnitTypes[option - 1] ?? ""
                  }
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="contract-start-ts">
                <StyledTextField
                  name="contract-start-ts"
                  label={formatMessage({
                    defaultMessage: "Date of contract",
                  })}
                  type="datetime-local"
                  parse={(x) => x}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="contract-billing-type">
                <Autocomplete
                  name="contract-billing-type"
                  label={formatMessage({
                    defaultMessage: "Billing",
                  })}
                  options={[...Array(2).keys()]}
                  getOptionLabel={(option) => contractBillingType[option] ?? ""}
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                />
              </HideableFormField>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default ContractForm;

import { Avatar, styled } from "@mui/material";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "20px",
  height: "20px",
  backgroundColor: `${theme.palette.common.white}`,
  transition: theme.transitions.create(["all"]),
  "&:hover": {
    width: "50px",
    height: "50px",
    position: "relative",
    zIndex: 1,
    padding: "5px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default StyledAvatar;

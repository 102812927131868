import { DatePicker } from "@mui/lab";
import { lighten, Menu, MenuItem } from "@mui/material";
import { FilterViewProps } from "../../types";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useIntervals from "./useIntervals";
import { Box } from "@mui/system";
import moment, { Moment } from "moment";
import MbProFilterTextField from "../../../components/MbProFilterTextField";
import { styled } from "@mui/material";
import MbProFilterButton from "../../../components/MbProFilterButton";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: lighten(theme.palette.primary.light, 0.7),
    color: theme.palette.primary.main,
  },
  "&.Mui-selected:hover": {
    backgroundColor: lighten(theme.palette.primary.light, 0.7),
    color: theme.palette.primary.main,
  },
}));

const DateRangeFilter = (filterViewProps: FilterViewProps) => {
  const {
    filterValue,
    setFilter,
    filterId,
    urlSearchParam,
    setUrlSearchParam,
  } = filterViewProps;

  const from = filterValue ? filterValue[0] : undefined;
  const to = filterValue ? filterValue[1] : undefined;

  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState<
    number | null
  >(null);

  const consolidatedRef = useRef(false);
  const { formatMessage } = useIntl();
  const intervals = useIntervals();

  const open = Boolean(anchorEl);

  const inputFormat = formatMessage({
    defaultMessage: "MM/DD/YYYY HH:mm",
    description: "Intervention table date range filter date input format",
  });

  const handleItervalItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
    index: number | null
  ) => {
    setSelectedIntervalIndex(index);
    if (index !== null) {
      setFilter(filterId, intervals[index].interval);
    } else {
      setFilter(filterId, null);
    }
    setAnchorEl(null);
  };

  const handleIntervalSelectButtonClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIntervalSelectClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!filterValue) {
      setSelectedIntervalIndex(null);
    }
  }, [filterValue]);

  useEffect(() => {
    if (urlSearchParam) {
      const dateRange = urlSearchParam.split(",");
      let from = undefined;
      let to = undefined;

      if (dateRange.length > 0 && dateRange[0] && dateRange[0].length > 0)
        from = dateRange[0];
      if (dateRange.length > 1 && dateRange[1] && dateRange[1].length > 0)
        to = dateRange[1];

      const value = [from, to];
      setFilter(filterId, value);
    }
  }, [urlSearchParam, setFilter, filterId]);

  useEffect(() => {
    if (from || to) {
      setUrlSearchParam(filterId, [from, to].toString());
    } else {
      if (consolidatedRef.current) {
        setUrlSearchParam(filterId, "");
      }
    }
  }, [from, to, filterId, setUrlSearchParam]);

  useEffect(() => {
    consolidatedRef.current = true;
  });

  return (
    <Box sx={{ display: "inherit" }}>
      <DatePicker
        value={from === undefined ? null : from}
        onChange={(date) => {
          const valid =
            (date && (date as Moment).isValid()) ||
            date === "" ||
            date === undefined ||
            date === null;
          if (valid) {
            setFromError(false);
          } else {
            setFromError(true);
          }

          if (date && (date as Moment).isValid()) {
            setFilter(filterId, [(date as Moment)?.format(), to]);
          } else if (!to) {
            setFilter(filterId, null);
          } else {
            setFilter(filterId, [undefined, to]);
          }
        }}
        inputFormat={inputFormat}
        mask={inputFormat.replace(/[A-Za-z]/g, "_")}
        renderInput={(params) => (
          <MbProFilterTextField
            {...params}
            error={fromError}
            size="small"
            variant="outlined"
            emphasize={!!filterValue?.[0]}
          />
        )}
        disableFuture
        cancelText={formatMessage({
          defaultMessage: "Cancel",
          description: "Date picker modal cancel label",
        })}
        clearText={formatMessage({
          defaultMessage: "Clear",
          description: "Date picker modal clear label",
        })}
        label={formatMessage({
          defaultMessage: "From",
          description: "Interventions table start time filter label",
        })}
      />
      <MbProFilterButton
        endIcon={<MoreHorizIcon />}
        sx={{
          minWidth: "24px",
          paddingRight: "5px",
          paddingLeft: "5px",
          display: "flex",
          justifyContent: "center",
          margin: 0,
        }}
        onClick={handleIntervalSelectButtonClick}
        active={open}
        emphasize={selectedIntervalIndex !== null}
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleIntervalSelectClose}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <StyledMenuItem
          onClick={(event) => handleItervalItemClick(event, null)}
          selected={selectedIntervalIndex === null}
        >
          {formatMessage({
            defaultMessage: "Unset",
            description: "Date range filter unset message",
          })}
        </StyledMenuItem>
        {intervals.map((interval, index) => (
          <StyledMenuItem
            key={interval.label}
            onClick={(event) => handleItervalItemClick(event, index)}
            selected={index === selectedIntervalIndex}
          >
            {interval.label}
          </StyledMenuItem>
        ))}
      </Menu>
      <DatePicker
        value={to === undefined ? null : to}
        onChange={(date) => {
          const valid =
            (date && (date as Moment).isValid()) ||
            date === "" ||
            date === undefined ||
            date === null;
          if (valid) {
            setToError(false);
          } else {
            setToError(true);
          }

          if (date && (date as Moment).isValid()) {
            setFilter(filterId, [from, (date as Moment)?.format()]);
          } else if (!from) {
            setFilter(filterId, null);
          } else {
            setFilter(filterId, [from, undefined]);
          }
        }}
        minDate={from && moment(from)}
        inputFormat={inputFormat}
        mask={inputFormat.replace(/[A-Za-z]/g, "_")}
        renderInput={(params) => (
          <MbProFilterTextField
            {...params}
            error={toError}
            size="small"
            variant="outlined"
            emphasize={!!filterValue?.[1]}
          />
        )}
        disableFuture
        cancelText={formatMessage({
          defaultMessage: "Cancel",
          description: "Date picker modal cancel label",
        })}
        clearText={formatMessage({
          defaultMessage: "Clear",
          description: "Date picker modal clear label",
        })}
        label={formatMessage({
          defaultMessage: "To",
          description: "Interventions table end time filter label",
        })}
      />
    </Box>
  );
};

export default DateRangeFilter;

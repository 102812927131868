import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";

import { Button } from "@mb-pro-ui/components";

import { Customer } from "@mb-pro-ui/utils/types/alarm";

import { useApi, useCreate, useGetAll } from "@mb-pro-ui/utils";

import { Form } from "react-final-form";
import { StyledTextField } from "../../components/form/StyledComponents";
import { CodeTable, CustomerGroup } from "../../components/customer/types";
import { Autocomplete } from "@mb-pro-ui/components/form";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { useState } from "react";
import { ApiResponseMany } from "@mb-pro-ui/utils/jsonapi/types";
import { isEqualById } from "../../components/utils/autocompleteUtils";

interface CreateCustomerDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  account?: string | null;
  onClose?: () => void;
}

const CreateCustomerDialog = ({
  open,
  setOpen,
  account,
  onClose,
}: CreateCustomerDialogProps) => {
  const [snackBarMessage, setSnackbarMessage] = useState<string | null>(null);

  const { formatMessage } = useIntl();

  const api = useApi();

  const history = useHistory();

  const { mutateAsync: createCustomerAsync } =
    useCreate<Customer>("alarm/customers");

  const { data: customerGroups, isLoading: areCustomerGroupsLoading } =
    useGetAll<CustomerGroup>("alarm/customer-groups", {
      refetchOnWindowFocus: false,
    });

  const { data: codeTables, isLoading: areCodeTablesLoading } =
    useGetAll<CodeTable>("alarm/code-tables", {
      refetchOnWindowFocus: false,
    });

  const onSubmit = async (vals: any) => {
    try {
      const customer = await createCustomerAsync(vals);
      history.push(`/alarm/customers/${customer.id}`);
    } catch (e) {
      setOpen(false);
      onClose?.();
      setSnackbarMessage(
        formatMessage({ defaultMessage: "Unsuccessful operation" })
      );
    }
  };

  if (areCustomerGroupsLoading || areCodeTablesLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Snackbar
        open={!!snackBarMessage}
        onClose={() => setSnackbarMessage(null)}
        autoHideDuration={10000}
        message={snackBarMessage}
      />
      <Dialog
        open={open}
        onClose={() => {
          onClose?.();
          setOpen(false);
        }}
      >
        <DialogTitle>
          {formatMessage({ defaultMessage: "Create customer" })}
        </DialogTitle>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, pristine, invalid }) => {
            return customerGroups && codeTables ? (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <StyledTextField
                    name="account"
                    initialValue={account && account}
                    readOnly={!!account}
                    label={formatMessage({ defaultMessage: "Account" })}
                    validate={async (value) => {
                      const response = await api<ApiResponseMany>(
                        `/alarm/customers?${new URLSearchParams(
                          `filter[account][eq]=${value}`
                        )}`
                      );
                      if (response.data.length > 0) {
                        return Promise.resolve(
                          formatMessage({
                            defaultMessage: "Occupied account!",
                          })
                        );
                      }
                    }}
                    required
                  />
                  <StyledTextField
                    name="name"
                    label={formatMessage({ defaultMessage: "Name" })}
                    required
                  />
                  <Autocomplete
                    name="code-table"
                    label={formatMessage({
                      defaultMessage: "Codetable",
                    })}
                    options={codeTables}
                    isEqual={isEqualById}
                    getOptionLabel={(option) =>
                      option["localized-description"] ?? ""
                    }
                    fullWidth
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                  />
                  <Autocomplete
                    name="group"
                    label={formatMessage({
                      defaultMessage: "Customer group",
                    })}
                    options={customerGroups}
                    fullWidth
                    getOptionLabel={(option) => option.description ?? ""}
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      onClose?.();
                      setOpen(false);
                    }}
                    disabled={submitting}
                  >
                    {formatMessage({ defaultMessage: "Cancel" })}
                  </Button>
                  <Button
                    type="submit"
                    disabled={submitting || pristine || invalid}
                  >
                    {formatMessage({ defaultMessage: "Create" })}
                  </Button>
                </DialogActions>
              </form>
            ) : (
              <></>
            );
          }}
        </Form>
      </Dialog>
    </>
  );
};

export default CreateCustomerDialog;

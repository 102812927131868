import { lighten } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useCallback } from "react";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import TextFieldInput, { TextFieldProps as TextFieldInputProps } from "../TextField";
import { useComposedValidate } from "./utils";

type PassedThroughProps = Omit<
  TextFieldInputProps,
  | "name"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "error"
  | "defaultValue"
  | "type"
>;

export interface TextFieldProps extends PassedThroughProps, UseFieldConfig<unknown> {
  name: string;
  requiredError?: ReactNode;
  highlightDirty?: boolean;
}

const TextFieldAdapter = ({
  input,
  meta,
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  ...rest
}: FieldRenderProps<unknown> & PassedThroughProps) => (
  <TextFieldInput
    label={label ?? input.name}
    className={clsx(className, meta.dirty && "Mbp-dirty")}
    error={meta.touched && !!meta.error}
    helperText={((meta.touched && meta.error) || helperText) ?? ""}
    readOnly={meta.submitting || readOnly}
    sx={[
      meta.dirty &&
        !meta.error &&
        highlightDirty && {
          backgroundColor: (theme) => lighten(theme.palette.warning.light, 0.6),
        },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
    {...input}
  />
);

const isEqualText = (a: unknown, b: unknown) =>
  a === 0 || b === 0 || (a && b) ? a === b : !a && !b;

const TextField = ({
  validate,
  required,
  isEqual = isEqualText,
  requiredError = "Required",
  ...rest
}: TextFieldProps) => (
  <Field
    validate={useComposedValidate(required, requiredError, validate)}
    required={required}
    isEqual={isEqual}
    component={TextFieldAdapter}
    {...rest}
  />
);

export default TextField;

const parseNumeric = (value: string | number | null | undefined) => {
  if (typeof value === "number") {
    return value;
  }
  if (!value) {
    return null;
  }
  const n = Number(value.replace(",", "."));
  if (Number.isNaN(n)) {
    return value;
  }
  return n;
};

const formatNumeric = (value: unknown) => {
  if (typeof value === "number") {
    return value.toString();
  }
  if (!value) {
    return "";
  }
  return value;
};

const isEqualNumeric = (
  a: string | number | null | undefined,
  b: string | number | null | undefined
) => formatNumeric(a) === formatNumeric(b);

export const NumericField = (props: TextFieldProps) => (
  <TextField
    type="number"
    parse={parseNumeric}
    format={formatNumeric}
    isEqual={isEqualNumeric}
    {...props}
  />
);

export const FixedPointField = ({
  precision,
  ...props
}: TextFieldProps & { precision: number }) => {
  const format = useCallback(
    (value: unknown) => {
      if (typeof value === "number") {
        return value.toFixed(precision);
      }
      if (!value) {
        return "";
      }
      return value;
    },
    [precision]
  );

  const isEqual = useCallback(
    (a: string | number | null | undefined, b: string | number | null | undefined) =>
      format(a) === format(b),
    [format]
  );

  return <NumericField type="text" format={format} isEqual={isEqual} {...props} />;
};

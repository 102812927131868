import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { Typography } from "@mui/material";
import { NotifiablePerson } from "@mb-pro-ui/utils/types/alarm";
import { EnhancedTable } from "@mb-pro-ui/components/table";

import { Intervention } from "./types";

const CustomerNotify = ({ intervention }: { intervention?: Intervention }) => {
  const { formatMessage } = useIntl();

  return (
    <EnhancedTable<NotifiablePerson>
      columns={useMemo(
        (): Column<NotifiablePerson>[] => [
          {
            id: "name",
            Header: formatMessage({
              defaultMessage: "Name",
              description:
                "Intervention Page customer notify table header Name",
            }),
            accessor: "name",
          },
          {
            id: "role",
            Header: formatMessage({
              defaultMessage: "Role",
              description:
                "Intervention Page customer notify table header Role",
            }),
            accessor: "role",
          },
          {
            id: "phone",
            Header: formatMessage({
              defaultMessage: "Phone",
              description:
                "Intervention Page customer notify table header Phone",
            }),
            accessor: "phone",
          },
          {
            id: "mobile",
            Header: formatMessage({
              defaultMessage: "Mobile",
              description:
                "Intervention Page customer notify table header Mobile",
            }),
            accessor: "mobile",
          },
          {
            id: "email",
            Header: formatMessage({
              defaultMessage: "Email",
              description:
                "Intervention Page customer notify table header Email",
            }),
            accessor: "email",
          },
        ],
        [formatMessage]
      )}
      data={intervention?.customer?.["notifiable-persons"] ?? []}
      prefix={
        <Typography color="primary.contrastText">
          {formatMessage({
            defaultMessage: "Customer Notify",
            description: "Intervention page customer notify subpage header",
          })}
        </Typography>
      }
    />
  );
};

export default CustomerNotify;

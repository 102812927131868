import {
  Autocomplete,
  Checkbox,
  RichEditor,
  TextField,
} from "@mb-pro-ui/components/form";
import { useGetAll } from "@mb-pro-ui/utils";
import {
  Customer,
  CustomerGroup,
  EmailRecipient,
  EmailTemplate,
  RegularReport,
  RegularReportInterval,
} from "@mb-pro-ui/utils/types/alarm";
import { useMemo } from "react";
import { FormRenderProps } from "react-final-form";
import { useIntl } from "react-intl";
import Widget from "../utils/Widget";
import { styled } from "@mui/system";
import { Box, Chip } from "@mui/material";

export type EmailTemplateFormValues = Partial<
  Omit<EmailTemplate, "max-interval" | "max-offset">
> & {};

const FormColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "8px",
  maxWidth: "300px",
});

export const EmailTemplateForm = (
  _props: FormRenderProps<EmailTemplateFormValues>
) => {
  const { formatMessage } = useIntl();

  const { data: customerGroups, isLoading: areCustomerGroupsLoading } =
    useGetAll<CustomerGroup>("alarm/customer-groups", {
      refetchOnWindowFocus: false,
    });

  const [customerGroupOptions, customerGroupValues] = useMemo(() => {
    const options = [] as string[];
    const values: { [key: string]: CustomerGroup } = {};
    customerGroups?.forEach((c) => {
      options.push(c.id);
      values[c.id] = c;
    });
    return [options, values];
  }, [customerGroups]);

  const { data: emailRecipients, isLoading: areEmailRecipientsLoading } =
    useGetAll<EmailRecipient>("alarm/email-recipients", {
      refetchOnWindowFocus: false,
    });
  const [emailRecipientsOptions, emailRecipientsValues] = useMemo(() => {
    const options = [] as string[];
    const values: { [key: string]: EmailRecipient } = {};
    emailRecipients?.forEach((c) => {
      options.push(c.id);
      values[c.id] = c;
    });
    return [options, values];
  }, [emailRecipients]);

  const { data: regularReports, isLoading: areRegularReportsLoading } =
    useGetAll<RegularReport>("alarm/regular-reports", {
      refetchOnWindowFocus: false,
      include: {
        customer: {},
        interval: {},
      },
      fields: {
        customers: ["id", "account", "name"],
        "regular-report-intervals": ["id", "localized-description"],
      },
      structuralSharing: false,
    });
  const [regularReportsOptions, regularReportsValues] = useMemo(() => {
    const options = [] as string[];
    const values: { [key: string]: RegularReport } = {};
    regularReports?.forEach((c) => {
      options.push(c.id);
      values[c.id] = c;
    });
    return [options, values];
  }, [regularReports]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <FormColumn>
        <Widget sx={{ padding: "8px" }}>
          <TextField
            name="description"
            label={formatMessage({
              defaultMessage: "Description",
            })}
            fullWidth
            required
          />

          <Autocomplete
            name="customer-group"
            label={formatMessage({
              defaultMessage: "Customer group",
            })}
            fullWidth
            disabled={areCustomerGroupsLoading}
            options={customerGroupOptions}
            getOptionLabel={(option) =>
              customerGroupValues[option]?.description || ""
            }
          />
          <Checkbox
            name={"active"}
            label={formatMessage({ defaultMessage: "Active" })}
            labelSx={{
              alignSelf: "flex-start",
            }}
          />
        </Widget>
      </FormColumn>
      <FormColumn>
        <Widget sx={{ padding: "8px" }}>
          <Autocomplete
            name="recipients"
            label={formatMessage({
              defaultMessage: "Recipients",
            })}
            fullWidth
            disabled={areEmailRecipientsLoading}
            options={emailRecipientsOptions}
            getOptionLabel={(option) =>
              emailRecipientsValues[option]?.name || ""
            }
            multiple={true}
          />

          <Autocomplete
            name="bcc-recipients"
            label={formatMessage({
              defaultMessage: "BCC recipients",
            })}
            fullWidth
            disabled={areEmailRecipientsLoading}
            options={emailRecipientsOptions}
            getOptionLabel={(option) =>
              emailRecipientsValues[option]?.name || ""
            }
            multiple={true}
          />
          <Autocomplete
            name="attached-regular-reports"
            label={formatMessage({
              defaultMessage: "Attachments",
            })}
            fullWidth
            disabled={areRegularReportsLoading}
            options={regularReportsOptions}
            getOptionLabel={(option) =>
              regularReportsValues[option]?.description || ""
            }
            multiple={true}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const r = regularReportsValues[option];
                if (areRegularReportsLoading || !r) {
                  return (
                    <Chip
                      variant="filled"
                      label={`${option}...`}
                      {...getTagProps({ index })}
                    />
                  );
                }
                const c = r.customer as Customer;
                const label = `${r.description}.${r.format} (${c.account})`;
                return (
                  <Chip
                    variant="filled"
                    label={label}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            renderOption={(props, option) => {
              const r = regularReportsValues[option];
              const c = r.customer as Customer;
              const i = r.interval as RegularReportInterval;
              return (
                <li
                  {...props}
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  {r && (
                    <>
                      <div>
                        <b>
                          {r.description}.{r.format}
                        </b>
                      </div>
                      <div>
                        {c.account} {c.name}
                      </div>
                      {c.address && <div>{c.address}</div>}
                      <div>
                        {i["localized-description"]} ({r.offset})
                      </div>
                    </>
                  )}
                  {!r && option}
                </li>
              );
            }}
          />
        </Widget>
      </FormColumn>
      <FormColumn>
        <Widget sx={{ padding: "8px", minWidth: "300px" }}>
          <TextField
            name="subject"
            label={formatMessage({
              defaultMessage: "Subject",
            })}
            fullWidth
            required
          />

          <RichEditor
            name="body"
            editorStyle={{
              minHeight: "200px",
              height: "1px",
            }}
            toolbar={{
              options: ["inline", "colorPicker", "fontSize", "remove"],
              inline: {
                options: ["bold", "italic"],
              },
            }}
          />
        </Widget>
      </FormColumn>
    </Box>
  );
};

import { Button } from "@mb-pro-ui/components";
import { EmailTemplate } from "@mb-pro-ui/utils/types/alarm";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { ModalFormProps } from "../settings/types";
import { useUseCreate } from "../settings/utils";
import { EmailTemplateForm, EmailTemplateFormValues } from "./EmailTemplateForm";


const initialValues:EmailTemplateFormValues = {
  recipients: [],
  "bcc-recipients": [],
  "attached-regular-reports": [],
};

const EmailTemplateCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: ModalFormProps) => {
  const { formatMessage } = useIntl();

  const { mutate: create } = useUseCreate<EmailTemplate>("alarm/email-templates", {
    refetch,
    setSnackbarState,
    onFormClose,
  });

  const onSubmit = (values: EmailTemplateFormValues) => {

    const createArg = {
      description: values.description || null,
      active: values.active || false,
      subject: values.subject || null,
      body: values.body || null,
      "customer-group": (values["customer-group"] as string) || null,
      recipients: values.recipients as string[],
      "bcc-recipients": values["bcc-recipients"] as string[],
      "attached-regular-reports": values["attached-regular-reports"] as string[],
    };

    create(createArg);
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create email template" })}
      </DialogTitle>

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={( props ) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >                
                  <EmailTemplateForm {...props} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={props.submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button type="submit" disabled={props.submitting || props.pristine}>
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />

    </Dialog>
  );
};

export default EmailTemplateCreateModal;

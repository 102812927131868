import {
  SpeedDial,
  SpeedDialAction,
  useTheme,
  darken,
  styled,
  tooltipClasses,
  SpeedDialActionProps,
  Box,
  SvgIcon,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Cdec } from "../../types";
import BulkInterventionModal from "./BulkInterventionModal";
import BootstrapTooltip from "../../../utils/BootstrapTooltip";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ReactComponent as InterventionsMenuIcon } from "../../../../icons/InterventionsMenu.svg";
import { NavLink } from "react-router-dom";

const StyledSpeedDialAction = styled(
  ({ className, ...props }: { className?: string } & SpeedDialActionProps) => (
    <SpeedDialAction
      TooltipClasses={{
        popper: className,
      }}
      {...props}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const InterventionButton = ({
  interventionCdecs,
  interventionNeeded,
  disabled,
  nextId,
}: {
  interventionCdecs: Array<Cdec>;
  interventionNeeded: number | undefined;
  disabled: boolean;
  nextId?: string;
}) => {
  const { formatMessage } = useIntl();
  const [isBulkModalOpen, setIsBulkModalOpen] = useState<boolean>(false);
  const [isTooltipOpen, setIsToolTipOpen] = useState<boolean>(false);
  const theme = useTheme();

  const handleBulkModalOpen = () => {
    setIsBulkModalOpen(true);
  };

  const handleBulkModalClose = () => {
    setIsBulkModalOpen(false);
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <BootstrapTooltip
        backgroundColor={theme.palette.warning.main}
        title={formatMessage({
          defaultMessage: "Next Intervention",
        })}
        open={isTooltipOpen}
      >
        <SpeedDial
          ariaLabel="Intervention speed dial"
          icon={
            <NavLink
              to={`/alarm/interventions/create?start-events=${nextId}`}
              style={{
                textDecoration: "none",
                color: theme.palette.common.white,
                display: "flex",
                alignItems: "center",
              }}
            >
              {formatMessage({ defaultMessage: "Intervention" })}
              <DoubleArrowIcon fontSize="small" />
            </NavLink>
          }
          direction="left"
          FabProps={{
            disabled: !nextId || disabled,
            size: "small",
            variant: "extended",
            onMouseEnter: () => {
              setIsToolTipOpen(true);
            },
            onMouseLeave: () => {
              setIsToolTipOpen(false);
            },
            sx: {
              backgroundColor: "warning.main",
              "&:hover": {
                backgroundColor: (theme) =>
                  darken(theme.palette.warning.main, 0.2),
              },
            },
          }}
        >
          <StyledSpeedDialAction
            icon={
              <SvgIcon sx={{ color: "warning.contrastText" }}>
                <InterventionsMenuIcon color="inherit" />
              </SvgIcon>
            }
            open={true}
            onClick={handleBulkModalOpen}
            tooltipTitle={formatMessage({
              defaultMessage: "Intervention all",
              description:
                "Intézkedések megnyitása az összes riasztásra egyszerre tooltip",
            })}
            tooltipPlacement="bottom"
            FabProps={{
              size: "small",
              variant: "extended",
              sx: {
                color: "common.white",
                width: "32px",
                minWidth: "32px",
                mr: "-4px",
                backgroundColor: "warning.main",
                "&:hover": {
                  backgroundColor: (theme) =>
                    darken(theme.palette.warning.main, 0.2),
                },
              },
            }}
            sx={{
              marginRight: 0,
            }}
          />
        </SpeedDial>
      </BootstrapTooltip>
      <BulkInterventionModal
        open={isBulkModalOpen}
        onClose={handleBulkModalClose}
        interventionCdecs={interventionCdecs}
        interventionNeeded={interventionNeeded}
      />
    </Box>
  );
};

export default InterventionButton;

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { Box } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { MouseEvent as ReactMouseEvent, ReactChild } from "react";
import Header from "./Header";

export interface HeaderLinkProp {
  href?: string;
  label: string;
}

type LinkEvent = ReactMouseEvent<HTMLAnchorElement, MouseEvent>;
type ButtonEvent = ReactMouseEvent<HTMLButtonElement, MouseEvent>;

export interface FormHeaderProps {
  className?: string;
  onClickBack?: (e: ButtonEvent) => void;
  onClickSave?: (e: ButtonEvent) => void;
  onClickLink?: (e: LinkEvent, link: HeaderLinkProp, index: number) => void;
  hasBack?: boolean;
  hasSave?: boolean;
  saveIcon?: ReactChild;
  links: HeaderLinkProp[];
  title: string;
  renderPrefix?: () => ReactChild;
  renderPostfix?: () => ReactChild;
}

export default function FormHeader({
  className,
  onClickBack,
  onClickSave,
  onClickLink,
  hasBack,
  hasSave,
  saveIcon,
  links,
  title,
  renderPrefix,
  renderPostfix,
}: FormHeaderProps) {
  return (
    <Header className={className}>
      <Box
        sx={{
          flex: 1,
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {(hasBack ?? true) && (
          <IconButton
            sx={{
              color: "primary.contrastText",
              mr: 2,
            }}
            onClick={onClickBack}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        )}
        {renderPrefix && renderPrefix()}
      </Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="//"
        sx={{
          flex: 2,
          flexShrink: 0,
          display: "flex",
          justifyContent: "center",
        }}
        maxItems={2}
        itemsBeforeCollapse={0}
        itemsAfterCollapse={2}
      >
        {links.map((link, index) => (
          <Link
            href={link.href}
            onClick={onClickLink && ((e: LinkEvent) => onClickLink(e, link, index))}
            key={link.href}
            sx={{
              display: "flex",
              fontFamily: "Lato",
              letterSpacing: 0.08,
              fontWeight: 400,
              fontSize: 16,
              textTransform: "uppercase",
            }}
            color="inherit"
          >
            {link.label}
          </Link>
        ))}
        <Typography
          color="inherit"
          sx={{
            display: "flex",
            fontFamily: "Lato",
            letterSpacing: 0.08,
            fontWeight: 400,
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          flex: 1,
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "flex-end",
          flexShrink: 1,
        }}
      >
        {renderPostfix && renderPostfix()}
        {(hasSave ?? true) && (
          <IconButton
            sx={{
              color: "primary.contrastText",
              ml: 2,
            }}
            onClick={onClickSave}
            size="large"
          >
            {saveIcon ?? <SaveOutlined />}
          </IconButton>
        )}
      </Box>
    </Header>
  );
}

import { IJSONObject } from "@mb-pro-ui/jsonapi-types";
import { useCreate, useEvents } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import { useCallback, useEffect, useState } from "react";
import { UseMutateFunction } from "react-query";

interface UseImportProps {
  formatMessage: any;
}

const useImport = ({
  formatMessage,
}: UseImportProps): [
  UseMutateFunction<
    {
      type: string;
      id: string;
    },
    JsonapiError,
    IJSONObject,
    unknown
  >,
  string | null,
  any,
  string | undefined,
  any
] => {
  const [reportID, setReportID] = useState<string>();
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [reportURL, setReportURL] = useState<string>();
  const [successIDs, setSuccessIDs] = useState<
    { id: string; result: string }[]
  >([]);
  const [failureIDs, setFailureIDs] = useState<number[]>([]);

  const { mutate: createReport } = useCreate("admin/reports", {
    onSuccess: (data) => {
      setReportID(data.id);
    },
    onError: () => {
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Export request failed",
        })
      );
    },
  });

  const handleNotification = useCallback((event: string, notification: any) => {
    if (event === "report") {
      if (notification.$channel === "UPDATE@public.reports") {
        if (notification.status === "success") {
          setSuccessIDs((previous) => [
            ...previous,
            { id: String(notification.id), result: notification.result },
          ]);
        } else if (notification.status === "failure") {
          setFailureIDs((previous) => [...previous, notification.id]);
        }
      }
    }
  }, []);

  useEvents(["report"], handleNotification);

  useEffect(() => {
    const successObj = successIDs.find((obj) => obj.id === reportID);
    if (successObj) {
      setReportURL(successObj.result);
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Export success",
          description: "Interventions table successful export message",
        })
      );
      setReportID(undefined);
    }
  }, [reportID, successIDs, formatMessage]);

  useEffect(() => {
    if (reportID) {
      if (failureIDs.indexOf(parseInt(reportID)) >= 0) {
        setSnackBarMessage(
          formatMessage({
            defaultMessage: "Export failed",
            description: "Interventions table failed export message",
          })
        );
      }
    }
  }, [reportID, failureIDs, formatMessage]);

  return [
    createReport,
    snackBarMessage,
    setSnackBarMessage,
    reportURL,
    setReportURL,
  ];
};

export default useImport;

import { Category } from "@mb-pro-ui/utils/types/alarm";

export const parseHexa = (value: string) => {
  if (!value) return value;
  const hexaCode = value.replace(/[^A-F0-9]/g, "");
  if (hexaCode.length <= 4) return hexaCode;
  return hexaCode.slice(0, 4);
};

export const normalizeEmptyString = (value: Category | string) => {
  if (!value) return undefined;
  return value;
};

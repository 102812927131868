import { useGetAll } from "@mb-pro-ui/utils";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column, Cell } from "react-table";

import { Maybe } from "@mb-pro-ui/utils/types";
import StyledAvatar from "../StyledAvatar";
import { Icon } from "../types";
import IconCreateModal from "./IconCreateModal";
import { AllEntities } from "../utils";

const AllIcons = () => {
  const { formatMessage } = useIntl();
  const queryResult = useGetAll<Icon>("alarm/icons", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
  });

  const columns = useMemo(
    (): Column<Icon>[] => [
      {
        id: "id",
        Header: formatMessage({
          defaultMessage: "ID",
        }),
        accessor: (icon) => icon.id,
      },
      {
        id: "icon",
        Header: formatMessage({
          defaultMessage: "Icon",
        }),
        accessor: (icon) => icon.icon,
        Cell: ({
          value: src,
          row: { original: icon },
        }: Cell<Icon, Maybe<string>>) => {
          return src ? (
            <StyledAvatar src={`${src}&s=${icon.md5sum}`} alt="thumbnail" />
          ) : (
            ""
          );
        },
      },
    ],
    [formatMessage]
  );

  const title = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      <FormattedMessage defaultMessage="Icons" />
    </Typography>
  );

  return (
    <AllEntities
      columns={columns}
      queryResult={queryResult}
      title={title}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <IconCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllIcons;

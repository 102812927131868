import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { SvgIcon, Typography, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { useParams } from "react-router-dom";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { Form } from "react-final-form";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { Checkbox, TextField } from "@mb-pro-ui/components/form";
import { Authority as _Authority } from "@mb-pro-ui/utils/types/alarm";

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Authority editor"
        description="System settings page sidebar authority editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

interface FormValues
  extends Pick<
    _Authority,
    "id" | "name" | "phone" | "mobile" | "remark" | "active"
  > {}

const postLoadFormat = ({
  id,
  name,
  phone,
  mobile,
  remark,
  active,
}: _Authority): FormValues => ({
  id,
  name,
  phone,
  mobile,
  remark,
  active,
});

const AuthorityUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: authority,
    refetch,
    isLoading,
  } = useGetOne<_Authority>("alarm/authorities", id);
  const name = authority?.name;
  const breadcrumbName = name || id;
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { waitFor } = useBackendSchemas();

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/authorities/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    refetch,
    setSnackbarState,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete<_Authority>(
    "alarm/authorities",
    {
      replaceTo: "/alarm/settings/authorities",
      setSnackbarState,
    }
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff(
        "authorities",
        authority as Record<string, unknown> | undefined,
        values,
        { schemas }
      ),
    ]);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{name}</Typography>;

  return (
    <Page
      backLink="/alarm/settings/authorities"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={authority && postLoadFormat(authority)}
          render={({ handleSubmit, submitting, pristine, form }) => {
            return (
              <SubPage
                prefix={prefix}
                sx={{ minWidth: "500px" }}
                innerSx={{ height: "auto" }}
              >
                <StyledForm>
                  <TextField
                    name="name"
                    required
                    requiredError={formatMessage({
                      defaultMessage: "Required",
                    })}
                    label={formatMessage({ defaultMessage: "Name" })}
                    disabled={submitting || isLoading || isDeleting}
                    parse={(v) => v}
                  />
                  <TextField
                    name="phone"
                    type="tel"
                    label={formatMessage({ defaultMessage: "Phone" })}
                    disabled={submitting || isLoading || isDeleting}
                    parse={(v) => v}
                  />
                  <TextField
                    name="mobile"
                    type="tel"
                    label={formatMessage({ defaultMessage: "Mobile" })}
                    disabled={submitting || isLoading || isDeleting}
                    parse={(v) => v}
                  />
                  <TextField
                    name="remark"
                    label={formatMessage({ defaultMessage: "Remark" })}
                    disabled={submitting || isLoading || isDeleting}
                    parse={(v) => v}
                  />
                  <Checkbox
                    name="active"
                    label={formatMessage({ defaultMessage: "Active" })}
                    labelSx={{
                      alignSelf: "flex-start",
                    }}
                    disabled={submitting || isLoading || isDeleting}
                  />
                  <UpdateFormActions
                    onSave={handleSubmit}
                    onReset={() => form.reset()}
                    onDelete={() => setDeleteDialogOpen(true)}
                    disabledDelete={submitting}
                    disabledReset={submitting || pristine}
                    disabledSave={submitting || pristine}
                    isLoading={isLoading}
                    isDeleting={isDeleting}
                  />
                </StyledForm>
              </SubPage>
            );
          }}
        />
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={authority?.name ?? ""}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default AuthorityUpdatePage;

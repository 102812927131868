import React, { useCallback, useMemo, useState } from "react";

import { FixedPointField } from "@mb-pro-ui/components/form";

import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { LeafletEventHandlerFnMap } from "leaflet";

import { useField, useForm } from "react-final-form";

import { Box, IconButton } from "@mui/material";

import MapIcon from "@mui/icons-material/Map";
import { useIntl } from "react-intl";
import { styled } from "@mui/system";

const centerOriginal = {
  lat: 47.498717445805994,
  lng: 19.04327125785084,
};

type ChildRenderer = (
  position: { lat: number; lng: number } | undefined
) => React.ReactNode;

interface CoordinatesFieldProps {
  latName: string;
  lngName: string;
  latLabel?: string;
  lngLabel?: string;
  inputFields?: ChildRenderer;
  mapComponents?: ChildRenderer;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
}

const StyledMapContainer = styled(MapContainer)({
  width: "100%",
  minHeight: "400px",
});

const CoordinatesField = ({
  latName,
  lngName,
  latLabel,
  lngLabel,
  disabled,
  inputFields,
  mapComponents,
  readOnly,
  required,
}: CoordinatesFieldProps) => {
  const { change } = useForm();
  const { formatMessage } = useIntl();

  const [mapOpen, setMapOpen] = useState(false);
  const toggleMap = useCallback(() => setMapOpen((open) => !open), []);

  const {
    input: { value: lat },
  } = useField(latName, { subscription: { value: true } });

  const {
    input: { value: lng },
  } = useField(lngName, { subscription: { value: true } });

  const position =
    Number.isFinite(lat) && Number.isFinite(lng) ? { lat, lng } : undefined;

  const eventHandlers = useMemo(
    () =>
      ({
        dragend(event) {
          const { lat, lng } = event.target.getLatLng();
          change(latName, lat);
          change(lngName, lng);
        },
      } as LeafletEventHandlerFnMap),
    [change, latName, lngName]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "horizontal",
          alignItems: "center",
        }}
      >
        <FixedPointField
          name={latName}
          precision={6}
          label={latLabel ?? formatMessage({ defaultMessage: "Latitude" })}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
        />
        <FixedPointField
          name={lngName}
          precision={6}
          label={lngLabel ?? formatMessage({ defaultMessage: "Longitude" })}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
        />
        <IconButton onClick={toggleMap} size="large">
          <MapIcon sx={{ height: 20 }} />
        </IconButton>
      </Box>
      {inputFields && inputFields(position)}
      {mapOpen && (
        <StyledMapContainer center={position ?? centerOriginal} zoom={11}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {mapComponents && mapComponents(position)}
          <Marker
            draggable={!disabled && !readOnly}
            eventHandlers={!disabled && !readOnly ? eventHandlers : undefined}
            position={position ?? centerOriginal}
          />
        </StyledMapContainer>
      )}
    </>
  );
};

export default CoordinatesField;

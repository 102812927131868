import { useMemo, useEffect, useState, useCallback } from "react";
import moment from "moment";
import { IntlShape, useIntl } from "react-intl";
import { Cell, Column, Row } from "react-table";
import {
  Typography,
  Tooltip,
  Box,
  CircularProgress,
  useTheme,
  IconButton,
  Badge,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useCreate, useEvents, useGetAll } from "@mb-pro-ui/utils";
import { EnhancedTable } from "@mb-pro-ui/components/table";

import { Cdec, Intervention } from "./types";
import { useAcknowledge } from "./AcknowledgeContext";
import { onlyInFirst } from "../operator/interventionCdec/InterventionCdecWindow";
import ManualEventButton from "../operator/cdec/header/ManualEventButton";
import CdecModal from "../operator/cdec/CdecModal";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import LinkStyleText from "../utils/LinkStyleText";
import { Snackbar } from "../settings/utils";
import { SnackbarState } from "../settings/types";
import checkFields from "../../utils/checkFields";
import { ID } from "@mb-pro-ui/utils/types";

export const getDateText = (
  timestamp: string,
  { formatTime, formatDate }: Pick<IntlShape, "formatTime" | "formatDate">
) => {
  const today = moment(timestamp).isSame(moment.now(), "day");
  const thisYear = moment(timestamp).isSame(moment.now(), "year");
  const time = formatTime(timestamp, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const date = thisYear
    ? formatDate(timestamp, {
        month: "2-digit",
        day: "2-digit",
      })
    : formatDate(timestamp, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

  let dateText = `${time}, ${date}`;

  if (today) {
    dateText = `${time}`;
  }

  return dateText;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.info.main,
    top: 33,
    right: -5,
  },
}));

const commonQueryOptions = {
  page: { limit: 10000 },
  refetchInterval: 10000,
  refetchIntervalInBackground: true,
  sort: ["-id"],
};

const cdecFields = checkFields<Cdec>()([
  "localized-description",
  "category-alert",
  "color",
  "arrived",
  "sent",
  "latitude",
  "longitude",
  "has-valid-coords",
  "event-code",
  "account",
  "unitid",
  "partition-number",
  "zone-number",
  "customer",
  "active",
]);

export const EventsWidget = ({
  intervention,
  refetchIntervention,
  isOwn,
  sx = [],
}: {
  intervention?: Intervention;
  refetchIntervention: () => Promise<any>;
  isOwn?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [unackCdecs, setUnackCdecs] = useAcknowledge();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);
  const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);

  const isOpen = intervention?.["close-time"] === null;

  const startEvent = intervention?.["start-event"];
  const customer = intervention?.customer?.id;

  const {
    data: cdecs,
    refetch,
    status: queryStatus,
    remove,
  } = useGetAll<Cdec>("alarm/cdec", {
    enabled: !!startEvent && !!customer && isOpen,
    filter: {
      customer: { eq: customer },
      id: { gte: startEvent ?? undefined },
    },
    fields: { cdec: cdecFields },
    ...commonQueryOptions,
  });

  const {
    data: cdecsAck,
    refetch: refetchAck,
    remove: removeAck,
    status: queryStatusAck,
  } = useGetAll<Cdec>("alarm/cdec-all", {
    enabled: !!intervention,
    filter: {
      intervention: { eq: intervention?.id },
    },
    fields: { "cdec-all": cdecFields },
    ...commonQueryOptions,
  });

  const { mutateAsync: create, isLoading } = useCreate("alarm/interventions", {
    onSuccess: () => {
      refetchAck();
      refetch();
      refetchIntervention();
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Acknowledged" }),
      });
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Unsuccessfull operation" }),
        error: true,
      });
    },
  });

  const firstCdec = cdecs?.[cdecs?.length - 1].id;
  const ackEvent = startEvent ?? firstCdec;

  const acknowledge = useCallback(() => {
    if (ackEvent) {
      create({ "start-event": ackEvent });
    }
  }, [ackEvent, create]);

  useEvents(["cdec-insert"], () => {
    refetchAck();
    refetch();
  });

  useEffect(() => {
    if (cdecs && cdecsAck) {
      setUnackCdecs(onlyInFirst(cdecs, cdecsAck));
    }
  }, [cdecs, cdecsAck, setUnackCdecs]);

  useEffect(() => {
    return () => {
      // cleanup shared state between EventsWidgets (EventsWidgets force re mounted with key prop)
      setUnackCdecs([]);
      remove();
      removeAck();
    };
  }, [setUnackCdecs, remove, removeAck]);

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const handleCdecModalOpen = (row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setCdecModalOpen(true);
  };

  const handleCdecModalClose = () => {
    setCdecModalOpen(false);
    setSelectedCdec(null);
  };

  const columns = useMemo(
    (): Column<Cdec>[] => [
      {
        id: "arrived",
        Header: formatMessage({
          defaultMessage: "Arrived",
          description: "Events widget arrived column label",
        }),
        accessor: "arrived",
        Cell: ({ value, row }: Cell<Cdec, string>) => {
          const arrivedDateText = getDateText(value, intl);
          const sentDateText = getDateText(row.original.sent, intl);

          const sent = formatMessage(
            {
              defaultMessage: "Sent: {date}",
              description:
                "Intervention events widget arrived column tooltip info: sent date",
            },
            {
              date: sentDateText,
            }
          );
          const arrived = formatMessage(
            {
              defaultMessage: "Arrived: {date}",
            },
            {
              date: arrivedDateText,
            }
          );

          return (
            <Box sx={{ display: "flex", width: "100%" }}>
              {unackCdecs.some((cdec) => cdec.id === row.original.id) && (
                <BootstrapTooltip
                  backgroundColor={"info.light"}
                  title={formatMessage({
                    defaultMessage: "Unacknowledged event",
                  })}
                >
                  <NewReleasesIcon color="info" fontSize={"small"} />
                </BootstrapTooltip>
              )}
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    {`${arrived} \n ${sent}`}
                  </div>
                }
              >
                <Typography>{arrivedDateText}</Typography>
              </Tooltip>
            </Box>
          );
        },
        width: 60,
      },
      {
        id: "description",
        Header: formatMessage({
          defaultMessage: "Description",
          description: "Events widget localized description column label",
        }),
        accessor: "localized-description",
        width: 150,
        Cell: ({ value, row }: Cell<Cdec, string>) => (
          <Tooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{value}</div>}
          >
            <LinkStyleText
              color={row.original.color}
              onClick={() => handleCdecModalOpen(row)}
            >
              {value}
            </LinkStyleText>
          </Tooltip>
        ),
      },
    ],
    [formatMessage, unackCdecs, intl]
  );

  return (
    <>
      <Box
        sx={[
          {
            display: "flex",
            overflow: "auto",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <EnhancedTable
          columns={columns}
          data={isOpen ? cdecs ?? [] : cdecsAck ?? []}
          disableGlobalFilter
          prefix={
            <>
              <Typography color="primary.contrastText">
                {formatMessage({ defaultMessage: "Events" })}
              </Typography>
              {isOwn && isOpen && (
                <ManualEventButton account={intervention?.customer?.account} />
              )}
            </>
          }
          queryStatus={isOpen ? queryStatus : queryStatusAck}
          postfix={
            isOpen ? (
              isOwn &&
              unackCdecs.length > 0 && (
                <Acknowledge
                  intervention={intervention}
                  isLoading={isLoading}
                  unackCdecs={unackCdecs}
                  acknowledge={acknowledge}
                />
              )
            ) : (
              <Typography sx={{ color: "primary.contrastText" }}>
                {formatMessage({ defaultMessage: "Closed intervention" })}
              </Typography>
            )
          }
          sx={[
            {
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        />
      </Box>
      <Snackbar
        onClose={snackbarOnClose}
        state={snackbarState}
        successColor="info.main"
      />
      {selectedCdec && (
        <CdecModal
          cdec={selectedCdec}
          open={cdecModalOpen}
          onClose={handleCdecModalClose}
        />
      )}
    </>
  );
};

type AcknowledgeProps = {
  intervention: Intervention | undefined;
  isLoading: boolean;
  acknowledge: () => void;
  unackCdecs: { id: ID }[];
};

const Acknowledge = ({
  intervention,
  isLoading,
  acknowledge,
  unackCdecs,
}: AcknowledgeProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <StyledBadge
          badgeContent={unackCdecs.length}
          color="info"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <BootstrapTooltip
            backgroundColor={theme.palette.info.main}
            title={formatMessage({
              defaultMessage: "Acknowledge events",
            })}
          >
            <IconButton
              disabled={
                !intervention?.["start-event"] ||
                isLoading ||
                unackCdecs.length === 0
              }
              onClick={() => {
                acknowledge();
              }}
            >
              <Box
                sx={{
                  backgroundColor: "info.main",
                  width: "5px",
                  height: "16px",
                  position: "absolute",
                }}
              />
              <NewReleasesIcon
                sx={{
                  color: "white",
                  borderRadius: "50%",
                  zIndex: 1,
                }}
              />
            </IconButton>
          </BootstrapTooltip>
        </StyledBadge>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "common.white",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

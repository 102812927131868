import React, { useEffect } from "react";
import {
  Box,
  Container,
  IconButton,
  Snackbar,
  Typography,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useIntl, FormattedMessage } from "react-intl";
import { utils } from "@mb-pro-ui/utils";
import { useModules } from "../hooks";
import { Module, ModuleID } from "../components/types";
import { Page } from "@mb-pro-ui/components";

import logo from "../icons/logo.svg";
import { useErrorHandler } from "../hooks";

const ModuleCards = ({ modules }: { modules: Module[] }) => {
  return (
    <Grid
      container
      spacing={10}
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      {modules.map((module) => (
        <Grid
          key={module.id}
          item
          xs={12}
          md={4}
          sm={6}
          container
          justifyContent="center"
        >
          <Tooltip
            followCursor
            title={!module.active ? (module.info ? module.info : "") : ""}
          >
            {(() => {
              const button = (
                <Button
                  id={module.id}
                  data-testid={module.id}
                  sx={{
                    width: "100%",
                    maxWidth: "280px",
                    height: "120px",
                    color: (theme) => theme.palette.primary.main,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    "&.Mui-disabled": {
                      color: (theme) => theme.palette.grey[500],
                      backgroundColor: (theme) =>
                        theme.palette.background.paper,
                      boxShadow: (theme) => theme.shadows[1],
                    },
                    fontSize: "48px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "unset",
                    },
                  }}
                  variant="contained"
                  disabled={!module.active}
                  href={module.active ? module.path : undefined}
                >
                  {module.id.valueOf() === ModuleID.Ozone.valueOf() ? (
                    module.img && module.img()
                  ) : (
                    <>
                      {module.icon && module.icon({ fontSize: "inherit" })}
                      <Box mt={2}>
                        {module.name && <Typography>{module.name}</Typography>}
                      </Box>
                    </>
                  )}
                </Button>
              );

              return module.active ? (
                button
              ) : (
                <Box
                  sx={{
                    width: "inherit",
                    maxWidth: 280,
                    display: "inline",
                  }}
                >
                  {button}
                </Box>
              );
            })()}
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

const HomePage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    document.title = formatMessage({
      defaultMessage: "Modules - Monitoringbook Pro",
      description: "Főoldal modulok cím",
    });
  }, [formatMessage]);

  const {
    isLoading,
    error: queryError,
    activeModules,
    inactiveModules,
  } = useModules();

  const [error, setError] = React.useState<typeof queryError>(null);
  const { getErrorMessage } = useErrorHandler();
  const clearError = () => setError(null);
  utils.useOnChange(queryError, setError);

  return (
    <Page>
      <Container
        sx={{
          pb: 12,
        }}
        maxWidth="md"
      >
        <Box pt={5} mb={2} textAlign="center">
          <Box mb={2}>
            <img src={logo} alt="" width="64px" height="73px" />
          </Box>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              "& > span": {
                color: (theme) => theme.palette.primary.main,
              },
              fontSize: "1.5rem",
              "@media (max-width:350px)": {
                display: "none",
              },
            }}
            variant="h1"
          >
            <span>Monitoringbook</span> Pro
          </Typography>
        </Box>
        <Box textAlign="center" mb={10}>
          <Typography>
            <FormattedMessage
              defaultMessage="Choose from the modules <span>available to you</span>"
              description="Modul selector page subtitle"
              values={{
                span: (chunks: string) => (
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {chunks}
                  </Box>
                ),
              }}
            />
          </Typography>
        </Box>
        <ModuleCards
          modules={
            isLoading ? inactiveModules : [...activeModules, ...inactiveModules]
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={!!error}
          autoHideDuration={6000}
          onClose={clearError}
          message={error && getErrorMessage(error)}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={clearError}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Container>
    </Page>
  );
};

export default HomePage;

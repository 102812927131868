import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const BootstrapTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    backgroundColor?: string;
  }) => <Tooltip {...props} classes={{ popper: className }} />,
  {
    shouldForwardProp: (prop) => prop !== "backgroundColor" && prop !== "color",
  }
)(({ theme, backgroundColor, color }) => {
  let bColor;
  switch (backgroundColor) {
    case "primary":
      bColor = theme.palette.primary.main;
      break;
    case "info":
      bColor = theme.palette.info.main;
      break;
    case "warning":
      bColor = theme.palette.warning.main;
      break;
    default:
      bColor = backgroundColor;
  }
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bColor,
      color,
    },
  };
});

export default BootstrapTooltip;

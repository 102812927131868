import { FormSpy, useForm } from "react-final-form";

import { Checkbox } from "@mb-pro-ui/components/form";
import { useState } from "react";
import { StyledTextField } from "./StyledComponents";

interface DatePickerCheckboxProps {
  name: string;
  checkboxLabel: string;
  inputLabel: string;
  readOnly?: boolean;
}

const DatePickerCheckbox = ({
  name,
  checkboxLabel,
  inputLabel,
  readOnly,
}: DatePickerCheckboxProps) => {
  const [pickerActive, setPickerActive] = useState(false);

  const { change } = useForm();

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) =>
        values[name] || pickerActive ? (
          <>
            {!pickerActive && (
              <Checkbox
                name={name}
                label={checkboxLabel}
                readOnly={readOnly}
                onChangeCapture={() => {
                  change(name, null);
                }}
              />
            )}
            <StyledTextField
              name={name}
              label={inputLabel}
              type="datetime-local"
              onChangeCapture={() => setPickerActive(false)}
              readOnly={readOnly}
            />
          </>
        ) : (
          <Checkbox
            name={name}
            label={checkboxLabel}
            readOnly={readOnly}
            onClick={() => setPickerActive(true)}
          />
        )
      }
    </FormSpy>
  );
};

export default DatePickerCheckbox;

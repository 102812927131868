import {
  Box,
  darken,
  styled,
  SxProps,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { HeaderGroup } from "react-table";
import getCustomColumnProps from "./getCustomColumnProps";

const HeaderCellContentWrapper = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1.5, 0, 2),
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const TableHeader = <Type extends object = {}>({
  headerGroups,
  sx = [],
}: {
  headerGroups: HeaderGroup<Type>[];
  sx?: SxProps<Theme>;
}) => (
  <TableHead
    component="div"
    sx={[
      {
        display: "flex",
        alignItems: "center",
        "@media (min-width: 600px)": {
          minHeight: "unset",
          height: "30px",
        },
        "@media (min-width: 0px) and (orientation: landscape)": {
          minHeight: "unset",
          height: "30px",
        },
        backgroundColor: (theme) => darken(theme.palette.background.default, 0),
        boxShadow: (theme) => `inset 0px -1px 0px 0px ${theme.palette.divider}`,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {headerGroups.map(({ getHeaderGroupProps, headers }) => (
      <TableRow
        {...getHeaderGroupProps()} // includes a `key` prop
        component="div"
        sx={{ backgroundColor: "transparent" }}
      >
        {headers.map((column, index) => (
          <Box
            {...column.getHeaderProps(getCustomColumnProps(column))} // includes a `key` prop
            sx={[
              index !== headers.length - 1 && {
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
              },
            ]}
          >
            <Box sx={{ display: "flex", maxWidth: "100%" }}>
              <HeaderCellContentWrapper noWrap>{column.render("Header")}</HeaderCellContentWrapper>
            </Box>
          </Box>
        ))}
      </TableRow>
    ))}
  </TableHead>
);

export default TableHeader;

import { IconButton, Snackbar as MuiSnackbar } from "@mui/material";
import { SnackbarState } from "../types";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarStateProps {
  state: SnackbarState | undefined;
  onClose: () => void;
  successColor?: string;
}

const Snackbar = ({ state, onClose, successColor }: SnackbarStateProps) => {
  const action = state?.autoHideDuration === null && (
    <IconButton size="small" aria-label="close" onClick={onClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={!!state?.message}
      autoHideDuration={
        state?.autoHideDuration === null
          ? null
          : state?.autoHideDuration === undefined
          ? 3000
          : state.autoHideDuration
      }
      onClose={onClose}
      message={state?.message}
      sx={{
        "& > div": {
          backgroundColor: state?.error
            ? "error.main"
            : successColor ?? "primary.main",
        },
      }}
      action={action}
    />
  );
};

export default Snackbar;

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useCallback,
} from "react";
import { Box, Toolbar, Typography, Theme, SxProps } from "@mui/material";
import { utils } from "@mb-pro-ui/utils";
import { Filter, FilterView } from "../filters/types";
import { FormattedMessage } from "react-intl";

const add = (previousFilters: Filters, curr: Filter) => {
  return [...previousFilters, curr];
};

const remove = (previousFilters: Filters, index: number) => {
  previousFilters.splice(index, 1);
  return Array.from(previousFilters);
};

const update = (previousFilters: Filters, index: number, curr: Filter) => {
  const prev = previousFilters[index];
  if (isEqual(prev, curr)) {
    return previousFilters;
  } else {
    previousFilters[index] = curr;
    return Array.from(previousFilters);
  }
};

type Filters = Filter[];
type FilterViews = FilterView[];

interface FilterHeaderProps {
  filterViews: FilterViews;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  className?: string;
  sx?: SxProps<Theme>;
}

const FilterHeader = forwardRef(
  (
    { className, filterViews, setFilters, filters, sx = [] }: FilterHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [urlSearchParams, setUrlSearchParams] = utils.useURLSearchParams();

    const getUrlSearchParam = useCallback(
      (key: string) => {
        return urlSearchParams.get(key);
      },
      [urlSearchParams]
    );

    const setUrlSearchParam = useCallback(
      (key, value) => {
        setUrlSearchParams({ [key]: value });
      },
      [setUrlSearchParams]
    );

    const getFilterValue = useCallback(
      (id: string) => {
        const filter = filters.find((filter) => filter.id === id);
        return filter?.value;
      },
      [filters]
    );

    const setFilter = useCallback(
      (id: string, value: any, reversed?: boolean) => {
        setFilters((previousFilters) => {
          const index = previousFilters.findIndex((filter) => filter.id === id);
          let result;
          const curr = { id, value, reversed };

          if (index !== -1) {
            if (typeof value === "boolean") {
              result = update(previousFilters, index, curr);
            } else {
              if (isEmpty(value)) {
                result = remove(previousFilters, index);
              } else {
                result = update(previousFilters, index, curr);
              }
            }
          } else {
            if (typeof value === "boolean") {
              result = add(previousFilters, curr);
            } else {
              if (isEmpty(value)) {
                result = previousFilters;
              } else {
                result = add(previousFilters, curr);
              }
            }
          }
          return result;
        });
      },
      [setFilters]
    );

    return (
      <Toolbar
        className={className}
        sx={[
          {
            alignItems: "normal",
            display: "flex",
            columnGap: "15px",
            flexWrap: "wrap",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        ref={ref}
      >
        <Box
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            minWidth: "100%",
          }}
        >
          <Typography
            variant="caption"
            color="text.primary"
            sx={{ fontWeight: "bold", width: "100%", flex: 1 }}
          >
            <FormattedMessage defaultMessage={"Advanced search:"} />
          </Typography>
        </Box>
        {filterViews.map((filter) => (
          <filter.Filter
            key={filter.id}
            filterId={filter.id}
            filterValue={getFilterValue(filter.id)}
            setFilter={setFilter}
            urlSearchParam={getUrlSearchParam(filter.id)}
            setUrlSearchParam={setUrlSearchParam}
          />
        ))}
      </Toolbar>
    );
  }
);

export default FilterHeader;

import denormalize from "@mb-pro-ui/denormalize";
import { IBatchOperation, IResourceObjectBase } from "@mb-pro-ui/jsonapi-types";
import { ApiResponseMany, ApiResponseOne, Schema } from "./types";

export const transformJsonapi = <TApiResponse extends ApiResponseOne | ApiResponseMany, TData>(
  resp: TApiResponse,
  includePaths?: string[]
): TApiResponse extends ApiResponseOne ? TData : TData[] & { $count?: number } => {
  const { data, meta } = denormalize<TData>(
    resp as any,
    includePaths?.map((path) => path.split("."))
  ) as any;

  if (typeof meta?.count === "number") {
    data.$count = meta.count;
  }

  return data;
};

function mapRelation(rel: NonNullable<Schema["relationships"]>["field"], value: any) {
  const batchKey = value?.meta?.["batch-key"];
  if (typeof batchKey === "string") {
    return {
      type: rel.type,
      meta: { "batch-key": batchKey },
    };
  }

  const id = value?.id ?? value;
  if (typeof id === "string" && id !== "") {
    return {
      type: rel.type,
      id,
    };
  }

  return null;
}

export function transformValues(
  schema: Schema,
  values: any
): IResourceObjectBase | IBatchOperation {
  const attributes = {} as any;
  const relationships = {} as any;
  const data = { type: schema.type } as any;

  for (const [field, value] of Object.entries(values)) {
    if (field === "type" || value === undefined || field.startsWith("_") || field.startsWith("$")) {
      continue;
    }

    if (field === "id" || field === "meta") {
      data[field] = value;
      continue;
    }

    const rel = schema.relationships?.[field];
    if (rel) {
      if (rel.readonly) {
        throw new Error(`Cannot set value for readonly relationship "${schema.type}"."${field}".`);
      }

      relationships[field] = {
        data: Array.isArray(value)
          ? value.map((item) => mapRelation(rel, item)).filter((item) => !!item)
          : mapRelation(rel, value),
      };
      continue;
    }

    if (schema.attributes.includes(field)) {
      attributes[field] = value;
      continue;
    }

    throw new Error(
      `Field "${field}" is neither relationship nor attribute on the type "${schema.type}".`
    );
  }

  if (Object.keys(attributes).length > 0) {
    data.attributes = attributes;
  }

  if (Object.keys(relationships).length > 0) {
    data.relationships = relationships;
  }

  return data;
}

import MuiTextField, { FilledTextFieldProps } from "@mui/material/TextField";

export interface TextFieldProps extends Omit<FilledTextFieldProps, "variant" | "hiddenLabel"> {
  readOnly?: boolean;
}

const TextField = ({ readOnly, InputProps, focused, ...rest }: TextFieldProps) => (
  <MuiTextField
    variant="filled"
    InputLabelProps={{ shrink: true }}
    InputProps={{
      ...InputProps,
      readOnly,
    }}
    focused={readOnly ? false : focused}
    {...rest}
  />
);

export default TextField;

import { ButtonProps, Tooltip } from "@mui/material";
import { ReactComponent as AlarmStopIcon } from "../../icons/AlarmStop.svg";
import { IconButton, SvgIcon } from "@mui/material";
import { useIntl } from "react-intl";
import { useAudio } from "./AudioPlayerProvider";
import { styled } from "@mui/system";

interface StopAllAlertsButtonProps {
  onClick?: () => void;
  blink: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "blink",
})<ButtonProps & { blink: boolean }>(({ theme, blink }) => ({
  color: theme.palette.common.white,
  "@keyframes blinkWhite": {
    "0%, 100%": {},
    "50%": {
      color: theme.palette.error.main,
    },
  },
  ...(blink && {
    animation: "blinkWhite 1s ease-in-out infinite",
  }),
}));

export const StopAllAlertsButton = ({
  onClick,
  blink,
}: StopAllAlertsButtonProps) => {
  const intl = useIntl();
  const { stopAllAlerts } = useAudio();

  const handleStopAlertsClick = () => {
    stopAllAlerts();
    onClick?.();
    localStorage.setItem("stop-alerts", Date.now().toString());
  };

  return (
    <Tooltip
      title={intl.formatMessage({
        defaultMessage: "Stop alerts",
        description: "Stop alerts button tooltip",
      })}
    >
      <StyledIconButton
        onClick={handleStopAlertsClick}
        size="large"
        blink={blink}
      >
        <SvgIcon component={AlarmStopIcon} />
      </StyledIconButton>
    </Tooltip>
  );
};

export default StopAllAlertsButton;

import { FormatDateOptions } from "react-intl";

const FULL_TIMESTAMP: FormatDateOptions = {
  hourCycle: "h24",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export default FULL_TIMESTAMP;

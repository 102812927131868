import { FieldValidator } from "final-form";

import _isString from "lodash/isString";
import { FormattedMessage } from "react-intl";

export const isString: FieldValidator<any> = (value) =>
  _isString(value) ? undefined : "Szöveges érték";

export const accountType: FieldValidator<any> & { message?: string } = (
  value
) =>
  value?.length === 4 && /^[A-F0-9]+$/.test(value) ? undefined : (
    <FormattedMessage
      defaultMessage={"Unique 4 digit hexadecimal number e.g. 001F"}
    />
  );

export const matchValue: (original: any) => FieldValidator<any> =
  (original) => (value) =>
    value === original ? undefined : "A két értek nem egyezik";

export const requiredValidator = (value: any) =>
  value ? undefined : <FormattedMessage defaultMessage={"Required"} />;

export const composeValidators =
  (...validators: FieldValidator<any>[]) =>
  (value: any, allValues: object) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues ?? {}),
      undefined
    );

import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Typography } from "@mui/material";

import { useGetAll } from "@mb-pro-ui/utils";
import { Category } from "@mb-pro-ui/utils/types/alarm";

import AutoCompleteFilter from "./base/AutocompleteFilter";
import { FilterViewProps } from "../types";
import { useLocale } from "../../../../locales/LocaleProvider";

import {
  EntityWithDescription,
  filterOptionsFromDescription,
  idAsLabel,
  localizeDescription,
  sortByDescription,
} from "../../../../utils/FilterUtils";

const renderOption = (v: Category & EntityWithDescription) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.description}
  </Typography>
);

const EventCategoryAutocompleteFilter = (props: FilterViewProps) => {
  const { locale } = useLocale();
  const localizeAndSort = useCallback(
    (data) =>
      localizeDescription<Category>(data, locale).sort(sortByDescription),
    [locale]
  );

  const { data } = useGetAll<Category>("alarm/categories", {
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: false,
    select: localizeAndSort,
  });

  const { formatMessage } = useIntl();

  return (
    <AutoCompleteFilter
      {...props}
      options={data as (Category & EntityWithDescription)[]}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      noOptionsText={formatMessage({
        defaultMessage: "No such category",
        description: "Intervention table event category filter no option label",
      })}
      label={formatMessage({
        defaultMessage: "Event Category",
        description: "Interventions table event category filter label",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Filter Categories: Name",
        description:
          "Interventions table event category filter input placeholder message",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default EventCategoryAutocompleteFilter;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Cell, CellProps, Column, Row } from "react-table";
import { useIntl } from "react-intl";
import moment from "moment";

import {
  Badge,
  Box,
  styled,
  SvgIcon,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";

import {
  Cdec as _Cdec,
  Customer as _Customer,
  Intervention as _Intervention,
} from "@mb-pro-ui/utils/types/alarm";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import { useEvents, useGetAll, utils } from "@mb-pro-ui/utils";
import Session from "@mb-pro-ui/utils/types/alarm/sessions";

import { useAudio } from "../../audio/AudioPlayerProvider";
import { EnhancedTable } from "../../table";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import StopAllAlertsButton from "../../audio/StopAllAlertsButton";
import InterventionButton from "./header/InterventionButton";
import { FormattedMessage } from "react-intl";
import ColoredRouterLink from "../../utils/ColoredRouterLink";
import LinkButton from "../../utils/LinkButton";
import { useTheme } from "@mui/material";
import LinkStyleText from "../../utils/LinkStyleText";
import StyledText from "../../utils/StyledText";
import CdecModal from "../cdec/CdecModal";
import CustomerModal from "../cdec/CustomerModal";

import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import HistoryIcon from "@mui/icons-material/History";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import Timer24h from "../interventions/Timer24h";
import { ReactComponent as InterventionsMenuIcon } from "../../../icons/InterventionsMenu.svg";
import { CounterIcon } from "../../audio";

type Cdec = Omit<_Cdec, "customer" | "intervention" | "event-category"> & {
  customer: _Customer;
  intervention: Intervention;
  "event-category": Exclude<_Cdec["event-category"], ID>;
} & (
    | {
        "has-valid-coords": true;
        latitude: number;
        longitude: number;
      }
    | {
        "has-valid-coords": false;
        latitude: null;
        longitude: null;
      }
  );

export type Intervention = Omit<
  _Intervention,
  "start-event" | "session" | "customer"
> & {
  "start-event": Cdec;
  session: Maybe<
    Omit<Session, "operator"> & {
      operator: Exclude<Session["operator"], ID>;
    }
  >;
  customer: Exclude<_Cdec["customer"], ID>;
};

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 12,
    padding: 0,
  },
}));

export const onlyInFirst = <T extends { id: string }>(
  first: T[],
  second: T[]
): T[] => {
  let inFirst: T[] = [];
  let newItem: T | null = null;

  if (first && second) {
    for (let firstItem of first) {
      newItem = firstItem;
      for (let secondItem of second) {
        if (newItem.id === secondItem.id) {
          newItem = null;
          break;
        }
      }
      if (newItem) {
        inFirst.push(newItem);
      }
    }
  }

  return inFirst;
};

const errorAlertID = "interventionCdecWindowErrorAlertID";

const AlertEventsInteractiveTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    width: "500px",
    backgroundColor: "transparent",
    padding: 0,
  },
}));

const groupByCustomer = (cdec: Cdec[] | undefined) => {
  return cdec?.reduce<Map<string | undefined, Cdec[]>>((customerMap, cdec) => {
    const customerId = cdec.customer?.id;
    const cdecs = customerMap.get(customerId);
    if (!cdecs) {
      customerMap.set(customerId, [cdec]);
    } else {
      cdecs.push(cdec);
    }
    return customerMap;
  }, new Map());
};

const PrioBadge = ({ value }: { value: string }) => (
  <>
    <Typography
      component="span"
      variant="caption"
      sx={{
        backgroundColor: "warning.main",
        color: "warning.contrastText",
        borderRadius: "4px",
        padding: "0px 2px",
        marginRight: "2px",
      }}
    >
      prio
    </Typography>
    {value}
  </>
);

const StartBadge = ({ value }: { value: string }) => {
  return (
    <>
      <Typography
        component="span"
        variant="caption"
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          borderRadius: "4px",
          padding: "0px 2px",
          marginRight: "2px",
        }}
      >
        start
      </Typography>
      {value}
    </>
  );
};

export const InterventionCdecWindow = () => {
  const {
    playErrorAlert,
    stopErrorAlert,
    playAlert,
    stopAlert,
    stopAllAlerts,
  } = useAudio();
  const [alertCustomerIDs, setAlertCustomerIDs] = useState<string[]>([]);
  const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);
  const [customerModalOpen, setCustomerModalOpen] = useState<boolean>(false);
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);

  const isFirstDataRef = useRef(true);

  const { formatMessage } = useIntl();
  const theme = useTheme();

  const {
    data: alertCdecs,
    status: queryStatus,
    failureCount,
    isSuccess,
    isFetchedAfterMount,
    refetch,
  } = useGetAll<Cdec>("alarm/cdec", {
    page: { limit: 10000 },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    filter: {
      or: {
        "intervention-open": { is: "true" },
        "intervention-needed": { is: "true" },
      },
      "category-alert": { is: "true" },
      customer: { null: "false" },
    },
    include: {
      customer: {},
      "event-category": {},
      intervention: {
        customer: {},
        session: {
          operator: {},
        },
        "start-event": {
          customer: {},
        },
      },
    },
    fields: {
      cdec: [
        "+localized-description",
        "+category-sound",
        "+category-alert",
        "+color",
        "+latitude",
        "+longitude",
        "+partition-number",
        "+zone-number",
        "+event-category",
        "+intervention-needed",
        "+has-valid-coords",
      ],
      customers: [
        "name",
        "account",
        "address",
        "email",
        "active",
        "phone",
        "mobile",
        "passwd",
      ],
      interventions: ["+fresh-cdec-count"],
    },
    sort: ["-category-priority", "id"],
  });

  useEvents(["cdec-insert", "interventions-insert"], () => {
    refetch();
  });

  const alertCdecsByCustomer = useMemo(
    () => groupByCustomer(alertCdecs),
    [alertCdecs]
  );
  const firstAlertCdecs = Array.from(alertCdecsByCustomer?.values() ?? []).map(
    (array) => array[0]
  );

  const iCdecs = alertCdecs?.filter(
    (cdec) => cdec["intervention-needed"] === true && cdec.intervention === null
  );
  const previousICdecs = utils.usePrevious<typeof iCdecs>(iCdecs);
  const iCdecsLoading = iCdecs ? iCdecs.length === 0 && !isSuccess : true;
  const iCdecsByCustomer = useMemo(() => groupByCustomer(iCdecs), [iCdecs]);
  const firstICdecs = Array.from(iCdecsByCustomer?.values() ?? []).map(
    (array) => array[0]
  );

  const iCustomerIds = useMemo(
    () =>
      alertCdecs?.reduce<Set<string>>((customers, cdec) => {
        if (cdec["intervention-needed"]) {
          customers.add(cdec.customer?.id);
        }
        return customers;
      }, new Set()),
    [alertCdecs]
  );

  useEffect(() => {
    const isFirstData = isFirstDataRef.current;
    if (iCdecs && previousICdecs) {
      if (!iCdecsLoading) {
        if (!isFirstData) {
          const newICdecs = onlyInFirst(iCdecs, previousICdecs);
          const removedICdecs = onlyInFirst(previousICdecs, iCdecs);

          for (let iCodec of newICdecs) {
            if (iCodec["category-sound"] && iCodec.active) {
              playAlert(iCodec["category-sound"], iCodec.id);
              setAlertCustomerIDs((prev) => [...prev, iCodec.customer.id]);
            }
          }

          for (let iCodec of removedICdecs) {
            if (iCodec["category-sound"] && iCodec.active) {
              stopAlert(iCodec["category-sound"], iCodec.id);
              setAlertCustomerIDs((prev) => {
                const index = alertCustomerIDs.findIndex(
                  (id) => id === iCodec.customer.id
                );
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
              });
            }
          }
        }
      }
    }
  }, [
    iCdecs,
    previousICdecs,
    playAlert,
    stopAlert,
    iCdecsLoading,
    alertCustomerIDs,
  ]);

  useEffect(() => {
    if (isSuccess && isFetchedAfterMount) {
      isFirstDataRef.current = false;
    }
  }, [isSuccess, isFetchedAfterMount]);

  useEffect(() => {
    if (queryStatus === "error") {
      playErrorAlert(errorAlertID);
    } else {
      stopErrorAlert(errorAlertID);
    }
  }, [queryStatus, playErrorAlert, stopErrorAlert]);

  const handleICdecLinkClick = useCallback(() => {
    stopAllAlerts();
    localStorage.setItem("stop-alerts", Date.now().toString());
  }, [stopAllAlerts]);

  const handleCdecModalOpen = (row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setCdecModalOpen(true);
  };

  const handleCustomerModalOpen = (row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => {
    setCustomerModalOpen(false);
    setSelectedCdec(null);
  };

  const handleCdecModalClose = () => {
    setCdecModalOpen(false);
    setSelectedCdec(null);
  };

  const tooltipColumns = useMemo(
    (): Column<Cdec>[] => [
      {
        id: "localized-description",
        accessor: "localized-description",
        width: 100,
        Header: formatMessage({ defaultMessage: "Alert event" }),
        Cell: ({ value, row, rows }) => {
          const customerNeedIv = iCustomerIds?.has(row.original.customer.id);

          return (
            <StyledText color={row.original.color}>
              {customerNeedIv ? (
                row.index === 0 ? (
                  <PrioBadge value={value} />
                ) : (
                  value
                )
              ) : row.index === rows.length - 1 ? (
                <StartBadge value={value} />
              ) : (
                value
              )}
            </StyledText>
          );
        },
      },
      {
        id: "arrived",
        accessor: "arrived",
        Header: () =>
          formatMessage({
            defaultMessage: "Arrived",
          }),
        width: 100,
        Cell: ({ value }: Cell<Cdec>) => {
          const arrived = moment(value).format(
            formatMessage({
              defaultMessage: "DD/MM/YYYY HH:mm:ss",
            })
          );
          return <Typography noWrap>{arrived}</Typography>;
        },
      },
      {
        id: "priority",
        accessor: (cdec) => cdec["event-category"]?.priority ?? 0,
        maxWidth: 50,
        Header: () => (
          <Tooltip
            title={formatMessage({
              defaultMessage: "Priority",
            })}
          >
            <DoubleArrowIcon
              fontSize="small"
              color="primary"
              sx={{ transform: "rotate(-90deg)" }}
            />
          </Tooltip>
        ),
        Cell: ({ value, row, toggleHideColumn }: CellProps<Cdec, number>) => {
          const customerNeedIv = iCustomerIds?.has(row.original.customer.id);
          if (!customerNeedIv) {
            toggleHideColumn("priority", true);
          }
          return value;
        },
      },
    ],
    [formatMessage, iCustomerIds]
  );

  const columns = useMemo(
    (): Column<Cdec>[] => [
      {
        id: "fix",
        accessor: (cdec) => cdec.id,
        Cell: ({ value, row }: Cell<Cdec>) => {
          const customerId = row.original.customer.id;
          const customerNeedIv = iCustomerIds?.has(customerId);

          const createLink = (
            <StyledBadge
              badgeContent={iCdecsByCustomer?.get(customerId)?.length}
              color={"error"}
              max={999}
            >
              <LinkButton
                row={row}
                url={`/alarm/interventions/create?start-events=${value}`}
                color={"warning"}
                sx={{
                  backgroundColor: "common.white",
                }}
                icon={
                  <SvgIcon>
                    <InterventionsMenuIcon fontSize="inherit" />
                  </SvgIcon>
                }
                message={formatMessage({
                  defaultMessage: "Intervention",
                })}
              />
            </StyledBadge>
          );

          const openLink = (
            <StyledBadge
              badgeContent={Number(
                row.original.intervention?.["fresh-cdec-count"]
              )}
              color={"primary"}
              max={999}
            >
              <LinkButton
                row={row}
                url={`/alarm/interventions/${row.original.intervention?.id}`}
                sx={{ backgroundColor: "common.white" }}
                icon={
                  <SvgIcon>
                    <InterventionsMenuIcon />
                  </SvgIcon>
                }
                message={<FormattedMessage defaultMessage={"Intervention"} />}
              />
            </StyledBadge>
          );

          return customerNeedIv ? createLink : openLink;
        },
      },
      {
        id: "customer",
        accessor: (cdec) => cdec.customer?.name,
        width: "100",
        Header: formatMessage({
          defaultMessage: "Customer",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          const text = value
            ? `${row.original.customer?.account} - ${value}`
            : formatMessage({
                defaultMessage: "Unknown customer",
                description:
                  "Beavatkozásra váró események táblázat ismeretlen ügyfél",
              });
          return row.original.customer?.id ? (
            <LinkStyleText onClick={() => handleCustomerModalOpen(row)}>
              {text}
            </LinkStyleText>
          ) : (
            <span>{text}</span>
          );
        },
      },
      {
        id: "description",
        accessor: "localized-description",
        width: "100",
        Header: formatMessage({
          defaultMessage: "Event description",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          const customerId = row.original.customer.id;

          const customerICdecs = iCdecsByCustomer?.get(customerId);
          const customerCdecs = alertCdecsByCustomer?.get(customerId);
          let reverseCdecs: Cdec[] = [];
          if (customerCdecs) {
            reverseCdecs = [...customerCdecs].sort((a, b) => {
              return Number(b.id) - Number(a.id);
            });
          }

          const startEvent =
            row.original.intervention?.["start-event"]?.[
              "localized-description"
            ];

          const customerNeedIv = iCustomerIds?.has(customerId);

          const prefix = customerNeedIv ? (
            formatMessage(
              {
                defaultMessage: `{account} customer's alert events waiting for intervention ({count})`,
              },
              {
                account: row.original.customer?.account,
                count: iCdecsByCustomer?.get(customerId)?.length,
              }
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {formatMessage(
                {
                  defaultMessage: `{account} customer's alert events`,
                },
                {
                  account: row.original.customer?.account,
                }
              )}
              <Tooltip
                title={formatMessage({
                  defaultMessage: "In order of arrival",
                })}
              >
                <SvgIcon
                  sx={{ color: "primary.contrastText", marginLeft: "5px" }}
                  fontSize="small"
                >
                  <HistoryIcon
                    fontSize="small"
                    color="inherit"
                    sx={{ marginLeft: 1 }}
                  />
                </SvgIcon>
              </Tooltip>
            </Box>
          );

          return (
            <AlertEventsInteractiveTooltip
              title={
                <EnhancedTable
                  data={customerNeedIv ? customerICdecs ?? [] : reverseCdecs}
                  columns={tooltipColumns}
                  disableGlobalFilter
                  prefix={
                    <Typography color="primary.contrastText">
                      {prefix}
                    </Typography>
                  }
                  innerSx={{
                    height: "200px",
                    maxHeight: "200px",
                  }}
                  sx={{
                    boxShadow: `0px 0px 10px 1px`,
                    display: "block",
                  }}
                />
              }
            >
              <LinkStyleText
                color={row.original.color}
                onClick={() => handleCdecModalOpen(row)}
              >
                {customerNeedIv ? (
                  <PrioBadge value={value} />
                ) : (
                  <StartBadge value={startEvent} />
                )}
              </LinkStyleText>
            </AlertEventsInteractiveTooltip>
          );
        },
      },
      {
        id: "operator",
        accessor: (cdec) => cdec?.intervention?.session?.operator?.login,
        width: 60,
        Header: formatMessage({
          defaultMessage: "Operator",
          description: "Beavatkozások táblázat fejléc operátor",
        }),
        Cell: ({ value }: Cell<Cdec, string | undefined>) => {
          return (
            value ?? (
              <Typography
                component="p"
                variant="caption"
                sx={{
                  backgroundColor: "text.primary",
                  color: "common.white",
                  borderRadius: "4px",
                  paddingLeft: "3px",
                  lineHeight: "1.3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {formatMessage({ defaultMessage: "none" })}
                <PriorityHighOutlinedIcon fontSize="inherit" color="warning" />
              </Typography>
            )
          );
        },
      },
      {
        id: "start-time",
        accessor: (cdec) => cdec.intervention?.["start-time"],
        width: 50,
        Header: formatMessage({
          defaultMessage: "Elapsed time",
          description: "Beavatkozások táblázat fejléc eltelt idő",
        }),
        Cell: ({ value }: Cell<Cdec>) => <Timer24h startTime={value} />,
      },
      {
        id: "alert-icon",
        width: 20,
        accessor: (cdec) => cdec.customer.id,
        Cell: ({ value }: Cell<Cdec>) => {
          return (
            <NotificationImportantIcon
              sx={{
                color: "white",
                "@keyframes blinkIcon": {
                  "0%, 100%": {
                    color: "white",
                  },
                  "50%": {
                    color: (theme) => theme.palette.error.main,
                  },
                },
                ...(alertCustomerIDs.indexOf(value) > -1 && {
                  animation: "blinkIcon 1s ease-in-out infinite",
                }),
              }}
            />
          );
        },
      },
    ],
    [
      formatMessage,
      alertCustomerIDs,
      tooltipColumns,
      iCdecsByCustomer,
      iCustomerIds,
      alertCdecsByCustomer,
    ]
  );

  const prefix = (
    <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      <ColoredRouterLink
        color={theme.palette.common.white}
        to={"/alarm/events?category-alert=true&active=true"}
      >
        <Typography color="primary.contrastText">
          <FormattedMessage defaultMessage="Interventions" />
        </Typography>
      </ColoredRouterLink>
      <StopAllAlertsButton
        onClick={() => setAlertCustomerIDs([])}
        blink={alertCustomerIDs && alertCustomerIDs.length > 0}
      />
    </Box>
  );

  const postfix = (
    <InterventionButton
      interventionCdecs={(iCdecs ?? []) as Cdec[]}
      interventionNeeded={iCdecs?.length}
      disabled={!iCdecs?.length}
      nextId={firstICdecs[0]?.id}
    />
  );

  if (iCustomerIds) {
    firstAlertCdecs.sort((a, b) => {
      const INA = iCustomerIds.has(a.customer.id);
      const INB = iCustomerIds.has(b.customer.id);
      if (INA && INB) {
        return 0;
      }

      if (INA) {
        return -1;
      }

      return 1;
    });
  }

  if (iCustomerIds) {
    firstAlertCdecs.sort((a, b) => {
      const INA = iCustomerIds.has(a.customer.id);
      const INB = iCustomerIds.has(b.customer.id);
      if (INA || INB) {
        return 0;
      }
      return (
        Number(b.intervention["fresh-cdec-count"]) -
        Number(a.intervention["fresh-cdec-count"])
      );
    });
  }

  return (
    <>
      <EnhancedTable
        data={firstAlertCdecs as Cdec[]}
        columns={columns}
        onRowClick={handleICdecLinkClick}
        disableGlobalFilter
        queryStatus={queryStatus}
        overscanCount={20}
        emptyIcon={DoneOutlineIcon}
        emptyMessage={formatMessage({
          defaultMessage: "No intervention required",
          description:
            "Operator Page Intervention needed cdecs table placeholder text",
        })}
        errorIcon={CounterIcon}
        errorIconProps={{ failureCount }}
        errorMessage=""
        prefix={prefix}
        postfix={postfix}
        sx={{ height: "100%", flex: 1, overflow: "hidden" }}
      />
      {selectedCdec && (
        <CustomerModal
          customer={selectedCdec.customer}
          open={customerModalOpen}
          onClose={handleCustomerModalClose}
        />
      )}
      {selectedCdec && (
        <CdecModal
          cdec={selectedCdec}
          open={cdecModalOpen}
          onClose={handleCdecModalClose}
        />
      )}
    </>
  );
};

export default InterventionCdecWindow;

import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  SxProps,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useUpdate } from "@mb-pro-ui/utils";
import type { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";

import { useErrorHandler } from "../../hooks";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import Widget from "../utils/Widget";
import { useAcknowledge } from "./AcknowledgeContext";
import { Intervention, TaskGroup, Subtask, ActionGroup } from "./types";

export const SubtasksWidget = ({
  intervention,
  isOwn,
  refetchIntervention,
  sx,
}: {
  intervention?: Intervention;
  isOwn: boolean;
  refetchIntervention: () => Promise<any>;
  sx?: SxProps;
}) => {
  const { formatMessage } = useIntl();
  const [unackCdecs] = useAcknowledge();

  const { mutate: updateSubtask } = useUpdate<Subtask>("alarm/subtasks");
  const isOpen = intervention?.["close-time"] === null;

  const { JsonApiErrorSnackBar: ErrorSnackBar } = useErrorHandler();
  const [error, setError] = useState<JsonapiError | null>(null);
  const handleSnackBarClose = () => {
    setError(null);
  };
  const handleQueryError = (error: JsonapiError) => {
    setError(error);
  };

  const handleSubtaskClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    updateSubtask(
      { id: id, status: event.target.checked },
      {
        onSettled: () => {
          refetchIntervention();
        },
        onError: handleQueryError,
      }
    );
  };

  const toListItem = (st: Subtask, ag: ActionGroup | null) => {
    const text = (
      <ListItemText
        primary={<Typography variant="body2">{st.description}</Typography>}
        secondary={
          <>
            <Typography variant="body2">{st.person}</Typography>
            <Typography variant="body2" sx={{ fontWeight: "inherit" }}>
              {st.address}
            </Typography>
          </>
        }
        primaryTypographyProps={{ component: "div" }}
        secondaryTypographyProps={{ component: "div" }}
      />
    );

    return (
      <ListItem
        key={st.id}
        disabled={!ag || !isOwn || !isOpen}
        button
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {isOwn ? (
          <FormControlLabel
            sx={{ width: "100%" }}
            control={
              <Checkbox
                onChange={(e) => handleSubtaskClick(e, st.id)}
                checked={st.status}
                disableRipple
                sx={{ marginRight: "20px", marginLeft: 0 }}
              />
            }
            label={text}
            disabled={!ag || !isOwn}
          />
        ) : (
          text
        )}
      </ListItem>
    );
  };

  const toList = ({ id, status, subtasks, "action-group": ag }: TaskGroup) => (
    <List dense disablePadding key={id}>
      <ListSubheader
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          backgroundColor: ag
            ? theme.palette.primary.contrastText
            : theme.palette.grey[300],
          color: ag ? theme.palette.primary.main : theme.palette.text.disabled,
        })}
      >
        {ag
          ? ag.description
          : formatMessage({
              defaultMessage: "Deleted task group",
              description: "Deleted task group message",
            })}
        {status && <DoneIcon color="primary" sx={{ marginLeft: "auto" }} />}
      </ListSubheader>
      {subtasks?.map((element) => toListItem(element, ag))}
    </List>
  );

  return (
    <>
      <Widget
        title={formatMessage({
          defaultMessage: "Tasks",
          description: "Intervention page Tasks widget title",
        })}
        postfix={
          !isOpen ? (
            <Typography>
              {formatMessage({ defaultMessage: "Closed intervention" })}
            </Typography>
          ) : (
            unackCdecs.length > 0 && (
              <BootstrapTooltip
                backgroundColor={"info.main"}
                title={formatMessage({
                  defaultMessage: "Unacknowledged events !",
                })}
              >
                <PriorityHighIcon
                  fontSize="small"
                  sx={{ color: "common.white" }}
                />
              </BootstrapTooltip>
            )
          )
        }
        placeholderMessage={formatMessage({
          defaultMessage: "No tasks",
          description: "Tasks widget No tasks message",
        })}
        sx={sx}
      >
        {intervention?.["task-groups"]?.length && (
          <Box display="flex" flexDirection="column" overflow="auto">
            {intervention["task-groups"].map(toList)}
          </Box>
        )}
      </Widget>
      <ErrorSnackBar error={error} onClose={handleSnackBarClose} />
    </>
  );
};

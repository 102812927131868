import { Autocomplete } from "@mb-pro-ui/components/form";
import { useGetAll } from "@mb-pro-ui/utils";
import {
  /*Category,*/ Category as _Category,
} from "@mb-pro-ui/utils/types/alarm";
import { formControlClasses } from "@mui/material";
import { useIntl } from "react-intl";
import { localDescr } from "../../../utils/LocaleUtils";
import { useLocale } from "../../../locales/LocaleProvider";
import { Lang } from "../../types";
import { normalizeEmptyString } from "../../form/Normalizators";
import { isEqualById } from "../../utils/autocompleteUtils";

// function isCategory(category: _Category | string): category is _Category {
//   return (category as Category).type !== undefined;
// }

const EventCategoryAutocomplete = ({
  disabled,
  required,
  highlightDirty,
}: {
  disabled?: boolean;
  required?: boolean;
  highlightDirty?: boolean;
}) => {
  const { data: categories, isLoading } =
    useGetAll<_Category>("alarm/categories");
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  return (
    <Autocomplete
      name={"category"}
      label={formatMessage({ defaultMessage: "Event category" })}
      options={categories ?? []}
      isEqual={isEqualById}
      required={required}
      requiredError={formatMessage({
        defaultMessage: "Required",
      })}
      sx={{
        display: "flex",
        [`& .${formControlClasses.root}`]: {
          flexGrow: 1,
        },
      }}
      getOptionLabel={(category) => {
        if (typeof category === "string") {
          return category;
        }
        return category?.descr ? localDescr(category?.descr, Lang[locale]) : "";
      }}
      disabled={isLoading || disabled}
      highlightDirty={highlightDirty}
      parse={normalizeEmptyString}
    />
  );
};

export default EventCategoryAutocomplete;

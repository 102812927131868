import { forwardRef, ElementType, ForwardedRef } from "react";

import { styled } from "@mui/system";
import {
  Tooltip,
  Divider,
  IconButton,
  IconButtonProps,
  TooltipProps,
  tooltipClasses,
  alpha,
} from "@mui/material";

export const StyledIconButton = styled(
  forwardRef(
    <C extends ElementType>(
      props: IconButtonProps<C, { component?: C }>,
      ref: ForwardedRef<HTMLButtonElement>
    ) => <IconButton {...props} ref={ref} />
  )
)(() => ({
  color: "rgba(0, 0, 0, 0.54)",
  "&:hover": {
    backgroundColor: "unset",
  },
}));

export const CloseIconButton = styled(
  forwardRef(
    <C extends ElementType>(
      props: IconButtonProps<C, { component?: C }>,
      ref: ForwardedRef<HTMLButtonElement>
    ) => <IconButton {...props} ref={ref} />
  )
)(({ theme, color }) => ({
  color: theme.palette.common.white,
  backgroundColor:
    color === "info" ? theme.palette.info.main : theme.palette.warning.main,
  boxShadow:
    "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
  "&:hover": {
    backgroundColor: alpha(
      color === "info" ? theme.palette.info.dark : theme.palette.warning.dark,
      0.8
    ),
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  marginLeft: 16,
  marginRight: 16,
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.92)",
  },
}));

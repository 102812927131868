import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { EnhancedTable } from "@mb-pro-ui/components/table";

import { Intervention, IntervalRule } from "./types";

const CustomerIntervalRules = ({
  intervention,
}: {
  intervention?: Intervention;
}) => {
  const { formatMessage } = useIntl();

  return (
    <EnhancedTable
      columns={useMemo(
        (): Column<IntervalRule>[] => [
          {
            id: "number",
            Header: formatMessage({
              defaultMessage: "Number",
              description:
                "Intervention page customer interval rules table Number header",
            }),
            accessor: "number",
            width: "10",
          },
          {
            id: "active",
            Header: formatMessage({
              defaultMessage: "Active",
              description:
                "Intervention page customer interval rules table Active header",
            }),
            accessor: (ir) =>
              ir.active ? <DoneIcon color="primary" /> : "false",
            width: "10",
          },
          {
            id: "date-code",
            Header: formatMessage({
              defaultMessage: "Date Code",
              description:
                "Intervention page customer interval rules table Date Code header",
            }),
            accessor: (ir) => ir["date-code"]["localized-description"],
            width: "10",
          },
          {
            id: "beginning",
            Header: formatMessage({
              defaultMessage: "Beginning",
              description:
                "Intervention page customer interval rules table Beginning header",
            }),
            accessor: "interval-start-time",
            width: "10",
          },
          {
            id: "length",
            Header: formatMessage({
              defaultMessage: "Length",
              description:
                "Intervention page customer interval rules table Length header",
            }),
            accessor: "interval-length",
            width: "10",
          },
          {
            id: "zone",
            Header: formatMessage({
              defaultMessage: "Zone",
              description:
                "Intervention page customer interval rules table Zone header",
            }),
            accessor: "event-zone",
            width: "10",
          },
          {
            id: "category",
            Header: formatMessage({
              defaultMessage: "Category",
              description:
                "Intervention page customer interval rules table Category header",
            }),
            accessor: (ir) => ir["event-category"]?.["localized-description"],
            width: "10",
          },
          {
            id: "expectation",
            Header: formatMessage({
              defaultMessage: "Expectation",
              description:
                "Intervention page customer interval rules table Expectation header",
            }),
            accessor: (ir) => {
              switch (ir["expectation-type"]) {
                case 0:
                  return formatMessage({
                    defaultMessage: "Expect event",
                    description:
                      "Intervention page customer interval rules table `expect event` message",
                  });
                case 1:
                  return formatMessage({
                    defaultMessage: "Expect no event",
                    description:
                      "Intervention page customer interval rules table `expect no event` message",
                  });
                case 2:
                  return formatMessage({
                    defaultMessage: "Expect armed",
                    description:
                      "Intervention page customer interval rules table `expect armed` message",
                  });
                case 3:
                  return formatMessage({
                    defaultMessage: "Expect not armed",
                    description:
                      "Intervention page customer interval rules table `Expect not armed` message",
                  });
              }
            },
            width: "10",
          },
          {
            id: "repetitions",
            Header: formatMessage({
              defaultMessage: "Repetitions",
              description:
                "Intervention page customer interval rules table Repetitions header",
            }),
            accessor: (ir) => ir["rep-count"],
            width: "10",
          },
          {
            id: "repetition-delay",
            Header: formatMessage({
              defaultMessage: "Repetition Delay",
              description:
                "Intervention page customer interval rules table Repetition Delay header",
            }),
            accessor: (ir) => ir["rep-interval"],
            width: "10",
          },
        ],
        [formatMessage]
      )}
      data={intervention?.customer?.["interval-rules"] ?? []}
      prefix={
        <Typography color="primary.contrastText">
          {formatMessage({
            defaultMessage: "Customer interval rules",
            description:
              "Intervention page customer interval rules subpage header",
          })}
        </Typography>
      }
    />
  );
};

export default CustomerIntervalRules;

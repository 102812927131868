import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { ReactNode, useState } from "react";
import Breadcrumbs, { BreadcrumbsProps } from "./Breadcrumbs";
import SideBar, { SideBarOption } from "./SideBar";

const closedSidebarWidth = 56;
const openedSidebarWidth = 240;

const Page = ({
  children,
  sx = [],
  breadcrumbs,
  breadcrumbNameMap,
  postfix,
  sidebarOptions,
  sidebarFooter,
  sidebarAnchor = "left",
  sidebarWidth = openedSidebarWidth,
  sidebarOpen,
  onSidebarOpen,
  onSidebarClose,
  sidebarHeader,
  backLink,
}: {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  breadcrumbs?: boolean;
  breadcrumbNameMap?: BreadcrumbsProps["breadcrumbNameMap"];
  postfix?: ReactNode;
  sidebarOpen?: boolean;
  onSidebarOpen?: () => void;
  onSidebarClose?: () => void;
  sidebarOptions?: SideBarOption[];
  sidebarWidth?: number;
  sidebarFooter?: ReactNode;
  sidebarAnchor?: "left" | "right";
  sidebarHeader?: ReactNode;
  backLink?: string;
}) => {
  const [sbOpen, setSbOpen] = useState<boolean>(sidebarOpen === undefined ? true : sidebarOpen);

  const handleSbOpen = () => {
    setSbOpen(true);
  };

  const handleSbClose = () => {
    setSbOpen(false);
  };

  const isHeader = !!(breadcrumbs || postfix);
  const open = sidebarOpen === undefined ? sbOpen : sidebarOpen;

  return (
    <>
      {sidebarOptions && (
        <SideBar
          width={sidebarWidth}
          options={sidebarOptions}
          footer={sidebarFooter}
          anchor={sidebarAnchor}
          open={open}
          handleOpen={handleSbOpen}
          handleClose={handleSbClose}
          header={sidebarHeader}
          onSidebarOpen={onSidebarOpen}
          onSidebarClose={onSidebarClose}
          backLink={backLink}
          closedWidth={closedSidebarWidth}
        />
      )}
      <Box
        sx={[
          {
            backgroundColor: (theme) => theme.palette.background.default,
            position: "relative",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingTop: breadcrumbs ? 0 : "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "24px",
            overflowY: "auto",
            ...(sidebarOptions && {
              ...(sidebarAnchor === "left" && {
                marginLeft: open ? `${sidebarWidth}px` : `${closedSidebarWidth}px`,
              }),
              ...(sidebarAnchor === "right" && {
                marginRight: open ? `${sidebarWidth}px` : `${closedSidebarWidth}px`,
              }),
            }),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {isHeader && (
          <Box sx={{ display: "flex", alignItems: "center", margin: 1, minHeight: "48px" }}>
            {breadcrumbs && <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} />}
            <Box sx={{ flex: 1 }} />
            {postfix}
          </Box>
        )}
        {children}
      </Box>
    </>
  );
};

export default Page;

import { Fragment } from "react";
import { Autocomplete } from "@mb-pro-ui/components/form";
import { useGetAll } from "@mb-pro-ui/utils";
import { Customer, CustomerField } from "../customer/types";
import { FormSpy } from "react-final-form";
import { useIntl } from "react-intl";
import Widget from "../utils/Widget";
import { StyledTextField } from "./StyledComponents";
import { Divider, Typography } from "@mui/material";
import { Maybe } from "@mb-pro-ui/utils/types";
import { isEqualById } from "../utils/autocompleteUtils";

const checkMissingKeysOnCustom = (
  customerField: Maybe<CustomerField>,
  custom: Maybe<{ [key: string]: any }>
) => {
  if (!custom) return false;

  if (!customerField && custom) return true;

  if (
    Object.keys(custom).some(
      (key) => !customerField?.fields?.some((field) => field.name === key)
    )
  )
    return true;
};

interface StructuredFormProps {
  readOnly?: boolean;
}

const StructuredForm = ({ readOnly }: StructuredFormProps) => {
  const { formatMessage } = useIntl();

  const { data: customerFields } = useGetAll<CustomerField>(
    "alarm/customer-custom-fields",
    {
      refetchOnWindowFocus: false,
    }
  );

  return customerFields ? (
    <Widget
      title={formatMessage({
        defaultMessage: "Custom data",
      })}
      sx={{ marginBottom: "8px" }}
    >
      <Autocomplete
        name="custom-fields"
        label={formatMessage({
          defaultMessage: "Customer fields",
        })}
        options={customerFields}
        fullWidth
        getOptionLabel={(option) => option.name ?? ""}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
        readOnly={readOnly}
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <>
            {values["custom-fields"] ? (
              <Divider sx={{ marginBottom: "8px" }} />
            ) : null}
            {checkMissingKeysOnCustom(
              values["custom-fields"],
              values.custom
            ) ? (
              <Typography
                variant="caption"
                sx={{ marginLeft: "12px", color: "warning.light" }}
              >
                {formatMessage({
                  defaultMessage: "Some fields are not visible!",
                })}
              </Typography>
            ) : (
              <></>
            )}
          </>
        )}
      </FormSpy>
      <FormSpy subscription={{ values: true }}>
        {({ values }) =>
          (values as Customer)["custom-fields"]?.fields?.map((field, i) => (
            <Fragment key={`custom-field-${i}`}>
              {field.type === "textfield" ? (
                <StyledTextField
                  name={`custom.${field.name}`}
                  label={field.name}
                  required={field.required}
                  readOnly={readOnly}
                />
              ) : (
                <Autocomplete
                  name={`custom.${field.name}`}
                  label={field.name}
                  options={field.options}
                  required={field.required}
                  readOnly={readOnly}
                  sx={{
                    width: "100%",
                    "& .MuiTextField-root": {
                      width: "calc(100% - 16px)",
                    },
                  }}
                />
              )}
            </Fragment>
          )) ?? <></>
        }
      </FormSpy>
    </Widget>
  ) : null;
};

export default StructuredForm;

import { SvgIconProps } from "@mui/material";

export interface Module {
  id: ModuleID;
  name?: string;
  path?: string;
  icon?: (props?: SvgIconProps) => React.ReactNode;
  img?: (width?: string, height?: string) => React.ReactElement<"img">;
  active?: boolean;
  hidden?: boolean;
  info?: string;
}

export enum Lang {
  "hu" = "hu-hu",
  "en" = "en-gb",
  "de" = "de-de",
  "sk" = "sk-sk",
}

export enum ModuleID {
  Dispatcher = "dispatcher",
  Patrol = "patrol",
  Stock = "stock",
  RemotControl = "remoteControl",
  Vehicle = "vehicle",
  Ozone = "ozone",
  Consumption = "consumption",
  IndoorPositioning = "indoorPositioning",
  Telemedicina = "telemedicina",
  Temperature = "temperature",
  Video = "video",
}

export enum DispatcherRoutes {
  AlarmOperator = "/alarm/operator",
  Customers = "/alarm/customers",
  Events = "/alarm/events",
  Interventions = "/alarm/interventions",
  Objects = "/alarm/objects",
  Exports = "/alarm/exports",
  Map = "/alarm/map",
  Notifications = "/alarm/notifications",
  SystemSettings = "/alarm/settings",
}

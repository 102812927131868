import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

type ProfileValue = 0 | 1 | 2;
// 0 - Never
// 1 - Only in expert mode
// 2 - Always

export interface ProfileFields {
  name?: ProfileValue;
  address?: ProfileValue;
  phone?: ProfileValue;
  mobile?: ProfileValue;
  email?: ProfileValue;
  password?: ProfileValue;
  "customer-group"?: ProfileValue;
  "lifesign-para"?: ProfileValue;
  "complex-lifesign-para"?: ProfileValue;
  "lifesign-hide"?: ProfileValue;
  "gprs-lifesign-hide"?: ProfileValue;
  "gprs-lifesign-para"?: ProfileValue;
  account?: ProfileValue;
  codetable?: ProfileValue;
  "object-type"?: ProfileValue;
  "device-phone-number"?: ProfileValue;
  active?: ProfileValue;
  "temporary-inactivity"?: ProfileValue;
  "base-sector"?: ProfileValue;
  "base-coordinates"?: ProfileValue;
  "contract-number"?: ProfileValue;
  "billing-id"?: ProfileValue;
  "contract-app-type"?: ProfileValue;
  "contract-service-type"?: ProfileValue;
  "contract-signal-type"?: ProfileValue;
  "contract-unit-type"?: ProfileValue;
  "contract-start-ts"?: ProfileValue;
  "contract-billing-type"?: ProfileValue;
  "equipment-type"?: ProfileValue;
  "equipment-serial"?: ProfileValue;
  "equipment-partitioned"?: ProfileValue;
  "battery-type"?: ProfileValue;
  "sounder-type"?: ProfileValue;
  "open-close-list"?: ProfileValue;
  "full-list"?: ProfileValue;
}

export interface ProfileCards {
  "basic-data": ProfileValue;
  "technical-informations": ProfileValue;
  "disabled-events": ProfileValue;
  deactivation: ProfileValue;
  remarks: ProfileValue;
  map: ProfileValue;
  "location-based-alerts": ProfileValue;
  images: ProfileValue;
  "automatic-notification": ProfileValue;
  "notification-templates": ProfileValue;
  notifications: ProfileValue;
}

export interface ProfileMenus {
  customer: ProfileValue;
  installation: ProfileValue;
  installers: ProfileValue;
  "technical-informations": ProfileValue;
  partitions: ProfileValue;
  zones: ProfileValue;
  users: ProfileValue;
  "external-receiver": ProfileValue;
  "event-processing": ProfileValue;
  "interval-rules": ProfileValue;
  "event-cancellations": ProfileValue;
  lifesigns: ProfileValue;
  "regular-reports": ProfileValue;
  "maps-and-position": ProfileValue;
  "map-profile": ProfileValue;
  location: ProfileValue;
  rfid: ProfileValue;
  notifiables: ProfileValue;
  interventions: ProfileValue;
  "manual-notification": ProfileValue;
  "automatic-notification": ProfileValue;
  "event-rules": ProfileValue;
  contract: ProfileValue;
}

export interface Profile {
  menu: ProfileMenus;
  cards: ProfileCards;
  fields: ProfileFields;
}

export interface ProfileContextType {
  profile: Profile | null;
  setExpertMode: (expertMode: boolean) => void;
  setProfile: (profile: Profile | null) => void;
  expertMode: boolean;
}

export const ProfileContext = createContext<ProfileContextType | undefined>(
  undefined
);

export default function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const [expertMode, setExpertMode] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null);

  const profileContext: ProfileContextType = useMemo(
    () => ({
      profile,
      setExpertMode,
      setProfile,
      expertMode,
    }),
    [setExpertMode, expertMode, profile]
  );

  return (
    <ProfileContext.Provider value={profileContext}>
      {children}
    </ProfileContext.Provider>
  );
}

export const useProfile = () => {
  const value = useContext(ProfileContext);
  if (value === undefined) {
    throw new Error("useProfile must be used inside ProfileProvider");
  }
  return value;
};

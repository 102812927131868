import SvgIcon from "@mui/icons-material/Memory";
import { ReactComponent as OperatorMenuIcon } from "../icons/OperatorMenu.svg";
import { ReactComponent as CustomerManagerMenuIcon } from "../icons/CustomerManagerMenu.svg";
import { ReactComponent as EventsMenuIcon } from "../icons/EventsMenu.svg";
import { ReactComponent as InterventionsMenuIcon } from "../icons/InterventionsMenu.svg";
import { ReactComponent as ExportsMenuIcon } from "../icons/ExportsMenu.svg";
import { ReactComponent as MapMenuIcon } from "../icons/MapMenu.svg";
import { ReactComponent as SystemSettingsMenuIcon } from "../icons/system_settings/SystemSettings.svg";

import AlertBadge from "../components/utils/AlertBadge";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { DispatcherRoutes } from "../components/types";

const useMenuLinks = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        icon: (
          <AlertBadge>
            <SvgIcon component={OperatorMenuIcon} viewBox="0 0 50 50" />
          </AlertBadge>
        ),
        href: DispatcherRoutes.AlarmOperator,
        label: formatMessage({
          defaultMessage: "Operator",
          description: "Main menu Operator label",
        }),
      },
      {
        icon: (
          <SvgIcon component={CustomerManagerMenuIcon} viewBox="0 0 50 50" />
        ),
        href: DispatcherRoutes.Customers,
        label: formatMessage({
          defaultMessage: "Customers",
          description: "Main menu Customers label",
        }),
      },
      {
        icon: <SvgIcon component={EventsMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Events,
        label: formatMessage({
          defaultMessage: "Events",
          description: "Main menu Events label",
        }),
      },
      {
        icon: <SvgIcon component={InterventionsMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Interventions,
        label: formatMessage({
          defaultMessage: "Interventions",
          description: "Main menu Interventions label",
        }),
      },
      // {
      //   icon: <SvgIcon component={ObjectsStateMenuIcon} viewBox="0 0 50 50" />,
      //   href: DispatcherRoutes.Objects,
      //   label: formatMessage({
      //     defaultMessage: "Objects",
      //     description: "Main menu Objects label",
      //   }),
      // },
      {
        icon: <SvgIcon component={ExportsMenuIcon} />,
        href: DispatcherRoutes.Exports,
        label: formatMessage({
          defaultMessage: "Exports",
          description: "Main menu Exports label",
        }),
      },
      {
        icon: <SvgIcon component={MapMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Map,
        label: formatMessage({
          defaultMessage: "Map",
          description: "Main menu Map label",
        }),
      },
      // {
      //   icon: <SvgIcon component={NotificationsMenuIcon} />,
      //   href: DispatcherRoutes.Notifications,
      //   label: formatMessage({
      //     defaultMessage: "Notifications",
      //     description: "Main menu Notifications label",
      //   }),
      // },
      {
        icon: <SvgIcon component={SystemSettingsMenuIcon} />,
        href: DispatcherRoutes.SystemSettings,
        label: formatMessage({
          defaultMessage: "System Settings",
          description: "Main menu System Settings label",
        }),
      },
    ],
    [formatMessage]
  );
};

export default useMenuLinks;

import { IErrorDocument, IResourceObject } from "@mb-pro-ui/jsonapi-types";
import type { HttpError, RequestError } from "../query/http";

export interface ApiResponseOne {
  data: IResourceObject;
  included?: IResourceObject[];
}

export interface ApiResponseMany {
  data: IResourceObject[];
  included?: IResourceObject[];
}

type JsonapiHttpError = HttpError<"json", IErrorDocument>;

export type JsonapiError = JsonapiHttpError | RequestError;

export function isJsonapiHttpError(error: JsonapiError): error is JsonapiHttpError {
  return (error as any).type === "json";
}

export interface Schema<T extends string = string> {
  type: T;
  attributes: string[];
  relationships?: Record<
    string,
    {
      type: string;
      manyToMany?: boolean;
      readonly?: boolean;
      required?: boolean;
    }
  >;
}

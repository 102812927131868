import { useState, useMemo, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column, Row } from "react-table";
import moment from "moment";

import {
  Cdec as _Cdec,
  Event as _Event,
  Intervention as _Intervention,
  Customer as _Customer,
  Category as _Category,
} from "@mb-pro-ui/utils/types/alarm";
import {
  useGetStream,
  useGetAll,
  useEvents,
  useStorageState,
} from "@mb-pro-ui/utils";

import { Box, Tooltip, Typography, useTheme } from "@mui/material";

import CdecModal from "./CdecModal";
import CustomerModal from "./CustomerModal";

import { useAudio } from "../../audio/AudioPlayerProvider";
import { EnhancedTable } from "../../table";

import NotificationToggleButton from "../../audio/NotificationToggleButton";
import ManualEventButton from "./header/ManualEventButton";
import FilterButton from "./header/FilterButton";
import ColoredRouterLink from "../../utils/ColoredRouterLink";
import LinkStyleText from "../../utils/LinkStyleText";
import EventIcon from "../../utils/EventIcon";
import InfoIcon from "@mui/icons-material/Info";
import { CounterIcon } from "../../audio";
import { Snackbar } from "../../settings/utils";
import { SnackbarState } from "../../settings/types";
import { ID } from "@mb-pro-ui/utils/types";
import CreateCustomerDialog from "../../../pages/dialogs/CreateCustomerDialog";

export interface FilterState {
  hideInactiveEvents: InactiveEventsFilter | null;
  hideUnknownCustomers: UnkownCustomersFilter | null;
  hideUnknownEvents: UnkownEventsFilter | null;
  hideNonAlertEvents: AlertsFilter | null;
}

type InactiveEventsFilter = {
  active: { is: "true" };
};

type UnkownCustomersFilter = {
  customer: { null: "false" };
};

type UnkownEventsFilter = {
  event: { null: "false" };
};

type AlertsFilter = {
  "category-alert": {
    is: "true";
  };
};

type Cdec = Omit<_Cdec, "customer" | "intervention" | "event-category"> & {
  customer: Omit<_Customer, "code-table"> & { "code-table": string };
  intervention: Exclude<_Cdec["intervention"], ID>;
  "event-category": Exclude<_Cdec["event-category"], ID>;
} & (
    | {
        "has-valid-coords": true;
        latitude: number;
        longitude: number;
      }
    | {
        "has-valid-coords": false;
        latitude: null;
        longitude: null;
      }
  );

const errorAlertID = "cdecWindowErrorAlertID";
let lastNotificationTimeStamp = moment();

export const CdecWindow = () => {
  const { formatMessage } = useIntl();
  const [customerModalOpen, setCustomerModalOpen] = useState<boolean>(false);
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);
  const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);
  const [filterState, setFilterState] = useStorageState<FilterState>("filter", {
    hideInactiveEvents: null,
    hideUnknownCustomers: null,
    hideUnknownEvents: null,
    hideNonAlertEvents: null,
  });
  const { playErrorAlert, stopErrorAlert, playNotification } = useAudio();
  const theme = useTheme();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [newUserAccount, setNewUserAccount] = useState<string | null>(null);

  const {
    data: cdecs,
    status: queryStatus,
    refetch: refetchOnWS,
    failureCount,
    isFetching,
  } = useGetStream<_Cdec>("alarm/cdec", {
    ...useMemo(
      () => ({
        refetchInterval: 10000,
        refetchIntervalInBackground: true,
        // 513 ==> updates Query failed to fetch (too long url)
        page: { limit: 400 },
        include: {
          customer: {},
          intervention: {},
        },
        fields: {
          cdec: [
            "+localized-description",
            "+category-sound",
            "+category-alert",
            "+color",
            "+latitude",
            "+longitude",
            "+partition-number",
            "+zone-number",
            "+has-valid-coords",
          ],
          customers: [
            "+name",
            "+account",
            "+address",
            "+email",
            "+active",
            "+phone",
            "+mobile",
            "+passwd",
          ],
          intervention: ["+close-time"],
        },
      }),
      []
    ),
    filter: useMemo(
      () => ({
        ...filterState.hideInactiveEvents,
        ...filterState.hideUnknownCustomers,
        ...filterState.hideUnknownEvents,
        ...filterState.hideNonAlertEvents,
      }),
      [
        filterState.hideInactiveEvents,
        filterState.hideUnknownCustomers,
        filterState.hideUnknownEvents,
        filterState.hideNonAlertEvents,
      ]
    ),
    onSuccess: (cdecs) => {
      const playedUrl: { [index: string]: boolean } = {};
      const previousQueryStatus = queryStatus; // onSuccess callback called with queryStatus which actually the previous one
      if (previousQueryStatus === "success") {
        for (const cdec of cdecs) {
          const url = cdec["category-sound"];
          if (!cdec["category-alert"] && cdec.active) {
            if (url) {
              if (!playedUrl[url as string]) {
                if (
                  moment(cdec.arrived).isAfter(
                    moment(lastNotificationTimeStamp)
                  )
                ) {
                  playNotification(url);
                  lastNotificationTimeStamp = moment();
                  playedUrl[url] = true;
                }
              }
            }
          }
        }
      }
    },
  });

  useEffect(() => {
    if (queryStatus === "error") {
      playErrorAlert(errorAlertID);
    } else {
      stopErrorAlert(errorAlertID);
    }
  }, [queryStatus, playErrorAlert, stopErrorAlert]);

  useEvents(["cdec-insert", "connect"], refetchOnWS);

  const needsUpdate = cdecs?.reduce((ids, item) => {
    if (item["category-alert"] && !item.intervention) {
      ids.push(item.id);
    }
    return ids;
  }, [] as string[]);

  const { data: updates, refetch } = useGetAll<
    Pick<_Cdec, "id" | "type" | "intervention">
  >("alarm/cdec", {
    filter: {
      id: { in: needsUpdate },
      intervention: { null: "false" },
    },
    fields: {
      cdec: ["intervention"],
    },
    enabled: needsUpdate && needsUpdate.length > 0,
    refetchInterval: needsUpdate && needsUpdate.length > 0 ? 10000 : false,
    retry: false,
    initialData: [],
  });

  useEvents(["interventions-insert"], () => {
    if (needsUpdate && needsUpdate.length > 0) {
      refetch();
    }
  });

  const cdecWithUpdate = useMemo(() => {
    if (updates?.length === 0) {
      return cdecs;
    }

    return cdecs?.map((item) => {
      const update = updates?.find((u) => u.id === item.id);
      if (update) {
        return { ...item, ...update };
      }
      return item;
    });
  }, [cdecs, updates]);

  const handleCdecModalOpen = (row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setCdecModalOpen(true);
  };

  const handleCdecModalClose = () => {
    setCdecModalOpen(false);
    setSelectedCdec(null);
  };

  const handleCustomerModalOpen = (row: Row<Cdec>) => {
    setSelectedCdec(row.original);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => {
    setCustomerModalOpen(false);
    setSelectedCdec(null);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (event.target.name === "hideInactiveEvents") {
      setFilterState({
        ...filterState,
        [event.target.name]: checked ? { active: { is: "true" } } : null,
      });
    }

    if (event.target.name === "hideUnknownCustomers") {
      setFilterState({
        ...filterState,
        [event.target.name]: checked ? { customer: { null: "false" } } : null,
      });
    }

    if (event.target.name === "hideUnknownEvents") {
      setFilterState({
        ...filterState,
        [event.target.name]: checked ? { event: { null: "false" } } : null,
      });
    }

    if (event.target.name === "hideNonAlertEvents") {
      setFilterState({
        ...filterState,
        [event.target.name]: checked
          ? { "category-alert": { is: "true" } }
          : null,
      });
    }
  };

  const [missingCusAcc, setMissingCusAcc] = useState<string[]>([]);

  const { data: missingCustomers } = useGetAll<_Customer>("alarm/customers", {
    filter: {
      account: { in: missingCusAcc },
    },
    enabled: missingCusAcc.length > 0,
  });

  const columns = useMemo(
    (): Column<Cdec>[] => [
      {
        id: "fix",
        accessor: "category-sound",
        width: 50,
        Header: () => {
          return (
            <Tooltip title={formatMessage({ defaultMessage: "Event icons" })}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          );
        },
        Cell: ({ value, row }: Cell<Cdec, boolean>) => {
          return value && <EventIcon cdec={row.original} />;
        },
      },
      {
        id: "customer",
        accessor: (cdec) =>
          cdec.customer
            ? `${cdec.customer.account} - ${cdec.customer.name ?? ""}`
            : `${cdec.account} `,
        width: 100,
        Header: formatMessage({
          defaultMessage: "Customer",
          description: "Események táblázat feljéc: ügyfél(customer)",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          const detachedCustomer = missingCustomers?.find(
            (c) => c.account === row.original.account
          );

          const firstRenderRef = useRef(true);

          const account = row.original.account;
          const customer = row.original.customer;

          useEffect(() => {
            if (firstRenderRef.current) {
              if (!customer) {
                if (missingCusAcc.indexOf(account) === -1) {
                  setMissingCusAcc([...missingCusAcc, account]);
                }
              }
            }
            firstRenderRef.current = false;
          }, [account, customer]);

          return row.original.customer ? (
            <LinkStyleText onClick={() => handleCustomerModalOpen(row)}>
              {value}
            </LinkStyleText>
          ) : (
            <Box
              sx={{ position: "relative", marginTop: "-4px" }}
              component="span"
            >
              {!detachedCustomer && (
                <LinkStyleText
                  onClick={() => {
                    setNewUserDialogOpen(true);
                    setNewUserAccount(row.original.account);
                  }}
                >
                  {`${formatMessage({
                    defaultMessage: "Create: ",
                  })}`}
                  {value}
                  {` ${formatMessage({ defaultMessage: "Unknown customer" })}`}
                </LinkStyleText>
              )}
              {value}
              {` (${formatMessage({
                defaultMessage: "Customer created",
              })})`}
            </Box>
          );
        },
      },
      {
        id: "description",
        accessor: "localized-description",
        width: 140,
        Header: formatMessage({
          defaultMessage: "Event description",
          description: "Események táblázat fejléc esemény leírás",
        }),
        Cell: ({ value, row }: Cell<Cdec>) => {
          return row.original.event ? (
            <LinkStyleText
              color={row.original.color}
              onClick={() => handleCdecModalOpen(row)}
            >
              {value}
            </LinkStyleText>
          ) : (
            <span>{value}</span>
          );
        },
      },
      {
        id: "arrived",
        accessor: (cdec) =>
          moment(cdec.arrived).format(
            formatMessage({
              defaultMessage: "DD/MM/YYYY HH:mm:ss",
              description: "Események táblázat dátum formátum érkezés",
            })
          ),
        width: 100,
        Header: formatMessage({
          defaultMessage: "Arrived",
          description: "Események táblázat fejléc érekezési idő",
        }),
        Cell: ({ value }: Cell<Cdec>) => <span>{value}</span>,
      },
    ],
    [formatMessage, missingCusAcc, missingCustomers]
  );

  const prefix = (
    <ColoredRouterLink color={theme.palette.common.white} to={"/alarm/events"}>
      <Typography color={"primary.contrastText"}>
        <FormattedMessage
          defaultMessage="Incoming events"
          description="Események táblázat fejléc cím"
        />
      </Typography>
    </ColoredRouterLink>
  );

  const postfix = (
    <Box sx={{ maxHeight: "48px" }}>
      <FilterButton
        filterState={filterState}
        onFilterChange={handleFilterChange}
      />
      <ManualEventButton />
      <NotificationToggleButton />
    </Box>
  );

  return (
    <>
      <EnhancedTable
        data={(cdecWithUpdate as Cdec[]) ?? []}
        columns={columns}
        queryStatus={
          queryStatus !== "error" &&
          isFetching &&
          (cdecWithUpdate?.length ?? 0) === 0
            ? "loading"
            : queryStatus
        }
        errorIcon={CounterIcon}
        errorIconProps={{ failureCount }}
        errorMessage=""
        overscanCount={25}
        rowHeight={24}
        prefix={prefix}
        postfix={postfix}
        sx={{
          flex: 1,
          height: "100%",
          overflow: "hidden",
        }}
      />
      {selectedCdec && (
        <CustomerModal
          customer={selectedCdec.customer}
          open={customerModalOpen}
          onClose={handleCustomerModalClose}
        />
      )}
      {selectedCdec && (
        <CdecModal
          cdec={selectedCdec}
          open={cdecModalOpen}
          onClose={handleCdecModalClose}
        />
      )}
      <Snackbar
        onClose={() => {
          setSnackbarState({ message: undefined });
        }}
        state={snackbarState}
      />
      <CreateCustomerDialog
        open={newUserDialogOpen}
        setOpen={setNewUserDialogOpen}
        onClose={() => {
          setNewUserAccount(null);
        }}
        account={newUserAccount}
      />
    </>
  );
};

export default CdecWindow;

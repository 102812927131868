import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  List,
  Typography,
  IconButton,
  Snackbar,
  listItemTextClasses,
  ListItemTextProps,
  ListItemIcon,
  lighten,
  darken,
  SpeedDial,
  SpeedDialAction,
  SpeedDialActionProps,
  FabProps,
  tooltipClasses,
  styled,
} from "@mui/material";
import { Button } from "@mb-pro-ui/components";
import { Cdec } from "@mb-pro-ui/utils/types/alarm";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { ReactElement, useEffect, useState } from "react";
import InactivateDialog from "./InactivateDialog";
import { Close } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useCreate } from "@mb-pro-ui/utils";
import { Link } from "react-router-dom";
import { useErrorHandler } from "../../../hooks";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { ID, Maybe } from "@mb-pro-ui/utils/types";

type Cdec_ = Pick<
  Cdec,
  | "id"
  | "localized-description"
  | "color"
  | "arrived"
  | "sent"
  | "event-code"
  | "account"
  | "unitid"
  | "partition-number"
  | "zone-number"
  | "active"
> & { customer: Maybe<ID | { id: ID }> } & (
    | {
        "has-valid-coords": true;
        latitude: number;
        longitude: number;
      }
    | {
        "has-valid-coords": false;
        latitude: null;
        longitude: null;
      }
  );

type CdecModalProps = {
  cdec: Cdec_;
  open: boolean;
  onClose(): void;
};

type MbProFabProps<C extends React.ElementType> = Partial<
  FabProps<C, { component?: C }>
>;

type MbProSpeedDialActionProps = Omit<SpeedDialActionProps, "FabProps"> & {
  tooltipTitle: SpeedDialActionProps["tooltipTitle"];
};

const MbProSpeedDialAction = styled(
  <C extends React.ElementType>({
    className,
    ...props
  }: MbProSpeedDialActionProps & MbProFabProps<C> & { className?: string }) => {
    const { TooltipClasses, ...rest } = props;
    return (
      <SpeedDialAction
        {...rest}
        TooltipClasses={{ popper: className, ...TooltipClasses }}
      />
    );
  }
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}));

const ModalListItemText = ({
  color,
  ...props
}: ListItemTextProps<"span", "div"> & { color?: string }) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        component: "span",
        sx: {
          [`&.${listItemTextClasses.root}, &.${listItemTextClasses.primary}`]: {
            color: color,
          },
        },
      }}
      secondaryTypographyProps={{
        component: "div",
        sx: {
          overflow: "auto",
          overflowWrap: "break-word",
          maxHeight: (theme) => theme.spacing(7),
        },
      }}
      {...props}
    />
  );
};

const CdecModal = ({ cdec, open, onClose: handleClose }: CdecModalProps) => {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<ReactElement>();
  const { formatMessage } = useIntl();
  const {
    mutate: disableEvent,
    status,
    error,
  } = useCreate("alarm/disabled-events");
  const { getErrorMessage } = useErrorHandler();

  const coordinate = cdec["has-valid-coords"] ? (
    <>
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="Longitude"
          description="Cdec Modal detailed view longitude"
        />
        {cdec.longitude}
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="Latitude"
          description="Cdec Modal detailed view latitude"
        />{" "}
        {cdec.latitude}
      </Typography>
    </>
  ) : (
    "N/A"
  );

  const handleInactivate = (customer: ID, eventCode: string) => {
    disableEvent({ customer, "event-code": eventCode });
    handleAlertClose();
  };

  useEffect(() => {
    if (status === "success") {
      const message = formatMessage(
        {
          defaultMessage: "{account} customer's {eventCode} events inactivated",
          description: "Event inactivation success",
        },
        {
          account: cdec.account,
          eventCode: cdec["event-code"],
        }
      );
      setSnackBarMessage(<div>{message}</div>);
      handleSnackBarOpen();
    }

    if (status === "error") {
      if (error) {
        setSnackBarMessage(getErrorMessage(error));
      }

      handleSnackBarOpen();
    }
  }, [status, formatMessage, cdec, error, getErrorMessage]);

  const handleSnackBarOpen = () => {
    setIsSnackBarOpen(true);
  };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleAlertOpen = () => {
    setIsAlertOpen(true);
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const color = cdec.color;

  const customerDialActions = [
    {
      name: "Ügyfél szerkesztése",
      href: `/alarm/customers/${cdec.customer}`,
    },
    {
      name: "Ügyfél eseményei",
      href: `/alarm/events?customer=${
        cdec.customer
      }&arrived=${encodeURIComponent(
        `${moment().subtract(1, "days").format()}`
      )}`,
    },
  ];

  return (
    <Dialog disableScrollLock open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          padding: (theme) => theme.spacing(2),
          whiteSpace: "nowrap",
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            display: "inline-block",
            maxWidth: "70%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {cdec["localized-description"]}
        </span>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.common.white,
          "&:hover": {
            backgroundColor: "unset",
          },
        }}
        onClick={handleClose}
        disableRipple
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minWidth: "550px" }}>
        <Grid container>
          <Grid item xs={6}>
            <List dense>
              <ListItem sx={{ paddingRight: "0" }}>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Event code"
                      description="Cdec felugró részletes nézet eseméykód"
                    />
                  }
                  secondary={cdec["event-code"]}
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage defaultMessage="Event description" />
                  }
                  secondary={cdec["localized-description"] ?? "N/A"}
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={formatMessage({
                    defaultMessage: "Customer account",
                  })}
                  secondaryTypographyProps={{
                    component: "div",
                    width: "200%",
                  }}
                  secondary={
                    <SpeedDial
                      ariaLabel="Customer speed dial"
                      FabProps={{
                        size: "small",
                        variant: "extended",
                        sx: {
                          backgroundColor: color,
                          "&:hover": {
                            backgroundColor: darken(color, 0.2),
                          },
                        },
                      }}
                      icon={cdec.account}
                      direction="right"
                    >
                      {customerDialActions.map((action, i) => (
                        <MbProSpeedDialAction
                          FabProps={{
                            size: "small",
                            variant: "extended",
                            sx: { color, zIndex: 1 },
                            component: Link,
                            to: action.href,
                          }}
                          icon={action.name}
                          tooltipTitle={action.name}
                          key={i}
                        />
                      ))}
                    </SpeedDial>
                  }
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={<FormattedMessage defaultMessage="Helios ID" />}
                  secondary={cdec.unitid}
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Coordinates"
                      description="Cdec felugró részletes nézet koordináta"
                    />
                  }
                  secondary={coordinate}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List dense>
              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Sent"
                      description="Cdec felugró részletes nézet küldve ekkor"
                    />
                  }
                  secondary={moment(cdec.sent).format(
                    formatMessage({
                      defaultMessage: "DD/MM/YYYY HH:mm:ss",
                    })
                  )}
                />
              </ListItem>
              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Arrived"
                      description="Cdec felugró részletes nézet érkezés"
                    />
                  }
                  secondary={moment(cdec.arrived).format(
                    formatMessage({
                      defaultMessage: "DD/MM/YYYY HH:mm:ss",
                    })
                  )}
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Partition"
                      description="Cdec felugró részletes nézet partíció"
                    />
                  }
                  secondary={cdec["partition-number"]}
                />
              </ListItem>

              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={
                    <FormattedMessage
                      defaultMessage="Zone"
                      description="Cdec felugró részletes nézet zóna"
                    />
                  }
                  secondary={cdec["zone-number"]}
                />
              </ListItem>
              <ListItem>
                <ModalListItemText
                  color={color}
                  primary={formatMessage({
                    defaultMessage: "Active",
                  })}
                  secondary={
                    <ListItemIcon>
                      {cdec.active ? (
                        <CheckIcon fontSize="small" color="primary" />
                      ) : (
                        <ClearIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {cdec.customer && cdec.active && (
          <Button
            mode={"warning"}
            onClick={handleAlertOpen}
            sx={{
              backgroundColor: color,
              "&:hover": {
                backgroundColor: darken(color, 0.2),
              },
            }}
          >
            <FormattedMessage
              defaultMessage="Disable event"
              description="Cdec felugró részletes nézet inaktiválás"
            />
          </Button>
        )}

        <Button
          to={`/alarm/settings/events/${cdec.id}`}
          component={Link}
          mode={"secondary"}
          sx={{
            color: color,
            border: `1px solid ${color}`,
            "&:hover": {
              border: `1px solid ${lighten(color, 0.5)}`,
              backgroundColor: lighten(color, 0.9),
            },
          }}
        >
          <FormattedMessage defaultMessage="Edit Event" />
        </Button>
      </DialogActions>
      {cdec.customer !== null ? (
        <InactivateDialog
          open={isAlertOpen}
          onClose={handleAlertClose}
          customer={cdec.customer}
          account={cdec.account}
          eventCode={cdec["event-code"]}
          onInactivate={handleInactivate}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isSnackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackBarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Dialog>
  );
};

export default CdecModal;

import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, Switch, Route, useRouteMatch } from "react-router-dom";
import { CircularProgress, SvgIcon, Box } from "@mui/material";

import { User } from "@mb-pro-ui/utils/types/admin";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import { useGetOne } from "@mb-pro-ui/utils";
import { Page } from "@mb-pro-ui/components";

import { InterventionWorkSheet } from "./InterventionWorkSheet";
import CustomerData from "./CustomerData";
import CustomerNotify from "./CustomerNotify";
import CustomerZones from "./CustomerZones";
import CustomerPartitions from "./CustomerPartitions";
import CustomerIntervalRules from "./CustomerIntervalRules";
import InterventionHeader from "./InterventionHeader";

import { ReactComponent as InterventionIcon } from "../../icons/intervention/Intervention.svg";
import { ReactComponent as CustomerDataIcon } from "../../icons/intervention/CustomerData.svg";
import { ReactComponent as ContactsIcon } from "../../icons/intervention/Contacts.svg";
import { ReactComponent as ZoneIcon } from "../../icons/intervention/Zone.svg";
import { ReactComponent as PartitionIcon } from "../../icons/intervention/Partition.svg";
import { ReactComponent as IntervalRuleIcon } from "../../icons/intervention/IntervalRule.svg";

import {
  Intervention,
  getInterventionFields,
  interventionIncludes,
} from "./types";

import { useErrorHandler } from "../../hooks";

const sidebarOptions = [
  {
    name: (
      <FormattedMessage
        defaultMessage="Intervention"
        description="SideBar Option Intervention worksheet"
      />
    ),
    link: "",
    icon: (
      <SvgIcon>
        <InterventionIcon />
      </SvgIcon>
    ),
  },
  {
    name: <FormattedMessage defaultMessage="Customer data" />,
    link: "/customer-data",
    icon: (
      <SvgIcon>
        <CustomerDataIcon />
      </SvgIcon>
    ),
  },
  {
    name: <FormattedMessage defaultMessage="Customer contacts" />,
    link: `/customer-contacts`,
    icon: (
      <SvgIcon>
        <ContactsIcon />
      </SvgIcon>
    ),
  },
  {
    name: <FormattedMessage defaultMessage="Customer zones" />,
    link: `/customer-zones`,
    icon: (
      <SvgIcon>
        <ZoneIcon />
      </SvgIcon>
    ),
  },
  {
    name: <FormattedMessage defaultMessage="Customer partitions" />,
    link: `/customer-partitions`,
    icon: (
      <SvgIcon>
        <PartitionIcon />
      </SvgIcon>
    ),
  },
  {
    name: <FormattedMessage defaultMessage="Customer interval rules" />,
    link: `/customer-interval-rules`,
    icon: (
      <SvgIcon>
        <IntervalRuleIcon />
      </SvgIcon>
    ),
  },
];

export const InterventionForm = ({
  sidebarOpen,
  onSidebarClose,
  onSidebarOpen,
}: {
  sidebarOpen: boolean;
  onSidebarClose: () => void;
  onSidebarOpen: () => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();
  const { JsonApiErrorSnackBar: ErrorSnackBar } = useErrorHandler();
  const [error, setError] = useState<JsonapiError | null>(null);
  const { formatMessage } = useIntl();

  const handleSnackBarClose = () => {
    setError(null);
  };
  const handleQueryError = (error: JsonapiError) => {
    setError(error);
  };

  const {
    data: intervention,
    isLoading,
    refetch: refetchIntervention,
  } = useGetOne<Intervention>("alarm/interventions-all", id, {
    include: interventionIncludes,
    fields: getInterventionFields("-all"),
    enabled: true,
    structuralSharing: false,
  });

  const { data: user } = useGetOne<User>("admin/users", "me", {
    fields: {
      users: ["alarm-operator"],
    },
    onError: handleQueryError,
  });

  const isOwn =
    intervention?.session?.operator?.id === user?.["alarm-operator"];

  const breadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      [`/alarm/interventions`]: formatMessage({
        defaultMessage: "Interventions",
      }),
      [`/alarm/interventions/${id}`]: formatMessage(
        {
          defaultMessage: `{name} - {account} 's intervention, #{id}`,
          description: "Breadcrumb intervention",
        },
        {
          id: id,
          account: intervention?.customer?.account,
          name: intervention?.customer?.name,
        }
      ),
      [`/alarm/interventions/${id}/customer-data`]: formatMessage({
        defaultMessage: "Customer data",
      }),
      [`/alarm/interventions/${id}/customer-contacts`]: formatMessage({
        defaultMessage: "Customer contacts",
      }),
      [`/alarm/interventions/${id}/customer-zones`]: formatMessage({
        defaultMessage: "Customer zones",
      }),
      [`/alarm/interventions/${id}/customer-partitions`]: formatMessage({
        defaultMessage: "Customer partitions",
      }),
      [`/alarm/interventions/${id}/customer-interval-rules`]: formatMessage({
        defaultMessage: "Customer interval rules",
      }),
    }),
    [
      formatMessage,
      id,
      intervention?.customer?.name,
      intervention?.customer?.account,
    ]
  );

  return (
    <Page
      breadcrumbs
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={breadcrumbNameMap}
      sx={{ overflow: "hidden" }}
      sidebarOpen={sidebarOpen}
      onSidebarOpen={onSidebarOpen}
      onSidebarClose={onSidebarClose}
      postfix={
        <InterventionHeader
          id={id}
          intervention={intervention}
          isOwn={isOwn}
          refetchIntervention={refetchIntervention}
          handleQueryError={handleQueryError}
        />
      }
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Switch>
          <Route exact path={`${path}`}>
            <InterventionWorkSheet
              intervention={intervention}
              id={id}
              isOwn={isOwn}
              refetchIntervention={refetchIntervention}
            />
          </Route>
          <Route exact path={`${path}/customer-data`}>
            <CustomerData intervention={intervention} />
          </Route>
          <Route exact path={`${path}/customer-contacts`}>
            <CustomerNotify intervention={intervention} />
          </Route>
          <Route exact path={`${path}/customer-zones`}>
            <CustomerZones intervention={intervention} />
          </Route>
          <Route exact path={`${path}/customer-partitions`}>
            <CustomerPartitions intervention={intervention} />
          </Route>
          <Route exact path={`${path}/customer-interval-rules`}>
            <CustomerIntervalRules intervention={intervention} />
          </Route>
        </Switch>
      )}
      <ErrorSnackBar error={error} onClose={handleSnackBarClose} />
    </Page>
  );
};

export default InterventionForm;

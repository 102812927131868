import React, { useState } from "react";
import { Menu, Typography, Button, alpha } from "@mui/material";
import { ListItemIcon, ListItemText, Link, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Module, ModuleID } from "../../components/types";
import useModules from "../../hooks/useModules";

export interface ModuleMenuProps {
  currentModuleID: ModuleID;
}

const ModuleItem = React.forwardRef<HTMLAnchorElement, { module: Module }>(
  ({ module }: { module: Module }, ref) => {
    return (
      <>
        <MenuItem
          ref={ref}
          dense
          disabled={!module.active}
          component={Link}
          href={module.path}
          color="primary"
        >
          {module.id.valueOf() === ModuleID.Ozone.valueOf() ? (
            <ListItemIcon
              sx={{
                minWidth: "40px",
                fontSize: "24px",
              }}
            >
              {module.img && module.img("24px", "24px")}
            </ListItemIcon>
          ) : (
            <ListItemIcon
              sx={{
                minWidth: "40px",
                fontSize: "24px",
              }}
            >
              {module.icon &&
                module.icon({ fontSize: "inherit", color: "primary" })}
            </ListItemIcon>
          )}
          <ListItemText primary={module.name} />
        </MenuItem>
      </>
    );
  }
);

export const ModuleMenu = ({ currentModuleID }: ModuleMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeModules, inactiveModules } = useModules();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentModule = activeModules.find(
    (module) => module.id.valueOf() === currentModuleID.valueOf()
  );

  const selectableModules = activeModules.filter(
    (module) => module.id.valueOf() !== currentModuleID.valueOf()
  );

  return (
    <>
      <Button
        variant="contained"
        sx={{
          alignSelf: "center",
          backgroundColor: (theme) => theme.palette.common.white,
          "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.grey[100], 1),
          },
        }}
        startIcon={
          currentModule?.icon && currentModule.icon({ color: "primary" })
        }
        endIcon={<ArrowDropDownIcon color="primary" />}
        onClick={handleClick}
        size={"medium"}
      >
        <Typography color="primary">{currentModule?.name}</Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiList-padding": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {selectableModules.map((module) => (
          <ModuleItem module={module} key={module.id} />
        ))}
        {inactiveModules.map((module) => (
          <ModuleItem module={module} key={module.id} />
        ))}
      </Menu>
    </>
  );
};

export default ModuleMenu;

import { Checkbox } from "@mb-pro-ui/components/form";
import { StyledNumericField, StyledTextField } from "../form/StyledComponents";

import { useGetAll } from "@mb-pro-ui/utils";
import FormLayout from "../form/FormLayout";

import Widget from "../utils/Widget";

import { EquipmentType, BatteryType, SounderType } from "./types";
import { useIntl } from "react-intl";
import { FormSpy } from "react-final-form";

interface ExternalReceiverFormProps {
  readOnly?: boolean;
}

const ExternalReceiverForm = ({ readOnly }: ExternalReceiverFormProps) => {
  const { formatMessage } = useIntl();

  const { data: equipmentTypes, isLoading: areEquipmentsLoading } =
    useGetAll<EquipmentType>("alarm/equipment-types", {
      refetchOnWindowFocus: false,
    });

  const { data: batteryTypes, isLoading: areBatteryTypesLoading } =
    useGetAll<BatteryType>("alarm/battery-types", {
      refetchOnWindowFocus: false,
    });

  const { data: sounderTypes, isLoading: areSounderTypesLoading } =
    useGetAll<SounderType>("alarm/sounder-types", {
      refetchOnWindowFocus: false,
    });

  return (
    <FormLayout
      firstChild={
        <Widget
          title={formatMessage({
            defaultMessage: "External ID translation",
          })}
          isLoading={
            areEquipmentsLoading ||
            areBatteryTypesLoading ||
            areSounderTypesLoading
          }
          children={
            equipmentTypes && batteryTypes && sounderTypes ? (
              <>
                <Checkbox
                  name="legacy-enabled"
                  label={formatMessage({
                    defaultMessage: "Enabled",
                  })}
                  readOnly={readOnly}
                />
                <FormSpy subscription={{ values: true }}>
                  {({ values }) =>
                    values["legacy-enabled"] ? (
                      <>
                        <StyledNumericField
                          name="legacy-port"
                          label={formatMessage({
                            defaultMessage: "Port",
                          })}
                          InputProps={{ inputProps: { step: 1 } }}
                          readOnly={readOnly}
                        />
                        <StyledTextField
                          name="legacy-account"
                          label={formatMessage({
                            defaultMessage: "Legacy account",
                          })}
                          parse={(x) => x}
                          readOnly={readOnly}
                        />
                      </>
                    ) : null
                  }
                </FormSpy>
              </>
            ) : null
          }
        />
      }
    />
  );
};

export default ExternalReceiverForm;

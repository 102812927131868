import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useMemo, useState } from "react";
import scrollbarWidth from "./scrollbarWidth";

const useScrollbarSize = () => {
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const scrollbarSize = useMemo(() => scrollbarWidth(), []);

  useLayoutEffect(() => {
    if (scrollContainer) {
      setIsScrollbarVisible(scrollContainer.scrollHeight > scrollContainer.clientHeight);
    }
  }, [scrollContainer]);

  useResizeObserver(scrollContainer, (e) =>
    setIsScrollbarVisible(e.target.scrollHeight > e.target.clientHeight)
  );

  return [isScrollbarVisible ? scrollbarSize : 0, setScrollContainer] as const;
};

export default useScrollbarSize;

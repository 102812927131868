import { Autocomplete } from "@mb-pro-ui/components/form";

import { StyledTextField } from "../form/StyledComponents";

import { useGetAll } from "@mb-pro-ui/utils";

import Widget from "../utils/Widget";

import FormLayout from "../form/FormLayout";

import { EquipmentType, BatteryType, SounderType } from "./types";

import { useIntl } from "react-intl";

import HideableFormField from "./profile/HideableFormField";
import { isEqualById } from "../utils/autocompleteUtils";

interface InstallFormProps {
  readOnly?: boolean;
}

const InstallForm = ({ readOnly }: InstallFormProps) => {
  const { formatMessage } = useIntl();

  const { data: equipmentTypes, isLoading: areEquipmentsLoading } =
    useGetAll<EquipmentType>("alarm/equipment-types", {
      refetchOnWindowFocus: false,
    });

  const { data: batteryTypes, isLoading: areBatteryTypesLoading } =
    useGetAll<BatteryType>("alarm/battery-types", {
      refetchOnWindowFocus: false,
    });

  const { data: sounderTypes, isLoading: areSounderTypesLoading } =
    useGetAll<SounderType>("alarm/sounder-types", {
      refetchOnWindowFocus: false,
    });

  return (
    <FormLayout
      firstChild={
        <Widget
          title={formatMessage({
            defaultMessage: "Equipment informations",
          })}
          isLoading={
            areBatteryTypesLoading ||
            areEquipmentsLoading ||
            areSounderTypesLoading
          }
          children={
            equipmentTypes && batteryTypes && sounderTypes ? (
              <>
                <HideableFormField name="equipment-type">
                  <Autocomplete
                    name="equipment-type"
                    label={formatMessage({
                      defaultMessage: "Type",
                    })}
                    options={equipmentTypes}
                    fullWidth
                    getOptionLabel={(option) => option.description ?? ""}
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                  />
                </HideableFormField>
                <HideableFormField name="equipment-serial">
                  <StyledTextField
                    name="equipment-serial"
                    label={formatMessage({
                      defaultMessage: "Serial number",
                    })}
                    parse={(x) => x}
                    readOnly={readOnly}
                  />
                </HideableFormField>
                <HideableFormField name="battery-type">
                  <Autocomplete
                    name="battery-type"
                    label={formatMessage({
                      defaultMessage: "Battery type",
                    })}
                    options={batteryTypes}
                    fullWidth
                    getOptionLabel={(option) => option.description ?? ""}
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                  />
                </HideableFormField>
                <HideableFormField name="sounder-type">
                  <Autocomplete
                    name="sounder-type"
                    label={formatMessage({
                      defaultMessage: "Sounder type",
                    })}
                    options={sounderTypes}
                    fullWidth
                    getOptionLabel={(option) => option.description ?? ""}
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                  />
                </HideableFormField>
              </>
            ) : null
          }
        />
      }
    />
  );
};

export default InstallForm;

import { useIntl } from "react-intl";
import { useGetAll } from "@mb-pro-ui/utils";
import { Operator as _Operator, Employee } from "@mb-pro-ui/utils/types/alarm";
import AutocompleteFilter from "./base/AutocompleteFilter";
import { FilterViewProps } from "../types";
import { useCallback, useMemo } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const Container = styled(Box)({
  padding: "0 10px",
  maxWidth: "100%",
});

type Operator = Omit<_Operator, "employee"> & { employee: Employee | null };

const getOptions = (operators: Operator[]) => {
  const options = [...operators];
  options.sort((a, b) => {
    const A = a.login;
    const B = b.login;
    if (!A) {
      return 1;
    }
    if (!B) {
      return -1;
    }
    return A.toLowerCase().localeCompare(B.toLowerCase());
  });

  return options;
};

const OperatorAutocompleteFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();
  const { data: operators } = useGetAll<Operator>("alarm/operators", {
    include: {
      employee: {},
    },
    refetchOnWindowFocus: false,
    notifyOnChangeProps: "tracked",
    sort: ["login"],
  });

  const getOptionLabel = useCallback((operator) => operator.id, []);

  const filterOptions = createFilterOptions({
    stringify: (operator: Operator) => operator.login + operator.employee?.name,
    trim: true,
  });

  return (
    <AutocompleteFilter
      {...props}
      options={useMemo(() => (operators ? getOptions(operators) : undefined), [
        operators,
      ])}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      virtualizedRowHeight={55.2}
      renderOption={(operator) => {
        return (
          <Container>
            <Typography noWrap variant={"body2"} color="primary">
              {operator.login}
            </Typography>
            <Typography noWrap variant={"caption"} component="p">
              {operator.employee?.name
                ? operator.employee.name
                : "Unkown Employee"}
            </Typography>
          </Container>
        );
      }}
      label={formatMessage({
        defaultMessage: "Operator",
        description: "Interventions table operator filter label",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Filter Operators: Username, Name",
        description:
          "Interventions table operator filter input placeholder message",
      })}
      noOptionsText={formatMessage({
        defaultMessage: "No such operator",
        description: "Intervention table operator filter no option label",
      })}
    />
  );
};

export default OperatorAutocompleteFilter;

import { PropsWithChildren } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
  useLocation,
} from "react-router-dom";

import { ThemeProvider, CssBaseline } from "@mui/material";

import { muiTheme, Page, FullPageLayout } from "@mb-pro-ui/components";
import {
  ApiProvider,
  ServiceHealthProvider,
  UserInfoProvider,
  AuthProvider,
  useAuth,
} from "@mb-pro-ui/utils";

import {
  LoginPage,
  HomePage,
  InterventionPage,
  OperatorPage,
  CustomerPage,
  SystemSettingsPage,
  InterventionCreatePage,
  AllCustomersPage,
} from "./pages";

import { AppHeader } from "./components/header/AppHeader";
import {
  useAudio,
  AudioPlayerProvider,
  AutoplayPolicybar,
} from "./components/audio";

import LocaleProvider from "./locales/LocaleProvider";
import EventQueryPage from "./pages/EventQueryPage";
import ExportPage from "./pages/ExportPage";
import MapTrackingPage from "./pages/MapTrackingPage";
import { SnackbarProvider } from "notistack";

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Providers = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <UserInfoProvider>
        <ApiProvider baseUrl="/api">
          <AuthProvider>
            <AudioPlayerProvider>
              <ServiceHealthProvider>
                <LocaleProvider>
                  <SnackbarProvider hideIconVariant>
                    {children}
                  </SnackbarProvider>
                </LocaleProvider>
              </ServiceHealthProvider>
            </AudioPlayerProvider>
          </AuthProvider>
        </ApiProvider>
      </UserInfoProvider>
    </ThemeProvider>
  );
};

const AppContent = () => {
  const { audioContextState } = useAudio();
  const { pathname } = useLocation();

  return (
    <>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/home">
          <FullPageLayout>
            <AppHeader />
            <HomePage />
          </FullPageLayout>
        </PrivateRoute>
        <PrivateRoute>
          <FullPageLayout>
            <AppHeader />
            <Switch>
              <PrivateRoute path="/alarm/operator">
                <OperatorPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/customers/:id">
                <CustomerPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/customers">
                <AllCustomersPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/events">
                <EventQueryPage />
                {/* <EventQueryChart /> */}
              </PrivateRoute>
              <PrivateRoute path="/alarm/interventions/create">
                <InterventionCreatePage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/interventions">
                <InterventionPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/objects">
                <Page>Objektumok...</Page>
              </PrivateRoute>
              <PrivateRoute path="/alarm/exports">
                <ExportPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/map">
                <MapTrackingPage />
              </PrivateRoute>
              <PrivateRoute path="/alarm/notifications">
                <Page>Értesítések...</Page>
              </PrivateRoute>
              <PrivateRoute path="/alarm/settings">
                <SystemSettingsPage />
              </PrivateRoute>
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
          </FullPageLayout>
          {<AutoplayPolicybar audioContextState={audioContextState} />}
        </PrivateRoute>
      </Switch>
    </>
  );
};

export default function App() {
  return (
    <Providers>
      <Router basename="/" getUserConfirmation={() => {}}>
        <AppContent />
      </Router>
    </Providers>
  );
}

import { Badge, IconButton, Typography } from "@mui/material";
import { useEvents, useGetAll } from "@mb-pro-ui/utils";
import { useAudio } from "../audio/AudioPlayerProvider";
import { useHistory, useRouteMatch } from "react-router-dom";
import alert_arrived from "../../sound/alert_arrived.wav";
import ErrorIcon from "@mui/icons-material/Error";
import { BadgeOrigin } from "@mui/base";
import {
  Cdec as _Cdec,
  Customer as _Customer,
} from "@mb-pro-ui/utils/types/alarm";
import { useCallback, useEffect, useState } from "react";
import { onlyInFirst } from "../operator/interventionCdec/InterventionCdecWindow";
import { useSnackbar } from "notistack";
import { CloseOutlined } from "@mui/icons-material";

type AlertBadgeProps = {
  children: JSX.Element;
};

type Cdec = Pick<
  Omit<_Cdec, "customer"> & {
    type: `${"alarm/" | ""}${"cdec" | "cdec-all"}`;
    customer: Pick<_Customer, "type" | "id" | "account" | "name">;
  },
  "type" | "id" | "customer" | "localized-description"
>;

const badgeAnchor: BadgeOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const errorIconContent = <ErrorIcon color="warning" style={{ fontSize: 22 }} />;

const AlertBadge = (props: AlertBadgeProps) => {
  const { children } = props;
  const { play } = useAudio();
  const history = useHistory();
  const match = useRouteMatch("/alarm/operator");

  const playEnabled = !match;
  const snackbarEnabled = !match;

  const [newCdecs, setNewCdecs] = useState<Cdec[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    data: cdecs,
    isError,
    refetch,
    status,
  } = useGetAll<Cdec>("alarm/cdec", {
    page: { limit: 10000 },
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    notifyOnChangeProps: "tracked",
    include: {
      customer: {},
    },
    fields: {
      cdec: ["localized-description", "customer"],
      customers: ["name", "account"],
    },
    filter: {
      "intervention-needed": { is: "true" },
      "category-alert": { is: "true" },
    },
    sort: ["-id"],
    onSuccess: (nextCdecs) => {
      if (cdecs) {
        setNewCdecs(onlyInFirst(nextCdecs, cdecs));
        const prevCount = Number(cdecs.length);
        const currentCount = Number(nextCdecs.length);
        if (status === "success") {
          if (prevCount && prevCount < currentCount) {
            if (playEnabled) {
              play(alert_arrived);
            }
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!snackbarEnabled) {
      closeSnackbar();
    }
  });

  const action = useCallback(
    (key: string) => (
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => closeSnackbar(key)}
      >
        <CloseOutlined />
      </IconButton>
    ),
    [closeSnackbar]
  );

  useEffect(() => {
    if (snackbarEnabled) {
      newCdecs.forEach((cdec) => {
        enqueueSnackbar(
          <Typography
            component="span"
            sx={{ whiteSpace: "pre", width: "300px", cursor: "pointer" }}
            onClick={() => history.push(`/alarm/operator`)}
          >
            <Typography variant="caption" sx={{ width: "100%" }} noWrap>
              {`${cdec.customer.name}(${cdec.customer.account}) \n`}
            </Typography>
            <Typography variant="subtitle2" sx={{ width: "100%" }} noWrap>
              {cdec["localized-description"]}
            </Typography>
          </Typography>,
          {
            variant: "error",
            autoHideDuration: 6000,
            disableWindowBlurListener: true,
            action,
          }
        );
      });
    }
  }, [newCdecs, enqueueSnackbar, action, snackbarEnabled, history]);

  useEvents(["cdec-insert", "interventions-insert"], () => {
    refetch();
  });

  const [badgeContent, color] = isError
    ? [errorIconContent, undefined]
    : [cdecs?.length, "error" as "error"];

  return (
    <>
      <Badge
        color={color}
        max={999}
        badgeContent={badgeContent}
        anchorOrigin={badgeAnchor}
      >
        {children}
      </Badge>
    </>
  );
};

export default AlertBadge;

import { Paper } from "@mui/material";

import { useGetAll } from "@mb-pro-ui/utils";

import { Checkbox, RichEditor, Autocomplete } from "@mb-pro-ui/components/form";

import { StyledTextField } from "../form/StyledComponents";

import Widget from "../utils/Widget";
import SimpleFieldArray from "../form/SimpleFieldArray";

import FormLayout from "../form/FormLayout";

import {
  CodeTable,
  CustomerGroup,
  ObjectType,
  CustomerManagerProfile,
} from "./types";
import { useIntl } from "react-intl";
import { useLocale } from "../../locales/LocaleProvider";

import { Lang } from "../types";

import HideableFormCard from "./profile/HideableFormCard";
import HideableFormField from "./profile/HideableFormField";
import StructuredForm from "../form/StructuredForm";
import { isEqualById } from "../utils/autocompleteUtils";
import DatePickerCheckbox from "../form/DatePickerCheckbox";

const DialogRenderer = () => {
  return (
    <StyledTextField
      name="event-code"
      label="Eseménykód"
      inputProps={{ maxLength: 4 }}
      required
    />
  );
};

interface BasicFormProps {
  readOnly?: boolean;
}

const BasicForm = ({ readOnly }: BasicFormProps) => {
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const { data: codeTables, isLoading: areCodeTablesLoading } =
    useGetAll<CodeTable>("alarm/code-tables", {
      refetchOnWindowFocus: false,
    });

  const { data: objectTypes, isLoading: areObjectTypesLoading } =
    useGetAll<ObjectType>("alarm/object-types", {
      fields: { "object-types": ["descr"] },
      refetchOnWindowFocus: false,
    });

  const { data: customerGroups, isLoading: areCustomerGroupsLoading } =
    useGetAll<CustomerGroup>("alarm/customer-groups", {
      refetchOnWindowFocus: false,
    });

  const { data: customerProfiles, isLoading: areCustomerProfilesLoading } =
    useGetAll<CustomerManagerProfile>("alarm/customer-manager-profiles", {
      refetchOnWindowFocus: false,
    });

  return (
    <FormLayout
      firstChild={
        <>
          <HideableFormCard name="basic-data">
            <Widget
              title={formatMessage({
                defaultMessage: "Basic data",
              })}
              isLoading={areCustomerGroupsLoading}
              children={
                customerGroups ? (
                  <>
                    <HideableFormField name="name">
                      <StyledTextField
                        name="name"
                        label={formatMessage({
                          defaultMessage: "Name",
                        })}
                        readOnly={readOnly}
                        required
                      />
                    </HideableFormField>
                    <HideableFormField name="address">
                      <StyledTextField
                        name="address"
                        label={formatMessage({
                          defaultMessage: "Address",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="phone">
                      <StyledTextField
                        name="phone"
                        label={formatMessage({
                          defaultMessage: "Phone",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="mobile">
                      <StyledTextField
                        name="mobile"
                        label={formatMessage({
                          defaultMessage: "Mobile",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="email">
                      <StyledTextField
                        name="email"
                        label={formatMessage({
                          defaultMessage: "Email",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="password">
                      <StyledTextField
                        name="passwd"
                        label={formatMessage({
                          defaultMessage: "Password",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="customer-group">
                      <Autocomplete
                        name="group"
                        label={formatMessage({
                          defaultMessage: "Customer group",
                        })}
                        options={customerGroups}
                        fullWidth
                        getOptionLabel={(option) => option.description ?? ""}
                        isEqual={isEqualById}
                        sx={{
                          marginLeft: "8px",
                          width: "calc(100% - 16px)",
                        }}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                  </>
                ) : null
              }
              sx={{ marginBottom: "8px" }}
            />
          </HideableFormCard>
        </>
      }
      secondChild={
        <>
          <StructuredForm readOnly={readOnly} />
          <HideableFormCard name="remarks">
            <Widget
              title={formatMessage({
                defaultMessage: "Remarks",
              })}
              children={
                <RichEditor
                  name="remarks"
                  toolbar={
                    readOnly
                      ? { options: [] }
                      : {
                          options: [
                            "inline",
                            "colorPicker",
                            "fontSize",
                            "remove",
                          ],
                          inline: {
                            options: ["bold", "italic"],
                          },
                        }
                  }
                  readOnly={readOnly}
                />
              }
            />
          </HideableFormCard>
        </>
      }
      thirdChild={
        <>
          <HideableFormCard name="technical-informations">
            <Widget
              title={formatMessage({
                defaultMessage: "Technical informations",
              })}
              isLoading={
                areObjectTypesLoading ||
                areCodeTablesLoading ||
                areCustomerProfilesLoading
              }
              children={
                objectTypes && codeTables && customerProfiles ? (
                  <>
                    <Autocomplete
                      name="customer-manager-profile"
                      label={formatMessage({
                        defaultMessage: "Profile",
                      })}
                      options={customerProfiles}
                      isEqual={isEqualById}
                      getOptionLabel={(option) => option.name ?? ""}
                      fullWidth
                      sx={{
                        marginLeft: "8px",
                        width: "calc(100% - 16px)",
                      }}
                      readOnly={readOnly}
                    />
                    <HideableFormField name="account">
                      <StyledTextField
                        name="account"
                        label={formatMessage({
                          defaultMessage: "Account",
                        })}
                        parse={(x) => x}
                        readOnly
                      />
                    </HideableFormField>
                    <HideableFormField name="codetable">
                      <Autocomplete
                        name="code-table"
                        label={formatMessage({
                          defaultMessage: "Codetable",
                        })}
                        options={codeTables}
                        isEqual={isEqualById}
                        getOptionLabel={(option) =>
                          option.descr?.[Lang[locale]] ?? ""
                        }
                        fullWidth
                        sx={{
                          marginLeft: "8px",
                          width: "calc(100% - 16px)",
                        }}
                        readOnly={readOnly}
                        required
                      />
                    </HideableFormField>
                    <HideableFormField name="object-type">
                      <Autocomplete
                        name="object-type"
                        label={formatMessage({
                          defaultMessage: "Object type",
                        })}
                        options={objectTypes}
                        getOptionLabel={(option) =>
                          option.descr?.[Lang[locale]] ?? ""
                        }
                        sx={{
                          marginLeft: "8px",
                          width: "calc(100% - 16px)",
                        }}
                        isEqual={isEqualById}
                        fullWidth
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                    <HideableFormField name="device-phone-number">
                      <StyledTextField
                        name="device-phone-number"
                        label={formatMessage({
                          defaultMessage: "Device phone number",
                        })}
                        parse={(x) => x}
                        readOnly={readOnly}
                      />
                    </HideableFormField>
                  </>
                ) : null
              }
              sx={{ marginBottom: "8px" }}
            />
          </HideableFormCard>
          <HideableFormCard name="deactivation">
            <Widget
              title={formatMessage({
                defaultMessage: "Deactivation",
              })}
              children={
                <>
                  <HideableFormField name="active">
                    <Checkbox
                      name="active"
                      label={formatMessage({
                        defaultMessage: "Customer status",
                      })}
                      readOnly={readOnly}
                    />
                  </HideableFormField>

                  <HideableFormField name="temporary-inactivity">
                    <DatePickerCheckbox
                      name="temp-inactive-ts"
                      checkboxLabel={formatMessage({
                        defaultMessage: "Temporary inactivity",
                      })}
                      inputLabel={formatMessage({
                        defaultMessage: "Temporary deactivation",
                      })}
                      readOnly={readOnly}
                    />
                  </HideableFormField>
                </>
              }
            />
          </HideableFormCard>
          <HideableFormCard name="disabled-events">
            <Paper style={{ marginTop: "8px", marginBottom: "8px" }}>
              <SimpleFieldArray
                name="disabled-events"
                label={formatMessage({
                  defaultMessage: "Disabled events",
                })}
                formDialogRenderer={DialogRenderer}
                labels={{
                  "event-code": formatMessage({
                    defaultMessage: "Event code",
                  }),
                }}
                initialValues={{ "event-code": "0000" }}
                readOnly={readOnly}
              />
            </Paper>
          </HideableFormCard>
        </>
      }
    />
  );
};

export default BasicForm;

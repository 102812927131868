import { Box, styled } from "@mui/material";
import moment from "moment";
import { ChangeEvent } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";

const fileTypes = ["image/png", "image/svg+xml"];

function returnFileSize(number: number) {
  if (number < 1024) {
    return number + "bytes";
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + "KB";
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + "MB";
  }
}

const StyledLabel = styled("label")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  alignSelf: "flex-start",
  borderRadius: "16px",
  padding: "8px 16px",
  marginBottom: "16px",
  "&:hover": {
    backgroundColor: "rgba(37, 135, 188, 0.04)",
  },
}));

const FileInput = ({
  imgSrc,
  disabled,
  onChange: customOnChange,
}: {
  imgSrc?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { formatMessage } = useIntl();

  const t = moment.now();

  return (
    <Field
      name="file"
      validate={(file: File | undefined) => {
        if (file) {
          return fileTypes.includes(file.type)
            ? undefined
            : formatMessage(
                {
                  defaultMessage: "Allowed file formats: {types}",
                },
                {
                  types: fileTypes.map((type) => type.split("/")[1]).join(", "),
                }
              );
        }
      }}
      isEqual={(a: File, b: File) => {
        if (a === b) {
          return true;
        }

        if (a && b) {
          return a.name === b.name;
        }

        return false;
      }}
      render={({
        input: { value: file, onChange, ...input },
        meta: { error },
      }) => {
        // https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
        return (
          <>
            <StyledLabel htmlFor="file_input">
              {formatMessage({
                defaultMessage: "Choose image to upload (PNG, SVG)",
              })}
            </StyledLabel>
            {!file && (
              <p style={{ margin: "16px" }}>
                {formatMessage({ defaultMessage: "No file selected" })}
              </p>
            )}
            <input
              {...input}
              id="file_input"
              type="file"
              accept="image/png,image/svg+xml"
              required
              disabled={disabled}
              style={{
                display: "none",
              }}
              onChange={(event) => {
                const {
                  target: { files },
                } = event;
                onChange(files?.[0]);
                customOnChange?.(event);
              }}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            {imgSrc && (
              <Box sx={{ padding: "16px" }}>
                <img
                  src={imgSrc.startsWith("data") ? imgSrc : `${imgSrc}&t=${t}`}
                  alt="image_to_upload"
                  style={{ maxHeight: "100px" }}
                />
                {file && <p>{returnFileSize(file.size)}</p>}
              </Box>
            )}
          </>
        );
      }}
    />
  );
};

export default FileInput;

import { useEffect, useReducer } from "react";
import moment, { MomentInput } from "moment";

const Timer24h = ({ startTime }: { startTime: MomentInput }) => {
  const reRender = useReducer((x) => x + 1, 0)[1];
  const start = moment(startTime);
  const currentTime = moment();
  const diff = currentTime.diff(start);
  const duration = moment.duration(diff);
  const elapsedDays = duration.days();

  useEffect(() => {
    if (elapsedDays === 0) {
      const timer = setInterval(() => {
        reRender();
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [elapsedDays, reRender]);

  const time = `${format(duration.hours())}:${format(
    duration.minutes()
  )}:${format(duration.seconds())}`;

  return elapsedDays >= 1 ? (
    <div>{"23:59:59+"}</div>
  ) : (
    <div>{time === "00:00:00" ? "--:--:--" : time}</div>
  );
};

function format(time: number) {
  if (time.toString().length < 2) {
    return `0${time}`;
  }
  return time;
}

export default Timer24h;

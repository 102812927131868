import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SavedQueriesIcon } from "../../icons/export/SavedQueries.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { useParams } from "react-router-dom";
import {
  useCreate,
  useDelete,
  useEvents,
  useGetOne,
  useUpdate,
} from "@mb-pro-ui/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { useHistory } from "react-router";
import { Form } from "react-final-form";

import { SnackbarState, SystemSettingsPageProps } from "../settings/types";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
} from "../settings/utils";
import { Customer, RegularReport } from "@mb-pro-ui/utils/types/alarm";
import { SavedQueryForm } from "./SavedQueryForm";

type SavedQueryFormValues = Partial<RegularReport> & {};

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Saved query editor"
        description="Sidebar option"
      />
    ),
    icon: <SvgIcon component={SavedQueriesIcon} />,
  },
];

const SavedQueryUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: report,
    isLoading,
    refetch,
  } = useGetOne<RegularReport>("alarm/regular-reports", id, {
    enabled: id !== "new",
    include: {
      customer: {},
    },
  });

  const customer = report?.customer as Customer;
  const name = report?.description
    ? `${customer?.account} - ${report?.description}`
    : customer?.account;
  const breadcrumbName = name || id;

  const history = useHistory();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/exports/saved-queries/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: update } = useUpdate("alarm/regular-reports", {
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully saved" }),
      });
      refetch();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Failed to save" }),
        error: true,
      });
    },
  });

  const { mutate: del, isLoading: isDeleting } = useDelete(
    "alarm/regular-reports",
    {
      onSuccess: () => {
        history.replace("/alarm/exports/saved-queries");
      },
      onError: () => {
        setSnackbarState({
          message: formatMessage({ defaultMessage: "Unsuccessful delete" }),
          error: true,
        });
        setDeleteDialogOpen(false);
      },
    }
  );

  const [reportID, setReportID] = useState<string>();
  const [_reportURL, setReportURL] = useState<string>();
  const [successIDs, setSuccessIDs] = useState<
    { id: string; result: string }[]
  >([]);
  const [failureIDs, setFailureIDs] = useState<string[]>([]);

  const { mutate: createReport } = useCreate("admin/reports", {
    onSuccess: (data) => {
      setReportID(data.id);
    },
    onError: () => {
      setSnackbarState({
        error: true,
        message: formatMessage({
          defaultMessage: "Export request failed",
          description: "Failed export request message",
        }),
      });
    },
  });

  const handleNotification = useCallback(
    (event: string, notification: any) => {
      if (event === "report") {
        if (notification.$channel === "UPDATE@public.reports") {
          refetch();
          if (notification.status === "success") {
            setSuccessIDs((previous) => [
              ...previous,
              { id: String(notification.id), result: notification.result },
            ]);
          } else if (notification.status === "failure") {
            setFailureIDs((previous) => [...previous, notification.id]);
          }
        }
      }
    },
    [refetch]
  );

  useEvents(["report"], handleNotification);
  useEffect(() => {
    const successObj = successIDs.find((obj) => obj.id === reportID);
    if (successObj) {
      setReportURL(successObj.result);
      setSnackbarState({
        error: false,
        message: formatMessage({
          defaultMessage: "Export success",
          description: "Successful export message",
        }),
      });
      setReportID(undefined);
    }
  }, [reportID, successIDs, formatMessage]);

  useEffect(() => {
    if (reportID) {
      if (failureIDs.indexOf(reportID) >= 0) {
        setSnackbarState({
          error: true,
          message: formatMessage({
            defaultMessage: "Export failed",
            description: "Failed export message",
          }),
        });
      }
    }
  }, [reportID, failureIDs, formatMessage]);

  const onSubmit = (values: SavedQueryFormValues) => {
    const {
      customer,
      categories,
      interval,
      "last-exported-by": _last_exported_by,
      "last-exported": _last_exported,
      ...rest
    } = values;
    update({
      id,
      customer: (customer as Customer).id,
      categories: categories as string[],
      interval: interval as string,
      ...rest,
    });
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{name}</Typography>;

  return (
    <Page
      backLink="/alarm/exports/saved-queries"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={report}
        render={(props) => {
          const postfix = (
            <>
              <Tooltip title={formatMessage({ defaultMessage: "Export" })}>
                <IconButton
                  sx={{
                    color: "primary.contrastText",
                  }}
                  onClick={() => {
                    setReportURL(undefined);
                    createReport({
                      description: formatMessage(
                        {
                          defaultMessage:
                            "Saved query ({customer} - {description})",
                          description: "Saved query report description",
                        },
                        {
                          customer: (report?.customer as Customer).account,
                          description: report?.description,
                        }
                      ),
                      "report-type": "default-query",
                      options: {
                        id,
                      },
                    });
                    setSnackbarState({
                      error: false,
                      message: formatMessage({
                        defaultMessage: "Exporting...",
                        description: "Currently exporting snackbar message",
                      }),
                    });
                  }}
                  size="small"
                  disabled={!props.pristine}
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          );
          return (
            <SubPage
              prefix={prefix}
              postfix={postfix}
              sx={{ minWidth: "500px", overflow: "unset" }}
              innerSx={{ padding: "8px" }}
            >
              <StyledForm>
                <SavedQueryForm {...props} />
                <UpdateFormActions
                  onSave={props.handleSubmit}
                  onReset={() => props.form.reset()}
                  onDelete={() => setDeleteDialogOpen(true)}
                  disabledDelete={props.submitting}
                  disabledReset={props.submitting || props.pristine}
                  disabledSave={props.submitting || props.pristine}
                  isLoading={isLoading}
                  isDeleting={isDeleting}
                />
              </StyledForm>
            </SubPage>
          );
        }}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={report?.description ?? ""}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default SavedQueryUpdatePage;

import { CustomerGroup } from "@mb-pro-ui/utils/types/alarm";
import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FilterViewProps } from "../../table";
import { AutocompleteFilter } from "../../table/filters";
import {
  filterOptionsFromDescription,
  idAsLabel,
  autoFilterOptions,
} from "../../../utils/FilterUtils";

const renderOption = (v: CustomerGroup) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.description}
  </Typography>
);

const CustomerGroupAutocompleteFilter = ({
  noNoGroup,
  ...props
}: FilterViewProps & { noNoGroup?: boolean }) => {
  const { formatMessage } = useIntl();

  const { data } = useGetAll<CustomerGroup>("alarm/customer-groups", {
    sort: ["description"],
    refetchOnWindowFocus: false,
    select: (original) =>
      noNoGroup
        ? original
        : autoFilterOptions(original, (x) => x, {
            type: "alarm/customer-groups",
            description: formatMessage({ defaultMessage: "No group" }),
          }),
  });

  return (
    <AutocompleteFilter
      {...props}
      options={data}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      noOptionsText={formatMessage({
        defaultMessage: "No such customer group",
        description: "Autocomplete Filter",
      })}
      label={formatMessage({
        defaultMessage: "Customer Group",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Customer Groups: Name",
        description: "Autocomplete Filter",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default CustomerGroupAutocompleteFilter;

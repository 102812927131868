import { ReactNode } from "react";
import { Box, styled, SxProps, Theme } from "@mui/material";
import DOMPurify from "dompurify";

interface Icon {
  "media-type": string;
  "raw-data": string | null;
  icon: string | null;
  $svg?: false | string;
}

const StyledImg = styled("img")({});

export const AnyIcon = ({
  icon,
  color,
  sx = [],
}: {
  icon: Icon;
  color: string;
  sx?: SxProps<Theme>;
}) => {
  if (icon.$svg === undefined) {
    if (icon["media-type"] === "image/svg+xml") {
      icon.$svg = DOMPurify.sanitize(atob(icon["raw-data"] ?? ""));
    } else {
      icon.$svg = false;
    }
  }

  if (icon.$svg) {
    return (
      <Box
        sx={[
          { "& .color1": { fill: color || "#000" } },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        dangerouslySetInnerHTML={{ __html: icon.$svg }}
      />
    );
  }

  if (icon.icon) {
    return <StyledImg src={icon.icon} alt="marker icon" sx={sx} />;
  }

  return null;
};

const MarkerIconBase = ({
  color,
  alarm,
  children,
}: {
  color: string;
  alarm?: boolean;
  children?: ReactNode;
}) => (
  <div className={`marker${alarm ? " alarm-marker" : ""}`}>
    <svg
      className="frame"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <path
        d="M24 .787a16.343 16.343 0 0116.343 16.344A16.343 16.343 0 0124 33.474 16.343 16.343 0 017.657 17.131 16.343 16.343 0 0124 .787z"
        fill="#fff"
        fillOpacity=".7"
      />
      <path
        d="M24 0C14.539 0 6.87 7.67 6.87 17.13 6.87 26.593 11.995 27.346 24 48c12.004-20.655 17.13-21.408 17.13-30.87C41.13 7.67 33.462 0 24 0zm0 1.137a15.993 15.993 0 0115.993 15.994A15.993 15.993 0 0124 33.124 15.993 15.993 0 018.007 17.131 15.993 15.993 0 0124 1.137z"
        fill={color}
      />
    </svg>
    {children}
  </div>
);

export const MarkerIcon = ({
  icon,
  alarm,
  color,
  iconColor,
}: {
  icon: Icon | null;
  alarm?: boolean;
  color: string;
  iconColor: string;
}) => (
  <MarkerIconBase color={color} alarm={alarm}>
    {icon && <AnyIcon icon={icon} color={iconColor} />}
  </MarkerIconBase>
);

export const ArrowIcon = ({ color }: { color: string }) => (
  <div className="arrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z"
      />
    </svg>
  </div>
);

export const defaultIcons = {
  car: {
    "media-type": "image/svg+xml",
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" class="color1" d="M18.9 6c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 12v8c0 .5.5 1 1 1h1c.6 0 1-.5 1-1v-1h12v1c0 .5.5 1 1 1h1c.5 0 1-.5 1-1v-8l-2.1-6zM6.8 7h10.3l1.1 3.1H5.8l1-3.1zM19 17H5v-5h14v5z"/><circle fill="currentColor" class="color1" cx="7.5" cy="14.5" r="1.5"/><circle fill="currentColor" class="color1" cx="16.5" cy="14.5" r="1.5"/></svg>',
    "raw-data": null,
    icon: null,
  },
  old: {
    "media-type": "image/svg+xml",
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" class="color1" d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6.5 7V23h-1V12.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v1h-1v-.7c-1.5-.4-2.7-1.3-3.5-2.5-.3.9-.5 1.8-.5 2.7v.7l2 2.8V23h-2v-5l-1.8-2.5L11 19l-3 4-1.6-1.2L9 18.3V13c0-1.1.2-2.3.5-3.4l-1.5.9V14H6V9.3l5.4-3.1c.6-.3 1.3-.3 1.9 0 .4.2.6.5.8.8l.8 1.7c.6 1.3 2 2.2 3.6 2.2.8.1 1.5.8 1.5 1.6z"/></svg>',
    "raw-data": null,
    icon: null,
  },
  siren: {
    "media-type": "image/svg+xml",
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" class="color1" d="M17.4 16.2l-.1-5c0-3-2.4-5.4-5.3-5.4s-5.4 2.4-5.4 5.4v5c-1.3 0-2.4 1.1-2.4 2.3v2.7c0 .5.4 1 1 1h13.6c.5 0 1-.4 1-1v-2.7c0-1.2-1.1-2.3-2.4-2.3zM12 7.8c1.9 0 3.5 1.6 3.5 3.5v5h-7v-5c0-2 1.6-3.5 3.5-3.5zM6.1 20.3v-1.8c0-.3.2-.5.5-.5h10.8c.3 0 .4.2.4.5v1.8H6.1zM12 5c.5 0 1-.4 1-1V2.7c0-.5-.4-1-1-1s-1 .4-1 1v1.4c0 .5.5.9 1 .9zM5.7 7.1c.1.1.4.3.7.3s.5-.1.7-.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-1-1c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l1 1zM17 7.1s.1 0 .1.1c.1.1.3.2.5.2.3 0 .5-.1.7-.3l1-1c.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.4-.4-1-.4-1.4 0l-1 1c-.4.4-.4 1 .1 1.4z"/><path fill="currentColor" class="color1" d="M9.7 12.1c.5 0 .9-.4.9-.9 0-.8.6-1.4 1.4-1.4.5 0 1-.4 1-1s-.5-.8-1-.8c-1.8 0-3.2 1.5-3.2 3.2 0 .5.4.9.9.9z"/></svg>',
    "raw-data": null,
    icon: null,
  },
  shoeprint: {
    "media-type": "image/svg+xml",
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.18 203.18" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path fill="currentColor" class="color1" d="M82.44 136.68c-1.62 4.5-2.12 10.78-1.84 18.31l-30.69 2.45c-2.58-14.06-6.16-29.72-4.42-54.06 10.47-47.75 65.53-36.55 36.95 33.3zm-1.45 24.62c.52 6.28 1.46 13.22 2.66 20.59 1.12 22.32-22.67 18.38-26.49 5.59-1.68-6.87-3.98-14.46-6.01-23.8l29.84-2.38zm39.75-93.65c1.62 4.5 2.12 10.78 1.84 18.31l30.69 2.45c2.58-14.06 6.16-29.72 4.42-54.06-10.47-47.75-65.53-36.55-36.95 33.3zm1.45 24.62c-.52 6.28-1.46 13.22-2.66 20.59-1.12 22.32 22.67 18.38 26.49 5.59 1.68-6.86 3.98-14.46 6.01-23.8l-29.84-2.38z"/></svg>',
    "raw-data": null,
    icon: null,
  },
  house: {
    "media-type": "image/svg+xml",
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" class="color1" d="M12 5.7l5 4.5V18h-2v-6H9v6H7v-7.8l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/></svg>',
    "raw-data": null,
    icon: null,
  },
  flag: {
    "media-type": "image/svg+xml",
    $svg: '<svg viewBox="0 0 203.18 203.18" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><rect ry="4.81" rx="5.09" height="182.13" width="10.18" y="11.17" x="38.1" fill="currentColor" class="color1"/><path d="M53.37 12.22C98.63-2.08 97.6 53.98 165.08 47.09c-51.33 70.67-67.48-.31-111.4 30.55l-.31-65.42z" fill="currentColor" class="color1"/></svg>',
    "raw-data": null,
    icon: null,
  },
};

// function createMarkerIcon({ id, icon, iconColors, color, alarm }, hidden) {
//   return L.divIcon({
//     html: markerIcon(id, color, icon, iconColors, alarm),
//     className:
//       (alarm ? "marker alarm-marker" : "marker") + (hidden ? " hidden" : ""),
//     iconSize: [40, 80],
//     iconAnchor: [40, 80],
//     tooltipAnchor: [30, -50],
//   });
// }

// function svgIcon(id, icon, size, color) {
//   const svg = `<div id="${id}" class="footprint">${
//     color ? `<style>#${id} .color1{fill:${color}}</style>` : ""
//   }${decodeURI(icon.svg)}<div>`;
//   return L.divIcon({
//     html: svg,
//     className: "footprint",
//     iconSize: [size, size],
//     iconAnchor: [size / 2, size / 2],
//   });
// }

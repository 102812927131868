import { useGetAll } from "@mb-pro-ui/utils";
import { Category } from "../types";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon, Sound } from "@mb-pro-ui/utils/types/alarm";
import { Maybe } from "@mb-pro-ui/utils/types";
import AudioButton from "../../audio/AudioButton";
import { useLocale } from "../../../locales/LocaleProvider";
import { Lang } from "../../types";
import { localDescr } from "../../../utils/LocaleUtils";
import StyledAvatar from "../StyledAvatar";
import EventCategoryCreateModal from "./EventCategoryCreateModal";
import { AllEntities } from "../utils";

const AllEventCategories = () => {
  const queryResult = useGetAll<Category>("alarm/categories", {
    refetchOnWindowFocus: false,
    include: {
      sound: {},
      icon: {},
    },
    sort: ["id"],
    page: {
      limit: 10000,
    },
  });

  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const columns = useMemo(
    (): Column<Category>[] => [
      {
        id: "descr",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (category) => localDescr(category.descr, Lang[locale]),
        width: 50,
      },
      {
        id: "color",
        Header: formatMessage({
          defaultMessage: "Color",
        }),
        accessor: (category) => category.color,
        width: 20,
        Cell: ({
          value: color,
        }: Cell<{}, { r: number; g: number; b: number }>) => {
          return (
            <Box
              sx={{
                width: "15px",
                height: "15px",
                backgroundColor: `rgb(${color.r},${color.g},${color.b})`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          );
        },
      },
      {
        id: "alert",
        Header: formatMessage({
          defaultMessage: "Alert",
        }),
        accessor: (category) => category["intervention-needed"],
        Cell: ({ value: isAlert }: Cell<{}, boolean>) => (
          <Checkbox
            checked={isAlert}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
        width: 20,
      },
      {
        id: "priority",
        Header: formatMessage({
          defaultMessage: "Priority",
        }),
        accessor: (category) => category.priority,
        width: 20,
      },
      {
        id: "sound",
        Header: formatMessage({
          defaultMessage: "Sound",
        }),
        accessor: (category) => category.sound,
        Cell: ({ value: sound }: Cell<{}, Maybe<Sound>>) => {
          const url = sound?.data;
          return <AudioButton url={url} />;
        },
        width: 20,
      },
      {
        id: "icon",
        Header: formatMessage({
          defaultMessage: "Icon",
        }),
        accessor: (category) => category.icon,
        Cell: ({ value: MbProIcon }: Cell<{}, Maybe<Icon>>) => {
          return MbProIcon?.icon ? <StyledAvatar src={MbProIcon.icon} /> : "";
        },
        width: 20,
      },
    ],
    [formatMessage, locale]
  );

  const title = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      <FormattedMessage defaultMessage="Event categories" />
    </Typography>
  );

  return (
    <AllEntities
      columns={columns}
      queryResult={queryResult}
      title={title}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <EventCategoryCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllEventCategories;

import { useState, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Marker, MarkerProps } from "react-leaflet";
import { DivIcon, DivIconOptions } from "leaflet";
import { MarkerOptions } from "./Marker";

export type DivIconMarkerProps = Omit<MarkerProps & MarkerOptions, "icon"> & {
  icon: ReactNode;
  tagName?: keyof HTMLElementTagNameMap;
  iconOptions?: Omit<DivIconOptions, "html">;
};

const DivIconMarker = ({
  icon,
  children,
  iconOptions,
  ...marker
}: DivIconMarkerProps) => {
  const [{ container, leafletIcon }] = useState(() => {
    const el = document.createElement("div");
    const leafletIcon = new DivIcon({ html: el, ...iconOptions });
    return { container: el, leafletIcon };
  });

  return (
    <>
      {createPortal(icon, container)}
      <Marker {...marker} icon={leafletIcon}>
        {children}
      </Marker>
    </>
  );
};

export default DivIconMarker;

import { lighten } from "@mui/material";
import { AutocompleteValue } from "@mui/material/useAutocomplete";
import clsx from "clsx";
import { ReactNode, useCallback } from "react";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import AutocompleteInput, { AutocompleteProps as AutocompleteInputProps } from "../Autocomplete";
import { useComposedValidate } from "./utils";

type PassedThroughProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>,
  | "name"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "error"
  | "defaultValue"
  | "type"
  | "multiple"
  | "isOptionEqualToValue"
>;

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends PassedThroughProps<T, Multiple, DisableClearable, FreeSolo>,
    UseFieldConfig<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>> {
  name: string;
  requiredError?: ReactNode;
  highlightDirty?: boolean;
}

export type AutocompleteAdapterProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = FieldRenderProps<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>> &
  PassedThroughProps<T, Multiple, DisableClearable, FreeSolo>;

const AutocompleteAdapter = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  input: { onChange, multiple, ...input },
  meta,
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  ...rest
}: AutocompleteAdapterProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <AutocompleteInput
    label={label ?? input.name}
    className={clsx(className, meta.dirty && "Mbp-dirty")}
    helperText={(meta.touched && meta.error) || helperText}
    readOnly={meta.submitting || readOnly}
    multiple={multiple as Multiple | undefined}
    error={meta.touched && !!meta.error}
    onChange={useCallback((_, value) => onChange(value), [onChange])}
    sx={[
      meta.dirty &&
        !meta.error &&
        highlightDirty && {
          ".MuiFormControl-root": {
            backgroundColor: (theme) => lighten(theme.palette.warning.light, 0.6),
          },
        },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
    {...input}
  />
);

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  required,
  disableClearable,
  requiredError,
  validate,
  isEqual,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <Field<
    AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    HTMLElement,
    AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    AutocompleteAdapterProps<T, Multiple, DisableClearable, FreeSolo>
  >
    component={AutocompleteAdapter}
    validate={useComposedValidate(required || disableClearable, requiredError, validate)}
    required={required || disableClearable}
    disableClearable={(required || disableClearable) as DisableClearable}
    isEqual={isEqual}
    isOptionEqualToValue={isEqual}
    {...rest}
  />
);

export default Autocomplete;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import { Button } from "@mb-pro-ui/components";
import { ID } from "@mb-pro-ui/utils/types";
import { useUpdate } from "@mb-pro-ui/utils";
import {
  Intervention as _Intervention,
  Cdec as _Cdec,
} from "@mb-pro-ui/utils/types/alarm";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import { FormattedMessage } from "react-intl";
import { Intervention } from "./types";

type InterventionTakeoverDialogProps = {
  open: boolean;
  onClose: () => void;
  id: ID;
  refetchIntervention: () => Promise<any>;
  handleQueryError: (error: JsonapiError) => void;
  intervention: Intervention | undefined;
};

const InterventionTakeoverDialog = ({
  open,
  onClose,
  id,
  refetchIntervention,
  handleQueryError,
  intervention,
}: InterventionTakeoverDialogProps) => {
  const {
    mutate: updateIntervention,
    isLoading,
    isSuccess,
    isError,
  } = useUpdate<Omit<Intervention, "type"> & { type: "interventions" }>(
    "alarm/interventions",
    {
      onSuccess: () => {
        refetchIntervention();
      },
      onError: handleQueryError,
    }
  );

  const handleAcknowledgeClick = () => {
    updateIntervention({
      id: id,
      session: { type: "sessions", id: "current" },
    });
    onClose();
  };

  const handleDenyClick = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {isLoading && isSuccess && isError ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Takover Intervention"
              description="Intervention Page Takeover intervention popup title"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                defaultMessage="Takeover intervention with id: {id}"
                description="Intervention Page Takeover intervention poup message"
                values={{
                  account: `${intervention?.customer?.account}`,
                  id: `${intervention?.id}`,
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAcknowledgeClick} mode="warning">
              <FormattedMessage
                defaultMessage="Takeover"
                description="Intervention Page Takeover intervention popup acknowledge button"
              />
            </Button>
            <Button onClick={handleDenyClick}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Intervention Page Takeover intervention popup deny button"
              />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default InterventionTakeoverDialog;

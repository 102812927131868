import { FormValues, Category, Customer } from "./types";

import { ReactComponent as RfidIcon } from "../../icons/customer/RfidBlue.svg";
import { ReactComponent as BasicIcon } from "../../icons/customer/BasicBlue.svg";
import { ReactComponent as UsersIcon } from "../../icons/customer/UsersBlue.svg";
import { ReactComponent as ZonesIcon } from "../../icons/customer/ZonesBlue.svg";
import { ReactComponent as TasksIcon } from "../../icons/customer/TasksBlue.svg";
import { ReactComponent as InterventionsIcon } from "../../icons/customer/InterventionsBlue.svg";
import { ReactComponent as ManualNotificationsIcon } from "../../icons/customer/ManualNotificationsBlue.svg";
import { ReactComponent as ContractIcon } from "../../icons/customer/ContractBlue.svg";
import { ReactComponent as PositionIcon } from "../../icons/customer/PositionBlue.svg";
import { ReactComponent as LifesignsIcon } from "../../icons/customer/LifesignsBlue.svg";
import { ReactComponent as EventRulesIcon } from "../../icons/customer/EventRulesBlue.svg";
import { ReactComponent as PartitionsIcon } from "../../icons/customer/PartitionsBlue.svg";
import { ReactComponent as MapProfileIcon } from "../../icons/customer/MapProfileBlue.svg";
import { ReactComponent as InstallersIcon } from "../../icons/customer/InstallersBlue.svg";
import { ReactComponent as IntervalRulesIcon } from "../../icons/customer/IntervalRulesBlue.svg";
import { ReactComponent as EquipmentInfoIcon } from "../../icons/customer/EquipmentInfoBlue.svg";
import { ReactComponent as RegularReportsIcon } from "../../icons/customer/RegularReportsBlue.svg";
import { ReactComponent as ExternalReceiverIcon } from "../../icons/customer/ExternalReceiverBlue.svg";
import { ReactComponent as EventCancellationIcon } from "../../icons/customer/EventCancellationBlue.svg";
import { ReactComponent as AutomaticNotificationsIcon } from "../../icons/customer/AutomaticNotificationsBlue.svg";

import moment from "moment";

import get from "lodash/get";
import { Profile } from "./profile/ProfileProvider";
import { SvgIcon } from "@mui/material";

const convertTimestamp = (timestamp: string | null) => {
  return timestamp ? moment(timestamp).format("YYYY-MM-DDTHH:mm") : null;
};

const convertTimestampToApi = (timestamp: string | null) => {
  return timestamp ? moment(timestamp).toDate().toISOString() : null;
};

const formatDatesToFormValues = ({
  "contract-start-ts": contractStartTs,
  "temp-inactive-ts": tempInactiveTs,
  zones,
  ...rest
}: FormValues): FormValues => {
  return {
    ...rest,
    "contract-start-ts": convertTimestamp(contractStartTs),
    "temp-inactive-ts": convertTimestamp(tempInactiveTs),
    zones:
      zones?.map(
        ({ "temporary-inactive-ts": temporaryInactiveTs, ...zone }) => ({
          ...zone,
          "temporary-inactive-ts": convertTimestamp(temporaryInactiveTs),
        })
      ) ?? null,
  };
};

const addRulesMatrix = (
  apiResponse: Customer,
  categories: Category[]
): FormValues => ({
  ...apiResponse,
  "rules-matrix":
    categories?.map((category) => [
      ...(apiResponse["action-groups"]?.map(
        (group) =>
          group.rules?.some(
            (rule) =>
              rule.category === category.id ||
              (rule.category as any)?.id === category.id
          ) ?? false
      ) ?? []),
    ]) ?? undefined,
});

const nullToEmptyArray = ({
  notifications,
  ...rest
}: FormValues): FormValues => ({
  notifications:
    notifications?.map(
      ({
        "email-recipients": emailRecipients,
        "sms-recipients": smsRecipients,
        ...restNotification
      }) => ({
        "email-recipients": emailRecipients ?? [],
        "sms-recipients": smsRecipients ?? [],
        ...restNotification,
      })
    ) ?? null,
  ...rest,
});

export const apiResponseToForm = (
  apiResponse: Customer,
  categories: Category[]
): FormValues => {
  return nullToEmptyArray(
    formatDatesToFormValues(addRulesMatrix(apiResponse, categories))
  );
};

const removeRuleMatrix = (formValues: FormValues, categories: Category[]) => {
  const {
    "rules-matrix": rulesMatrix,
    "action-groups": actionGroups,
    ...apiResponse
  } = formValues;

  const newActionGroups =
    actionGroups?.map(({ rules, ...restGroup }, i) => {
      const newRules = categories
        .map(({ id }, j) => {
          if (rulesMatrix?.[j][i]) {
            const existingRule = rules?.find((rule) => rule.category === id);

            if (existingRule) {
              return {
                type: "action-rules",
                category: categories[j].id,
                id: existingRule.id,
              };
            } else {
              return {
                type: "action-rules",
                category: categories[j].id,
              };
            }
          } else {
            return null;
          }
        })
        .filter((rule) => rule);

      return {
        ...restGroup,
        rules: (newRules?.length ?? 0) > 0 ? newRules : null,
      };
    }) ?? null;

  return { "action-groups": newActionGroups, ...apiResponse };
};

const orderActionPlans = (apiResponse: Customer): Customer => {
  return {
    ...apiResponse,
    "action-groups":
      apiResponse["action-groups"]?.map(({ subactions, ...restGroup }) => ({
        ...restGroup,
        subactions:
          subactions?.map((subaction, i) => ({
            ...subaction,
            ord: i,
          })) ?? null,
      })) ?? null,
  };
};

const formatDatesToApi = ({
  "contract-start-ts": contractStartTs,
  "temp-inactive-ts": tempInactiveTs,
  ...rest
}: Customer): Customer => {
  return {
    "contract-start-ts": convertTimestampToApi(contractStartTs),
    "temp-inactive-ts": convertTimestampToApi(tempInactiveTs),
    ...rest,
    zones:
      rest.zones?.map(
        ({ "temporary-inactive-ts": temporaryInactiveTs, ...zoneRest }) => ({
          ...zoneRest,
          "temporary-inactive-ts": convertTimestampToApi(temporaryInactiveTs),
        })
      ) ?? null,
  };
};

export const formValuesForSave = (
  formValues: FormValues | undefined,
  categories: Category[] | undefined
): any | undefined => {
  if (!formValues || !categories) {
    return undefined;
  }

  return formatDatesToApi(
    orderActionPlans(removeRuleMatrix(formValues, categories) as Customer)
  );
};

export const getMenuOptions = (
  profile: Profile | null,
  expertMode: boolean,
  formatMessage: any
) => {
  const defaultOptions = [
    {
      id: "customer",
      name: formatMessage({
        defaultMessage: "Customer",
        description: "Customer page menu basic item",
      }),
      link: "",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={BasicIcon}
        />
      ),
    },
    {
      id: "installation",
      name: formatMessage({
        defaultMessage: "Installation",
      }),
      link: "/zones",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={EquipmentInfoIcon}
        />
      ),
      subPages: [
        {
          id: "zones",
          link: "/zones",
          name: formatMessage({
            defaultMessage: "Zones",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={ZonesIcon}
            />
          ),
        },
        {
          id: "partitions",
          link: "/partitions",
          name: formatMessage({
            defaultMessage: "Partitions",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={PartitionsIcon}
            />
          ),
        },
        {
          id: "installers",
          link: "/installers",
          name: formatMessage({
            defaultMessage: "Installers",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={InstallersIcon}
            />
          ),
        },
        {
          id: "equipment-information",
          link: "/equipment-information",
          name: formatMessage({
            defaultMessage: "Technical informations",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={EquipmentInfoIcon}
            />
          ),
        },
        {
          id: "users",
          link: "/users",
          name: formatMessage({
            defaultMessage: "Users",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={UsersIcon}
            />
          ),
        },
        {
          id: "external-receiver",
          link: "/external-receiver",
          name: formatMessage({
            defaultMessage: "External receiver",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={ExternalReceiverIcon}
            />
          ),
        },
      ],
    },
    {
      id: "event-processing",
      name: formatMessage({
        defaultMessage: "Event processing",
        description: "Customer page menu Event processing item",
      }),
      link: "/interval-rules",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={IntervalRulesIcon}
        />
      ),
      subPages: [
        {
          id: "interval-rules",
          link: "/interval-rules",
          name: formatMessage({
            defaultMessage: "Interval rules",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={IntervalRulesIcon}
            />
          ),
        },
        {
          id: "event-cancellation",
          name: formatMessage({
            defaultMessage: "Event cancellation",
          }),
          link: "/event-cancellation",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={EventCancellationIcon}
            />
          ),
        },
        {
          id: "lifesigns",
          name: formatMessage({
            defaultMessage: "Lifesigns",
          }),
          link: "/lifesigns",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={LifesignsIcon}
            />
          ),
        },
      ],
    },
    {
      id: "interventions",
      name: formatMessage({
        defaultMessage: "Interventions",
      }),
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={InterventionsIcon}
        />
      ),
      link: "/notifiables",
      subPages: [
        {
          id: "notifiables",
          name: formatMessage({
            defaultMessage: "Notifiables",
          }),
          link: "/notifiables",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={TasksIcon}
            />
          ),
        },
        {
          id: "manual-notifications",
          name: formatMessage({
            defaultMessage: "Manual notifications",
          }),
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={ManualNotificationsIcon}
            />
          ),
          link: "/manual-notifications",
        },
        {
          id: "automatic-notifications",
          name: formatMessage({
            defaultMessage: "Automatic notifications",
          }),
          link: "/automatic-notifications",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={AutomaticNotificationsIcon}
            />
          ),
        },
        {
          id: "event-rules",
          name: formatMessage({
            defaultMessage: "Event rules",
          }),
          link: "/event-rules",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={EventRulesIcon}
            />
          ),
        },
      ],
    },
    {
      id: "map-and-position",
      name: formatMessage({
        defaultMessage: "Map and Position",
        description: "Customer page menu map and position item",
      }),
      link: "/map-profile",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={PositionIcon}
        />
      ),
      subPages: [
        {
          id: "map-profile",
          name: formatMessage({
            defaultMessage: "Map profile",
          }),
          link: "/map-profile",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={MapProfileIcon}
            />
          ),
        },
        {
          id: "location",
          name: formatMessage({
            defaultMessage: "Location",
          }),
          link: "/position",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={PositionIcon}
            />
          ),
        },
        {
          id: "rfid",
          name: formatMessage({
            defaultMessage: "RFID",
          }),
          link: "/rfid-tokens",
          icon: (
            <SvgIcon
              color="primary"
              sx={{ "& .cls-1": { fill: "inherit" } }}
              component={RfidIcon}
            />
          ),
        },
      ],
    },
    {
      id: "regular-reports",
      name: formatMessage({
        defaultMessage: "Regular reports",
      }),
      link: "/regular-reports",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={RegularReportsIcon}
        />
      ),
    },
    {
      id: "contract",
      name: formatMessage({
        defaultMessage: "Contract",
      }),
      link: "/contract",
      icon: (
        <SvgIcon
          color="primary"
          sx={{ "& .cls-1": { fill: "inherit" } }}
          component={ContractIcon}
        />
      ),
    },
  ];

  return defaultOptions.map(({ subPages, ...restOpt }) => ({
    subPages: subPages?.filter(
      (subPage) =>
        (get(profile?.menu, subPage.id, 2) as number) > 1 || expertMode
    ),
    ...restOpt,
  }));
};

import { LocalizedString, Maybe } from "@mb-pro-ui/utils/types";
import { Lang } from "../components/types";
import { localDescr } from "./LocaleUtils";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { LocaleName } from "../locales/LocaleProvider";
import { Filter as TableFilter } from "../components/table";
import { useMemo, useRef } from "react";
import { MutableRefObject } from "react";
import { Filters } from "@mb-pro-ui/utils";

export interface EntityWithId {
  id: string;
}
export interface EntityWithDescription {
  description: string | null;
}

export interface EntityWithIdAndDescription
  extends EntityWithId,
    EntityWithDescription {}

export interface EntityWithName {
  name: string | null;
}
export interface EntityWithDescr {
  descr: LocalizedString | null;
}
export interface EntityWithLocalizedDescription {
  "localized-description": string | null;
}

export function idAsLabel<T extends EntityWithId>(v: T) {
  return v.id;
}

export function nameAsLabel<T extends EntityWithName>(v: T) {
  return v.name;
}

export function sortByName(a: EntityWithName, b: EntityWithName) {
  if (a === null || a.name === null) return -1;
  if (b === null || b.name === null) return 1;
  const A = a.name;
  const B = b.name;
  return A.toLowerCase().localeCompare(B.toLowerCase());
}

export function sortByDescription(
  a: EntityWithDescription,
  b: EntityWithDescription
) {
  if (a === null || a.description === null) return -1;
  if (b === null || b.description === null) return 1;
  const A = a.description;
  const B = b.description;
  return A.toLowerCase().localeCompare(B.toLowerCase());
}

export function sortByLocalizedDescription(
  a: EntityWithLocalizedDescription,
  b: EntityWithLocalizedDescription
) {
  if (a === null || a["localized-description"] === null) return -1;
  if (b === null || b["localized-description"] === null) return 1;
  const A = a["localized-description"];
  const B = b["localized-description"];
  return A.toLowerCase().localeCompare(B.toLowerCase());
}

export function localizeDescription<T extends EntityWithDescr>(
  data: T[],
  locale: LocaleName
) {
  return data?.map((data) => {
    const extendedData = data as unknown as EntityWithDescription;
    extendedData.description =
      data.descr === null ? null : localDescr(data.descr, Lang[locale]);
    return extendedData;
  }) as (T & EntityWithDescription)[];
}

export function descriptionToString<T extends EntityWithDescription>(v: T) {
  return v.description || "";
}

export function nameToString<T extends EntityWithName>(v: T) {
  return v.name || "";
}

export const filterOptionsFromDescription = createFilterOptions({
  stringify: descriptionToString,
  trim: true,
});

export declare type FilterOp = (filter: TableFilter) => Filters[string];

export const eqInNeqNinFilter: FilterOp = ({
  value,
  reversed,
}: TableFilter) => {
  if (Array.isArray(value) && value.length !== 1) {
    return { [reversed ? "nin" : "in"]: value };
  }
  value = Array.isArray(value) ? value[0] : value;
  if (value === "null") {
    return { null: reversed ? "false" : "true" };
  }
  return { [reversed ? "ne" : "eq"]: value };
};

export const manyToManyFilter: FilterOp = (filter: TableFilter) => {
  return { m2m: filter.value };
};

export const boolFilter: FilterOp = (filter: TableFilter) => {
  const value = Array.isArray(filter.value) ? filter.value[0] : filter.value;
  return { is: String(value) === "true" ? "true" : "not_true" };
};

export const nullFilter: FilterOp = (
  filter: Omit<TableFilter, "value"> & {
    value: true | false | "true" | "false";
  }
) => {
  return {
    null: String(filter.value) as "true" | "false",
  };
};

export const patternFilter: FilterOp = (filter: TableFilter) => {
  const s = String(filter.value);
  if (s.startsWith("=")) {
    return { eq: s.substring(1) };
  }
  if (s.includes("%")) {
    return { pattern: s };
  }
  return { pattern: "%" + String(filter.value) + "%" };
};

interface RangeFilter {
  gte?: string;
  lte?: string;
}

export const dateRangeFilter: FilterOp = (filter: TableFilter): RangeFilter => {
  if (Array.isArray(filter.value)) {
    const arr = filter.value as string[];
    const result: RangeFilter = {};

    if (arr.length > 0 && arr[0] && arr[0].length > 0) result.gte = arr[0];
    if (arr.length > 1 && arr[1] && arr[1].length > 0) result.lte = arr[1];

    return result;
  } else {
    return { gte: filter.value };
  }
};

export const commaSeparatedValuesFilter: FilterOp = (filter: TableFilter) => {
  const arr = Array.isArray(filter.value) ? filter.value : [filter.value];

  const re = /(?:[\s,;]|$)+\s*/;
  const parts = [] as string[];
  arr.forEach((v) => {
    parts.push(...String(v).split(re));
  });

  if (parts.length === 1) return { eq: parts[0] };
  return { in: parts };
};

export const commaSeparatedValuesOrNotFilter: FilterOp = (filter: TableFilter) => {
  const arr = Array.isArray(filter.value) ? filter.value : [filter.value];

  const re = /(?:[\s,;]|$)+\s*/;
  const parts = [] as string[];
  arr.forEach((v) => {
    parts.push(...String(v).split(re));
  });

  if (parts.length > 0 && parts[0].charAt(0) === '!') {
    parts[0] = parts[0].substring(1);
    if (parts.length === 1) return { ne: parts[0] };
    return { nin: parts };  
  } else {
    if (parts.length === 1) return { eq: parts[0] };
    return { in: parts };  
  }
};


export function idAndDescriptionEq<T extends EntityWithIdAndDescription>(
  a: T,
  b: T
): boolean {
  return a.id === b.id && a.description === b.description;
}

type MergeEntitiesRef<T> = [Maybe<Map<string, T>>, Maybe<T[]>];

function doMergeEntities<T extends EntityWithId>(
  ref: MutableRefObject<MergeEntitiesRef<T>>,
  data: T[] | undefined,
  eq: (a: T, b: T) => boolean
) {
  const [currentMap, currentArray] = ref.current;
  const map = new Map<string, T>();
  let same = currentMap?.size === data?.length;
  //console.log("[doMergeEntities]: Size match", same, currentMap?.size, data?.length, ref);

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const id = data[i].id;

      const current = currentMap?.get(id);
      if (current && eq(current, data[i])) {
        map.set(id, current);
      } else {
        if (same)
          //console.log("[doMergeEntities]: First diff", current, data[i]);
          same = false;
        map.set(id, data[i]);
      }
    }
  } else {
    //console.log("[doMergeEntities]: Undefined data");
    same = false;
  }

  if (currentArray && same) {
    //console.log("[doMergeEntities]: same", currentMap);
    return currentArray;
  } else {
    //console.log("[doMergeEntities]: not same", currentMap, map, difference(currentMap, map));
    if (data === undefined) {
      data = [];
    }
    ref.current = [map, data];
    return data;
  }
}

export function useMergedEntities<T extends EntityWithId>(
  data: T[] | undefined,
  eq: (a: T, b: T) => boolean
): T[] {
  const ref = useRef<MergeEntitiesRef<T>>([null, null]);
  return useMemo(() => {
    const result = doMergeEntities(ref, data, eq);
    //console.log("Reprocessing Merge Entities", data, result);
    return result;
  }, [data, eq]);
}

export function autoFilterOptions<TApi, TOpt extends { id: string }>(
  data: TApi[],
  map: (item: TApi) => TOpt,
  nullOpt?: Omit<TOpt, "id"> | null | false
): TOpt[] {
  const options = nullOpt ? [{ id: "null", ...nullOpt } as TOpt] : [];
  for (const item of data) {
    options.push(map(item));
  }
  return options;
}

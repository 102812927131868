import { FormattedMessage } from "react-intl";
import { SnackbarState } from "../types";
import { Column, Row } from "react-table";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { EnhancedTable, Filter, FilterView } from "../../table";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import LinkButton from "../../utils/LinkButton";
import { ID } from "@mb-pro-ui/utils/types";
import { UseQueryResult } from "react-query";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import { ActionType } from "./types";
import Snackbar from "./Snackbar";
import AdvancedFilters from "../../table/AdvancedFilters/AdvancedFilters";

interface RenderCreateFormProps {
  isFormOpen: boolean;
  onFormClose: () => void;
  refetch: () => void;
  setSnackbarState: Dispatch<SetStateAction<SnackbarState | undefined>>;
}

interface RenderUpdateFormProps extends RenderCreateFormProps {
  selectedId: string | undefined;
}

interface AllEntitiesPageProps<T extends { id: ID }> {
  columns: Column<T>[];
  queryResult: UseQueryResult<T[], JsonapiError>;
  title: ReactNode;
  renderForm?: (props: RenderCreateFormProps) => ReactNode;
  renderUpdateForm?: (props: RenderUpdateFormProps) => ReactNode;
  actionType?: ActionType;
  tableFilters?: FilterView[];
  handleFilterChange?: (filters: Filter[]) => void;
}

const linkAction = <T extends { id: ID }>(row: Row<T>, url: string) => (
  <LinkButton
    row={row}
    url={`${url}/${row.original.id}`}
    message={<FormattedMessage defaultMessage={"Edit"} />}
  />
);

const AllEntitiesPage = <T extends { id: ID }>({
  columns,
  queryResult,
  title,
  renderForm,
  renderUpdateForm,
  actionType = ActionType.LINK,
  tableFilters,
  handleFilterChange,
}: AllEntitiesPageProps<T>) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<
    SnackbarState | undefined
  >();
  const [selectedId, setSelectedId] = useState<string | undefined>();

  const { data: entities, status: queryStatus, refetch } = queryResult;

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const popupAction = useCallback((row: Row<T>) => {
    return (
      <LinkButton
        onClick={() => {
          setSelectedId(row.original.id);
        }}
      />
    );
  }, []);

  const prefix = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      {title}
    </Typography>
  );

  const onClickAdd = () => {
    setIsFormOpen(true);
  };

  const onFormClose = () => {
    setIsFormOpen(false);
  };

  const onUpdateFormClose = () => {
    setSelectedId(undefined);
  };

  const postfix = (
    <Box>
      <IconButton
        sx={{
          color: "primary.contrastText",
        }}
        onClick={onClickAdd}
        size="small"
      >
        <AddOutlinedIcon />
      </IconButton>
    </Box>
  );

  const filters = tableFilters && (
    <AdvancedFilters
      tableFilters={tableFilters}
      onFilterChange={handleFilterChange}
    />
  );

  return (
    <>
      <EnhancedTable
        data={entities || []}
        columns={columns}
        action={actionType === ActionType.LINK ? linkAction : popupAction}
        actionCellWidth={130}
        actionPosition="left"
        prefix={prefix}
        postfix={postfix}
        queryStatus={queryStatus}
        sx={{ height: "100%" }}
        emptyLineCount={1}
        filters={filters}
      />
      {renderForm &&
        renderForm({ isFormOpen, onFormClose, refetch, setSnackbarState })}
      {renderUpdateForm &&
        renderUpdateForm({
          isFormOpen: !!selectedId,
          onFormClose: onUpdateFormClose,
          refetch,
          setSnackbarState,
          selectedId,
        })}
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </>
  );
};

export default AllEntitiesPage;

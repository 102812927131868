import { Box, Paper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { ReactNode } from "react";

interface SubPageProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  showHeader?: boolean;
  prefix?: ReactNode;
  center?: ReactNode;
  postfix?: ReactNode;
  elevation?: number;
}

const SubPage = ({
  sx = [],
  innerSx = [],
  children,
  prefix,
  center,
  postfix,
  showHeader = true,
  elevation,
}: SubPageProps) => {
  const isHeader = !!(prefix || center || postfix) && showHeader;
  return (
    <Paper
      elevation={elevation}
      sx={[
        { padding: 0, flex: 1, display: "flex", flexDirection: "column", overflow: "auto" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {isHeader && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "primary.main",
            padding: "0 24px",
            borderRadius: "4px 4px 0 0",
            minHeight: "40px",
          }}
        >
          {prefix}
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              position: "absolute",
            }}
          >
            {center}
          </Box>
          <Box>{postfix}</Box>
        </Box>
      )}
      <Box
        sx={[
          {
            overflow: "hidden",
            borderRadius: "0 0 4px 4px",
            flex: 1,
          },
          ...(Array.isArray(innerSx) ? innerSx : [innerSx]),
        ]}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default SubPage;

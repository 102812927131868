import { SyntheticEvent, useCallback, useMemo, useState } from "react";

import { muiTheme, Page } from "@mb-pro-ui/components";
import { alpha } from "@mui/system";
import { Filters, useGetAll } from "@mb-pro-ui/utils";
import { useIntl, defineMessage as $d, FormattedMessage } from "react-intl";

import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";

import { Column, Row } from "react-table";

import {
  Intervention as _Intervention,
  Cdec as _Cdec,
  Customer,
} from "@mb-pro-ui/utils/types/alarm";

import {
  EnhancedTable,
  Filter as TableFilter,
  FilterViewProps,
} from "../components/table";

import { MessageDescriptor } from "@formatjs/intl";

import { TextFilter } from "../components/table/filters";
import CustomerGroupAutocompleteFilter from "../components/customer/filters/CustomerGroupAutocompleteFilter";
import InstallerAutocompleteFilter from "../components/customer/filters/InstallerAutocompleteFilter";
import EquipmentTypeAutocompleteFilter from "../components/customer/filters/EquipmentTypeAutocompleteFilter";
import ObjectTypeAutocompleteFilter from "../components/customer/filters/ObjectTypeAutocompleteFilter";
import CodeTableAutocompleteFilter from "../components/customer/filters/CodeTableAutocompleteFilter";
import ActiveFilter from "../components/customer/filters/ActiveFilter";
import {
  boolFilter,
  eqInNeqNinFilter,
  FilterOp,
  manyToManyFilter,
  patternFilter,
} from "../utils/FilterUtils";
import { IconButton, Link, Snackbar, Tooltip, Typography } from "@mui/material";
import LinkButton from "../components/utils/LinkButton";
import AdvancedFilters from "../components/table/AdvancedFilters/AdvancedFilters";

import useImport from "../utils/useImport";
import CreateCustomerDialog from "./dialogs/CreateCustomerDialog";

const filterSpec: { [id: string]: FilterOp } = {
  account: patternFilter,
  name: patternFilter,
  active: boolFilter,
  address: patternFilter,
  phone: patternFilter,
  mobile: patternFilter,
  passwd: patternFilter,
  unitid: patternFilter,
  remarks: patternFilter,
  "contract-number": patternFilter,
  "billing-id": patternFilter,
  "guard-names": patternFilter,

  "authority-names": manyToManyFilter,
  "legacy-account": manyToManyFilter,

  group: eqInNeqNinFilter,
  installer: eqInNeqNinFilter,
  "equipment-type": eqInNeqNinFilter,
  "object-type": eqInNeqNinFilter,
  "code-table": eqInNeqNinFilter,
};

const mapToServerFilters = (filters: TableFilter[]): Filters => {
  const result: Filters = {};
  if (filters !== null) {
    filters.forEach((filter) => {
      if (filter.id in filterSpec) {
        result[filter.id] = filterSpec[filter.id](filter);
      }
    });
  }
  return result;
};

const action = (row: Row<Customer>, url: string) => (
  <LinkButton
    row={row}
    url={`${url}/${row.original.id}`}
    message={<FormattedMessage defaultMessage={"Edit"} />}
  />
);

const getStyle = (item: Customer) => {
  let color = muiTheme.palette.common.black;
  let backgroundColor = muiTheme.palette.common.white;

  if (!item.active) {
    color = muiTheme.palette.grey[500];
  }

  return { color, backgroundColor };
};

const SimpleTextFilter = (msg: MessageDescriptor) => {
  const SimpleTextFilter = (props: FilterViewProps) => {
    const { formatMessage } = useIntl();
    return <TextFilter {...props} label={formatMessage(msg)} />;
  };
  return SimpleTextFilter;
};

const tableFilters = [
  {
    id: "account",
    Filter: SimpleTextFilter($d({ defaultMessage: "Account" })),
  },
  {
    id: "name",
    Filter: SimpleTextFilter(
      $d({ defaultMessage: "Name", description: "Customer name" })
    ),
  },
  { id: "active", Filter: ActiveFilter },

  {
    id: "address",
    Filter: SimpleTextFilter($d({ defaultMessage: "Address" })),
  },
  { id: "phone", Filter: SimpleTextFilter($d({ defaultMessage: "Phone" })) },
  { id: "mobile", Filter: SimpleTextFilter($d({ defaultMessage: "Mobile" })) },
  {
    id: "passwd",
    Filter: SimpleTextFilter($d({ defaultMessage: "Password" })),
  },
  { id: "unitid", Filter: SimpleTextFilter($d({ defaultMessage: "Helios" })) },
  {
    id: "remarks",
    Filter: SimpleTextFilter($d({ defaultMessage: "Remarks" })),
  },
  {
    id: "contract-number",
    Filter: SimpleTextFilter($d({ defaultMessage: "Contract Number" })),
  },
  {
    id: "billing-id",
    Filter: SimpleTextFilter($d({ defaultMessage: "Billing ID" })),
  },
  {
    id: "guard-names",
    Filter: SimpleTextFilter($d({ defaultMessage: "Guard" })),
  },
  {
    id: "authority-names",
    Filter: SimpleTextFilter($d({ defaultMessage: "Authority" })),
  },
  {
    id: "legacy-account",
    Filter: SimpleTextFilter($d({ defaultMessage: "Legacy account" })),
  },

  { id: "group", Filter: CustomerGroupAutocompleteFilter },
  { id: "installer", Filter: InstallerAutocompleteFilter },
  { id: "equipment-type", Filter: EquipmentTypeAutocompleteFilter },
  { id: "object-type", Filter: ObjectTypeAutocompleteFilter },
  { id: "code-table", Filter: CodeTableAutocompleteFilter },
];

const rowProps = (row: Row<Customer>) => ({
  style: {
    ...getStyle(row.original),
    "&:hover": {
      backgroundColor: (theme: any) =>
        `${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
});

export const AllCustomersPage = () => {
  const { formatMessage } = useIntl();
  const [filters, setFilters] = useState<Filters>({});

  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);

  const [createReport, snackBarMessage, setSnackBarMessage, reportURL] =
    useImport({ formatMessage });

  const { data, status } = useGetAll<Customer>("alarm/customers", {
    include: {},
    fields: {
      customers: ["account", "name", "address", "email", "phone", "active"],
    },
    sort: ["account"],
    keepPreviousData: true,
    notifyOnChangeProps: "tracked",
    initialData: [],
    filter: { ...filters },
  });

  const columns = useMemo(
    (): Column<Customer>[] => [
      {
        id: "account",
        Header: formatMessage({
          defaultMessage: "Account",
        }),
        accessor: (item) => item.account,
        width: 120,
      },
      {
        id: "name",
        Header: formatMessage({
          defaultMessage: "Name",
          description: "Customer name",
        }),
        accessor: (item) => item.name ?? "",
        width: 300,
      },
      {
        id: "address",
        Header: formatMessage({
          defaultMessage: "Address",
        }),
        accessor: "address",
        width: 300,
      },
      {
        id: "phone",
        Header: formatMessage({
          defaultMessage: "Phone",
        }),
        accessor: "phone",
        width: 175,
      },
      {
        id: "email",
        Header: formatMessage({
          defaultMessage: "Email",
        }),
        accessor: "email",
        width: 175,
      },
    ],
    [formatMessage]
  );

  const handleFilterChange = useCallback(
    (filters: TableFilter[]) => {
      setFilters(mapToServerFilters(filters));
    },
    [setFilters]
  );

  const prefix = useMemo(() => {
    const message = status === "success" ? `: ${data?.length}` : "";
    return (
      <Typography color="primary.contrastText">
        {formatMessage({ defaultMessage: "Customers" })}
        {message}
      </Typography>
    );
  }, [data, status, formatMessage]);

  const handleSnackbarClose = (
    event: Event | SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "timeout") {
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Please try again",
          description: "Interventions table export try agin message",
        })
      );
    } else {
      setSnackBarMessage(null);
    }
  };

  const downloadAction = (
    <>
      {reportURL && (
        <Link
          href={reportURL}
          target="_blank"
          rel="noopener"
          onClick={() => {
            setSnackBarMessage(null);
          }}
          sx={{ mr: 1 }}
        >
          {formatMessage({ defaultMessage: "Download" })}
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <CreateCustomerDialog
        open={newUserDialogOpen}
        setOpen={setNewUserDialogOpen}
      />
      <Snackbar
        open={!!snackBarMessage}
        onClose={handleSnackbarClose}
        autoHideDuration={reportURL ? null : 10000}
        message={snackBarMessage}
        action={downloadAction}
      />
      <Page sx={{ overflow: "hidden", padding: "12px" }}>
        <EnhancedTable
          columns={columns}
          data={data ?? []}
          action={action}
          actionCellWidth={130}
          actionPosition="left"
          getRowProps={rowProps}
          queryStatus={status}
          overscanCount={5}
          prefix={prefix}
          postfix={
            <>
              <IconButton
                size="small"
                sx={{
                  color: "primary.contrastText",
                }}
                onClick={() => {
                  createReport({
                    description: formatMessage({
                      defaultMessage: "Customer database",
                    }),
                    "report-type": "customers-export",
                    options: {},
                  });
                  setSnackBarMessage(
                    formatMessage({
                      defaultMessage: "Exporting...",
                      description:
                        "Interventions table currently exporting snackbar message",
                    })
                  );
                }}
              >
                <Tooltip
                  title={formatMessage({
                    defaultMessage: "Export",
                  })}
                  placement="bottom-start"
                >
                  <DownloadIcon fontSize="inherit" />
                </Tooltip>
              </IconButton>
              <IconButton
                sx={{
                  color: "primary.contrastText",
                }}
                size="small"
                onClick={() => setNewUserDialogOpen(true)}
              >
                <Tooltip
                  title={formatMessage({
                    defaultMessage: "Add customer",
                  })}
                >
                  <AddIcon />
                </Tooltip>
              </IconButton>
            </>
          }
          filters={
            <AdvancedFilters
              tableFilters={tableFilters}
              onFilterChange={handleFilterChange}
            />
          }
        />
      </Page>
    </>
  );
};

export default AllCustomersPage;

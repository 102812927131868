import { AnyObject } from "final-form";

import { Checkbox } from "@mb-pro-ui/components/form";

import { SyntheticEvent } from "react";
import { Form, FormSpy } from "react-final-form";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Box } from "@mui/system";
import { FormDialog } from "@mb-pro-ui/components";
import { useBaseUrl, useUserInfo } from "@mb-pro-ui/utils";
import { useParams } from "react-router-dom";

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface PrintOption {
  all?: boolean;
  "contact-info"?: boolean;
  "customer-details"?: boolean;
  "technical-details"?: boolean;
  "installer-contact"?: boolean;
}

interface PrintOptionsProps {
  disabled: boolean;
}

const PrintOptions = ({ disabled }: PrintOptionsProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Checkbox
        name="contact-info"
        label={formatMessage({
          defaultMessage:
            "Contact info (phone, mobile, email and notifiable persons)",
        })}
        disabled={disabled}
      />
      <Checkbox
        name="customer-details"
        label={formatMessage({
          defaultMessage:
            "Customer details (password, remarks, customer group, ...)",
        })}
        disabled={disabled}
      />
      <Checkbox
        name="technical-details"
        label={formatMessage({
          defaultMessage:
            "Technical details (zones, partitions, alarm users, equipment information, ...)",
        })}
        disabled={disabled}
      />
      <Checkbox
        name="installer-contact"
        label={formatMessage({
          defaultMessage: "The installer's contact info",
        })}
        disabled={disabled}
      />
    </>
  );
};

interface UrlParams {
  id?: string;
}

interface PrintDialogProps {
  printDialogOpen: boolean;
  setPrintDialogOpen: (open: boolean) => void;
}

const PrintDialog = ({
  printDialogOpen,
  setPrintDialogOpen,
}: PrintDialogProps) => {
  let submit: Submit = null;

  const baseUrl = useBaseUrl();
  const [sid] = useUserInfo();

  const { id } = useParams<UrlParams>();

  const { formatMessage } = useIntl();

  const onPrintDialogAction = (
    _: {},
    action: "close" | "cancel" | "confirm"
  ) => {
    switch (action) {
      case "close":
        setPrintDialogOpen(false);
        break;
      case "cancel":
        setPrintDialogOpen(false);
        break;
      case "confirm":
        setPrintDialogOpen(false);
        if (submit) submit();
        break;
    }
  };

  const onSubmit = (vals: PrintOption) => {
    const selected = [];
    if (vals.all) {
      selected.push("full");
    } else {
      if (vals["contact-info"]) {
        selected.push("contact-info");
      }
      if (vals["customer-details"]) {
        selected.push("customer-details");
      }
      if (vals["technical-details"]) {
        selected.push("technical-configuration");
      }
      if (vals["installer-contact"]) {
        selected.push("installer");
      }
    }

    const win = window.open(
      `${baseUrl}/actions/alarm/customer-print/${id}?sid=${sid}&selected=${selected.join(
        ","
      )}`,
      "_blank"
    );
    if (win) win.focus();
  };

  return (
    <FormDialog
      title={formatMessage({ defaultMessage: "Saved data" })}
      open={printDialogOpen}
      onDialogAction={onPrintDialogAction}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          all: false,
          "contact-info": false,
          "customer-details": false,
          "installer-contact": false,
          "technical-details": false,
        }}
        render={({ handleSubmit }) => {
          submit = handleSubmit;
          return (
            <Box sx={{ width: "fit-content" }}>
              <Typography sx={{ marginBottom: "5%", marginTop: "5%" }}>
                {formatMessage({
                  defaultMessage:
                    "The saved PDF contains only the customer's name, address, account and Helios ID by default.",
                })}
              </Typography>
              <Checkbox
                name="all"
                label={formatMessage({ defaultMessage: "Everything" })}
              />

              <FormSpy>
                {({ values }) =>
                  values.all ? (
                    <PrintOptions disabled={true} />
                  ) : (
                    <PrintOptions disabled={false} />
                  )
                }
              </FormSpy>
            </Box>
          );
        }}
      />
    </FormDialog>
  );
};

export default PrintDialog;

import { Box, styled } from "@mui/system";
import { CircularProgress, IconButton, SxProps } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  "&:hover": {
    backgroundColor: "unset",
    color: theme.palette.primary.main,
  },
}));

interface AudioButtonProps {
  url: string | undefined | null;
  sx?: SxProps;
}

let actualPlayingAudio: HTMLAudioElement | null = null;

const stop = (audio: HTMLAudioElement) => {
  audio.pause();
  audio.currentTime = 0;
};

const AudioButton = ({ url, sx }: AudioButtonProps) => {
  const [state, setState] = useState<"idle" | "playing" | "loading">("idle");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(() =>
    url ? new Audio(url) : null
  );

  useEffect(() => {
    if (url) {
      setAudio(new Audio(url));
    }
  }, [url]);

  useEffect(() => {
    return () => {
      audio?.pause();
    };
  }, [audio]);

  const onPlayClick = (url: string | undefined | null) => {
    if (!audio && url) {
      setAudio(new Audio(url));
      setState("loading");
    }
    if (actualPlayingAudio) {
      stop(actualPlayingAudio);
    }
    audio?.play();
    actualPlayingAudio = audio;
  };

  const onPauseClick = () => {
    if (audio) {
      stop(audio);
    }
  };

  const onLoadStart = useCallback(() => {
    setState("loading");
  }, []);

  const onLoadedData = useCallback(() => {
    setState("idle");
  }, []);

  const onPlaying = useCallback(() => {
    setState("playing");
  }, []);

  const onPause = useCallback(() => {
    setState("idle");
  }, []);

  const onEnded = useCallback(() => {
    setState("idle");
  }, []);

  useEffect(() => {
    if (audio) {
      audio.onloadstart = onLoadStart;
      audio.onloadeddata = onLoadedData;
      audio.onplaying = onPlaying;
      audio.onpause = onPause;
      audio.onended = onEnded;
    }
    return () => {
      audio?.removeEventListener("loadstart", onLoadStart);
      audio?.removeEventListener("loadeddata", onLoadedData);
      audio?.removeEventListener("playing", onPlaying);
      audio?.removeEventListener("pause", onPause);
      audio?.removeEventListener("ended", onEnded);
    };
  }, [onLoadStart, onLoadedData, onEnded, onPlaying, onPause, audio]);

  if (state === "idle") {
    return (
      <StyledButton
        size="small"
        onClick={() => onPlayClick(url)}
        disabled={!url}
        sx={sx}
      >
        <PlayArrowIcon fontSize="small" />
      </StyledButton>
    );
  }

  if (state === "playing") {
    return (
      <StyledButton size="small" onClick={onPauseClick} sx={sx}>
        <PauseIcon fontSize="small" />
      </StyledButton>
    );
  }

  if (state === "loading") {
    return (
      <Box
        sx={{
          width: "20px",
          height: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...sx,
        }}
      >
        <CircularProgress size="10px" sx={{ color: "text.primary" }} />
      </Box>
    );
  }

  throw new Error("Missing AuidoButton state handler");
};

export default AudioButton;

import { Skeleton, styled } from "@mui/material";
import { ColumnInstance } from "react-table";
import getCustomColumnProps from "./getCustomColumnProps";

const getCustomSkeletonColumnProps = (col: ColumnInstance) =>
  getCustomColumnProps(col).concat({
    style: { display: "block" },
  });

const SkeletonRowRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "width",
})<{
  width: number;
}>(({ width }) => ({
  width: `${width}px`,
  display: "flex",
  alignItems: "center",
}));

const SkeletonCellRoot = styled("div")({ padding: "0 16px" });

export interface TableSkeletonProps {
  columns: ColumnInstance<any>[];
  width: number;
  height: number;
  rowHeight: number;
}

const TableSkeleton = ({ columns, width, height, rowHeight }: TableSkeletonProps) => (
  <>
    {Array.from(Array(Math.floor(height / rowHeight))).map((_, index) => (
      <SkeletonRowRoot key={index} width={width}>
        {columns.map((column) => (
          <SkeletonCellRoot
            {...column.getHeaderProps(getCustomSkeletonColumnProps(column))} // includes a `key` prop
          >
            <Skeleton variant="text" height={rowHeight} />
          </SkeletonCellRoot>
        ))}
      </SkeletonRowRoot>
    ))}
  </>
);

export default TableSkeleton;

import { useMemo } from "react";

import { Column, Row } from "react-table";

import { Autocomplete } from "@mb-pro-ui/components/form";

import CoordinatesField from "../form/CoordinatesField";
import { StyledTextField } from "../form/StyledComponents";

import { useGetAll } from "@mb-pro-ui/utils";

import { Sector, Zone, ZoneType, SensorType } from "./types";
import { useIntl } from "react-intl";

import { Lang } from "../types";
import { useLocale } from "../../locales/LocaleProvider";
import LinkButton from "../utils/LinkButton";

import FormTable from "../form/FormTable";
import { isEqualById } from "../utils/autocompleteUtils";

const ZonesDialog = () => {
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const { data: zones } = useGetAll<ZoneType>("alarm/zone-types", {
    refetchOnWindowFocus: false,
  });

  const { data: sensorTypes } = useGetAll<SensorType>("alarm/sensor-types", {
    refetchOnWindowFocus: false,
  });

  const { data: sectors } = useGetAll<Sector>("alarm/sectors", {
    refetchOnWindowFocus: false,
  });

  return zones && sensorTypes && sectors ? (
    <>
      <StyledTextField
        name="number"
        label={formatMessage({
          defaultMessage: "Number",
        })}
        inputProps={{ maxLength: 3 }}
        required
      />
      <StyledTextField
        name="place"
        label={formatMessage({
          defaultMessage: "Place",
        })}
      />
      <Autocomplete
        name="zone-type"
        label={formatMessage({
          defaultMessage: "Zone type",
        })}
        options={zones}
        fullWidth
        getOptionLabel={(option) => option.descr?.[Lang[locale]] ?? ""}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
      />
      <Autocomplete
        name="sensor-type"
        label={formatMessage({
          defaultMessage: "Sensor type",
        })}
        options={sensorTypes}
        fullWidth
        getOptionLabel={(option) => option.descr?.[Lang[locale]] ?? ""}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
      />
      <StyledTextField
        name="temporary-inactive-ts"
        label={formatMessage({
          defaultMessage: "Temporary bypass",
        })}
        type="datetime-local"
      />
      <Autocomplete
        name="sector"
        label={formatMessage({
          defaultMessage: "Sector",
          description: "Zóna területi szektora",
        })}
        options={sectors}
        fullWidth
        getOptionLabel={(option) => option.description ?? ""}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
      />
      <CoordinatesField latName="latitude" lngName="longitude" />
    </>
  ) : (
    <></>
  );
};

interface ZonesProps {
  readOnly?: boolean;
}

const Zones = ({ readOnly }: ZonesProps) => {
  const action = (row: Row<Zone>, url: string) =>
    readOnly ? (
      <></>
    ) : (
      <LinkButton row={row} url={`${url}/zones/byNum/${row.index}`} />
    );

  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const columns = useMemo(
    (): Column<Zone>[] => [
      {
        id: "number",
        Header: formatMessage({
          defaultMessage: "Number",
        }),
        accessor: (p) => p.number,
      },
      {
        id: "place",
        Header: formatMessage({
          defaultMessage: "Place",
        }),
        accessor: (p) => p.place,
      },
      {
        id: "senor-type",
        Header: formatMessage({
          defaultMessage: "Sensor",
        }),
        accessor: (p) => p["sensor-type"]?.descr?.[Lang[locale]] ?? "",
      },
      {
        id: "sector",
        Header: formatMessage({
          defaultMessage: "Sector",
          description: "Zóna területi szektora",
        }),
        accessor: (p) => p.sector?.description ?? "",
      },
      {
        id: "temporary-inactive",
        Header: formatMessage({
          defaultMessage: "Temporary inactivity",
        }),
        accessor: (p) =>
          p["temporary-inactive-ts"]
            ? new Date(p["temporary-inactive-ts"]).toLocaleString()
            : "",
      },
      {
        id: "coordinates",
        Header: formatMessage({
          defaultMessage: "Coordinates",
        }),
        accessor: (p) =>
          p.latitude && p.longitude
            ? `${p.latitude.toFixed(5)}, ${p.longitude.toFixed(5)}`
            : "",
      },
    ],
    [locale, formatMessage]
  );

  return (
    <FormTable
      name="zones"
      columns={columns}
      action={action}
      dialogNewTitle={formatMessage({
        defaultMessage: "New zone",
      })}
      dialogEditTitle={formatMessage({
        defaultMessage: "Edit zone",
      })}
      dialog={<ZonesDialog />}
    />
  );
};

export default Zones;

import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { Box, SvgIcon, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { useGetOne } from "@mb-pro-ui/utils";
import { useParams } from "react-router-dom";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { Icon as _Icon } from "@mb-pro-ui/utils/types/alarm";
import { Form } from "react-final-form";
import FileInput from "./FileInput";
import { preSaveFormat } from "./IconCreateModal";
import moment from "moment";

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Icon editor"
        description="System settings page sidebar icon editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

type FormValues = {
  file: File;
};

type Icon = _Icon;

const reader = new FileReader();

const IconUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: icon, refetch, isLoading } = useGetOne<Icon>("alarm/icons", id);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataUrl, setDataUrl] = useState<string>();

  const breadcrumbName = useMemo(() => {
    const t = moment.now();
    return icon?.icon ? (
      <img src={`${icon.icon}&t=${t}`} alt="icon" width="20" height="20" />
    ) : (
      id
    );
  }, [icon, id]);

  const finalBreadcrumbNameMap: { [key: string]: ReactNode } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/icons/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: del, isLoading: isDeleting } = useUseDelete("alarm/icons", {
    replaceTo: "/alarm/settings/icons",
    setSnackbarState,
  });

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    refetch,
    setSnackbarState,
    onSuccess: () => {
      setDataUrl("");
    },
  });

  useEffect(() => {
    const loaded = (e: any) => {
      setDataUrl(e.target.result);
    };
    reader.onload = loaded;
    return () => {
      reader.removeEventListener("load", loaded);
    };
  }, []);

  const onSubmit = async (values: FormValues) => {
    const variables = preSaveFormat(values, dataUrl);
    if (icon && variables) {
      batch([{ type: "icons", meta: { op: "update" }, id, ...variables }]);
    }
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const prefix = <Typography color="primary.contrastText">{id}</Typography>;

  return (
    <Page
      backLink="/alarm/settings/icons"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      {...systemSettingsPageProps}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, pristine }) => {
              return (
                <SubPage
                  prefix={prefix}
                  sx={{ minWidth: "500px" }}
                  innerSx={{ height: "auto" }}
                >
                  <StyledForm sx={{ padding: 2 }}>
                    <FileInput
                      imgSrc={dataUrl || (icon?.icon ? icon.icon : undefined)}
                      disabled={reader.readyState === 1}
                      onChange={({
                        target: { files },
                      }: ChangeEvent<HTMLInputElement>) => {
                        if (files?.[0]) {
                          reader.readAsDataURL(files?.[0]);
                        } else {
                          setDataUrl("");
                        }
                      }}
                    />
                    <UpdateFormActions
                      onSave={handleSubmit}
                      onDelete={() => setDeleteDialogOpen(true)}
                      disabledDelete={submitting}
                      disabledSave={
                        submitting ||
                        pristine ||
                        !dataUrl ||
                        reader.readyState === 1
                      }
                      isLoading={isLoading}
                      isDeleting={isDeleting}
                    />
                  </StyledForm>
                </SubPage>
              );
            }}
          />
        </Box>
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={"icon"}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default IconUpdatePage;

import { PropsWithChildren, useMemo } from "react";
import { ManagerOptions, SocketOptions } from "socket.io-client";
import { useUserInfo } from "../state/user-info";
import SocketIOProvider from "./SocketIOProvider";

function getSocketOptions(baseUrl: string) {
  const isRelative = baseUrl.indexOf("//") === -1;
  const sourceUrl = isRelative ? window.location : new URL(baseUrl);
  const pathname = isRelative ? baseUrl : sourceUrl.pathname;

  return {
    url:
      sourceUrl.protocol +
      "//" +
      sourceUrl.hostname +
      (sourceUrl.port
        ? ":" + (isRelative ? sourceUrl.port : parseInt(sourceUrl.port, 10) + 5)
        : ""),
    path: pathname + (pathname.endsWith("/") ? "" : "/") + "socket.io",
  };
}

type MBProSocketProviderProps = PropsWithChildren<{
  baseUrl: string;
  options?: Omit<Partial<ManagerOptions & SocketOptions>, "path" | "auth" | "transports">;
}>;

export default function MBProSocketProvider(props: MBProSocketProviderProps) {
  const { children, baseUrl, options } = props;
  const [sid] = useUserInfo();

  const socketProps = useMemo(() => {
    const { url, path } = getSocketOptions(baseUrl);
    return {
      url,
      options: {
        path,
        auth: { sid },
        // Proxying requests seems to require the "polling" transport
        transports: ["websocket"],
        ...options,
      },
    };
  }, [baseUrl, options, sid]);

  return (
    <SocketIOProvider {...socketProps} enabled={!!sid}>
      {children}
    </SocketIOProvider>
  );
}

import { VictoryLabel } from "victory";

const CustomLabel = ({ index, data, ...restProps }: any) => {
  return (
    <VictoryLabel
      {...restProps}
      dx={data[index]?.symbol?.fill === "none" ? -20 : 0}
    />
  );
};

export default CustomLabel;

import { Button, ButtonProps, styled } from "@mui/material";
import { ReactNode } from "react";

const MbProFilterButton = styled(
  (
    props: ButtonProps & {
      children?: ReactNode;
    }
  ) => {
    const { children, ...rest } = props;
    return (
      <Button {...rest}>
        {children}
        <span id="MbPro-outline"></span>
      </Button>
    );
  },
  { shouldForwardProp: (prop) => prop !== "active" && prop !== "emphasize" }
)<ButtonProps & { active?: boolean; emphasize?: boolean }>(
  ({ theme, active, emphasize }) => ({
    textTransform: "none",
    minWidth: "200px",
    borderRadius: 4,
    padding: "7px 14px 7px 14px",
    alignSelf: "center",
    border: "hidden",
    justifyContent: "space-between",
    margin: "8px",
    color: theme.palette.primary.main,
    ...(emphasize && {
      color: theme.palette.info.main,
    }),
    borderColor: "rgba(0, 0, 0, 0.2)",
    backgroundColor: theme.palette.common.white,
    "& .MuiButton-endIcon": {
      margin: 0,
    },
    "& .MuiButton-endIcon, & .MuiIcon-root": {
      fontSize: "24px",
      display: "flex",
      aligItems: "center",
    },
    "&:hover": {
      border: "hidden",
      backgroundColor: theme.palette.common.white,
    },
    "&:hover > #MbPro-outline": {
      borderColor: theme.palette.primary.main,
      transition: theme.transitions.create(["border"]),
      ...(active && {
        border: `2px solid ${theme.palette.primary.main}`,
      }),
      ...(emphasize && {
        border: `2px solid ${theme.palette.info.main}`,
      }),
    },
    "& > #MbPro-outline": {
      position: "absolute",
      width: "100%",
      height: "100%",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: "inherit",
      left: 0,
      transition: theme.transitions.create(["border"]),
      ...(active && {
        border: `2px solid ${theme.palette.primary.main}`,
      }),
      ...(emphasize && {
        border: `2px solid ${theme.palette.info.main}`,
      }),
    },
  })
);

export default MbProFilterButton;

import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Button, ButtonProps } from "@mui/material";
import { Row } from "react-table";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { ReactElement, ReactNode } from "react";
import { useIntl } from "react-intl";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  height: "100%",
});

interface LinkButtonProps<T extends {}> {
  row?: Row<T>;
  url?: string;
  icon?: ReactElement;
  message?: ReactNode;
  onClick?: () => void;
}

const LinkButton = <T, C extends React.ElementType>({
  row,
  url,
  icon,
  message,
  onClick,
  ...props
}: LinkButtonProps<T> & ButtonProps<C, { component?: C }>) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      {...(!onClick && {
        component: StyledLink,
        to: `${url}`,
      })}
      onClick={onClick}
      key={row ? row.getRowProps().key + "linkButton" : "linkButton"}
      size="small"
      style={{ height: "24px", borderRadius: 0 }}
      startIcon={icon ?? <EditTwoToneIcon fontSize="inherit" />}
      {...props}
    >
      {message ??
        formatMessage({
          defaultMessage: "Edit",
          description: "LinkButton Edit",
        })}
    </Button>
  );
};

export default LinkButton;

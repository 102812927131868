import { useGetAll } from "@mb-pro-ui/utils";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { CustomerGroup, EmailRecipient } from "@mb-pro-ui/utils/types/alarm";
import { AllEntities } from "../settings/utils";
import { useTheme } from "@mui/system";
import EmailRecipientCreateModal from "./EmailRecipientCreateModal";


const EmailRecipientList = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const queryResult = useGetAll<EmailRecipient>("alarm/email-recipients", {
    page: {
      limit: 10000,
    },
    sort: ["-active", "id"],
    fields: {
        "customer-groups": ["description"],
    },
    include: {
        "customer-group": {},
    }
  });

  const columns = useMemo(
    (): Column<EmailRecipient>[] => [
      {
        id: "name",
        width: 200,
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (recipient) => recipient.name,
        Cell: ({ row, value }: Cell<EmailRecipient>) => {
          const t = row.original;          
          let sx = t.active ? undefined : {color: theme.palette.grey[500], fontStyle: "italic"};
          return <Typography sx={sx}>{value}</Typography>
        },
      },
      {
        id: "email",
        width: 200,
        Header: formatMessage({
          defaultMessage: "Email",
        }),
        accessor: (recipient) => recipient.email,
        Cell: ({ row, value }: Cell<EmailRecipient>) => {
          const t = row.original;          
          let sx = t.active ? undefined : {color: theme.palette.grey[500], fontStyle: "italic"};
          return <Typography sx={sx}>{value}</Typography>
        },
      },
      {
        id: "customer-group",
        width: 140,
        Header: formatMessage({
          defaultMessage: "Customer group",
        }),
        accessor: (recipient) => (recipient["customer-group"] as CustomerGroup)?.description || "",
      },
    ],
    [formatMessage, theme]
  );

  return (
    <>
    <AllEntities
      columns={columns}
      title={<FormattedMessage defaultMessage="Email recipients" />}
      queryResult={queryResult}            
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <EmailRecipientCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
    </>
  );
};

export default EmailRecipientList;

import { Category as _Category, Icon } from "@mb-pro-ui/utils/types/alarm";
import { Box, MenuItemProps, Tooltip } from "@mui/material";
import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";

import { Select } from "@mb-pro-ui/components/form";

const getMenuItemProps = (option: Icon, index: number): MenuItemProps => {
  if (index === 0) {
    return {
      style: {
        gridColumnStart: 1,
        gridColumnEnd: 6,
        justifySelf: "stretch",
      },
    };
  }
  return {};
};

const IconSelect = ({ name, label }: { name?: string; label?: string }) => {
  const { formatMessage } = useIntl();
  const { data: icons } = useGetAll<Icon>("alarm/icons");

  return (
    <Select<"id", Icon>
      name={name ?? `icon`}
      MenuProps={{
        MenuListProps: {
          style: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          },
        },
      }}
      getMenuItemProps={getMenuItemProps}
      outerSx={{
        minWidth: "100px",
        width: "120px",
        margin: "8px",
        marginRight: 5,
      }}
      sx={{
        height: "72px",
      }}
      label={label ?? formatMessage({ defaultMessage: "Icon" })}
      items={
        icons
          ? [
              {
                // provide a fake id to generate a valid key prop
                id: "none",
              } as unknown as Icon,
              ...icons,
            ]
          : []
      }
      parse={(value: string) =>
        value === "none" ? (null as unknown as string) : value
      }
      renderOption={({ option: icon }) => {
        const title = icon.width
          ? `${icon.width}px x ${icon.height}px`
          : formatMessage({ defaultMessage: "Unknown" });

        if (icon.id === "none") {
          return (
            <Box sx={{ textAlign: "center", width: "100%" }}>
              {formatMessage({ defaultMessage: "None" })}
            </Box>
          );
        }

        return (
          <Tooltip title={title} disableInteractive>
            <img
              src={icon?.icon ?? undefined}
              alt="icon"
              style={{ width: "32px", height: "32px" }}
            />
          </Tooltip>
        );
      }}
      valueKey={"id"}
    />
  );
};

export default IconSelect;

import { SyntheticEvent, useEffect, useState } from "react";

import {
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";

import { Button, FormDialog } from "@mb-pro-ui/components";
import { Autocomplete } from "@mb-pro-ui/components/form";

import { Form, FormSpy, AnyObject, useFormState } from "react-final-form";

import {
  FieldArray as FinalFormFieldArray,
  useFieldArray,
} from "react-final-form-arrays";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useGetAll } from "@mb-pro-ui/utils";

import {
  NavLink,
  NavLinkProps,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { StyledTextField } from "../StyledComponents";
import Widget from "../../utils/Widget";
import {
  getAvailableDescriptions,
  getAvailableChannels,
  notifiedChannel,
  notifiedChannelToName,
  notifiedType,
  notifiedChannelFromName,
} from "./helpers";

import {
  Subaction,
  NotifiablePerson,
  Installer,
  Guard,
  Authority,
} from "../../customer/types";
import { useIntl } from "react-intl";

import range from "lodash/range";

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(
  () => ({
    textDecoration: "none",
    color: "inherit",
  })
);

interface UrlParams {
  id: string;
  value: string;
  groupCode: string;
}
interface ActionPlanSubFormDialogProps {
  url: string;
}

export const ActionPlanSubFormDialog = ({
  url,
}: ActionPlanSubFormDialogProps) => {
  let submit: Submit = null;

  const [contactType, setContactType] = useState("0");

  const history = useHistory();

  const { formatMessage } = useIntl();

  const {
    params: { value, groupCode, id },
  } = useRouteMatch<UrlParams>();

  const {
    fields: { push },
  } = useFieldArray(`action-groups[${value}].subactions`);

  const { data: installers, isLoading: areInstallersLoading } =
    useGetAll<Installer>("alarm/installers", { refetchOnWindowFocus: false });

  const { data: guards, isLoading: areGuardsLoading } = useGetAll<Guard>(
    "alarm/guards",
    { refetchOnWindowFocus: false }
  );

  const { data: authorities, isLoading: areAuthoritiesLoading } =
    useGetAll<Authority>("alarm/authorities", {
      refetchOnWindowFocus: false,
    });

  const { data: notifiablePersons, isLoading: areNotifiablePersons } =
    useGetAll<NotifiablePerson>("alarm/notifiable-persons", {
      refetchOnWindowFocus: false,
      filter: { customer: { eq: id } },
    });

  const onActionPlanDialogAction = (
    _: {},
    action: "close" | "cancel" | "confirm"
  ) => {
    switch (action) {
      case "close":
        history.replace(url);
        break;
      case "cancel":
        history.replace(url);
        break;
      case "confirm":
        if (submit) submit();
        history.replace(url);
        break;
    }
  };

  const onSubmit = (newSubtask: any) => {
    push({
      authority: contactType === "2" ? newSubtask.contact : null,
      channel: notifiedChannelFromName(newSubtask.channel),
      code: "C",
      description:
        newSubtask.description === "Egyéb"
          ? newSubtask["description-text"]
          : newSubtask.description,
      guard: contactType === "1" ? newSubtask.contact : null,
      installer: contactType === "3" ? newSubtask.contact : null,
      "notifiable-person": contactType === "0" ? newSubtask.contact : null,
      ord: 0,
      "ref-type": parseInt(contactType),
      type: "subactions",
    });
  };

  return (
    <FormDialog
      title={formatMessage({
        defaultMessage: "New Sub-task",
      })}
      open={true}
      onDialogAction={onActionPlanDialogAction}
    >
      <Widget
        isLoading={
          areAuthoritiesLoading ||
          areGuardsLoading ||
          areInstallersLoading ||
          areNotifiablePersons
        }
        sx={{
          backgroundColor: "inherit",
          border: "none",
          boxShadow: "none",
          minHeight: "100px",
        }}
        children={
          guards && authorities && installers && notifiablePersons ? (
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => {
                submit = handleSubmit;
                return (
                  <form>
                    <FormSpy subscription={{ values: true }}>
                      {(props) => {
                        return (
                          <>
                            {groupCode === "0" ? (
                              <FormControl
                                component="fieldset"
                                style={{ marginTop: "5%" }}
                              >
                                <RadioGroup
                                  name="contact-type"
                                  value={contactType}
                                  onChange={(e) =>
                                    setContactType(e.target.value)
                                  }
                                >
                                  <div>
                                    <FormControlLabel
                                      value="0"
                                      control={<Radio />}
                                      label="Ügyfél"
                                    />
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio />}
                                      label="Kivonuló"
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio />}
                                      label="Hatóságok"
                                    />
                                    <FormControlLabel
                                      value="3"
                                      control={<Radio />}
                                      label="Telepítő"
                                    />
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            ) : null}
                            {(contactType === "0" || groupCode === "1") &&
                            groupCode !== "2" &&
                            groupCode !== "3" &&
                            groupCode !== "4" ? (
                              <Autocomplete
                                name="contact"
                                label={formatMessage({
                                  defaultMessage: "Contacts",
                                })}
                                options={notifiablePersons}
                                getOptionLabel={(option) => option.name ?? ""}
                                fullWidth
                              />
                            ) : null}
                            {contactType === "1" || groupCode === "2" ? (
                              <Autocomplete
                                name="contact"
                                label="Kivonulók"
                                options={guards}
                                getOptionLabel={(option) => option.name ?? ""}
                                fullWidth
                              />
                            ) : null}
                            {contactType === "2" || groupCode === "3" ? (
                              <Autocomplete
                                name="contact"
                                label={formatMessage({
                                  defaultMessage: "Authorities",
                                })}
                                options={authorities}
                                getOptionLabel={(option) => option.name ?? ""}
                                fullWidth
                              />
                            ) : null}
                            {contactType === "3" || groupCode === "4" ? (
                              <Autocomplete
                                name="contact"
                                label={formatMessage({
                                  defaultMessage: "Installers",
                                })}
                                options={installers}
                                getOptionLabel={(option) => option.name ?? ""}
                                fullWidth
                              />
                            ) : null}
                            <Autocomplete
                              name="channel"
                              label={formatMessage({
                                defaultMessage: "Channels",
                              })}
                              options={getAvailableChannels(
                                props.values?.contact
                              )}
                              fullWidth
                            />
                            <Autocomplete
                              name="description"
                              label={formatMessage({
                                defaultMessage: "Description",
                              })}
                              options={getAvailableDescriptions(
                                props.values?.channel
                              )}
                              fullWidth
                              freeSolo
                            />
                            {props.values?.description === "Egyéb" ? (
                              <StyledTextField
                                name="description-text"
                                label=""
                                sx={{
                                  margin: 0,
                                  marginTop: "8px",
                                  width: "100%",
                                }}
                              />
                            ) : null}
                          </>
                        );
                      }}
                    </FormSpy>
                  </form>
                );
              }}
            </Form>
          ) : null
        }
      />
    </FormDialog>
  );
};

interface ActionPlansSubFormProps {
  name: string;
  i: number;
  groupCode: number;
  readOnly?: boolean;
}

const ActionPlansSubForm = ({
  name,
  i,
  groupCode,
  readOnly,
}: ActionPlansSubFormProps) => {
  const { url } = useRouteMatch();

  const { formatMessage } = useIntl();

  const [deletedItems, setDeletedItems] = useState<any[]>([]);

  const { submitting } = useFormState();

  const { fields } = useFieldArray(`${name}[${i}].subactions`);

  const moveUp = (j: number) => {
    if (j !== 0) {
      fields.swap(j, j - 1);
    }
  };

  const moveDown = (length: number | undefined, j: number) => {
    if (length !== j + 1) {
      fields.swap(j, j + 1);
    }
  };

  const onDelete = (index: number, values: any, ord: number) => {
    fields.remove(index);
    if (values.id) {
      setDeletedItems([...deletedItems, { ...values, ind: ord }]);
    }
  };

  useEffect(() => {
    if (submitting) {
      setDeletedItems([]);
    }
  }, [setDeletedItems, submitting]);

  return (
    <FinalFormFieldArray name={`${name}[${i}].subactions`}>
      {({ fields: subFields }) => {
        const newFields = subFields.value ? [...subFields.value] : [];

        const arrayLength = (subFields?.length ?? 0) + deletedItems.length;
        return (
          <List>
            {range(arrayLength).map((j) => {
              if (deletedItems.some((item) => item.ind === j)) {
                const deletedItem = deletedItems.find((item) => item.ind === j);
                return (
                  <ListItem
                    key={`actiongroup.${i}.${j}`}
                    sx={{
                      border: "1px solid",
                      borderColor: "primary.main",
                      padding: "0",
                      marginLeft: "10%",
                      marginTop: "2px",
                      "&.MuiListItem-root": {
                        width: "80%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                      }}
                    >
                      <IconButton
                        size="large"
                        sx={{
                          padding: 1,
                        }}
                      >
                        <KeyboardArrowUpIcon
                          sx={{
                            color: "grey.300",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        size="large"
                        sx={{
                          padding: 1,
                        }}
                      >
                        <ExpandMoreIcon
                          sx={{
                            color: "grey.300",
                          }}
                        />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: "10%",
                      }}
                    >
                      <Typography
                        style={{
                          marginLeft: "-10px",
                          textDecoration: "line-through",
                        }}
                      >
                        {deletedItem.description}
                      </Typography>
                      <Typography sx={{ textDecoration: "line-through" }}>
                        {deletedItem[notifiedType(deletedItem["ref-type"])]
                          ?.name ??
                          formatMessage({
                            defaultMessage: "Missing notifiable",
                          })}
                      </Typography>

                      <Typography sx={{ textDecoration: "line-through" }}>
                        {deletedItem.channel !== "O"
                          ? `${notifiedChannelToName(
                              deletedItem.channel,
                              formatMessage
                            )}${
                              (
                                deletedItem[
                                  notifiedType(deletedItem["ref-type"])
                                ] as any
                              )?.[notifiedChannel(deletedItem.channel)] ?? ""
                            }`
                          : formatMessage({ defaultMessage: "Other channel" })}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              } else {
                const fieldValue = newFields.shift() as Subaction;

                const fieldsCount =
                  arrayLength - newFields.length - 1 - deletedItems.length;

                return (
                  <ListItem
                    key={`actiongroup.${i}.${j}`}
                    sx={{
                      border: "1px solid",
                      borderColor: "primary.main",
                      padding: "0",
                      marginLeft: "10%",
                      marginTop: "2px",
                      "&.MuiListItem-root": {
                        width: "80%",
                      },
                    }}
                  >
                    {readOnly ? null : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }}
                      >
                        <IconButton
                          onClick={() => moveUp(fieldsCount)}
                          size="large"
                          sx={{
                            padding: 1,
                          }}
                        >
                          {fieldsCount === 0 ? (
                            <KeyboardArrowUpIcon
                              sx={{
                                color: "grey.300",
                              }}
                            />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            moveDown(subFields?.length, fieldsCount)
                          }
                          size="large"
                          sx={{
                            padding: 1,
                          }}
                        >
                          {subFields?.length === fieldsCount + 1 ? (
                            <ExpandMoreIcon
                              sx={{
                                color: "grey.300",
                              }}
                            />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}
                    <Box
                      sx={{
                        marginLeft: "10%",
                      }}
                    >
                      <Typography style={{ marginLeft: "-10px" }}>
                        {fieldValue.description}
                      </Typography>
                      <Typography>
                        {fieldValue[notifiedType(fieldValue["ref-type"])]
                          ?.name ??
                          formatMessage({
                            defaultMessage: "Missing notifiable",
                          })}
                      </Typography>

                      {fieldValue.channel !== "O"
                        ? `${notifiedChannelToName(
                            fieldValue.channel,
                            formatMessage
                          )}${
                            (
                              fieldValue[
                                notifiedType(fieldValue["ref-type"])
                              ] as any
                            )?.[notifiedChannel(fieldValue.channel)] ?? ""
                          }`
                        : formatMessage({ defaultMessage: "Other channel" })}
                    </Box>
                    {readOnly ? null : (
                      <IconButton
                        onClick={() => onDelete(fieldsCount, fieldValue, j)}
                        size="large"
                        sx={{
                          color: "error.main",
                          marginLeft: "auto",
                          marginRight: "20px",
                          padding: 1,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItem>
                );
              }
            })}
            {readOnly ? null : (
              <StyledNavLink to={`${url}/${name}/${groupCode}/${i}/new`}>
                <Button
                  mode="secondary"
                  sx={{
                    marginLeft: "50%",
                    marginTop: "2%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {formatMessage({
                    defaultMessage: "New Sub-task",
                  })}
                </Button>
              </StyledNavLink>
            )}
          </List>
        );
      }}
    </FinalFormFieldArray>
  );
};

export default ActionPlansSubForm;

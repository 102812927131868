import { encode } from "js-base64";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useMutation, UseMutationResult } from "react-query";
import { HttpError, useApi } from "../query";
import { UserInfo, useUserInfo } from "../state/user-info";

interface SigninOptions {
  username: string;
  password: string;
}

interface Auth {
  user: UserInfo | null;
  signin: UseMutationResult<string, HttpError, SigninOptions>;
  signout: UseMutationResult<void, HttpError, void>;
}

const AuthContext = createContext<Auth | undefined>(undefined);

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [user, setUser] = useUserInfo();

  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onSigninSuccess = useCallback(
    (token: UserInfo, _user: SigninOptions) => {
      if (mounted.current) {
        setUser(token);
      }
    },
    [setUser, mounted]
  );

  const onSignoutSettled = useCallback(() => {
    if (mounted.current) {
      setUser(null);
    }
  }, [setUser, mounted]);

  const api = useApi();

  const loginApi = useCallback(
    ({ username, password }: SigninOptions) =>
      api<string>("/auth/login", {
        method: "post",
        headers: {
          Authorization: `Basic ${encode(username + ":" + password)}`,
          Accept: "application/vnd.api+json, application/json, text/plain",
        },
      }),
    [api]
  );

  const logoutApi = useCallback(() => api<void>("/auth/logout", { method: "post" }), [api]);

  const signin = useMutation<string, HttpError, SigninOptions>(loginApi, {
    onSuccess: onSigninSuccess,
  });

  const signout = useMutation<void, HttpError, void>(logoutApi, {
    onSettled: onSignoutSettled,
  });

  const value = useMemo(
    () => ({
      user,
      signin,
      signout,
    }),
    [user, signin, signout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return auth;
}

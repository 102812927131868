import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputBase, inputBaseClasses, styled } from "@mui/material";
import { useIntl } from "react-intl";

const StyledInput = styled(InputBase)(({ theme }) => ({
  input: {
    borderRadius: 4,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: "1px solid #ced4da",
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.action.focus,
      boxShadow: `0 0 5px 0.2rem ${theme.palette.action.focus}`,
    },
    padding: "5px 5px 5px 8px",
    width: "150px",
    position: "relative",
    paddingRight: "32px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    textAlign: "left",
  },
}));

export interface GlobalFilterProps {
  globalFilter: string | undefined;
  setGlobalFilter: (filterValue: string | undefined) => void;
  placement?: "left" | "right";
}

const inputSelector = `& .${inputBaseClasses.input}`;

const GlobalFilter = ({ globalFilter, setGlobalFilter, placement }: GlobalFilterProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormControl
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px",
        display: "flex",
        marginRight: "24px",
        zIndex: 1,
        width: "unset",
        minWidth: "unset",
        ...(placement ? { [placement]: 7 } : {}),
      })}
    >
      <StyledInput
        value={globalFilter ?? ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder={formatMessage({
          id: "label",
          defaultMessage: "Filter results",
          description: "Enhanced Table Globalfilter label",
        })}
        endAdornment={
          <SearchIcon color="primary" sx={{ position: "absolute", right: 7 }} fontSize="small" />
        }
        sx={(theme) => ({
          cursor: "auto",
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          [inputSelector]: {
            transition: theme.transitions.create("width", {
              duration: theme.transitions.duration.shorter,
            }),
            width: 96,
          },
          [`${inputSelector}:focus`]: {
            width: 168,
            backgroundColor: theme.palette.background.paper,
          },
          [`${inputSelector}:not(:focus)::-webkit-input-placeholder`]: {
            opacity: "0 !important",
          },
          [`${inputSelector}:not(:focus)::-moz-placeholder`]: {
            opacity: "0 !important",
          },
          [`${inputSelector}:not(:focus)::-ms-input-placeholder`]: {
            opacity: "0 !important",
          },
        })}
      />
    </FormControl>
  );
};

export default GlobalFilter;

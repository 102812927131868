import { StyledTextField } from "../form/StyledComponents";

import FormLayout from "../form/FormLayout";

import SimpleFieldArray from "../form/SimpleFieldArray";

import { Box } from "@mui/system";
import { useIntl } from "react-intl";

const UsersDialogRenderer = () => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledTextField
        name="number"
        label={formatMessage({
          defaultMessage: "Number",
        })}
        inputProps={{ maxLength: 3 }}
        required
      />
      <StyledTextField
        name="name"
        label={formatMessage({
          defaultMessage: "Name",
        })}
      />
      <StyledTextField
        name="role"
        label={formatMessage({
          defaultMessage: "Role",
          description: "Ügyfélkezelő szerepkör",
        })}
      />
      <StyledTextField
        name="phone"
        label={formatMessage({
          defaultMessage: "Phone",
        })}
      />
    </Box>
  );
};

interface UsersProps {
  readOnly?: boolean;
}

const Users = ({ readOnly }: UsersProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <SimpleFieldArray
          name="users"
          label={formatMessage({
            defaultMessage: "Users",
          })}
          labels={{
            number: formatMessage({
              defaultMessage: "Number",
            }),
            name: formatMessage({
              defaultMessage: "Name",
            }),
            role: formatMessage({
              defaultMessage: "Role",
              description: "Ügyfélkezelő szerepkör",
            }),
            phone: formatMessage({
              defaultMessage: "Phone",
            }),
          }}
          formDialogRenderer={UsersDialogRenderer}
          initialValues={{ number: "001" }}
          readOnly={readOnly}
        />
      }
    />
  );
};

export default Users;

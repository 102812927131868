import { Paper } from "@mui/material";
import { Box } from "@mui/system";

import { StyledTextField } from "../form/StyledComponents";

import FieldArray from "../form/FieldArray";
import FormLayout from "../form/FormLayout";
import { useIntl } from "react-intl";

const EventCancellation = () => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledTextField
        name="zone"
        label={formatMessage({
          defaultMessage: "Zone",
        })}
        required
      />
      <StyledTextField
        name="start-event-code"
        label={formatMessage({
          defaultMessage: "Alert event code",
        })}
        parse={(x) => x}
      />
      <StyledTextField
        name="stop-event-code"
        label={formatMessage({
          defaultMessage: "Restore event code",
        })}
        parse={(x) => x}
      />
      <StyledTextField
        name="interval-length"
        label={formatMessage({
          defaultMessage: "Interval",
        })}
        parse={(x) => x}
      />
    </Box>
  );
};

interface EventCancellationFormProps {
  readOnly?: boolean;
}

const EventCancellationForm = ({ readOnly }: EventCancellationFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <Paper>
          <FieldArray
            name="event-storno"
            label={formatMessage({
              defaultMessage: "Event cancellation",
            })}
            formDialogHeaderNew={formatMessage({
              defaultMessage: "New event cancellation",
            })}
            formDialogHeaderEdit={formatMessage({
              defaultMessage: "Edit event cancellation",
            })}
            labels={{
              "start-event-code": formatMessage({
                defaultMessage: "Alert event code",
              }),
              "stop-event-code": formatMessage({
                defaultMessage: "Restore event code",
              }),
              zone: formatMessage({
                defaultMessage: "Zone",
              }),
              "interval-length": formatMessage({
                defaultMessage: "Interval",
                description: "Customer editor, event cancellation",
              }),
            }}
            formDialogRenderer={EventCancellation}
            initialValues={{
              "interval-length": "00:05:00",
              zone: "%",
              "start-event-code": "%",
              "stop-event-code": "%",
            }}
            readOnly={readOnly}
          />
        </Paper>
      }
    />
  );
};

export default EventCancellationForm;

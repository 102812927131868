import { Box, SvgIcon, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { useParams, Redirect } from "react-router-dom";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { localDescr } from "../../../utils/LocaleUtils";
import { Lang } from "../../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { ZoneType as _ZoneType } from "@mb-pro-ui/utils/types/alarm";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { Form } from "react-final-form";
import { TextField } from "@mb-pro-ui/components/form";

type ZoneType = _ZoneType;

interface FormValues extends Pick<ZoneType, "id" | "descr"> {}

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Zone type editor"
        description="System settings page sidebar zone type editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

const postLoadFormat = ({ id, descr }: ZoneType): FormValues => ({
  id,
  descr,
});

const ZoneTypeUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: zoneType,
    refetch,
    isLoading,
    isError,
  } = useGetOne<ZoneType>("alarm/zone-types", id);
  const { locale } = useLocale();
  const { waitFor } = useBackendSchemas();
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    setSnackbarState,
    refetch,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/sensor-types",
    {
      refetch,
      setSnackbarState,
    }
  );

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const descr = zoneType?.descr
    ? localDescr(zoneType?.descr, Lang[locale])
    : "";
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/zone-types/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff(
        "zone-types",
        zoneType as unknown as Record<string, unknown>,
        values,
        { schemas }
      ),
    ]);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  if (isError) {
    return <Redirect to="/alarm/settings/sensor-types" />;
  }

  return (
    <Page
      backLink="/alarm/settings/zone-types"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={zoneType && postLoadFormat(zoneType)}
          render={({ handleSubmit, submitting, pristine, form }) => {
            return (
              <SubPage
                prefix={prefix}
                sx={{ minWidth: "500px" }}
                innerSx={{ height: "auto" }}
              >
                <StyledForm onSubmit={handleSubmit}>
                  <TextField
                    name={`descr.[${Lang[locale]}]`}
                    label={formatMessage(
                      { defaultMessage: "Name ({locale})" },
                      { locale }
                    )}
                    required
                    requiredError={formatMessage({
                      defaultMessage: "Required",
                    })}
                    disabled={submitting || isLoading || isDeleting}
                  />
                  <UpdateFormActions
                    onSave={handleSubmit}
                    onReset={() => form.reset()}
                    onDelete={handleDeleteClick}
                    disabledDelete={submitting}
                    disabledReset={submitting || pristine}
                    disabledSave={submitting || pristine}
                    isLoading={isLoading}
                    isDeleting={isDeleting}
                  />
                </StyledForm>
              </SubPage>
            );
          }}
        />
      </Box>
      <DeleteDialog
        deleteResource={del}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        resourceId={id}
        resourceName={formatMessage(
          { defaultMessage: "{name}" },
          {
            name: zoneType?.descr
              ? localDescr(zoneType.descr, Lang[locale])
              : "",
          }
        )}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default ZoneTypeUpdatePage;

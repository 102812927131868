import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  DrawerProps,
  IconButton,
  lighten,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { ReactChild, ReactNode } from "react";
import { Route, Switch } from "react-router";
import { NavLink, NavLinkProps, useRouteMatch } from "react-router-dom";

type StyleProps = {
  anchor?: "left" | "right";
  width: number | undefined;
};

export type SideBarOption = {
  name: ReactNode;
  link: string;
  icon?: ReactChild;
  classname?: string;
  absolute?: boolean;
  subPages?: {
    name: ReactNode;
    link: string;
    classname?: string;
    icon?: ReactChild;
    absolute?: boolean;
  }[];
};

type SideBarProps = {
  options: SideBarOption[];
  open?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
  onSidebarOpen?: () => void;
  onSidebarClose?: () => void;
  header?: ReactNode;
  footer?: ReactNode;
  backLink?: string;
  closedWidth?: number;
} & StyleProps;

const classes = {
  active: `NavLink-active`,
};

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
  [`&.${classes.active}`]: {
    "& > div": {
      backgroundColor: lighten(theme.palette.primary.light, 0.7),
      boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
    },
    "& > div > div": {
      color: theme.palette.primary.main,
    },
    "& > div:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.7),
    },
  },
}));

const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "anchor" })<{
  anchor: "left" | "right" | undefined;
}>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SidebarIcon = styled(ListItemIcon)(() => ({
  ".MuiListItemIcon-root": {
    minWidth: "30px",
    marginRight: "10px",
  },
}));

const SidebarText = styled((props) => (
  <ListItemText primaryTypographyProps={{ noWrap: true }} {...props} />
))<ListItemTextProps>(() => ({
  ".MuiListItemText-primary": {
    color: "inherit",
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "width" && prop !== "closedWidth",
})<DrawerProps & { width: number | undefined; closedWidth: number | undefined }>(
  ({ open, width, closedWidth }) => ({
    "& .MuiDrawer-paper": {
      width: open ? width : `${closedWidth}px`,
      overflowX: "hidden",
    },
  })
);

const SideBar = ({
  options,
  footer,
  width,
  anchor,
  open,
  handleClose,
  handleOpen,
  onSidebarOpen,
  onSidebarClose,
  header,
  backLink,
  closedWidth,
}: SideBarProps) => {
  const { url, path } = useRouteMatch();
  const theme = useTheme();

  const handleSidebarCloseClick = () => {
    handleClose?.();
    onSidebarClose?.();
  };

  const handleSidebarOpenClick = () => {
    handleOpen?.();
    onSidebarOpen?.();
  };

  return (
    <Drawer
      anchor={anchor ?? "left"}
      variant="permanent"
      open={open}
      width={width}
      closedWidth={closedWidth}
    >
      <Toolbar />
      <DrawerHeader anchor={anchor}>
        {open ? (
          <>
            {header || (
              <Typography variant="h6" sx={{ padding: "0 0 0 16px" }} color="primary">
                Monitoringbook <span style={{ color: `${theme.palette.text.primary}` }}>Pro</span>
              </Typography>
            )}
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={handleSidebarCloseClick}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Box sx={{ paddingLeft: "4px" }}>
            <IconButton color="inherit" onClick={handleSidebarOpenClick}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          height: "100%",
        }}
      >
        <Switch>
          {options.map((option, i) =>
            option.subPages ? (
              <Route
                // exact
                path={option.subPages.map((op) => `${path}${op.link}`)}
                key={`${option.name}`}
              >
                <StyledNavLink exact key={i} to={url} activeClassName={classes.active}>
                  <ListItem key={`${i}-item`}>
                    <SidebarIcon>
                      <ArrowBackIcon />
                    </SidebarIcon>
                    <SidebarText primary={"Vissza"} />
                  </ListItem>
                  <Divider />
                </StyledNavLink>
                {option.subPages.map(({ link, name, classname, icon, absolute }, index) => (
                  <StyledNavLink
                    // exact
                    key={`${index}.sublink`}
                    to={absolute ? `${url}` : `${url}${link}`}
                    className={clsx(classname)}
                    activeClassName={classes.active}
                  >
                    <ListItem button key={`${index}-navlink`}>
                      <SidebarIcon>{icon || <InboxIcon />}</SidebarIcon>
                      <SidebarText primary={name} />
                    </ListItem>
                  </StyledNavLink>
                ))}
              </Route>
            ) : null
          )}
          <Route exact path="">
            {backLink && (
              <StyledNavLink exact to={backLink} activeClassName={classes.active}>
                <ListItem>
                  <SidebarIcon>
                    <ArrowBackIcon />
                  </SidebarIcon>
                  <SidebarText primary="Vissza" />
                </ListItem>
                <Divider />
              </StyledNavLink>
            )}
            {options.map(({ link, classname, name, subPages, icon, absolute }, i) => (
              <StyledNavLink
                exact
                key={`${i}-navlink`}
                to={absolute ? `${link}` : `${url}${link}`}
                activeClassName={classes.active}
                className={clsx(classname)}
              >
                <ListItem
                  button
                  key={`${i}-listitem`}
                  sx={{
                    "&:hover": {
                      "& > svg": {
                        display: "block",
                      },
                    },
                  }}
                >
                  <SidebarIcon>{icon || <InboxIcon />}</SidebarIcon>
                  <SidebarText primary={name} />
                  {subPages ? (
                    <ArrowForwardIcon
                      sx={{
                        display: "none",
                        color: "grey.300",
                      }}
                    />
                  ) : null}
                </ListItem>
              </StyledNavLink>
            ))}
          </Route>
        </Switch>
      </List>
      {footer && (
        <Box
          sx={{
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            boxShadow: `3px 0px 0px ${theme.palette.primary.dark} inset`,
            justifySelf: "flex-end",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          }}
        >
          {footer}
        </Box>
      )}
    </Drawer>
  );
};

export default SideBar;

import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { FunctionComponent } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

interface CustomTabProps {
  value: string;
  title: string;
  tabProps: TabProps<"div", { component: typeof Link; to: string }>;
}

const CustomTab: FunctionComponent<CustomTabProps> = ({ tabProps, title }) => (
  <Tooltip title={title}>
    <Tab {...tabProps} />
  </Tooltip>
);

export interface LinkProp {
  href: string;
  label?: TabProps["label"];
  icon?: TabProps["icon"];
}

export interface MainMenuProps {
  links: LinkProp[];
  showLabels?: boolean;
  routes?: string[];
}

export default function MainMenu({ links, showLabels, routes }: MainMenuProps) {
  const routeMatch = useRouteMatch({ path: routes });
  const currentTab = routeMatch?.path;

  return (
    <Tabs
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "left",
        "& .MuiTabs-flexContainer": {
          height: "100%",
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "common.white",
        },
      }}
      value={currentTab}
      scrollButtons="auto"
      variant="scrollable"
    >
      {links
        ? links.map((link) => (
            <CustomTab
              tabProps={{
                label: showLabels ? link.label : null,
                icon: link.icon,
                value: link.href,
                sx: {
                  "& .MuiSvgIcon-root": {
                    fontSize: "2.5rem",
                  },
                  opacity: 1,
                },
                component: Link,
                to: link.href,
              }}
              // value needs to be present on direct children of <Tabs/> in order to use custom values instead of default index values
              value={link.href}
              title={String(link.label)}
              key={link.href}
            />
          ))
        : null}
    </Tabs>
  );
}

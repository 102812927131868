import React, { useState } from "react";
import moment from "moment";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  SxProps,
  Typography,
  lighten,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { TextField } from "@mb-pro-ui/components";
import { useCreate } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";

import { Intervention, Operator, Log } from "./types";
import { useErrorHandler } from "../../hooks";
import Widget from "../utils/Widget";

const OperatorName = styled("span", {
  shouldForwardProp: (prop) => prop !== "deleted" && prop !== "active",
})<{ deleted?: boolean; active?: boolean }>(({ deleted, active, theme }) => ({
  textDecoration: deleted ? "line-through" : "none",
  color: active ? "inherit" : theme.palette.grey[500],
}));

function operatorName(
  operator: Operator | undefined,
  formatMessage: IntlShape["formatMessage"]
) {
  const text = operator?.employee?.name || operator?.login;
  return text ? (
    <OperatorName deleted={operator.deleted} active={operator.active}>
      {text}
    </OperatorName>
  ) : (
    `[${formatMessage({
      defaultMessage: "Operator not found",
      description:
        "The operator of an intervention log entry is missing, it probably has been deleted",
    })}]`
  );
}

const LogItem = ({ log }: { log: Log }) => {
  const { formatMessage, formatDate } = useIntl();
  return (
    <ListItem disablePadding sx={{ paddingX: 2 }}>
      <ListItemText
        sx={{ whiteSpace: "pre-line" }}
        primaryTypographyProps={{
          component: "div",
          sx: { display: "flex", justifyContent: "space-between" },
        }}
        primary={
          <>
            <div>
              {formatDate(log.time, {
                ...(moment(log.time).isSame(moment.now(), "day")
                  ? {}
                  : {
                      hourCycle: "h24",
                      year: moment(log.time).isSame(moment.now(), "year")
                        ? undefined
                        : "numeric",
                      month: "long",
                      day: "2-digit",
                    }),
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </div>
            <div>{operatorName(log.session?.operator, formatMessage)}</div>
          </>
        }
        secondary={
          <>
            {log.automatic && <AutoBadge />} {log.text}
          </>
        }
      />
    </ListItem>
  );
};

const AutoBadge = () => (
  <Typography
    component="span"
    variant="caption"
    sx={{
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      borderRadius: "4px",
      padding: "0px 2px",
      marginRight: "2px",
    }}
  >
    auto
  </Typography>
);

export const LogWidget = ({
  id,
  isOwn,
  sx,
  intervention,
  refetchIntervention,
}: {
  id: string;
  isOwn?: boolean;
  sx: SxProps;
  intervention?: Intervention;
  refetchIntervention: () => Promise<any>;
}) => {
  const [input, setInput] = useState<string>("");
  const { formatMessage } = useIntl();
  const { mutate: createLog } = useCreate("alarm/log-all");
  const isOpen = intervention?.["close-time"] === null;

  const { JsonApiErrorSnackBar } = useErrorHandler();
  const [error, setError] = useState<JsonapiError | null>(null);
  const handleSnackBarClose = () => {
    setError(null);
  };
  const handleQueryError = (error: JsonapiError) => {
    setError(error);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === "Enter" || e.key === "NumpadEnter") &&
      !e.shiftKey &&
      !e.ctrlKey &&
      !e.metaKey
    ) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!input || !input.trim()) {
      return;
    }

    createLog(
      { text: input.trim(), intervention: { id } },
      {
        onSuccess: () => {
          refetchIntervention();
        },
        onError: handleQueryError,
      }
    );
    setInput("");
  };

  return (
    <>
      <Widget
        title={formatMessage({
          defaultMessage: "Logs",
          description: "Intervention page Logs widget title",
        })}
        postfix={
          !isOpen && (
            <Typography>
              {formatMessage({ defaultMessage: "Closed intervention" })}
            </Typography>
          )
        }
        sx={sx}
      >
        {isOpen && isOwn && (
          <Box display="flex" alignItems="center">
            <TextField
              label={
                <FormattedMessage
                  defaultMessage="Log entry"
                  description="Intervention page log input label"
                />
              }
              multiline
              maxRows={3}
              onKeyPress={handleInputKeyPress}
              onChange={handleInputChange}
              value={input}
              InputLabelProps={{ shrink: true }}
              sx={[
                {
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                  marginBottom: 0,
                  paddingRight: 0,
                  paddingLeft: 0,
                  "& > label": {
                    paddingRight: 0,
                    paddingLeft: 0,
                  },
                  "& > div": {
                    paddingLeft: 1,
                    paddingRight: 1,
                  },
                  flex: 1,
                },
                input && input.trim()
                  ? {
                      backgroundColor: (theme) =>
                        lighten(theme.palette.warning.light, 0.6),
                    }
                  : false,
              ]}
            />
            <IconButton
              size="large"
              sx={{ color: "primary.main" }}
              disabled={!input || !input.trim()}
            >
              <SendIcon />
            </IconButton>
          </Box>
        )}
        <List
          dense
          sx={{
            overflow: "auto",
          }}
        >
          {intervention?.logs?.map((_, index, logs) => (
            <React.Fragment key={logs[logs.length - index - 1].id}>
              <LogItem log={logs[logs.length - index - 1]} />
              {index < logs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Widget>
      <JsonApiErrorSnackBar error={error} onClose={handleSnackBarClose} />
    </>
  );
};

import { useGetAll } from "@mb-pro-ui/utils";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column } from "react-table";
import { SounderType } from "../types";
import { Typography } from "@mui/material";
import { AllEntities } from "../utils";
import SounderTypeCreateModal from "./SounderTypeCreateModal";

const AllSounderTypes = () => {
  const { formatMessage } = useIntl();
  const queryResult = useGetAll<SounderType>("alarm/sounder-types", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
  });

  const columns = useMemo(
    (): Column<SounderType>[] => [
      {
        id: "id",
        Header: formatMessage({
          defaultMessage: "ID",
        }),
        accessor: (sounder) => sounder.id,
      },
      {
        id: "description",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (sounder) => sounder.description,
      },
    ],
    [formatMessage]
  );

  const title = (
    <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
      <FormattedMessage defaultMessage="Sounder types" />
    </Typography>
  );

  return (
    <AllEntities
      columns={columns}
      title={title}
      queryResult={queryResult}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => {
        return (
          <SounderTypeCreateModal
            open={isFormOpen}
            onFormClose={onFormClose}
            refetch={refetch}
            setSnackbarState={setSnackbarState}
          />
        );
      }}
    />
  );
};

export default AllSounderTypes;

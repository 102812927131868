import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import {
  Box,
  Paper,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "./types";
import { ReactComponent as BasicIcon } from "../../icons/customer/BasicBlue.svg";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { useParams, Redirect } from "react-router-dom";
import { useMemo, useState } from "react";
import { Page, SubPage } from "@mb-pro-ui/components";
import { useLocale } from "../../locales/LocaleProvider";
import { Lang } from "../types";
import { localDescr } from "../../utils/LocaleUtils";
import { MapProfile as _MapProfile } from "@mb-pro-ui/utils/types/alarm";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "./utils";
import { Checkbox, NumericField, TextField } from "@mb-pro-ui/components/form";
import { Form } from "react-final-form";
import { colorToHex } from "./code-table/EventCategoryUpdatePage";
import ColorTextField from "./code-table/ColorTextField";
import { hexToColor } from "./code-table/EventCategoryCreateModal";
import IconSelect from "./code-table/IconSelect";
import HelpIcon from "@mui/icons-material/Help";

type MapProfile = _MapProfile;

type FormValues = Pick<
  MapProfile,
  | "id"
  | "descr"
  | "icon"
  | "base-icon"
  | "alarm-icon"
  | "track-icon"
  | "track-icon-rotated"
  | "line-icon"
  | "line-icon-rotated"
  | "line-icon-spacing"
  | "line-arrow"
> & {
  "line-color"?: string;
  "line-thickness": boolean;
  "line-style-dash-length": number | undefined;
  "line-style-dash-gap": number | undefined;
};

const StyledPaper = styled(Paper)(() => ({
  margin: "8px",
  padding: "8px",
}));

const intToHex = (int: number) => {
  const r = (int >> 16) & 0xff;
  const g = (int >> 8) & 0xff;
  const b = int & 0xff;
  return colorToHex({ r, g, b });
};

const hexToInt = (hex: string) => {
  const { r, g, b } = hexToColor(hex);
  const R = (r & 0xff) << 16;
  const G = (g & 0xff) << 8;
  const B = b & 0xff;
  return 0xff000000 | R | G | B;
};

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Map Profile Editor"
        description="System settings page sidebar map profile editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

const postLoadFormat = ({
  id,
  descr,
  "line-color": lineColor,
  icon,
  "alarm-icon": alarmIcon,
  "base-icon": baseIcon,
  "track-icon": trackIcon,
  "track-icon-rotated": trackIconRotated,
  "line-icon": lineIcon,
  "line-icon-rotated": lineIconRotated,
  "line-icon-spacing": lineIconSpacing,
  "line-arrow": lineArrow,
  "line-thickness": lineThickness,
  "line-style": lineStyle,
}: MapProfile): FormValues => {
  // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
  const strokeDasharray = lineStyle
    ?.replace(/ /g, "")
    .split(",")
    .map((num) => {
      return Number.isNaN(Number(num)) ? 0 : Number(num);
    });
  return {
    id,
    descr,
    "line-color": intToHex(lineColor),
    icon,
    "alarm-icon": alarmIcon,
    "base-icon": baseIcon,
    "track-icon": trackIcon,
    "track-icon-rotated": trackIconRotated,
    "line-icon": lineIcon,
    "line-icon-rotated": lineIconRotated,
    "line-icon-spacing": lineIconSpacing,
    "line-arrow": lineArrow,
    "line-thickness": !!lineThickness,
    "line-style-dash-length": strokeDasharray?.[0] ?? undefined,
    "line-style-dash-gap": strokeDasharray?.[1] ?? undefined,
  };
};

const preSaveFormat = ({
  "line-color": lineColor,
  "line-thickness": lineThickness,
  "line-style-dash-length": lineStyleDashLength,
  "line-style-dash-gap": lineStyleDashGap,
  ...rest
}: FormValues): Partial<MapProfile> => {
  return {
    ...(lineColor && { "line-color": hexToInt(lineColor) }),
    "line-thickness": lineThickness ? 1 : 0,
    "line-style": `${lineStyleDashLength}, ${lineStyleDashGap}`,
    ...rest,
  };
};

const MapProfilePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: mapProfile,
    isLoading,
    refetch,
    isError,
  } = useGetOne<MapProfile>("alarm/map-profiles", id);
  const { locale } = useLocale();

  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { waitFor } = useBackendSchemas();

  const descr = mapProfile?.descr
    ? localDescr(mapProfile?.descr, Lang[locale])
    : "";
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/map-profiles/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    refetch,
    setSnackbarState,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/map-profiles",
    {
      replaceTo: "/alarm/settings/map-profiles",
      setSnackbarState,
    }
  );

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff(
        "map-profiles",
        mapProfile as unknown as Record<string, unknown>,
        preSaveFormat(values),
        { schemas }
      ),
    ]);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  if (isError) {
    return <Redirect to="/alarm/settings/map-profiles" />;
  }

  return (
    <Page
      backLink="/alarm/settings/map-profiles"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      sx={{ alignItems: "flex-start" }}
      {...systemSettingsPageProps}
    >
      <Box sx={{ height: "100%" }}>
        <Form
          onSubmit={onSubmit}
          initialValues={mapProfile && postLoadFormat(mapProfile)}
          render={({ handleSubmit, submitting, pristine, form }) => {
            return (
              <>
                <SubPage
                  prefix={prefix}
                  sx={{ minWidth: "500px" }}
                  innerSx={{ height: "auto" }}
                >
                  <StyledForm>
                    <StyledPaper>
                      <Typography color="primary">
                        {formatMessage({
                          defaultMessage: "Base data",
                        })}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          name={`descr.[${Lang[locale]}]`}
                          label={formatMessage(
                            { defaultMessage: "Name ({locale})" },
                            { locale }
                          )}
                          disabled={submitting || isLoading || isDeleting}
                          required
                          requiredError={formatMessage({
                            defaultMessage: "Required",
                          })}
                        />
                        <ColorTextField name="line-color" />
                        <Tooltip
                          title={formatMessage({
                            defaultMessage: "Line and Svg icon color",
                          })}
                        >
                          <HelpIcon
                            color="primary"
                            fontSize="small"
                            sx={{ alignSelf: "flex-end", marginBottom: 1 }}
                          />
                        </Tooltip>
                      </Box>
                    </StyledPaper>
                    <StyledPaper>
                      <Typography color="primary">
                        {formatMessage({
                          defaultMessage: "Current position icons",
                        })}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <IconSelect />
                        <IconSelect
                          name="base-icon"
                          label={formatMessage({
                            defaultMessage: "Base icon",
                          })}
                        />
                        <IconSelect
                          name="alarm-icon"
                          label={formatMessage({
                            defaultMessage: "Alarm icon",
                          })}
                        />
                      </Box>
                    </StyledPaper>
                    <StyledPaper>
                      <Typography color="primary">
                        {formatMessage({
                          defaultMessage: "Previous positions icon",
                        })}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <IconSelect
                          name="track-icon"
                          label={formatMessage({
                            defaultMessage: "Track icon",
                          })}
                        />
                        <Checkbox
                          name={"track-icon-rotated"}
                          label={formatMessage({
                            defaultMessage: "Indicate heading",
                          })}
                          disabled={submitting}
                        />
                      </Box>
                    </StyledPaper>
                    <StyledPaper>
                      <Typography color="primary">
                        {formatMessage({
                          defaultMessage: "Connecting icon",
                        })}
                      </Typography>
                      <IconSelect
                        name="line-icon"
                        label={formatMessage({
                          defaultMessage: "Connecting icon",
                        })}
                      />
                      <Checkbox
                        name={"line-icon-rotated"}
                        label={formatMessage({
                          defaultMessage: "Indicate heading",
                        })}
                        disabled={submitting}
                      />
                      <NumericField
                        name="line-icon-spacing"
                        label={formatMessage({
                          defaultMessage: "Distance",
                        })}
                        sx={{ width: "100px", minWidth: "100px" }}
                      />
                    </StyledPaper>
                    <StyledPaper>
                      <Typography color="primary">
                        {formatMessage({
                          defaultMessage: "Connecting line",
                        })}
                      </Typography>
                      <Checkbox
                        name={"line-thickness"}
                        label={formatMessage({
                          defaultMessage: "Show line",
                        })}
                        disabled={submitting}
                      />
                      <Checkbox
                        name={"line-arrow"}
                        label={formatMessage({
                          defaultMessage: "Show line arrow",
                        })}
                        isEqual={(a, b) => {
                          if (a === null && b === false) {
                            return true;
                          }
                          return a === b;
                        }}
                        disabled={submitting}
                      />
                      <Box>
                        <NumericField
                          name="line-style-dash-length"
                          label={formatMessage({
                            defaultMessage: "Dash length (px)",
                          })}
                          sx={{ width: "110px", minWidth: "110px" }}
                          parse={(v) => (v > -1 ? v : 0)}
                        />
                        <NumericField
                          name="line-style-dash-gap"
                          label={formatMessage({
                            defaultMessage: "Dash gap (px)",
                          })}
                          sx={{ width: "110px", minWidth: "110px" }}
                          parse={(v) => (v > -1 ? v : 0)}
                        />
                      </Box>
                      <UpdateFormActions
                        onSave={handleSubmit}
                        onReset={() => form.reset()}
                        onDelete={() => setDeleteDialogOpen(true)}
                        disabledDelete={submitting}
                        disabledReset={submitting || pristine}
                        disabledSave={submitting || pristine}
                        isLoading={isLoading}
                        isDeleting={isDeleting}
                      />
                    </StyledPaper>
                  </StyledForm>
                </SubPage>
              </>
            );
          }}
        />
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={descr}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default MapProfilePage;

import { SyntheticEvent } from "react";

import {
  useHistory,
  useParams,
  useRouteMatch,
  generatePath,
} from "react-router-dom";

import { FormDialog } from "@mb-pro-ui/components";
import { useApi } from "@mb-pro-ui/utils";

import { AnyObject } from "final-form";
import { Form } from "react-final-form";

import { StyledTextField } from "../../form/StyledComponents";
import { accountType } from "../../form/Validators";
import { useIntl } from "react-intl";

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface CloningDialogProps {
  open: boolean;
  onDialogAction: (event: {}, action: "close" | "cancel" | "confirm") => void;
  handleError: (error: string) => void;
}

const CloningDialog = ({
  open,
  onDialogAction: onCloningDialogAction,
  handleError,
}: CloningDialogProps) => {
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const history = useHistory();

  const api = useApi();

  const clone = (itemId: string, cloneTarget: string) => {
    return api<any>("/actions/alarm/clone-customer", {
      method: "post",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
      body: JSON.stringify({
        oldId: Number(itemId),
        newAccount: cloneTarget,
      }),
    });
  };

  let submit: Submit = null;

  const onSubmit = async ({ account }: { account: string }) => {
    try {
      const res = await clone(id, account);
      const newID = await res.text();

      history.push(
        generatePath(path, {
          id: newID,
        })
      );
    } catch (e) {
      handleError(formatMessage({ defaultMessage: "Unsuccessful cloning" }));
    }
  };

  const onDialogAction = async (
    event: {},
    action: "close" | "cancel" | "confirm",
    valid: boolean
  ) => {
    if (action === "confirm" && submit) {
      if (!valid) return;
      submit();
    }
    onCloningDialogAction(event, action);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, valid }) => {
        submit = handleSubmit;
        return (
          <FormDialog
            title="Ügyfél klónozása"
            open={open}
            onDialogAction={(event, action) =>
              onDialogAction(event, action, valid)
            }
          >
            <form>
              <StyledTextField
                name="account"
                label="Új azonosító"
                validate={accountType}
                sx={{
                  width: "200px",
                }}
              />
            </form>
          </FormDialog>
        );
      }}
    </Form>
  );
};

export default CloningDialog;

import { MessageDescriptor, useIntl } from "react-intl";
import { FilterViewProps } from "../types";
import BooleanFilter from "./base/BooleanFilter";

const SimpleBooleanFilter = (
  trueMsg: MessageDescriptor,
  falseMsg: MessageDescriptor
) => {
  const Filter = (props: FilterViewProps) => {
    const { formatMessage } = useIntl();
    return (
      <BooleanFilter
        {...props}
        options={[formatMessage(trueMsg), formatMessage(falseMsg)]}
      />
    );
  };
  return Filter;
};

export default SimpleBooleanFilter;

export default function streamQuery<T, E>(
  fetchWithCursor: (cursorValue?: any) => Promise<T[]>,
  cursor: keyof T,
  limit: number,
  opts?: {
    onError?: (error: E) => void;
    onNewData?: (data: T[]) => void;
    onData?: (data: T[]) => void;
    onLoading?: () => void;
  }
): [cancel: () => void, trigger: () => void] {
  let isCancelled = false;
  let isTriggered = false;
  let data: T[] | undefined;
  let promise: Promise<void> | null;

  async function query() {
    try {
      opts?.onLoading?.();
      isTriggered = false;
      const response = await fetchWithCursor(data?.[0][cursor]);

      if (isCancelled) {
        return;
      }

      opts?.onNewData?.(response);

      if (!data || response.length >= limit || (data.length === 0 && response.length > 0)) {
        data = response;
      } else {
        data = response.concat(data);
      }

      if (data.length > limit) {
        data.splice(limit, data.length - limit);
      }

      opts?.onData?.(data);
    } catch (error) {
      if (!isCancelled) {
        if (data && data.length > 0) {
          data = undefined;
        }

        opts?.onError?.(error as E);
      }
    } finally {
      if (!isCancelled) {
        if (isTriggered) {
          promise = query();
        } else {
          promise = null;
        }
      }
    }
  }

  promise = query();

  return [
    function cancel() {
      isCancelled = true;
    },

    function trigger() {
      if (isTriggered || isCancelled) {
        return;
      }

      if (promise) {
        isTriggered = true;
      } else {
        promise = query();
      }
    },
  ];
}

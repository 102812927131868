import React, {
  SyntheticEvent,
  useMemo,
  useState,
  Fragment,
  useEffect,
} from "react";

import {
  IconButton,
  Typography,
  List,
  ListItem,
  Divider,
  Box,
  lighten,
  useTheme,
  Dialog as MUIDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Route, useRouteMatch, useHistory } from "react-router";
import { Link, NavLink, NavLinkProps } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { Form, useForm, useFormState } from "react-final-form";
import {
  FieldArray as FinalFormFieldArray,
  useFieldArray,
} from "react-final-form-arrays";

import range from "lodash/range";

import { AnyObject } from "final-form";
import { styled } from "@mui/system";
import Widget from "../utils/Widget";
import { useIntl } from "react-intl";

import get from "lodash/get";
import { Button } from "@mb-pro-ui/components";

const classes = {
  active: `NavLink-active`,
};

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(
  ({ theme }) => ({
    textDecoration: "none",
    color: "inherit",
    [`&.${classes.active}`]: {
      "& > div": {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
        boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
      },
      "& > div > div": {
        color: theme.palette.primary.main,
      },
      "& > div:hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
    },
  })
);

type Submit =
  | ((
      event?:
        | Partial<
            Pick<
              SyntheticEvent<Element, Event>,
              "preventDefault" | "stopPropagation"
            >
          >
        | undefined
    ) => Promise<AnyObject | undefined> | undefined)
  | null;

interface UrlParams {
  by: "new" | "byId" | "byNum";
  id: string;
  num: string;
}

interface DialogProps<T> {
  name: string;
  formDialogHeaderNew: string;
  formDialogHeaderEdit: string;
  formDialogRenderer: () => JSX.Element;
  newItemInitialValues?: Partial<T>;
  url: string;
}

export const Dialog = <T,>({
  formDialogHeaderNew,
  formDialogHeaderEdit,
  formDialogRenderer,
  name,
  newItemInitialValues,
  url,
}: DialogProps<T>) => {
  const history = useHistory();

  const { formatMessage } = useIntl();

  const { fields } = useFieldArray(name);
  const { push, update } = fields;

  const {
    params: { by, num },
  } = useRouteMatch<UrlParams>();

  let submit: Submit = null;

  const onSubmit = (vals: T) => {
    if (initialValues) {
      if (by === "byNum") {
        update(parseInt(num), vals);
      }
    } else {
      push(vals);
    }
  };

  const onDialogAction = (_: {}, action: "close" | "cancel" | "confirm") => {
    switch (action) {
      case "close":
        history.replace(url);
        break;
      case "cancel":
        history.replace(url);
        break;
      case "confirm":
        if (submit) {
          submit();
          history.replace(url);
        }

        break;
    }
  };

  const initialValues = useMemo(() => {
    if (by === "byNum") {
      return fields.value[parseInt(num)];
    } else if (by === "byId") {
      return fields.value.find((f) => f.id === parseInt(num));
    } else {
      return undefined;
    }
  }, [by, fields.value, num]);

  return (
    <MUIDialog open={true} onClose={() => onDialogAction({}, "close")}>
      <DialogTitle>
        {initialValues ? formDialogHeaderEdit : formDialogHeaderNew}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues ? initialValues : newItemInitialValues}
      >
        {({ handleSubmit, submitting, invalid, pristine, values }) => {
          submit = handleSubmit;
          return (
            <>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {formDialogRenderer()}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => onDialogAction({}, "cancel")}
                  disabled={submitting}
                >
                  {formatMessage({
                    defaultMessage: "Cancel",
                    description: "Card component with dialog,  cancel button",
                  })}
                </Button>
                <Button
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  onClick={() => onSubmit(values)}
                  sx={{ marginLeft: "32px" }}
                >
                  {formatMessage({
                    defaultMessage: "Confirm",
                    description: "Card component with dialog,  confirm button",
                  })}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </MUIDialog>
  );
};

interface FieldArrayProps<T> {
  name: string;
  label: string;
  formDialogHeaderNew: string;
  formDialogHeaderEdit: string;
  placeholderMessage?: string;
  itemRenderer?: (fieldValue: T) => JSX.Element;
  labels?: { [key: string]: any };
  formDialogRenderer: () => JSX.Element;
  initialValues?: Partial<T>;
  hasAdd?: boolean;
  hasDelete?: boolean;
  readOnly?: boolean;
}

const FieldArray = <T,>({
  name,
  label,
  labels,
  formDialogHeaderNew,
  formDialogHeaderEdit,
  placeholderMessage,
  itemRenderer,
  formDialogRenderer,
  initialValues,
  hasAdd = true,
  hasDelete = true,
  readOnly,
}: FieldArrayProps<T>) => {
  const { path, url } = useRouteMatch();

  const { palette } = useTheme();

  const { change } = useForm();
  const { submitting } = useFormState();

  const { fields } = useFieldArray(name);

  const [deleteMode, setDeleteMode] = useState(false);
  const [deletedItems, setDeletedItems] = useState<any[]>([]);

  useEffect(() => {
    if (submitting) {
      setDeletedItems([]);
    }
  }, [setDeletedItems, submitting]);

  const onDelete = (index: number, values: any, ord: number) => {
    fields.remove(index);
    if (fields.length === 1) {
      change(name, null);
    }
    if (values.id) {
      setDeletedItems([...deletedItems, { ...values, ind: ord }]);
      setDeleteMode(false);
    }
  };

  return (
    <>
      <Route path={`${path}/${name}/:by/:num`}>
        <Dialog
          name={name}
          formDialogRenderer={formDialogRenderer}
          newItemInitialValues={initialValues}
          formDialogHeaderNew={formDialogHeaderNew}
          formDialogHeaderEdit={formDialogHeaderEdit}
          url={url}
        />
      </Route>

      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 1,
          height: "38px",
        }}
        paddingY={0}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: "5px",
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              marginLeft: "5px",
              marginRight: "auto",
            }}
          >
            {label}
          </Typography>
          {fields?.length && fields.length > 0 ? (
            <Typography
              variant="subtitle2"
              sx={{ margin: "8px", marginLeft: "5px" }}
            >
              {`(${fields.length})`}
            </Typography>
          ) : null}
        </Box>

        {readOnly || !hasDelete ? null : (
          <IconButton
            sx={
              (fields?.length ?? 0) > 0
                ? {
                    color: "common.white",
                    padding: 1,
                  }
                : {
                    color: "grey.400",
                    padding: 1,
                  }
            }
            onClick={() => {
              if ((fields?.length ?? 0) > 0) {
                setDeleteMode(!deleteMode);
              }
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        )}
        {readOnly || !hasAdd ? null : (
          <Link to={`${url}/${name}/new/0`}>
            <IconButton
              size="large"
              sx={{
                color: "common.white",
                padding: 1,
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Link>
        )}
      </Box>
      <Widget
        placeholderMessage={
          placeholderMessage ? placeholderMessage : "No items"
        }
        children={
          (fields?.length ?? 0) > 0 || deletedItems.length > 0 ? (
            <FinalFormFieldArray name={name}>
              {({ fields }) => {
                const newFields = fields.value ? [...fields.value] : [];

                const arrayLength = (fields?.length ?? 0) + deletedItems.length;

                return fields.length || deletedItems.length > 0 ? (
                  <List
                    sx={{
                      "&.MuiList-root": {
                        paddingTop: "0",
                        paddingBottom: "0",
                      },
                    }}
                  >
                    {range(arrayLength).map((i) => {
                      if (deletedItems.some((item) => item.ind === i)) {
                        const deletedItem = deletedItems.find(
                          (item) => item.ind === i
                        );
                        return (
                          <Fragment key={`${name}-array-${i}`}>
                            {i !== 0 ? <Divider /> : null}
                            <ListItem
                              sx={
                                deleteMode
                                  ? {
                                      display: "flex",
                                      justifyContent: "space-between",
                                      backgroundColor: "grey.300",
                                      textDecoration: "line-through",
                                    }
                                  : {
                                      display: "flex",

                                      backgroundColor: "grey.300",
                                      textDecoration: "line-through",
                                    }
                              }
                            >
                              <Box>
                                {itemRenderer ? (
                                  itemRenderer(deletedItem)
                                ) : labels ? (
                                  <>
                                    {Object.keys(labels ?? {}).map((e, j) => (
                                      <React.Fragment
                                        key={`${name}-array-${i}.${j}`}
                                      >
                                        <Typography
                                          sx={{
                                            color: "primary.light",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          {labels[e] ?? ""}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            marginBottom: "2%",
                                          }}
                                        >
                                          {get(deletedItem, e, "")}
                                        </Typography>
                                      </React.Fragment>
                                    ))}
                                  </>
                                ) : null}
                              </Box>
                            </ListItem>
                          </Fragment>
                        );
                      } else {
                        const fieldValue = newFields.shift();

                        const fieldsCount =
                          arrayLength -
                          newFields.length -
                          1 -
                          deletedItems.length;

                        return (
                          <Fragment key={`${name}-array-${i}`}>
                            {i !== 0 ? <Divider /> : null}

                            <ListItem
                              sx={{
                                display: "flex",
                                ...(deleteMode
                                  ? {
                                      justifyContent: "space-between",
                                    }
                                  : {}),
                                ...(!fieldValue?.id
                                  ? {
                                      backgroundColor: lighten(
                                        palette.warning.light,
                                        0.6
                                      ),
                                    }
                                  : {}),
                              }}
                            >
                              <StyledNavLink
                                to={
                                  readOnly ? "#" : `${url}/${name}/byNum/${i}`
                                }
                                sx={readOnly ? { cursor: "auto" } : {}}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {fieldValue ? (
                                    itemRenderer ? (
                                      itemRenderer(fieldValue)
                                    ) : labels ? (
                                      <>
                                        {Object.keys(labels ?? {}).map(
                                          (e, j) => (
                                            <React.Fragment
                                              key={`${name}-array-${i}.${j}`}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "primary.light",
                                                  fontSize: "0.8rem",
                                                }}
                                              >
                                                {labels[e] ?? ""}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  marginBottom: "2%",
                                                }}
                                              >
                                                {get(fieldValue, e, "")}
                                              </Typography>
                                            </React.Fragment>
                                          )
                                        )}
                                      </>
                                    ) : null
                                  ) : null}
                                </Box>
                              </StyledNavLink>
                              {deleteMode ? (
                                <IconButton
                                  size="large"
                                  sx={{
                                    padding: 0,
                                  }}
                                >
                                  <DeleteIcon
                                    onClick={() => {
                                      onDelete(fieldsCount, fieldValue, i);
                                    }}
                                    sx={{
                                      color: "error.main",
                                    }}
                                  />
                                </IconButton>
                              ) : null}
                            </ListItem>
                          </Fragment>
                        );
                      }
                    })}
                  </List>
                ) : null;
              }}
            </FinalFormFieldArray>
          ) : null
        }
      />
    </>
  );
};

export default FieldArray;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useIntl } from "react-intl";
import { ModalFormProps } from "../types";
import { CodeTable as _CodeTable, Icon } from "@mb-pro-ui/utils/types/alarm";
import { Button } from "@mb-pro-ui/components";
import { Form } from "react-final-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useUseCreate } from "../utils";
import FileInput from "./FileInput";

import md5 from "md5";

interface FormValues {
  file: File;
}

const reader = new FileReader();

export const preSaveFormat = (
  values: FormValues,
  dataUrl: string | undefined
) => {
  if (dataUrl) {
    const data = dataUrl.split(",");
    const rawData = data[1];
    let mime = values.file.type || data[0].split(/[:;]/)[1];
    const checksum = md5(rawData);
    return { "raw-data": rawData, "media-type": mime, md5sum: checksum };
  }
};

const IconCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: ModalFormProps) => {
  const { formatMessage } = useIntl();
  const [dataUrl, setDataUrl] = useState<string>();
  const { mutate: create } = useUseCreate<Icon>("alarm/icons", {
    refetch,
    setSnackbarState,
    onFormClose,
    onSuccess: () => {
      setDataUrl("");
    },
  });

  useEffect(() => {
    const loaded = (e: any) => {
      setDataUrl(e.target.result);
    };
    reader.onload = loaded;
    return () => {
      reader.removeEventListener("load", loaded);
    };
  }, []);

  const onSubmit = (values: FormValues) => {
    const variables = preSaveFormat(values, dataUrl);
    if (variables) {
      create(variables);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create Icon" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <FileInput
                  imgSrc={dataUrl}
                  disabled={reader.readyState === 1}
                  onChange={({
                    target: { files },
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files?.[0]) {
                      reader.readAsDataURL(files?.[0]);
                    } else {
                      setDataUrl("");
                    }
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    setDataUrl("");
                    onFormClose();
                  }}
                  disabled={submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button
                  type="submit"
                  disabled={
                    submitting ||
                    pristine ||
                    !dataUrl ||
                    reader.readyState !== 2
                  }
                >
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default IconCreateModal;

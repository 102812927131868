import WarningIcon from "@mui/icons-material/Warning";
import { styled, SxProps, Table, TableBody, TableRowProps, Theme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import {
  CellProps,
  Column,
  Row,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import EmptyPlaceholder, { PlaceholderIcon } from "../EmptyPlaceholder";
import SubPage from "../SubPage";
import GlobalFilter, { GlobalFilterProps } from "./filters/GlobalFilter";
import TableHeader from "./header";
import TableRow, { TableRowContext } from "./row";
import TableSkeleton from "./skeleton";
import useScrollbarSize from "./useScrollbarSize";

const StyledFixedSizeList = styled(FixedSizeList)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  overflowX: "hidden",
})) as unknown as typeof FixedSizeList; // workaround to not loose the generic type parameter

export interface EnhancedTableProps<Type extends object, ErrorIconProps extends object> {
  columns: Column<Type>[];
  data: Type[];
  rowHeight?: number;
  getRowProps?: (row: Row<Type>) => Omit<Partial<TableRowProps>, "key">;
  onRowClick?: (row: Row<Type>) => void;
  queryStatus?: "error" | "loading" | "idle" | "success";
  emptyLineCount?: number; // number of empty lines at the bottom for overscrolling
  overscanCount?: number; // number lines to render outside the visible area
  hideHeader?: boolean; // hide the table header (with the column names)
  emptyIcon?: PlaceholderIcon;
  emptyMessage?: string;
  errorIcon?: PlaceholderIcon<ErrorIconProps>;
  errorIconProps?: Partial<ErrorIconProps>;
  errorMessage?: string;
  showHeader?: boolean; // show the widget header (with the global filter, prefix, center and postfix )
  disableGlobalFilter?: boolean; // hide the global filter in the widget header
  globalFilterPlacement?: GlobalFilterProps["placement"];
  prefix?: ReactNode; // header prefix, before the global filter
  center?: ReactNode; // header center, after the global filter
  postfix?: ReactNode; // header postfix, after the center
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  action?: (row: Row<Type>, url: string) => ReactNode;
  actionCellWidth?: number;
  actionPosition?: "left" | "right";
  filters?: React.ReactNode;
}

const EnhancedTable = <Type extends object = {}, ErrorIconProps extends object = {}>({
  columns,
  data,
  onRowClick,
  getRowProps: getCustomRowProps,
  queryStatus = "success",
  disableGlobalFilter,
  globalFilterPlacement,
  emptyLineCount = 0,
  rowHeight = 28,
  hideHeader,
  emptyIcon,
  emptyMessage,
  errorIcon = WarningIcon,
  errorIconProps,
  errorMessage,
  overscanCount,
  prefix,
  center,
  postfix,
  showHeader,
  sx = [],
  innerSx = [],
  action,
  actionCellWidth = 150,
  actionPosition = "right",
  filters,
}: EnhancedTableProps<Type, ErrorIconProps>) => {
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();

  const [scrollbarSize, listOuterDomRef] = useScrollbarSize();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      disableGlobalFilter,
      // autoResetFilters: false,
      // manualFilters: true,
      // disableFilters: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    (hooks) => {
      if (action) {
        hooks.visibleColumns.push((vcs) => [
          ...(actionPosition === "right" ? vcs : []),
          {
            id: "action",
            Cell: ({ row }: CellProps<Type>) => action(row, url),
            width: actionCellWidth,
            minWidth: actionCellWidth,
          },
          ...(actionPosition === "left" ? vcs : []),
        ]);
      }
    }
  );

  const tableRowContext = useMemo<TableRowContext<Type>>(
    () => ({
      rows,
      prepareRow,
      onRowClick,
      getCustomRowProps,
    }),
    [rows, prepareRow, onRowClick, getCustomRowProps]
  );

  return (
    <SubPage
      showHeader={showHeader}
      prefix={
        <>
          {prefix}
          {!disableGlobalFilter && (
            <GlobalFilter
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              placement={globalFilterPlacement}
            />
          )}
        </>
      }
      center={center}
      postfix={postfix}
      sx={sx}
      innerSx={[
        {
          display: "flex",
          flexDirection: "column",
        },
        ...(Array.isArray(innerSx) ? innerSx : [innerSx]),
      ]}
    >
      {filters}
      <Table
        {...getTableProps()}
        component="div"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        {!hideHeader && (
          <TableHeader headerGroups={headerGroups} sx={{ paddingRight: `${scrollbarSize}px` }} />
        )}
        {queryStatus === "error" ? (
          <EmptyPlaceholder
            Icon={errorIcon}
            iconProps={errorIconProps}
            message={
              errorMessage ??
              formatMessage({
                id: "query error message",
                defaultMessage: "Query Error",
                description: "Table Component query error message",
              })
            }
            sx={{ flex: 1 }}
          />
        ) : queryStatus === "success" && rows.length === 0 ? (
          <EmptyPlaceholder
            Icon={emptyIcon}
            message={
              emptyMessage ??
              formatMessage({
                id: "empty table message",
                defaultMessage: "No data match your query",
                description: "Table Component no result query message",
              })
            }
            sx={{ flex: 1 }}
          />
        ) : (
          <TableBody
            {...getTableBodyProps()}
            component="div"
            sx={{ flex: 1, position: "relative" }}
          >
            <AutoSizer>
              {({ width, height }) =>
                queryStatus === "loading" || queryStatus === "idle" ? (
                  <TableSkeleton
                    width={width}
                    height={height}
                    rowHeight={rowHeight}
                    columns={visibleColumns}
                  />
                ) : (
                  <StyledFixedSizeList<TableRowContext<Type>>
                    width={width}
                    height={height}
                    itemCount={rows.length + emptyLineCount}
                    itemSize={rowHeight}
                    itemData={tableRowContext}
                    overscanCount={overscanCount}
                    outerRef={listOuterDomRef}
                  >
                    {TableRow}
                  </StyledFixedSizeList>
                )
              }
            </AutoSizer>
          </TableBody>
        )}
      </Table>
    </SubPage>
  );
};

export default EnhancedTable;

import { Button } from "@mb-pro-ui/components";
import { TextField } from "@mb-pro-ui/components/form";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { Category as _Category } from "@mb-pro-ui/utils/types/alarm";
import EventCategoryAutocomplete from "./EventCategoryAutocomplete";
import { ModalFormProps } from "../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { Event as _Event } from "@mb-pro-ui/utils/types/alarm";
import { localDescr } from "../../../utils/LocaleUtils";
import { Lang } from "../../types";
import { DeleteDialog, useUseBatchedChanges, useUseDelete } from "../utils";

type Event = Omit<_Event, "category"> & {
  category: _Category;
};

interface FormValues extends Pick<Event, "id" | "category" | "descr"> {}

const postLoadFormat = (values: Event): FormValues => ({
  id: values.id,
  category: values.category,
  descr: values.descr,
});

const preSaveFormat = (values: FormValues) => ({
  ...values,
  category: values.category.id,
});

const EventTypeUpdateModal = ({
  open,
  id,
  onFormClose,
  setSnackbarState,
  refetch,
}: ModalFormProps) => {
  const { formatMessage } = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { waitFor } = useBackendSchemas();
  const { locale } = useLocale();

  const { data: event, isLoading } = useGetOne<Event>(
    "alarm/events",
    id ?? "",
    {
      include: { category: {} },
      enabled: !!id,
    }
  );

  const { mutate: del, isLoading: isDeleting } = useUseDelete("alarm/events", {
    refetch,
    setSnackbarState,
  });

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    setSnackbarState,
    refetch,
  });

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([...diff("events", event, preSaveFormat(values), { schemas })]);
    onFormClose();
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    onFormClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Edit event type" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={event && postLoadFormat(event)}
        render={({ handleSubmit, submitting, pristine, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <EventCategoryAutocomplete
                  disabled={submitting || isLoading}
                  required
                />
                <TextField
                  name={`descr.[${Lang[locale]}]`}
                  label={formatMessage(
                    { defaultMessage: "Name ({locale})" },
                    { locale }
                  )}
                  disabled={submitting || isLoading || isDeleting}
                  parse={(v) => v}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type="button"
                  onClick={() => form.reset()}
                  disabled={pristine}
                >
                  {formatMessage({ defaultMessage: "Reset" })}
                </Button>
                <Button
                  type="button"
                  mode={"warning"}
                  onClick={handleDeleteClick}
                  disabled={isLoading}
                >
                  {formatMessage({ defaultMessage: "Delete" })}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => onFormClose()}
                  disabled={submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                {isLoading ? (
                  <CircularProgress size={"30px"} color="primary" />
                ) : (
                  <Button
                    type="submit"
                    disabled={isLoading || submitting || pristine}
                  >
                    {formatMessage({ defaultMessage: "Save" })}
                  </Button>
                )}
              </DialogActions>
            </form>
          );
        }}
      />
      <DeleteDialog
        deleteResource={del}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        resourceId={id}
        resourceName={formatMessage(
          { defaultMessage: "{name}" },
          { name: event?.descr ? localDescr(event.descr, Lang[locale]) : "" }
        )}
      />
    </Dialog>
  );
};

export default EventTypeUpdateModal;

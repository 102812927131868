import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

const useURLSearchParams = (): [
  URLSearchParams,
  (values: Record<string, string | null>) => void
] => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = useCallback(
    (values) => {
      for (const [key, value] of Object.entries(values)) {
        if (value && typeof value === "string") {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      }

      searchParams.sort();
      history.replace({ search: searchParams.toString() });
    },
    [searchParams, history]
  );

  return [searchParams, setSearchParams];
};

export default useURLSearchParams;

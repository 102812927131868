import { FormValues, SubactionRefType } from "../../customer/types";

export const setOrder = (formValues: FormValues): FormValues => ({
  ...formValues,
  "action-groups":
    formValues["action-groups"]?.map((actGroup) => ({
      ...actGroup,
      subactions:
        actGroup.subactions?.map((subAct, ind) => ({
          ...subAct,
          ord: ind,
        })) ?? null,
    })) ?? null,
});

export const notifiedType = (refType: SubactionRefType) => {
  switch (refType) {
    case 0:
    case "0":
      return "notifiable-person";
    case 1:
    case "1":
      return "guard";
    case 2:
    case "2":
      return "authority";
    case 3:
    case "3":
      return "installer";
  }
};

export const notifiedChannel = (channel: "P" | "M" | "E") => {
  switch (channel) {
    case "P":
      return "phone";
    case "M":
      return "mobile";
    case "E":
      return "email";
  }
};

export const notifiedChannelFromName = (channel: string) => {
  switch (channel) {
    case "Telefon":
      return "P";
    case "Mobil":
      return "M";
    case "Email":
      return "E";
    default:
      return "O";
  }
};

export const notifiedChannelToName = (
  channel: "P" | "M" | "E",
  formatMessage: any
) => {
  switch (channel) {
    case "P":
      return `${formatMessage({ defaultMessage: "Phone" })}: `;
    case "M":
      return `${formatMessage({ defaultMessage: "Mobile" })}: `;
    case "E":
      return `${formatMessage({ defaultMessage: "Email" })}: `;
  }
};

export const getAvailableChannels = (contact: any) => {
  const channels = [];
  if (contact?.email) {
    channels.push("Email");
  }
  if (contact?.phone) {
    channels.push("Telefon");
  }
  if (contact?.mobile) {
    channels.push("Mobil");
  }
  if (contact) {
    channels.push("Egyéb");
  }
  return channels;
};

export const getAvailableDescriptions = (channel: string) => {
  switch (channel) {
    case "Mobil":
      return ["Hívás", "SMS Küldése", "Egyéb"];
    case "Telefon":
      return ["Hívás", "Egyéb"];
    case "Email":
      return ["Email küldése", "Egyéb"];
    default:
      return ["Egyéb"];
  }
};

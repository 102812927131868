import Widget from "../utils/Widget";
import { useIntl } from "react-intl";
import { Intervention } from "./types";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { SxProps } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const CustomerDataWidget = ({
  intervention,
  sx,
}: {
  intervention?: Intervention;
  sx?: SxProps;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Widget
      title={formatMessage({
        defaultMessage: "Customer",
        description: "Intervention page customer data customer widget title",
      })}
      sx={sx}
    >
      {intervention?.customer && (
        <List dense sx={{ overflow: "auto" }}>
          {intervention.customer.name && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Name",
                  description:
                    "Intervention page customer data cutomer Name field",
                })}
                secondary={intervention.customer.name}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "Identifier",
                description:
                  "Intervention page customer data cutomer Identifier field",
              })}
              secondary={intervention.customer.account}
            />
          </ListItem>
          {intervention.customer.unitid && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Helios Id",
                  description:
                    "Intervention page customer data cutomer Helios Id field",
                })}
                secondary={intervention.customer.unitid}
              />
            </ListItem>
          )}
          {intervention.customer.address && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Address",
                  description:
                    "Intervention page customer data cutomer Address field",
                })}
                secondary={intervention.customer.address}
              />
            </ListItem>
          )}
          {intervention.customer.phone && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Phone",
                  description:
                    "Intervention page customer data cutomer Phone field",
                })}
                secondary={intervention.customer.phone}
              />
            </ListItem>
          )}
          {intervention.customer.mobile && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Mobile",
                  description:
                    "Intervention page customer data cutomer Mobile field",
                })}
                secondary={intervention.customer.mobile}
              />
            </ListItem>
          )}
          {intervention.customer.passwd && (
            <ListItem>
              <ListItemText
                primary={formatMessage({
                  defaultMessage: "Password",
                  description:
                    "Intervention page customer data cutomer Password field",
                })}
                secondary={intervention.customer.passwd}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "Active",
                description:
                  "Intervention page customer data cutomer Active field",
              })}
              secondary={
                <ListItemIcon>
                  {intervention.customer.active ? (
                    <CheckIcon fontSize="small" color="primary" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </ListItemIcon>
              }
            />
          </ListItem>
        </List>
      )}
    </Widget>
  );
};

export default CustomerDataWidget;

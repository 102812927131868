import { useMemo } from "react";
import { Column } from "react-table";
import { FormattedMessage, useIntl } from "react-intl";
import { Lang } from "../../types";
import { CodeTable } from "../types";
import { useLocale } from "../../../locales/LocaleProvider";

import { useGetAll } from "@mb-pro-ui/utils";

import { localDescr } from "../../../utils/LocaleUtils";
import { AllEntities } from "../utils";
import CodeTableCreateModal from "./CodeTableCreateModal";

const AllCodeTables = () => {
  const { formatMessage } = useIntl();
  const queryResult = useGetAll<CodeTable>("alarm/code-tables", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
  });
  const { locale } = useLocale();

  const columns = useMemo(
    (): Column<CodeTable>[] => [
      {
        id: "id",
        width: 30,
        Header: formatMessage({
          defaultMessage: "ID",
        }),
        accessor: (codeTable) => codeTable.id,
      },
      {
        id: "name",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (codeTable) => localDescr(codeTable.descr, Lang[locale]),
      },
    ],
    [formatMessage, locale]
  );

  const title = <FormattedMessage defaultMessage="Code tables" />;

  return (
    <AllEntities
      columns={columns}
      queryResult={queryResult}
      title={title}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <CodeTableCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllCodeTables;

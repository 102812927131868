import { ModalFormProps } from "../types";
import { StyledDialogContent, useUseCreate } from "../utils";
import { Category as _Category } from "@mb-pro-ui/utils/types/alarm";
import { ID } from "@mb-pro-ui/utils/types";
import { IJSONObject } from "@mb-pro-ui/jsonapi-types";
import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useIntl } from "react-intl";
import { Form } from "react-final-form";
import { Button } from "@mb-pro-ui/components";
import { Lang } from "../../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { Checkbox, NumericField, TextField } from "@mb-pro-ui/components/form";
import IconSelect from "./IconSelect";
import SoundSelect from "./SoundSelect";
import ColorTextField from "./ColorTextField";

type FormValues = Pick<
  _Category,
  "descr" | "intervention-needed" | "priority"
> & {
  color: string;
  sound: ID;
  icon: ID;
};

export const hexToColor = (hex: string) => {
  let r = "0";
  let g = "0";
  let b = "0";
  if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  return { r: +r, g: +g, b: +b };
};

const preSaveFormat = (
  { color, descr, ...rest }: Partial<FormValues>,
  locale: Lang
) => {
  return {
    descr: { [`${locale}`]: descr },
    ...(color && { color: hexToColor(color) }),
    ...rest,
  };
};

const EventCategoryCreateModal = ({
  open,
  onFormClose,
  refetch,
  setSnackbarState,
}: ModalFormProps) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const { mutate: create } = useUseCreate<_Category>("alarm/categories", {
    refetch,
    setSnackbarState,
    onFormClose,
  });

  const onSubmit = (values: Partial<FormValues>) => {
    create(preSaveFormat(values, Lang[locale]) as unknown as IJSONObject);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create Event Category" })}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <StyledDialogContent>
                <TextField
                  name={`descr`}
                  label={formatMessage(
                    { defaultMessage: "Name ({locale})" },
                    { locale }
                  )}
                  disabled={submitting}
                  required
                  requiredError={formatMessage({
                    defaultMessage: "Required",
                  })}
                  highlightDirty={false}
                />
                <NumericField
                  name={"priority"}
                  label={formatMessage({ defaultMessage: "Priority" })}
                  highlightDirty={false}
                />
                <ColorTextField />
                <Box sx={{ display: "flex", marginLeft: "8px" }}>
                  <SoundSelect selectedId={values.sound} />
                </Box>
                <Box sx={{ marginRight: "8px", marginLeft: "8px" }}>
                  <IconSelect />
                </Box>
                <Checkbox
                  name={"intervention-needed"}
                  label={formatMessage({ defaultMessage: "Alert" })}
                  disabled={submitting}
                  highlightDirty={false}
                  labelSx={{
                    alignSelf: "flex-start",
                  }}
                />
              </StyledDialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button type="submit" disabled={submitting || pristine}>
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default EventCategoryCreateModal;

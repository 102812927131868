import { useCreate } from "@mb-pro-ui/utils";
import { Customer, RegularReport } from "@mb-pro-ui/utils/types/alarm";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { SavedQueryForm } from "./SavedQueryForm";
import { ModalFormProps } from "../settings/types";
type SavedQueryFormValues = Partial<RegularReport> & {
};

const initialValues:Partial<RegularReport> = {
    format: "xlsx",
    interval: "2",
    categories: [],
    "event-codes": [],
    "offset": "00:00:00",
  };

const SavedQueryCreateModal = ({
    open,
    onFormClose,
    refetch,
    setSnackbarState,
  }: ModalFormProps) => {

    const { mutate: createRegularReport } = useCreate("alarm/regular-reports", {
        onSuccess: () => {
          setSnackbarState({
            message: formatMessage({
              defaultMessage: "Saved succesfully",
            }),
          });
          refetch();
          onFormClose();
        },
        onError: () => {
            setSnackbarState({
                message: formatMessage({
                  defaultMessage: "Unsuccessful operation",
                }),
                error: true,
              });
        },
      });

    const { formatMessage } = useIntl();
    const onSubmit = (values:SavedQueryFormValues) => {
        createRegularReport({
          customer: (values.customer as Customer).id,
          interval: values.interval as string,
          description: values.description || (values.customer as Customer).account,
          categories: values.categories as string[],
          "event-codes": values["event-codes"] as string[],
          format: values.format || "xlsx",
          offset: values.offset || "00:00:00",
        });
      };

    return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create saved query" })}
      </DialogTitle>

      <Form initialValues={initialValues} onSubmit={onSubmit} render={(props) => { 
          return (
            <form onSubmit={props.handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <SavedQueryForm {...props} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={props.submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button
                  type="submit"
                  disabled={props.submitting || props.pristine}
                >
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
          
      }} />      
    </Dialog>
    );

  };

export default SavedQueryCreateModal;
import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FilterViewProps } from "../../table";
import { CodeTable } from "../types";
import { useLocale } from "../../../locales/LocaleProvider";
import { AutocompleteFilter } from "../../table/filters";

import {
  idAsLabel,
  sortByDescription,
  filterOptionsFromDescription,
  localizeDescription,
  EntityWithDescription,
} from "../../../utils/FilterUtils";
import { useCallback } from "react";

const renderOption = (v: EntityWithDescription) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.description}
  </Typography>
);

const CodeTableAutocompleteFilter = (props: FilterViewProps) => {
  const { locale } = useLocale();
  const localizeAndSort = useCallback(
    (data) =>
      localizeDescription<CodeTable>(data, locale).sort(sortByDescription),
    [locale]
  );
  const { data } = useGetAll<CodeTable>("alarm/code-tables", {
    notifyOnChangeProps: "tracked",
    refetchOnWindowFocus: false,
    select: localizeAndSort,
  });

  const { formatMessage } = useIntl();

  return (
    <AutocompleteFilter
      {...props}
      options={data as (CodeTable & EntityWithDescription)[]}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      noOptionsText={formatMessage({
        defaultMessage: "No such code table",
        description: "Autocomplete Filter",
      })}
      label={formatMessage({
        defaultMessage: "Code table",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Code tables: Name",
        description: "Autocomplete Filter",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default CodeTableAutocompleteFilter;

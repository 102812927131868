import { Typography, InputAdornment } from "@mui/material";

import { Autocomplete, Checkbox } from "@mb-pro-ui/components/form";
import { StyledNumericField } from "../form/StyledComponents";

import FieldArray from "../form/FieldArray";

import CoordinatesField from "../form/CoordinatesField";
import { useGetAll } from "@mb-pro-ui/utils";

import Widget from "../utils/Widget";

import { Sector, AreaRule, Area } from "./types";
import FormLayout from "../form/FormLayout";

import ImageInput from "../form/ImageInput";
import { useIntl } from "react-intl";
import HideableFormCard from "./profile/HideableFormCard";
import { Field, FormSpy } from "react-final-form";
import HideableFormField from "./profile/HideableFormField";
import { Circle } from "react-leaflet";
import { isEqualById } from "../utils/autocompleteUtils";

const ItemRenderer = (fieldValue: AreaRule) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography
        sx={{
          color: "primary.light",
          fontSize: "0.8rem",
        }}
      >
        {formatMessage({
          defaultMessage: "Location name",
        })}
      </Typography>
      <Typography
        sx={{
          marginBottom: "2%",
        }}
      >
        {fieldValue.area?.description ?? ""}
      </Typography>
      <Typography
        sx={{
          color: "primary.light",
          fontSize: "0.8rem",
        }}
      >
        {formatMessage({
          defaultMessage: "Alert rule",
        })}
      </Typography>
      <Typography
        sx={{
          marginBottom: "2%",
        }}
      >
        {fieldValue.allowed
          ? formatMessage({
              defaultMessage: "Allowed location",
            })
          : formatMessage({
              defaultMessage: "Forbidden location",
            })}
      </Typography>
    </>
  );
};

const DialogRenderer = () => {
  const { formatMessage } = useIntl();

  const { data: areas, isLoading: areAreasLoading } = useGetAll<Area>(
    "alarm/areas",
    {
      refetchOnWindowFocus: false,
    }
  );

  if (areAreasLoading) {
    return <Typography>Loading...</Typography>;
  }

  return areas ? (
    <>
      <Autocomplete
        name="area"
        label={formatMessage({
          defaultMessage: "Location name",
        })}
        fullWidth
        options={areas}
        getOptionLabel={(option) => option.description ?? ""}
        isEqual={isEqualById}
        sx={{
          marginLeft: "8px",
          width: "calc(100% - 16px)",
        }}
        required
      />

      <FormSpy>
        {({ values }) =>
          values["allowed"] ? (
            <Checkbox
              name="allowed"
              label={formatMessage({
                defaultMessage: "Allowed location",
              })}
            />
          ) : (
            <Checkbox
              name="allowed"
              label={formatMessage({
                defaultMessage: "Forbidden location",
              })}
            />
          )
        }
      </FormSpy>
    </>
  ) : (
    <></>
  );
};

interface PositionFormProps {
  readOnly?: boolean;
}

const PositionForm = ({ readOnly }: PositionFormProps) => {
  const { data: sectors, isLoading: areSectorsLoading } = useGetAll<Sector>(
    "alarm/sectors",
    {
      refetchOnWindowFocus: false,
    }
  );

  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <HideableFormCard name="map">
          <Widget
            title={formatMessage({
              defaultMessage: "Map",
            })}
            isLoading={areSectorsLoading}
            children={
              sectors ? (
                <>
                  <HideableFormField name="base-sector">
                    <Autocomplete
                      name="base-sector"
                      label={formatMessage({
                        defaultMessage: "Base sector",
                      })}
                      options={sectors}
                      fullWidth
                      getOptionLabel={(option) => option.description ?? ""}
                      isEqual={isEqualById}
                      sx={{
                        marginLeft: "8px",
                        width: "calc(100% - 16px)",
                      }}
                      readOnly={readOnly}
                    />
                  </HideableFormField>
                  <HideableFormField name="base-coordinates">
                    <CoordinatesField
                      latName="base-latitude"
                      lngName="base-longitude"
                      inputFields={(position) =>
                        position && (
                          <StyledNumericField
                            name="base-radius"
                            label={formatMessage({
                              defaultMessage: "Allowed distance",
                            })}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  m
                                </InputAdornment>
                              ),
                            }}
                            readOnly={readOnly}
                          />
                        )
                      }
                      mapComponents={(position) => (
                        <Field
                          name="base-radius"
                          subscription={{ value: true }}
                        >
                          {({ input: { value: radius } }) =>
                            !!position &&
                            Number(radius) > 0 && (
                              <Circle center={position} radius={radius} />
                            )
                          }
                        </Field>
                      )}
                      readOnly={readOnly}
                    />
                  </HideableFormField>
                </>
              ) : null
            }
          />
        </HideableFormCard>
      }
      secondChild={
        <HideableFormCard name="location-based-alerts">
          <FieldArray
            label={formatMessage({
              defaultMessage: "Location-based alerts",
            })}
            name="area-rules"
            formDialogHeaderNew="Új területi szabály"
            formDialogHeaderEdit="Területi szabály szerkesztése"
            formDialogRenderer={DialogRenderer}
            itemRenderer={ItemRenderer}
            readOnly={readOnly}
          />
        </HideableFormCard>
      }
      thirdChild={
        <HideableFormCard name="images">
          <ImageInput name="maps" readOnly={readOnly} />
        </HideableFormCard>
      }
    />
  );
};

export default PositionForm;

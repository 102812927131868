import { LocalizedString } from "@mb-pro-ui/utils/types";
import { Lang } from "../components/types";

export const lang_prefix = {    
    "hu-hu" : "hu_HU_",
    "en-gb" : "en_GB_",
    "de-de" : "de_DE_",
    "sk-sk" : "sk_SK_",
}

export function isReal(descr: LocalizedString, lang: Lang) : boolean {
    const value = descr[lang];
    return (value != null) && !(value.startsWith(lang_prefix[lang]));
}

export function localDescr(descr: LocalizedString, lang: Lang) : string {
    const v = descr[lang];
    if (v !== null && isReal(descr, lang)) return v;
    for (const l of Object.values(Lang)) {
        const v = descr[l];
        if (v !== null && isReal(descr, l)) return v;
    }
    return descr[lang] || descr[Lang.en] || descr[Lang.hu] || "";
}

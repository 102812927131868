import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router";

import { FormattedMessage, useIntl } from "react-intl";

import { Page } from "@mb-pro-ui/components";

import { SvgIcon } from "@mui/material";

import { ReactComponent as ExportedQueriesIcon } from "../icons/export/ExportedQueries.svg";
import { ReactComponent as RegularReportsIcon } from "../icons/export/RegularReports.svg";
import { ReactComponent as SavedQueriesIcon } from "../icons/export/SavedQueries.svg";
import { ReactComponent as EmailTemplatesIcon } from "../icons/export/EmailTemplates.svg";
import { ReactComponent as SentEmailsIcon } from "../icons/export/SentEmails.svg";
import { ReactComponent as ContactsIcon } from "../icons/export/Contacts.svg";

import ExportedQueries from "../components/export/ExportedQueries";
import { useCallback, useMemo, useState } from "react";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import RegularReports from "../components/export/RegularReports";
import SavedQueries from "../components/export/SavedQueries";
import SavedQueryUpdatePage from "../components/export/SavedQueryUpdatePage";
import AllEmailTemplates from "../components/export/AllEmailTemplates";
import EmailTemplateUpdatePage from "../components/export/EmailTemplateUpdatePage";
import SentEmailList from "../components/export/SentEmailList";
import EmailRecipientList from "../components/export/EmailRecipientList";
import EmailRecipientUpdatePage from "../components/export/EmailRecipientUpdatePage";

const sidebarOptions: SideBarOption[] = [
    {
      link: "/exported-queries",
      name: <FormattedMessage defaultMessage="Exported queries" />,
      icon: <SvgIcon component={ExportedQueriesIcon} />,
    },
    {
      link: "/regular-reports",
      name: <FormattedMessage defaultMessage="Regular reports" />,
      icon: <SvgIcon component={RegularReportsIcon} />,
    },
    {
      link: "/saved-queries",
      name: <FormattedMessage defaultMessage="Saved queries" />,
      icon: <SvgIcon component={SavedQueriesIcon} />,
    },
    {
      link: "/email-templates",
      name: <FormattedMessage defaultMessage="Email templates" />,
      icon: <SvgIcon component={EmailTemplatesIcon} />,
    },
    {
      link: "/sent-emails",
      name: <FormattedMessage defaultMessage="Sent emails" />,
      icon: <SvgIcon component={SentEmailsIcon} />,
    },
    {
      link: "/email-recipients",
      name: <FormattedMessage defaultMessage="Email recipients" />,
      icon: <SvgIcon component={ContactsIcon} />,
    },  
  ];

const ExportPage = () => {
    const { path } = useRouteMatch();
    const matchLandingPage = useRouteMatch({
      path: "/alarm/exports",
      exact: true,
      strict: false,
    });
    const { formatMessage } = useIntl();
    const [sidebarOpen, setSidebarOpen] = useState(true);
  
    const breadcrumbNameMap: { [key: string]: string } = useMemo(
      () => ({
        "/alarm/exports": formatMessage({
            defaultMessage: "Exports",
            description: "Main menu Exports label",
          }),
        "/alarm/exports/exported-queries": formatMessage({
          defaultMessage: "Exported queries",
        }),
        "/alarm/exports/regular-reports": formatMessage({
          defaultMessage: "Regular reports",
        }),
        "/alarm/exports/saved-queries": formatMessage({
          defaultMessage: "Saved queries",
        }),
        "/alarm/exports/email-templates": formatMessage({
          defaultMessage: "Email templates",
        }),
        "/alarm/exports/sent-emails": formatMessage({
          defaultMessage: "Sent emails",
        }),
        "/alarm/exports/email-recipients": formatMessage({
          defaultMessage: "Contacts",
        }),
      }),
      [formatMessage]
    );
  
    const onSidebarOpen = useCallback(() => {
      setSidebarOpen(true);
    }, []);
  
    const onSidebarClose = useCallback(() => {
      setSidebarOpen(false);
    }, []);
  
    const pageProps = useMemo(
      () => ({
        breadcrumbNameMap,
        sidebarOpen,
        onSidebarClose,
        onSidebarOpen,
      }),
      [breadcrumbNameMap, sidebarOpen, onSidebarClose, onSidebarOpen]
    );

  return (
    <Switch>
      <Route exact path={`${path}/saved-queries/:id`}>
        <SavedQueryUpdatePage {...pageProps} />
      </Route>
      <Route exact path={`${path}/email-templates/:id`}>
        <EmailTemplateUpdatePage {...pageProps} />
      </Route>
      <Route exact path={`${path}/email-recipients/:id`}>
        <EmailRecipientUpdatePage {...pageProps} />
      </Route>
      <Route>
        <Page
          sidebarOptions={sidebarOptions}
          breadcrumbs={!matchLandingPage}
          {...pageProps}
        >
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/exported-queries`} />
            </Route>
            <Route path={`${path}/exported-queries`}>
              <ExportedQueries />
            </Route>
            <Route path={`${path}/saved-queries`}>
              <SavedQueries />
            </Route>
            <Route path={`${path}/regular-reports`}>
              <RegularReports />
            </Route>
            <Route path={`${path}/email-templates`}>
              <AllEmailTemplates />
            </Route>
            <Route path={`${path}/sent-emails`}>
              <SentEmailList />
            </Route>
            <Route path={`${path}/email-recipients`}>
              <EmailRecipientList />
            </Route>
          </Switch>
        </Page>
      </Route>
    </Switch>
  );
};

export default ExportPage;

import { Paper } from "@mui/material";

import { Autocomplete } from "@mb-pro-ui/components/form";

import { useGetAll } from "@mb-pro-ui/utils";

import Widget from "../utils/Widget";

import { MapProfile } from "./types";

import FormLayout from "../form/FormLayout";
import { useIntl } from "react-intl";
import { useLocale } from "../../locales/LocaleProvider";

import { Lang } from "../types";
import { isEqualById } from "../utils/autocompleteUtils";

interface MapProfileFormProps {
  readOnly?: boolean;
}

const MapProfileForm = ({ readOnly }: MapProfileFormProps) => {
  const { data: mapProfiles, isLoading: areMapProfilesLoading } =
    useGetAll<MapProfile>("alarm/map-profiles", {
      refetchOnWindowFocus: false,
    });

  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  return (
    <>
      <FormLayout
        firstChild={
          <Widget
            title={formatMessage({
              defaultMessage: "Map",
              description: "Customer editor, map profile card title",
            })}
            isLoading={areMapProfilesLoading}
            children={
              mapProfiles ? (
                <Paper
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Autocomplete
                    name="mapprofile"
                    label={formatMessage({
                      defaultMessage: "Profile",
                    })}
                    options={mapProfiles}
                    fullWidth
                    getOptionLabel={(option) =>
                      option.descr?.[Lang[locale]] ?? ""
                    }
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                  />
                </Paper>
              ) : null
            }
          />
        }
      />
    </>
  );
};

export default MapProfileForm;

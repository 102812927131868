import { TextField } from "@mb-pro-ui/components/form";
import { useIntl } from "react-intl";

const ColorTextField = ({ name }: { name?: string }) => {
  const { formatMessage } = useIntl();
  return (
    <TextField
      name={name ?? "color"}
      type="color"
      label={formatMessage({ defaultMessage: "Color" })}
      sx={{
        alignSelf: "flex-start",
        minWidth: "50px",
        "& input": {
          width: "30px",
        },
      }}
    />
  );
};

export default ColorTextField;

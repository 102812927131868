import { useIntl } from "react-intl";

import { Paper } from "@mui/material";
import { Box } from "@mui/system";

import FormLayout from "../form/FormLayout";
import SimpleFieldArray from "../form/SimpleFieldArray";
import { StyledTextField } from "../form/StyledComponents";

const ZonesDialogRenderer = () => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledTextField
        name="number"
        label={formatMessage({
          defaultMessage: "Number",
        })}
        inputProps={{ maxLength: 3 }}
        initialValue="001"
      />
      <StyledTextField
        name="place"
        label={formatMessage({
          defaultMessage: "Place",
        })}
      />
      <StyledTextField
        name="code"
        label={formatMessage({
          defaultMessage: "Code",
        })}
        initialValue="00000000"
      />
    </Box>
  );
};

interface RfidProps {
  readOnly?: boolean;
}

const Rfid = ({ readOnly }: RfidProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <Paper>
          <SimpleFieldArray
            name="rfid-tokens"
            label="RFID tokenek"
            labels={{
              number: formatMessage({
                defaultMessage: "Number",
              }),
              place: formatMessage({
                defaultMessage: "Place",
              }),
              code: formatMessage({
                defaultMessage: "Code",
              }),
            }}
            formDialogRenderer={ZonesDialogRenderer}
            readOnly={readOnly}
          />
        </Paper>
      }
    />
  );
};

export default Rfid;

import FormLayout from "../form/FormLayout";

import { useIntl } from "react-intl";
import ActionPlansWidget from "../form/ActionPlansWidget";

interface ManualNotificationFormProps {
  readOnly?: boolean;
}

const ManualNotificationForm = ({ readOnly }: ManualNotificationFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <ActionPlansWidget
          label={formatMessage({ defaultMessage: "Action plans" })}
          name="action-groups"
          readOnly={readOnly}
        />
      }
    />
  );
};

export default ManualNotificationForm;

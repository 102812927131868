import CdecWindow from "../components/operator/cdec/CdecWindow";
import InterventionCdecWindow from "../components/operator/interventionCdec/InterventionCdecWindow";

import { Cdec, Intervention } from "../components/operator/types";
import { muiTheme } from "@mb-pro-ui/components";
import { Resizable } from "re-resizable";
import { useCallback, useEffect, useState } from "react";
import { useAudio } from "../components/audio/AudioPlayerProvider";
import { Page } from "@mb-pro-ui/components";
import { Box } from "@mui/material";

export const getStyle = (item: Cdec | Intervention) => {
  let color = muiTheme.palette.common.black;
  let emphasis = false;
  let backgroundColor = muiTheme.palette.background.paper;

  if (item.type === "cdec" || item.type === "cdec-all") {
    color = item.color ?? color;
    const hasIntervention = !!item.intervention;
    emphasis =
      (item["category-alert"] && !hasIntervention) ||
      !item.customer ||
      !item.event;
  } else if (
    item.type === "interventions" ||
    item.type === "interventions-all"
  ) {
    const startEvent = item["start-event"];
    color = startEvent.color ?? color;
    emphasis = !item.session?.operator;
  }

  if (emphasis) {
    backgroundColor = `${color}`;
    color = muiTheme.palette.common.white;
  }

  return { color, backgroundColor };
};

const useSessionStorageItem = (key: string, fallback: string) => {
  const [state, setState] = useState(
    () => sessionStorage.getItem(key) ?? fallback
  );

  return [
    state,
    useCallback(
      (value: string) => {
        sessionStorage.setItem(key, value);
        setState(value);
      },
      [key]
    ),
  ] as const;
};

const OperatorPage = () => {
  const { stopAllAlerts } = useAudio();

  useEffect(() => {
    return () => {
      stopAllAlerts();
    };
  }, [stopAllAlerts]);

  const [resiableWidth, setResizableWidth] = useSessionStorageItem(
    "resizableWidth",
    "50%"
  );

  return (
    <Page sx={{ padding: 0, flexDirection: "row" }}>
      <Resizable
        defaultSize={{ width: resiableWidth, height: "100%" }}
        maxWidth={"99.2%"}
        minWidth="1"
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResizeStop={(_ev, _dir, el) =>
          setResizableWidth(`${el.getBoundingClientRect().width}px`)
        }
        handleStyles={{
          right: {
            width: "5px",
            right: "-2.5px",
            position: "absolute",
            backgroundColor: muiTheme.palette.primary.main,
            zIndex: 1101,
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            flex: 1,
            padding: "12px",
          }}
        >
          <CdecWindow />
        </Box>
      </Resizable>
      <Box
        sx={{
          height: "100%",
          flex: 1,
          overflow: "hidden",
          padding: "12px",
        }}
      >
        <InterventionCdecWindow />
      </Box>
    </Page>
  );
};

export default OperatorPage;

import { Filters } from "@mb-pro-ui/utils";
import { Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

import ReportTable from "./ReportTable";

const filter:Filters = {
  "report-type": {
    nin: ["ozone-rlod-export", "ozone-certificate", "regular-report"],
  },
}

const prefix = (
  <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
    <FormattedMessage defaultMessage="Exported queries" />
  </Typography>
);

const ExportedQueries = () => {
  return (
    <ReportTable prefix={prefix} filter={filter} />
  );
};

export default ExportedQueries;

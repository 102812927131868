import { useIntl } from "react-intl";
import FormLayout from "../form/FormLayout";
import SimpleFieldArray from "../form/SimpleFieldArray";
import { StyledTextField } from "../form/StyledComponents";
import Widget from "../utils/Widget";

const DialogRenderer = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <StyledTextField
        name="number"
        label={formatMessage({
          defaultMessage: "Number",
        })}
        inputProps={{ maxLength: 2 }}
        required
      />
      <StyledTextField
        name="description"
        label={formatMessage({
          defaultMessage: "Description",
        })}
        required
      />
    </>
  );
};

interface PartitionsFormProps {
  readOnly?: boolean;
}

const PartitionsForm = ({ readOnly }: PartitionsFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormLayout
        firstChild={
          <>
            <Widget
              children={
                <SimpleFieldArray
                  label={formatMessage({
                    defaultMessage: "Partitions",
                  })}
                  name="partitions"
                  labels={{
                    number: formatMessage({
                      defaultMessage: "Number",
                    }),
                    description: formatMessage({
                      defaultMessage: "Description",
                    }),
                  }}
                  formDialogRenderer={DialogRenderer}
                  initialValues={{ number: "01" }}
                  readOnly={readOnly}
                />
              }
            />
          </>
        }
      />
    </>
  );
};

export default PartitionsForm;

import { useIntl } from "react-intl";
import { isHttpError, isJsonapiHttpError } from "@mb-pro-ui/utils";
import { JsonapiErrorMessage } from "@mb-pro-ui/components";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import { Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const useErrorHandler = () => {
  const { formatMessage } = useIntl();

  const getErrorMessage = (error: JsonapiError) => {
    let message = (
      <div>
        {formatMessage({
          defaultMessage: "Something went wrong",
          description: "Error handler default error message",
        })}
      </div>
    );

    if (isJsonapiHttpError(error)) {
      const errors = error.data && error.data.errors;
      message = errors.map((error) => <JsonapiErrorMessage error={error} />)[0];
    } else if (isHttpError(error)) {
      message = <div>{`${error.status} ${error.message}`}</div>;
    } else {
      message = <div>{error.message}</div>;
    }

    return message;
  };

  return { getErrorMessage, JsonApiErrorSnackBar: JsonApiErrorSnackBar };
};

const JsonApiErrorSnackBar = ({
  onClose: handleSnackBarClose,
  error,
}: {
  onClose: () => void;
  error: JsonapiError | null;
}) => {
  const { getErrorMessage } = useErrorHandler();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={!!error}
      autoHideDuration={5000}
      message={error && getErrorMessage(error)}
      onClose={handleSnackBarClose}
      action={
        <>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleSnackBarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default useErrorHandler;

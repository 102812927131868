// Checks, if T misses keys from R
type CheckMissing<T extends readonly any[], R extends Record<string, any>> = {
  [K in keyof R]: K extends T[number] ? never : K;
}[keyof R] extends infer I
  ? [I] extends [never]
    ? T
    : T & `Error: '${I & string}' is missing`
  : never;

type CheckDuplicate<T extends readonly any[]> = {
  [P1 in keyof T]: "_flag_" extends {
    [P2 in keyof T]: P2 extends P1
      ? never
      : T[P2] extends T[P1]
      ? "_flag_"
      : never;
  }[keyof T]
    ? [`Error: duplicate '${T[P1] & string}' field`]
    : T[P1];
};

type TupleOf<T> = readonly (keyof T)[] | [keyof T];

type ReservedFields = "id" | "type";

const checkFields =
  <
    ResultInterface extends Record<string, any> | null,
    U extends Record<string, any> = Omit<
      Required<NonNullable<ResultInterface>>,
      ReservedFields
    >
  >() =>
  <FieldsTuple extends TupleOf<U>>(
    fields: FieldsTuple &
      CheckDuplicate<FieldsTuple> &
      CheckMissing<FieldsTuple, U>
  ) =>
    fields as (keyof U)[];

export default checkFields;

import { lighten, styled, SxProps, Theme } from "@mui/material";
import clsx from "clsx";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useState } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Field, FieldRenderProps } from "react-final-form";

const StyledEditor = styled(
  ({ editorClassName, className, ...props }: EditorProps & { className?: string }) => (
    <Editor editorClassName={clsx(editorClassName, className)} {...props} />
  )
)({
  marginTop: "0",
  paddingLeft: "10px",
  minHeight: "290px",
});

type PassedThroughProps = Omit<EditorProps, "editorState" | "onEditorStateChange">;

export interface RichEditorAdapterProps extends PassedThroughProps, FieldRenderProps<string> {
  highlightDirty?: boolean;
  sx?: SxProps<Theme>;
}

const RichEditorAdapter = ({
  input,
  meta,
  highlightDirty = true,
  sx = [],
  ...rest
}: RichEditorAdapterProps) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(input.value).contentBlocks)
    )
  );

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    return input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <StyledEditor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      sx={[
        !!meta.dirty &&
          !meta.error &&
          highlightDirty && {
            backgroundColor: (theme) => lighten(theme.palette.warning.light, 0.6),
          },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <textarea disabled value={stateToHTML(editorState.getCurrentContent())} />
    </StyledEditor>
  );
};

export type RichEditorProps = PassedThroughProps & {
  name: string;
};

const RichEditor = ({ ...rest }: RichEditorProps) => (
  <Field component={RichEditorAdapter} {...rest} />
);

export default RichEditor;

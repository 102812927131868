import { useGetAll, Filters } from "@mb-pro-ui/utils";
import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { useLocale } from "../../../locales/LocaleProvider";
import { localDescr } from "../../../utils/LocaleUtils";
import {
  Filter as TableFilter,
  FilterView as TableFilterView,
} from "../../table";

import { Lang } from "../../types";
import { Event } from "../types";
import CodeTableAutocompleteFilter from "../../customer/filters/CodeTableAutocompleteFilter";
import { eqInNeqNinFilter, FilterOp } from "../../../utils/FilterUtils";
import { LocalizedString } from "@mb-pro-ui/utils/types";
import ColoredRouterLink from "../../utils/ColoredRouterLink";
import EventTypeCreateModal from "./EventTypeCreateModal";
import { AllEntities } from "../utils";
import { EventCategoryAutocompleteFilter } from "../../table/filters";

const filterSpec: { [id: string]: FilterOp } = {
  "code-table": eqInNeqNinFilter,
  category: eqInNeqNinFilter,
};

const mapToServerFilters = (filters: TableFilter[]): Filters => {
  const result: Filters = {};
  if (filters !== null) {
    filters.forEach((filter) => {
      if (filter.id in filterSpec) {
        result[filter.id] = filterSpec[filter.id](filter);
      }
    });
  }
  return result;
};

const AllEventTypes = () => {
  const [filters, setFilters] = useState<Filters>({});
  const limit = filters && Object.keys(filters).length > 0 ? 1000 : 100;
  const queryResult = useGetAll<Event>("alarm/events", {
    include: {
      category: {},
      "code-table": {},
    },
    page: {
      limit,
    },
    sort: ["id"],
    filter: { ...filters },
  });

  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  const columns = useMemo(
    (): Column<Event>[] => [
      {
        id: "code",
        Header: formatMessage({
          defaultMessage: "Code",
        }),
        accessor: (event) => event.code,
        width: 50,
      },
      {
        id: "description",
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (event) => localDescr(event.descr, Lang[locale]),
        width: 100,
      },
      {
        id: "category",
        Header: formatMessage({
          defaultMessage: "Category",
        }),
        accessor: (event) => event.category.descr?.[Lang[locale]],
        width: 70,
        Cell: ({
          value: categoryDescr,
          row: { original: event },
        }: Cell<Event, LocalizedString>) => {
          const MbProColor = event.category.color;
          const color = `rgb(${MbProColor.r},${MbProColor.g},${MbProColor.b})`;
          return (
            <ColoredRouterLink
              to={`/alarm/settings/event-categories/${event.category.id}`}
              color={color}
            >
              {categoryDescr}
            </ColoredRouterLink>
          );
        },
      },
      {
        id: "code-table",
        Header: formatMessage({
          defaultMessage: "Code table",
        }),
        accessor: (event) => event["code-table"].descr?.[Lang[locale]],
        width: 70,
        Cell: ({
          value: codeTableDescr,
          row: { original: event },
        }: Cell<Event, LocalizedString>) => {
          return (
            <ColoredRouterLink
              to={`/alarm/settings/code-tables/${event["code-table"].id}`}
            >
              {codeTableDescr}
            </ColoredRouterLink>
          );
        },
      },
    ],
    [formatMessage, locale]
  );

  const title = (
    <Typography color="primary.contrastText">
      <FormattedMessage defaultMessage="Event types" />:{" "}
      {queryResult.data?.length}
      {queryResult.data?.length === limit ? "+" : ""}
    </Typography>
  );

  const handleFilterChange = useCallback(
    (filters: TableFilter[]) => {
      setFilters(mapToServerFilters(filters));
    },
    [setFilters]
  );

  const tableFilters = useMemo(
    (): TableFilterView[] => [
      { id: "category", Filter: EventCategoryAutocompleteFilter },
      { id: "code-table", Filter: CodeTableAutocompleteFilter },
    ],
    []
  );

  return (
    <AllEntities
      columns={columns}
      queryResult={queryResult}
      title={title}
      tableFilters={tableFilters}
      handleFilterChange={handleFilterChange}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <EventTypeCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );
};

export default AllEventTypes;

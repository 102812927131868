import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";

import { useGetAll } from "@mb-pro-ui/utils";

import { Checkbox } from "@mb-pro-ui/components/form";

import { Customer, Category } from "./types";
import Widget from "../utils/Widget";
import { useMemo } from "react";

import EmptyPlaceholder from "@mb-pro-ui/components/EmptyPlaceholder";
import { useIntl } from "react-intl";

interface EventRulesProps {
  values: Customer;
  readOnly?: boolean;
}

const EventRules = ({
  values: { "action-groups": actionGroups },
  readOnly,
}: EventRulesProps) => {
  const { data: categories, isLoading: areCategoriesAreLoading } =
    useGetAll<Category>("alarm/categories", {
      refetchOnWindowFocus: false,
      filter: {
        "intervention-needed": {
          is: "true",
        },
      },
    });

  const tableBody = useMemo(
    () =>
      categories
        ? categories.map((category, i) => (
            <TableRow key={`row${i}`}>
              <TableCell
                align="left"
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <Typography>{category["localized-description"]}</Typography>
              </TableCell>
              {actionGroups?.map((_, j) => (
                <TableCell
                  key={`cell${i}${j}`}
                  align="center"
                  sx={{
                    minWidth: "100px",
                    "&.MuiTableCell-root": {
                      padding: "0",
                    },
                  }}
                >
                  <Checkbox
                    name={`rules-matrix[${i}][${j}]`}
                    label=""
                    readOnly={readOnly}
                    sx={{ margin: 0 }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))
        : null,
    [categories, actionGroups, readOnly]
  );

  const tableHead = useMemo(
    () =>
      actionGroups?.map((group, i) => (
        <TableCell
          key={`descCell${i}`}
          align="center"
          sx={{
            backgroundColor: "primary.main",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: "fit-content",
          }}
        >
          {group.description}
        </TableCell>
      )),
    [actionGroups]
  );

  const { formatMessage } = useIntl();

  return (
    <Widget
      sx={{
        minWidth: "100%",
        height: "calc(100vh - 152px)",
      }}
      isLoading={areCategoriesAreLoading}
      children={
        categories && (actionGroups?.length ?? 0) > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "primary.main",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 2,
                  }}
                ></TableCell>
                {tableHead}
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        ) : (
          <EmptyPlaceholder
            sx={{ height: "100%" }}
            message={formatMessage({
              defaultMessage: "Not action groups found",
            })}
          />
        )
      }
    />
  );
};

export default EventRules;

import { formControlClasses } from "@mui/material";

import {
  Category as _Category,
  CodeTable as _CodeTable,
  Event as _Event,
} from "@mb-pro-ui/utils/types/alarm";

import { Autocomplete } from "@mb-pro-ui/components/form";
import { Lang } from "../../types";
import { useGetAll } from "@mb-pro-ui/utils";
import { localDescr } from "../../../utils/LocaleUtils";
import { useIntl } from "react-intl";
import { useLocale } from "../../../locales/LocaleProvider";
import { isEqualById } from "../../utils/autocompleteUtils";

const CodeTableAutocomplete = ({ disabled }: { disabled?: boolean }) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  const { data: codeTables, isLoading } =
    useGetAll<_CodeTable>("alarm/code-tables");

  return (
    <Autocomplete
      name="code-table"
      required
      label={formatMessage({ defaultMessage: "Code table" })}
      options={codeTables ?? []}
      highlightDirty={false}
      disabled={disabled || isLoading}
      isEqual={isEqualById}
      sx={{
        display: "flex",
        [`& .${formControlClasses.root}`]: {
          flexGrow: 1,
        },
      }}
      getOptionLabel={(codeTable) => {
        if (typeof codeTable === "string") {
          return codeTable;
        }
        return codeTable?.descr
          ? localDescr(codeTable?.descr, Lang[locale])
          : "";
      }}
    />
  );
};

export default CodeTableAutocomplete;

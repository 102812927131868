import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";
import { Cell, Column, Row } from "react-table";

import { Typography } from "@mui/material";

import {
  Intervention,
  Customer,
  Operator,
  Cdec,
} from "@mb-pro-ui/utils/types/alarm";
import { Maybe } from "@mb-pro-ui/utils/types";
import { useGetAll, Filters } from "@mb-pro-ui/utils";
import { Page } from "@mb-pro-ui/components";

import HeaderActions from "../intervention/HeaderActions";
// import { Intervention } from "../operator/types";
import FULL_TIMESTAMP from "../utils/FULL_TIMESTAMP";
import {
  LogTextFilter,
  DateRangeFilter,
  CustomerAutocompleteFilter,
  OperatorAutocompleteFilter,
  EventCategoryAutocompleteFilter,
  SimpleBooleanFilter,
} from "../table/filters";
import {
  Filter as TableFilter,
  EnhancedTable,
  FilterView as TableFilterView,
} from "../table";
import LinkButton from "../utils/LinkButton";
import {
  dateRangeFilter,
  eqInNeqNinFilter,
  FilterOp,
  manyToManyFilter,
  nullFilter,
} from "../../utils/FilterUtils";
import ColoredRouterLink from "../utils/ColoredRouterLink";
import StyledText from "../utils/StyledText";
import AdvancedFilters from "../table/AdvancedFilters/AdvancedFilters";

type TableFilters = TableFilter[];

const filterSpec: { [id: string]: FilterOp } = {
  "log-entries": manyToManyFilter,
  customer: eqInNeqNinFilter,
  "session-operator": eqInNeqNinFilter,
  "event-category": eqInNeqNinFilter,
  "start-time": dateRangeFilter,
  "close-time": nullFilter,
};

const mapToServerFilters = (filters: TableFilters): Filters => {
  return filters.reduce((acc: Filters, filter) => {
    if (filter.id in filterSpec) {
      acc[filter.id] = filterSpec[filter.id](filter);
    }
    return acc;
  }, {});
};

const action = (row: Row<Intervention_>, url: string) => (
  <LinkButton
    row={row}
    url={`${url}/${row.original.id}`}
    message={<FormattedMessage defaultMessage={"Edit"} />}
  />
);

// const defaultFilters = {
//   "start-event": { null: "false" },
// } as const;

type Intervention_ = Pick<
  Intervention,
  "type" | "id" | "start-time" | "close-time"
> & {
  customer: Maybe<Pick<Customer, "id" | "name" | "account">>;
  session: Maybe<{
    operator: Pick<Operator, "id" | "login">;
  }>;
  "start-event": Maybe<
    Pick<
      Cdec,
      | "id"
      | "localized-description"
      | "category-sound"
      | "category-alert"
      | "color"
      | "active"
    >
  >;
};

const AllInterventionPage = () => {
  const { formatMessage, formatDate } = useIntl();
  const [filters, setFilters] = useState<Omit<Filters, "or">>({});
  const limit = filters && Object.keys(filters).length > 0 ? 1000 : 100;

  const { data: interventions, status } = useGetAll<Intervention_>(
    "alarm/interventions-all",
    {
      include: {
        customer: {},
        "session.operator": {},
        "start-event": {},
      },
      fields: {
        "interventions-all": [
          "start-time",
          "close-time",
          "session",
          "start-event",
          "customer",
        ],
        customers: ["name", "account"],
        "cdec-all": [
          "localized-description",
          "category-sound",
          "category-alert",
          "color",
          "active",
        ],
        sessions: ["operator"],
        operators: ["login"],
      },
      sort: ["-id"],
      keepPreviousData: true,
      filter: filters,
      page: { limit },
    }
  );

  const tableFilters = useMemo(
    (): TableFilterView[] => [
      { id: "log-entries", Filter: LogTextFilter },
      { id: "customer", Filter: CustomerAutocompleteFilter },
      { id: "session-operator", Filter: OperatorAutocompleteFilter },
      { id: "event-category", Filter: EventCategoryAutocompleteFilter },
      { id: "start-time", Filter: DateRangeFilter },
      {
        id: "close-time",
        Filter: SimpleBooleanFilter(
          defineMessage({ defaultMessage: "Open" }),
          defineMessage({ defaultMessage: "Closed" })
        ),
      },
    ],
    []
  );

  const columns = useMemo(
    (): Column<Intervention_>[] => [
      {
        id: "id",
        Header: formatMessage({
          defaultMessage: "ID",
          description: "AllIntervention table ID field or column label",
        }),
        accessor: (item) => item.id,
        width: 15,
      },
      {
        id: "customer",
        Header: formatMessage({
          defaultMessage: "Customer",
          description:
            "AllIntervention table customer (name) field or column label",
        }),
        accessor: (item) =>
          `${item.customer?.account} - ${item.customer?.name}`,
        width: 40,
        Cell: ({ value, row }: Cell<Intervention_>) => {
          return value ? (
            <ColoredRouterLink
              to={`/alarm/customers/${row.original.customer?.id}`}
            >
              {value}
            </ColoredRouterLink>
          ) : (
            <span>
              {formatMessage({
                defaultMessage: "Unknown customer",
              })}
            </span>
          );
        },
      },
      {
        id: "session-operator",
        Header: formatMessage({
          defaultMessage: "Operator",
          description:
            "AllIntervention table operator (username) field or column label",
        }),
        accessor: (item) => item.session?.operator?.login ?? "",
        width: 20,
      },
      {
        id: "start-event",
        Header: formatMessage({
          defaultMessage: "Event",
          description:
            "AllIntervention table start event of intervention column label",
        }),
        accessor: (item) => item["start-event"]?.["localized-description"],
        width: 40,
        Cell: ({ value, row }: Cell<Intervention_, string | null>) => (
          <StyledText
            color={row.original?.["start-event"]?.color}
            component="span"
          >
            {value ??
              `[${formatMessage({
                defaultMessage: "Event not found",
                description:
                  "The start-event of an intervention is missing, it probably has been deleted",
              })}]`}
          </StyledText>
        ),
      },
      {
        id: "start-time",
        Header: formatMessage({
          defaultMessage: "Start time",
          description:
            "AllIntervention table start time of intervention field or column label",
        }),
        accessor: (item) => formatDate(item["start-time"], FULL_TIMESTAMP),
        width: 30,
      },
      {
        id: "close-time",
        Header: formatMessage({
          defaultMessage: "Close time",
          description:
            "AllIntervention table close time of intervention field or column label",
        }),
        accessor: (item) =>
          item["close-time"]
            ? formatDate(item["close-time"], FULL_TIMESTAMP)
            : formatMessage({
                defaultMessage: "Open",
                description: "AllIntervention table open placeholder",
              }),
        width: 30,
      },
    ],
    [formatMessage, formatDate]
  );

  const handleFilterChange = useCallback(
    (filters: TableFilters) => {
      setFilters(mapToServerFilters(filters));
    },
    [setFilters]
  );

  const prefix = useMemo(() => {
    const resultCount =
      status === "success"
        ? `: ${interventions?.length}${
            interventions?.length === limit ? "+" : ""
          }`
        : "";
    return (
      <Typography color="primary.contrastText">
        {formatMessage({
          defaultMessage: "Interventions",
        })}
        {resultCount}
      </Typography>
    );
  }, [interventions, status, limit, formatMessage]);

  return (
    <Page sx={{ padding: "12px", overflow: "hidden" }}>
      <EnhancedTable
        columns={columns}
        data={interventions ?? []}
        action={action}
        actionCellWidth={130}
        actionPosition="left"
        queryStatus={status}
        overscanCount={20}
        prefix={prefix}
        postfix={<HeaderActions filters={filters} />}
        filters={
          <AdvancedFilters
            tableFilters={tableFilters}
            onFilterChange={handleFilterChange}
          />
        }
      />
    </Page>
  );
};

export default AllInterventionPage;

import React, { SyntheticEvent, useEffect, useState } from "react";

import {
  IconButton,
  Typography,
  List,
  ListItem,
  Divider,
  Box,
  lighten,
} from "@mui/material";

import { Button } from "@mb-pro-ui/components";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { Form, useForm, useFormState } from "react-final-form";
import {
  FieldArray as FinalFormFieldArray,
  useFieldArray,
} from "react-final-form-arrays";

import range from "lodash/range";

import { AnyObject } from "final-form";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/system";

import Widget from "../utils/Widget";
export interface FieldArrayProps<T> {
  name: string;
  label: string;
  placeholderMessage?: string;
  itemRenderer?: (fieldValue: T) => JSX.Element;
  labels?: { [key: string]: any };
  formDialogRenderer: () => JSX.Element;
  initialValues?: Partial<T>;
  hasAdd?: boolean;
  hasDelete?: boolean;
  readOnly?: boolean;
}

const SimpleFieldArray = <T,>({
  name,
  label,
  placeholderMessage,
  itemRenderer,
  formDialogRenderer,
  labels,
  initialValues,
  hasAdd = true,
  hasDelete = true,
  readOnly,
}: FieldArrayProps<T>) => {
  const { palette } = useTheme();

  const { formatMessage } = useIntl();

  const { change } = useForm();
  const { submitting } = useFormState();

  const { fields } = useFieldArray(name);
  const { push, update } = fields;

  const [add, setAdd] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [edit, setEdit] = useState<number | null>(null);
  const [deletedItems, setDeletedItems] = useState<any[]>([]);

  useEffect(() => {
    if (submitting) {
      setDeletedItems([]);
    }
  }, [setDeletedItems, submitting]);

  let submit:
    | ((
        event?:
          | Partial<
              Pick<
                SyntheticEvent<Element, Event>,
                "preventDefault" | "stopPropagation"
              >
            >
          | undefined
      ) => Promise<AnyObject | undefined> | undefined)
    | null = null;

  const onDelete = (index: number, values: any, ord: number) => {
    fields.remove(index);

    if (fields.length === 1) {
      change(name, null);
    }
    if (values.id) {
      setDeletedItems([...deletedItems, { ...values, ind: ord }]);
      setDeleteMode(false);
    }
  };

  const onUpdate = (index: number, values: any) => {
    update(index, values);
    setEdit(null);
  };

  const onSubmit = (vals: T) => {
    push(vals);
  };

  return (
    <>
      <Box
        paddingY={0}
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 1,
          height: "38px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: "5px",
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              marginLeft: "5px",
              marginRight: "auto",
            }}
          >
            {label}
          </Typography>
          {fields?.length && fields.length > 0 ? (
            <Typography
              variant="subtitle2"
              sx={{ margin: "8px", marginLeft: "5px" }}
            >
              {`(${fields.length})`}
            </Typography>
          ) : null}
        </Box>
        {readOnly || !hasDelete ? null : (
          <IconButton
            sx={{
              padding: 1,
              ...((fields?.length ?? 0) > 0
                ? {
                    color: "common.white",
                  }
                : {
                    color: "grey.300",
                  }),
            }}
            onClick={() => {
              if ((fields?.length ?? 0) > 0 && !readOnly) {
                setDeleteMode(!deleteMode);
              }
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        )}
        {readOnly || !hasAdd ? null : (
          <IconButton
            onClick={() => {
              if (!readOnly) setAdd(!add);
            }}
            size="large"
            sx={{
              color: "common.white",
              padding: 1,
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        )}
      </Box>
      <Widget
        placeholderMessage={
          placeholderMessage ? placeholderMessage : "No items "
        }
        children={
          (fields?.length ?? 0) > 0 || add || deletedItems.length > 0 ? (
            <>
              <FinalFormFieldArray name={name}>
                {({ fields }) => {
                  const newFields = fields.value ? [...fields.value] : [];

                  const arrayLength =
                    (fields?.length ?? 0) + deletedItems.length;

                  return fields.length || deletedItems.length > 0 ? (
                    <List
                      sx={{
                        "&.MuiList-root": {
                          paddingTop: "0",
                          paddingBottom: "0",
                        },
                      }}
                    >
                      {range(arrayLength).map((i) => {
                        if (deletedItems.some((item) => item.ind === i)) {
                          const deletedItem = deletedItems.find(
                            (item) => item.ind === i
                          );
                          return (
                            <React.Fragment key={`${name}-array-${i}`}>
                              {i !== 0 ? <Divider /> : null}
                              <ListItem
                                sx={{
                                  display: "flex",
                                  backgroundColor: "grey.300",
                                  textDecoration: "line-through",
                                  "MuiListItem-root": {
                                    paddingLeft: "0",
                                  },
                                  ...(deleteMode
                                    ? {
                                        justifyContent: "space-between",
                                      }
                                    : {}),
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {itemRenderer ? (
                                    itemRenderer(deletedItem)
                                  ) : labels ? (
                                    <>
                                      {Object.keys(labels ?? {}).map((e) => (
                                        <>
                                          <Typography
                                            sx={{
                                              color: "primary.light",
                                              fontSize: "0.8rem",
                                            }}
                                          >
                                            {labels[e] ?? ""}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              marginBottom: "2%",
                                            }}
                                          >
                                            {deletedItem[e] ?? ""}
                                          </Typography>
                                        </>
                                      ))}
                                    </>
                                  ) : null}
                                </Box>
                              </ListItem>
                            </React.Fragment>
                          );
                        } else {
                          const fieldValue = newFields.shift();

                          const fieldsCount =
                            arrayLength -
                            newFields.length -
                            1 -
                            deletedItems.length;

                          if (i === edit) {
                            return (
                              <React.Fragment key={`${name}-array-${i}`}>
                                <Box>
                                  {edit && i > 0 ? <Divider /> : null}
                                  <Form
                                    onSubmit={() => {}}
                                    initialValues={fieldValue}
                                    render={({
                                      values: subFormValues,
                                      invalid,
                                      pristine,
                                    }) => {
                                      return (
                                        <form>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              marginTop: "0",
                                              margin: "2%",
                                              border: `1px solid primary.main`,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              {formDialogRenderer()}
                                            </Box>
                                            <div style={{ display: "flex" }}>
                                              <div
                                                onClick={() => setEdit(null)}
                                              >
                                                <Button
                                                  mode="warning"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  {formatMessage({
                                                    defaultMessage: "Cancel",
                                                    description:
                                                      "Inline card component  editing item cancel button",
                                                  })}
                                                </Button>
                                              </div>
                                              <div
                                                style={{
                                                  marginRight: "10px",
                                                }}
                                                onClick={() =>
                                                  onUpdate(
                                                    fieldsCount,
                                                    subFormValues
                                                  )
                                                }
                                              >
                                                <Button
                                                  mode="secondary"
                                                  disabled={invalid || pristine}
                                                >
                                                  {formatMessage({
                                                    description:
                                                      "Inline card component editing item confirm button",
                                                    defaultMessage: "Confirm",
                                                  })}
                                                </Button>
                                              </div>
                                            </div>
                                          </Box>
                                        </form>
                                      );
                                    }}
                                  />
                                </Box>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={`${name}-array-${i}`}>
                                {i !== 0 ? <Divider /> : null}
                                <ListItem
                                  onClick={() => {
                                    setAdd(false);
                                    if (!deleteMode && !readOnly) {
                                      setEdit(i);
                                    }
                                  }}
                                  sx={{
                                    display: "flex",
                                    ...(readOnly ? {} : { cursor: "pointer" }),
                                    ...(deleteMode
                                      ? {
                                          justifyContent: "space-between",
                                        }
                                      : {}),
                                    ...(!fieldValue?.id
                                      ? {
                                          backgroundColor: lighten(
                                            palette.warning.light,
                                            0.6
                                          ),
                                        }
                                      : {}),
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {fieldValue ? (
                                      itemRenderer ? (
                                        itemRenderer(fieldValue)
                                      ) : labels ? (
                                        <>
                                          {Object.keys(labels ?? {}).map(
                                            (e, j) => (
                                              <React.Fragment
                                                key={`${name}-array-${i}.${j}`}
                                              >
                                                <Typography
                                                  sx={{
                                                    color: "primary.light",
                                                    fontSize: "0.8rem",
                                                  }}
                                                >
                                                  {labels[e] ?? ""}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    marginBottom: "2%",
                                                  }}
                                                >
                                                  {fieldValue[e] ?? ""}
                                                </Typography>
                                              </React.Fragment>
                                            )
                                          )}
                                        </>
                                      ) : null
                                    ) : null}
                                  </Box>

                                  {deleteMode ? (
                                    <IconButton
                                      size="large"
                                      sx={{
                                        padding: 0,
                                      }}
                                    >
                                      <DeleteIcon
                                        onClick={() => {
                                          onDelete(fieldsCount, fieldValue, i);
                                        }}
                                        sx={{
                                          color: "error.main",
                                        }}
                                      />
                                    </IconButton>
                                  ) : null}
                                </ListItem>
                              </React.Fragment>
                            );
                          }
                        }
                      })}
                    </List>
                  ) : null;
                }}
              </FinalFormFieldArray>
              {add ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "0",
                      margin: "2%",
                      border: `1px solid `,
                      borderColor: "primary.main",
                    }}
                  >
                    <Form
                      onSubmit={onSubmit}
                      initialValues={initialValues}
                      render={({ handleSubmit, invalid }) => {
                        submit = handleSubmit;
                        return (
                          <>
                            <form>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {formDialogRenderer()}
                              </Box>
                            </form>
                            <div style={{ display: "flex" }}>
                              <div onClick={() => setAdd(false)}>
                                <Button
                                  mode="warning"
                                  style={{ marginRight: "10px" }}
                                >
                                  {formatMessage({
                                    defaultMessage: "Cancel",
                                    description:
                                      "Inline card component adding new item cancel button",
                                  })}
                                </Button>
                              </div>
                              <div
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                  if (submit) {
                                    submit();
                                    setAdd(false);
                                  }
                                }}
                              >
                                <Button mode="secondary" disabled={invalid}>
                                  {formatMessage({
                                    description:
                                      "Inline card component adding new item confirm button",
                                    defaultMessage: "Confirm",
                                  })}
                                </Button>
                              </div>
                            </div>
                          </>
                        );
                      }}
                    />
                  </Box>
                </>
              ) : null}
            </>
          ) : null
        }
      />
    </>
  );
};

export default SimpleFieldArray;

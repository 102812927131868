import { useCallback, useEffect, useRef, useState } from "react";
import { useApi } from "../query/ApiProvider";
import streamQuery from "../query/streamQuery";
import { ID } from "../types";
import { ApiResponseMany, JsonapiError } from "./types";
import { GetAllParams, useGetAllSearchParams } from "./useGetAll";
import { useGetOneSearchParams } from "./useGetOne";
import { transformJsonapi } from "./utils";

export interface GetStreamParams<T>
  extends Pick<GetAllParams<T, T>, "include" | "fields" | "filter" | "enabled" | "onSuccess"> {
  refetchInterval?: number;
  page: {
    limit: number;
  };
}

type BaseData = { type: string; id: ID };

// function useIsChanged(value: any, name: string) {
//   useEffect(() => {
//     console.log(name, "changed");
//   }, [value, name]);
// }

export default function useGetStream<T extends BaseData>(
  type: T["type"],
  {
    include,
    fields,
    filter,
    page: { limit },
    enabled = true,
    refetchInterval,
    onSuccess,
  }: GetStreamParams<T[]>,
  cursor: keyof T & string = "id"
) {
  const [state, setState] = useState<{
    status: "idle" | "loading" | "success" | "error";
    isFetching: boolean;
    error?: JsonapiError;
    data?: T[];
    dataUpdatedAt?: number;
    failureCount?: number;
  }>({ status: "idle", isFetching: !!enabled });

  const triggerRef = useRef(() => {});
  const refetch = () => triggerRef.current();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const { includePaths, searchParams: getOneSearchParams } = useGetOneSearchParams({
    include,
    fields,
  });

  const getAllSearchParams = useGetAllSearchParams(getOneSearchParams, {
    filter,
    page: { limit },
    sort: [`-${cursor}`],
  });

  const api = useApi();

  // The value of this "callback" will change exactly when the query should be reset
  const fetchWithCursor = useCallback(
    async (value?: any) => {
      let response: Promise<ApiResponseMany>;
      if (!value) {
        response = api<ApiResponseMany>(`/${type}${getAllSearchParams}`);
      } else {
        const p = new URLSearchParams(getAllSearchParams);
        p.set(`filter[${cursor}][gt]`, String(value));
        response = api<ApiResponseMany>(`/${type}?${p.toString()}`);
      }
      return transformJsonapi(await response, includePaths);
    },
    [api, type, cursor, getAllSearchParams, includePaths]
  );

  // useIsChanged(fetchWithCursor, "fetchWithCursor");
  // useIsChanged(enabled, "enabled");
  // useIsChanged(cursor, "cursor");
  // useIsChanged(limit, "limit");
  // useIsChanged(refetchInterval, "refetchInterval");
  // useIsChanged(include, "include");
  // useIsChanged(includePaths, "includePaths");
  // useIsChanged(select, "select");
  // useIsChanged(state.data, "data");

  useEffect(() => {
    setState((s) => ({
      ...s,
      data: undefined,
      error: undefined,
      isFetching: false,
    }));

    if (enabled) {
      const [cancel, trigger] = streamQuery<T, JsonapiError>(fetchWithCursor, cursor, limit, {
        onLoading() {
          setState((s) => ({
            ...s,
            status: s.status === "idle" ? "loading" : s.status,
            isFetching: true,
          }));
        },
        onData(data) {
          setState((s) => ({
            ...s,
            status: "success",
            data,
            dataUpdatedAt: Date.now(),
            error: undefined,
            isFetching: false,
            failureCount: 0,
          }));
        },
        onNewData(data) {
          onSuccessRef.current?.(data);
        },
        onError(error) {
          setState((s) => ({
            ...s,
            status: "error",
            error,
            isFetching: false,
            failureCount: s.failureCount === undefined ? undefined : s.failureCount + 1,
          }));
        },
      });
      triggerRef.current = trigger;
      if (refetchInterval) {
        const handle = setInterval(trigger, refetchInterval);
        return () => {
          clearInterval(handle);
          cancel();
        };
      }
      return cancel;
    }
  }, [fetchWithCursor, enabled, cursor, limit, refetchInterval]);

  return {
    ...state,
    isLoading: state.status === "loading",
    isSuccess: state.status === "success",
    isError: state.status === "error",
    isFetching: state.isFetching,
    failureCount: state.failureCount,
    refetch,
  };
}

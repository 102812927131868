import { Autocomplete } from "@mb-pro-ui/components/form";

import { StyledMBPTextField } from "../form/StyledComponents";

import { useGetAll } from "@mb-pro-ui/utils";
import { FormSpy } from "react-final-form";

import Widget from "../utils/Widget";

import { Installer } from "./types";
import FormLayout from "../form/FormLayout";
import { useIntl } from "react-intl";
import { isEqualById } from "../utils/autocompleteUtils";

interface InstallersFormProps {
  readOnly?: boolean;
}

const InstallersForm = ({ readOnly }: InstallersFormProps) => {
  const { formatMessage } = useIntl();

  const { data: installers, isLoading: areInstallersLoading } =
    useGetAll<Installer>("alarm/installers", { refetchOnWindowFocus: false });

  return (
    <FormLayout
      firstChild={
        <Widget
          title={formatMessage({
            defaultMessage: "Installer details",
          })}
          isLoading={areInstallersLoading}
          children={
            installers ? (
              <>
                <Autocomplete
                  name="installer"
                  label={formatMessage({
                    defaultMessage: "Name",
                  })}
                  options={installers}
                  getOptionLabel={(option) => option?.name ?? ""}
                  isEqual={isEqualById}
                  sx={{
                    marginLeft: "8px",
                    width: "calc(100% - 16px)",
                  }}
                  readOnly={readOnly}
                  fullWidth
                />

                <FormSpy subscription={{ values: true }}>
                  {({ values }) =>
                    values["installer"] ? (
                      <>
                        <FormSpy subscription={{ values: true }}>
                          {(props) => (
                            <>
                              <StyledMBPTextField
                                value={
                                  props.values["installer"].company ?? undefined
                                }
                                name="installer.company"
                                label={formatMessage({
                                  defaultMessage: "Company",
                                })}
                                disabled
                              />
                              <StyledMBPTextField
                                name="installer.phone"
                                value={values["installer"]?.phone ?? undefined}
                                label={formatMessage({
                                  defaultMessage: "Phone",
                                })}
                                disabled
                              />
                              <StyledMBPTextField
                                name="installer.mobile"
                                value={values["installer"]?.mobile ?? undefined}
                                label={formatMessage({
                                  defaultMessage: "Mobile",
                                })}
                                disabled
                              />
                              <StyledMBPTextField
                                name="installer.email"
                                value={values["installer"]?.email ?? undefined}
                                label={formatMessage({
                                  defaultMessage: "Email",
                                })}
                                disabled
                              />
                            </>
                          )}
                        </FormSpy>
                      </>
                    ) : null
                  }
                </FormSpy>
              </>
            ) : null
          }
        />
      }
    />
  );
};

export default InstallersForm;

import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { Box, SvgIcon, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { Page, SubPage } from "@mb-pro-ui/components";
import { useParams } from "react-router-dom";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { TextField } from "@mb-pro-ui/components/form";
import { SnackbarState } from "../types";
import { Redirect } from "react-router";
import {
  Snackbar,
  StyledForm,
  UpdateFormActions,
  DeleteDialog,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { Installer as _Installer } from "@mb-pro-ui/utils/types/alarm";

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Installer editor"
        description="System settings page sidebar installer editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

interface FormValues
  extends Pick<
    _Installer,
    "id" | "name" | "company" | "address" | "phone" | "mobile" | "email"
  > {}

const postLoadFormat = ({
  id,
  name,
  company,
  address,
  phone,
  mobile,
  email,
}: _Installer): FormValues => ({
  id,
  name,
  company,
  address,
  phone,
  mobile,
  email,
});

const InstallerUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {
    data: installer,
    refetch,
    isLoading,
    isError,
  } = useGetOne<_Installer>("alarm/installers", id);
  const { formatMessage } = useIntl();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const name = installer?.name;
  const breadcrumbName = name || id;
  const { waitFor } = useBackendSchemas();

  const { mutate: batch, isLoading: isUpdating } = useUseBatchedChanges(
    "alarm",
    {
      setSnackbarState,
      refetch,
    }
  );

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/installers",
    {
      replaceTo: "/alarm/settings/installers",
      setSnackbarState,
    }
  );

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/installers/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff(
        "installers",
        installer as Record<string, unknown> | undefined,
        values,
        { schemas }
      ),
    ]);
  };

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  if (isError) {
    return <Redirect to="/alarm/settings/installers" />;
  }

  const prefix = <Typography color="primary.contrastText">{name}</Typography>;

  return (
    <Page
      backLink="/alarm/settings/installers"
      sidebarOptions={sidebarOptions}
      breadcrumbNameMap={finalBreadcrumbNameMap}
      breadcrumbs
      {...systemSettingsPageProps}
    >
      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={installer && postLoadFormat(installer)}
          render={({ handleSubmit, submitting, pristine, form }) => (
            <SubPage
              prefix={prefix}
              sx={{ width: "500px" }}
              innerSx={{ height: "auto" }}
            >
              <StyledForm>
                <TextField
                  name="name"
                  label={formatMessage({ defaultMessage: "Name" })}
                  required
                  requiredError={formatMessage({
                    defaultMessage: "Required",
                  })}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <TextField
                  name="company"
                  label={formatMessage({ defaultMessage: "Company" })}
                  parse={(v) => v}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <TextField
                  name="address"
                  label={formatMessage({ defaultMessage: "Address" })}
                  parse={(v) => v}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <TextField
                  name="phone"
                  type="tel"
                  label={formatMessage({ defaultMessage: "Phone" })}
                  parse={(v) => v}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <TextField
                  name="mobile"
                  type="tel"
                  parse={(v) => v}
                  label={formatMessage({ defaultMessage: "Mobile" })}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <TextField
                  name="email"
                  type="email"
                  parse={(v) => v}
                  label={formatMessage({ defaultMessage: "Email" })}
                  disabled={submitting || isLoading || isDeleting || isUpdating}
                />
                <UpdateFormActions
                  onSave={handleSubmit}
                  onReset={() => form.reset()}
                  onDelete={() => setDeleteDialogOpen(true)}
                  disabledDelete={submitting}
                  disabledReset={submitting || pristine}
                  disabledSave={submitting || pristine}
                  isLoading={isLoading}
                  isDeleting={isDeleting}
                />
              </StyledForm>
            </SubPage>
          )}
        />
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={installer?.id}
        resourceName={installer?.name ?? ""}
        handleClose={dialogOnclose}
        deleteResource={del}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default InstallerUpdatePage;

import { Autocomplete, Checkbox } from "@mb-pro-ui/components/form";

import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import FormLayout from "../form/FormLayout";

import Widget from "../utils/Widget";

import { LifesignParam } from "./types";

import { Lang } from "../types";
import { useLocale } from "../../locales/LocaleProvider";
import HideableFormField from "./profile/HideableFormField";
import { isEqualById } from "../utils/autocompleteUtils";

interface LifesignsFormProps {
  readOnly?: boolean;
}

const LifesignsForm = ({ readOnly }: LifesignsFormProps) => {
  const { locale } = useLocale();

  const { formatMessage } = useIntl();
  const { data: lifesignParams, isLoading: areLifesignParamsLoading } =
    useGetAll<LifesignParam>("alarm/lifesign-params", {
      refetchOnWindowFocus: false,
      sort: ["delta-t"],
    });

  return (
    <FormLayout
      firstChild={
        <Widget
          title={formatMessage({
            defaultMessage: "Lifesign rules",
          })}
          isLoading={areLifesignParamsLoading}
          children={
            lifesignParams ? (
              <>
                <HideableFormField name="lifesign-para">
                  <Autocomplete
                    name="lifesign-para"
                    label={formatMessage({
                      defaultMessage: "Lifesign check",
                    })}
                    options={lifesignParams}
                    fullWidth
                    getOptionLabel={(option) =>
                      option.descr?.[Lang[locale]] ?? ""
                    }
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                    required
                  />
                </HideableFormField>
                <HideableFormField name="gprs-lifesign-para">
                  <Autocomplete
                    name="gprs-lifesign-para"
                    label={formatMessage({
                      defaultMessage: "GSM lifesign check",
                    })}
                    options={lifesignParams}
                    fullWidth
                    required
                    getOptionLabel={(option) =>
                      option.descr?.[Lang[locale]] ?? ""
                    }
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                  />
                </HideableFormField>
                <HideableFormField name="complex-lifesign-para">
                  <Autocomplete
                    name="complex-lifesign-para"
                    label={formatMessage({
                      defaultMessage: "Complex lifesign check",
                    })}
                    options={lifesignParams}
                    fullWidth
                    getOptionLabel={(option) =>
                      option.descr?.[Lang[locale]] ?? ""
                    }
                    isEqual={isEqualById}
                    sx={{
                      marginLeft: "8px",
                      width: "calc(100% - 16px)",
                    }}
                    readOnly={readOnly}
                    required
                  />
                </HideableFormField>
                <HideableFormField name="lifesign-hide">
                  <Checkbox
                    name="lifesign-hide"
                    label={formatMessage({
                      defaultMessage: "Hide lifesign events",
                    })}
                    readOnly={readOnly}
                  />
                </HideableFormField>
                <HideableFormField name="gprs-lifesign-hide">
                  <Checkbox
                    name="gprs-lifesign-hide"
                    label={formatMessage({
                      defaultMessage: "Hide GPRS lifesign event",
                    })}
                    readOnly={readOnly}
                  />
                </HideableFormField>
              </>
            ) : null
          }
        />
      }
    />
  );
};

export default LifesignsForm;

import { Page, SubPage } from "@mb-pro-ui/components";
import { SideBarOption } from "@mb-pro-ui/components/SideBar";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackbarState, SystemSettingsPageProps } from "../types";
import { ReactComponent as BasicIcon } from "../../../icons/customer/BasicBlue.svg";
import { SvgIcon, Typography } from "@mui/material";
import { useParams } from "react-router";
import { diff, useBackendSchemas, useGetOne } from "@mb-pro-ui/utils";
import { useLocale } from "../../../locales/LocaleProvider";
import { Lang } from "../../types";
import { localDescr } from "../../../utils/LocaleUtils";
import { Box } from "@mui/system";
import {
  Category as _Category,
  Icon,
  Sound,
} from "@mb-pro-ui/utils/types/alarm";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import _ from "lodash";
import {
  DeleteDialog,
  Snackbar,
  StyledForm,
  UpdateFormActions,
  useUseBatchedChanges,
  useUseDelete,
} from "../utils";
import { Form } from "react-final-form";
import { Checkbox, NumericField, TextField } from "@mb-pro-ui/components/form";
import ColorTextField from "./ColorTextField";
import SoundSelect from "./SoundSelect";
import IconSelect from "./IconSelect";
import { hexToColor } from "./EventCategoryCreateModal";

type Category = Omit<
  Pick<
    _Category,
    | "type"
    | "id"
    | "descr"
    | "intervention-needed"
    | "priority"
    | "sound"
    | "icon"
  >,
  "sound" | "icon"
> & {
  color: { r: Maybe<number>; g: Maybe<number>; b: Maybe<number> };
  sound: Maybe<Sound>;
  icon: Maybe<Icon>;
};

type FormValues = Pick<Category, "id" | "descr" | "intervention-needed"> & {
  color?: string;
  priority: Maybe<number>;
  sound: Maybe<ID>;
  icon: Maybe<ID>;
};

export const colorToHex = ({
  r,
  g,
  b,
}: {
  r: Maybe<number>;
  g: Maybe<number>;
  b: Maybe<number>;
}) => {
  if (r && g && b) {
    let R = r.toString(16);
    let G = g.toString(16);
    let B = b.toString(16);
    if (R.length === 1) R = "0" + R;
    if (G.length === 1) G = "0" + G;
    if (B.length === 1) B = "0" + B;
    return "#" + R + G + B;
  }

  return undefined;
};

const postLoadFormat = ({
  id,
  descr,
  "intervention-needed": interventionNeeded,
  priority,
  color,
  sound,
  icon,
}: Category): FormValues => {
  return {
    id,
    descr,
    "intervention-needed": interventionNeeded,
    priority,
    color: colorToHex(color),
    sound: sound ? sound.id : null,
    icon: icon ? icon.id : null,
  };
};

const preSaveFormat = ({ color, ...rest }: FormValues) => {
  return {
    ...(color && { color: hexToColor(color) }),
    ...rest,
  };
};

const sidebarOptions: SideBarOption[] = [
  {
    link: "",
    name: (
      <FormattedMessage
        defaultMessage="Event category editor"
        description="System settings page sidebar event category editor option"
      />
    ),
    icon: <SvgIcon component={BasicIcon} />,
  },
];

const EventCategoryUpdatePage = ({
  breadcrumbNameMap,
  ...systemSettingsPageProps
}: SystemSettingsPageProps) => {
  const { id } = useParams<{ id: string }>();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { waitFor } = useBackendSchemas();
  const {
    data: category,
    isLoading,
    refetch,
  } = useGetOne<Category>("alarm/categories", id, {
    include: {
      sound: {},
      icon: {},
    },
  });
  const { locale } = useLocale();

  const descr = category?.descr
    ? localDescr(category?.descr, Lang[locale])
    : "";
  const breadcrumbName = descr || id;

  const finalBreadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      ...breadcrumbNameMap,
      [`/alarm/settings/event-categories/${id}`]: breadcrumbName,
    }),
    [id, breadcrumbName, breadcrumbNameMap]
  );

  const { mutate: batch } = useUseBatchedChanges("alarm", {
    refetch,
    setSnackbarState,
  });

  const { mutate: del, isLoading: isDeleting } = useUseDelete(
    "alarm/categories",
    {
      replaceTo: "/alarm/settings/event-categories",
      setSnackbarState,
    }
  );

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const dialogOnclose = () => {
    setDeleteDialogOpen(false);
  };

  const onSubmit = async (values: FormValues) => {
    const { alarm: schemas } = await waitFor(1000);
    batch([
      ...diff("categories", category, preSaveFormat(values), {
        schemas,
      }),
    ]);
  };

  const prefix = <Typography color="primary.contrastText">{descr}</Typography>;

  return (
    <Page
      backLink="/alarm/settings/event-categories"
      sidebarOptions={sidebarOptions}
      breadcrumbs
      breadcrumbNameMap={finalBreadcrumbNameMap}
      {...systemSettingsPageProps}
    >
      <Box sx={{ alignSelf: "flex-start" }}>
        <Form
          onSubmit={onSubmit}
          initialValues={category && postLoadFormat(category)}
          render={({ handleSubmit, submitting, pristine, form, values }) => {
            return (
              <SubPage
                prefix={prefix}
                sx={{ minWidth: "500px" }}
                innerSx={{ height: "auto" }}
              >
                <StyledForm>
                  <TextField
                    name={`descr.[${Lang[locale]}]`}
                    label={formatMessage(
                      { defaultMessage: "Name ({locale})" },
                      { locale }
                    )}
                    disabled={submitting}
                    required
                    requiredError={formatMessage({
                      defaultMessage: "Required",
                    })}
                  />
                  <NumericField
                    name={"priority"}
                    label={formatMessage({ defaultMessage: "Priority" })}
                  />
                  <Box sx={{ display: "flex" }}>
                    <ColorTextField />
                    <p>
                      {values.color
                        ? `Hex: ${values.color}`
                        : formatMessage({ defaultMessage: "(Unset)" })}
                    </p>
                  </Box>
                  <Box sx={{ display: "flex", marginLeft: "8px" }}>
                    <SoundSelect selectedId={values.sound} />
                  </Box>
                  <Box sx={{ marginRight: "8px", marginLeft: "8px" }}>
                    <IconSelect />
                  </Box>
                  <Checkbox
                    name={"intervention-needed"}
                    label={formatMessage({ defaultMessage: "Alert" })}
                    disabled={submitting}
                    labelSx={{
                      alignSelf: "flex-start",
                    }}
                  />
                  <UpdateFormActions
                    onSave={handleSubmit}
                    onReset={() => form.reset()}
                    onDelete={() => setDeleteDialogOpen(true)}
                    disabledDelete={submitting}
                    disabledReset={submitting || pristine}
                    disabledSave={submitting || pristine}
                    isLoading={isLoading}
                    isDeleting={isDeleting}
                  />
                </StyledForm>
              </SubPage>
            );
          }}
        />
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        resourceId={id}
        resourceName={descr}
        deleteResource={del}
        handleClose={dialogOnclose}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Page>
  );
};

export default EventCategoryUpdatePage;

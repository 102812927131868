import { useGetAll } from "@mb-pro-ui/utils";
import { Checkbox } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";
import { Guard } from "../types";
import GuardCreateModal from "./GuardCreateModal";
import { AllEntities } from "../utils";

const AllGuards = () => {
  const { formatMessage } = useIntl();
  const queryResult = useGetAll<Guard>("alarm/guards", {
    page: {
      limit: 10000,
    },
    sort: ["id"],
    filter: {
      deleted: {
        is: "false",
      },
    },
  });

  const columns = useMemo(
    (): Column<Guard>[] => [
      {
        id: "name",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Name",
        }),
        accessor: (guard) => guard.name || "",
      },
      {
        id: "company",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Company",
        }),
        accessor: (guard) => guard.company || "",
      },
      {
        id: "phone",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Phone",
        }),
        accessor: (guard) => guard.phone,
      },
      {
        id: "mobile",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Mobile",
        }),
        accessor: (guard) => guard.mobile || "",
      },
      {
        id: "remark",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Remark",
        }),
        accessor: (guard) => guard.remark,
      },
      {
        id: "active",
        width: 40,
        Header: formatMessage({
          defaultMessage: "Active",
        }),
        accessor: (installer) => installer.active,
        Cell: ({ value: isAlert }: Cell<Guard, boolean>) => (
          <Checkbox
            checked={isAlert}
            tabIndex={-1}
            disabled
            size="small"
            sx={{ padding: 0 }}
          />
        ),
      },
    ],
    [formatMessage]
  );

  const title = <FormattedMessage defaultMessage="Guards" />;

  return (
    <AllEntities
      queryResult={queryResult}
      columns={columns}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <GuardCreateModal
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
      title={title}
    />
  );
};

export default AllGuards;

import { ProfileCards, useProfile } from "./ProfileProvider";

interface HideableFormCardProps {
  name: keyof ProfileCards;
  children: JSX.Element;
}

const HideableFormCard = ({ name, children }: HideableFormCardProps) => {
  const profileWMode = useProfile();

  if (!profileWMode || !profileWMode.profile) {
    return children;
  }

  const { expertMode, profile } = profileWMode;

  if (profile?.cards[name] === 1 && expertMode) {
    return children;
  }
  if (profile?.cards[name] === 2) {
    return children;
  }
  return null;
};

export default HideableFormCard;

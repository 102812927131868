import { styled } from "@mui/system";

const SubForm = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "4px",
  marginRight: "4px",
  marginTop: "4px",

  width: "calc(33.33% - 10px)",
});

interface FormLayoutProps {
  firstChild: JSX.Element;
  secondChild?: JSX.Element;
  thirdChild?: JSX.Element;
}

const FormLayout = ({
  firstChild,
  secondChild,
  thirdChild,
}: FormLayoutProps) => {
  return (
    <>
      <SubForm sx={{ marginLeft: "8px" }}>{firstChild}</SubForm>
      <SubForm>{secondChild}</SubForm>
      <SubForm>{thirdChild}</SubForm>
    </>
  );
};

export default FormLayout;

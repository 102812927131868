import { ReactNode } from "react";
import "leaflet.markercluster";
import {
  MarkerClusterGroup as LeafletMarkerClusterGroup,
  MarkerCluster as LeafletMarkerCluster,
  MarkerClusterGroupOptions,
  LeafletEventHandlerFnMap,
  LayerEventHandlerFn,
} from "leaflet";
import { createLayerComponent, EventedProps } from "@react-leaflet/core";
import Marker from "./Marker";

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

type MarkerCluster = Omit<LeafletMarkerCluster, "getAllChildMarkers"> & {
  getAllChildMarkers(): Marker[];
};

interface ClusterGroupEvent {
  cluster: MarkerCluster;
  markers: Marker[];
}

type ClusterGroupEventHandlerFn = (event: ClusterGroupEvent) => void;

type ClusterEventHandlerFnMap = {
  [E in keyof LeafletEventHandlerFnMap as `cluster${E}`]?:
    | ((
        event: Omit<
          Parameters<Exclude<LeafletEventHandlerFnMap[E], undefined>>[0],
          "layer" | "propagatedFrom"
        > & {
          propagatedFrom: MarkerCluster;

          /**
           * @deprecated The same as {@link LeafletEvent.propagatedFrom propagatedFrom}.
           */
          layer: MarkerCluster;
        }
      ) => void)
    | undefined;
};

export interface ClusterGroupEventHandlerFnMap
  extends LeafletEventHandlerFnMap,
    ClusterEventHandlerFnMap {
  animationend?: LayerEventHandlerFn | undefined;
  spiderfied?: ClusterGroupEventHandlerFn | undefined;
  unspiderfied?: ClusterGroupEventHandlerFn | undefined;
}

export interface MarkerClusterGroupProps
  extends MarkerClusterGroupOptions,
    EventedProps {
  children?: ReactNode;
  eventHandlers?: ClusterGroupEventHandlerFnMap;
}

const MarkerClusterGroup = createLayerComponent<
  LeafletMarkerClusterGroup,
  MarkerClusterGroupProps
>(function createMarkerClusterGroup(props, ctx) {
  const instance = new LeafletMarkerClusterGroup({
    ...props,
    clusterPane: ctx.pane,
  });
  return {
    instance,
    context: { ...ctx, layerContainer: instance },
  };
});

export default MarkerClusterGroup;

import { lighten } from "@mui/material";
import clsx from "clsx";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import CheckboxInput, { CheckboxProps as CheckboxInputProps } from "../Checkbox";

type PassedThroughProps = Omit<
  CheckboxInputProps,
  "name" | "onBlur" | "onChange" | "onFocus" | "value" | "checked" | "error" | "defaultValue"
>;

export interface CheckboxProps extends PassedThroughProps, UseFieldConfig<boolean> {
  name: string;
  highlightDirty?: boolean;
}

const CheckboxAdapter = ({
  input,
  meta,
  className,
  label,
  readOnly,
  highlightDirty = true,
  sx = [],
  ...rest
}: FieldRenderProps<boolean>) => (
  <CheckboxInput
    label={label ?? input.name}
    className={clsx(className, meta.dirty && "Mbp-dirty")}
    sx={[
      meta.dirty &&
        !meta.error &&
        highlightDirty && {
          backgroundColor: (theme) => lighten(theme.palette.warning.light, 0.6),
        },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    readOnly={meta.submitting || readOnly}
    {...rest}
    {...input}
  />
);

const Checkbox = ({ defaultValue, defaultChecked, ...rest }: CheckboxProps) => (
  <Field
    type="checkbox"
    defaultValue={defaultValue ?? defaultChecked}
    component={CheckboxAdapter}
    {...rest}
  />
);

export default Checkbox;

import { Chip, InputAdornment } from "@mui/material";
import { useIntl } from "react-intl";

import { useGetAll } from "@mb-pro-ui/utils";
import { useMemo } from "react";
import {
  Category,
  CodeTable,
  Customer,
  Event,
  RegularReport,
  RegularReportInterval,
} from "@mb-pro-ui/utils/types/alarm";

import { StyledTextField } from "../form/StyledComponents";
import { Autocomplete } from "@mb-pro-ui/components/form";
import { FormRenderProps } from "react-final-form";
import { sortByLocalizedDescription } from "../../utils/FilterUtils";
import { isEqualById } from "../utils/autocompleteUtils";

const reportFormatLabels: any = {
  xlsx: "Excel (.xslx)",
  pdf: "PDF",
};

const reportFormats = ["xlsx", "pdf"];

type SavedQueryFormValues = Partial<RegularReport> & {};

function codeTableForCustomer(customer: Customer) {
  if (customer) {
    const ct = customer["code-table"];
    if (ct === null) return null;
    if (typeof ct === "string") return ct as string;
    return (ct as CodeTable).id;
  } else {
    return null;
  }
}

function customerLabel(customer: Customer) {
  if (customer) {
    if (customer.name) {
      return `${customer.account} - ${customer.name}`;
    }
    return customer.account;
  }
  return "";
}

function eventLabel(event: Event) {
  if (event) {
    if (event["localized-description"]) {
      return `${event.code} - ${event["localized-description"]}`;
    }
    return event.code;
  }
  return null;
}

export const SavedQueryForm = (
  props: FormRenderProps<SavedQueryFormValues>
) => {
  const { values } = props;
  const { formatMessage } = useIntl();

  const { data: customers } = useGetAll<Customer>("alarm/customers", {
    sort: ["account", "id"],
    refetchOnWindowFocus: false,
  });
  const { data: intervals } = useGetAll<RegularReportInterval>(
    "alarm/regular-report-intervals",
    {
      refetchOnWindowFocus: false,
    }
  );

  const [intervalOptions, intervalValues] = useMemo(() => {
    const options = [] as string[];
    const values: { [key: string]: RegularReportInterval } = {};
    intervals?.forEach((c) => {
      options.push(c.id);
      values[c.id] = c;
    });
    return [options, values];
  }, [intervals]);

  const [customerOptions, customerValues] = useMemo(() => {
    const options = [] as Customer[];
    const values: { [key: string]: Customer } = {};
    customers?.forEach((c) => {
      options.push(c);
      values[c.id] = c;
    });
    return [options, values];
  }, [customers]);

  const customer: Customer =
    typeof values.customer === "string"
      ? customerValues[values.customer as string]
      : (values.customer as Customer);
  const codeTableId = codeTableForCustomer(customer);
  const { data: events } = useGetAll<Event>("alarm/events", {
    refetchOnWindowFocus: false,
    enabled: codeTableId !== null,
    filter: {
      "code-table": { eq: codeTableId || undefined },
    },
    include: {
      category: {},
      "code-table": {},
    },
    sort: ["code", "id"],
  });

  const [categoryOptions, categoryValues, eventOptions, eventValues] =
    useMemo(() => {
      let categoryOptions = [] as string[];
      const categoryValues: { [key: string]: Category } = {};
      const eventOptions = [] as string[];
      const eventValues: { [key: string]: Event } = {};
      if (events) {
        const categories: Category[] = [];

        events.forEach((e) => {
          eventOptions.push(e.code);
          eventValues[e.code] = e;

          const c = e.category as Category;
          if (!(c.id in categoryValues)) {
            categories.push(c);
            categoryValues[c.id] = c;
          }
        });

        categories.sort(sortByLocalizedDescription);
        categoryOptions = categories.map((c) => c.id);
      }
      return [categoryOptions, categoryValues, eventOptions, eventValues];
    }, [events]);

  return (
    <>
      <Autocomplete
        name="customer"
        label={formatMessage({
          defaultMessage: "Customer",
        })}
        fullWidth
        required={true}
        isEqual={isEqualById}
        options={customerOptions}
        getOptionLabel={(option) => customerLabel(option) || ""}
      />

      <Autocomplete
        name="categories"
        label={formatMessage({
          defaultMessage: "Category",
        })}
        fullWidth
        disabled={categoryOptions.length === 0}
        options={categoryOptions}
        getOptionLabel={(option) =>
          categoryValues[option]?.["localized-description"] || option || ""
        }
        multiple={true}
      />
      <Autocomplete
        name="event-codes"
        label={formatMessage({
          defaultMessage: "Event",
        })}
        fullWidth
        disabled={eventOptions.length === 0}
        options={eventOptions}
        getOptionLabel={(option) =>
          eventLabel(eventValues[option]) || option || ""
        }
        multiple={true}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="filled" label={option} {...getTagProps({ index })} />
          ))
        }
      />

      <Autocomplete
        name="interval"
        label={formatMessage({
          defaultMessage: "Interval",
        })}
        fullWidth
        required
        disabled={intervalOptions.length === 0}
        options={intervalOptions}
        getOptionLabel={(option) =>
          intervalValues[option]?.["localized-description"] || option || ""
        }
      />
      <StyledTextField
        name="offset"
        label={formatMessage({
          defaultMessage: "Offset",
        })}
        sx={{ mx: "unset", width: "100%" }}
        type="time"
      />
      <StyledTextField
        name="description"
        label={formatMessage({
          defaultMessage: "Filename",
        })}
        sx={{ mx: "unset", width: "100%" }}
        placeholder={(values?.customer as Customer)?.account || "Events"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ "margin-top": "12px" }}>
              .{values.format}
            </InputAdornment>
          ),
        }}
      />

      <Autocomplete
        name="format"
        label={formatMessage({
          defaultMessage: "File format",
        })}
        fullWidth
        options={reportFormats}
        getOptionLabel={(option) => reportFormatLabels[option] || option || ""}
      />
    </>
  );
};

export default SavedQueryForm;

import { useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import InterventionForm from "../components/intervention/InterventionForm";
import AllInterventionPage from "../components/intervention/AllInterventionPage";
import { AcknowledgeProvider } from "../components/intervention/AcknowledgeContext";

const InterventionPage = () => {
  const { path } = useRouteMatch();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const onSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const onSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <Switch>
      <Route exact path={path}>
        <AllInterventionPage />
      </Route>
      <Route path={`${path}/:id`}>
        <AcknowledgeProvider>
          <InterventionForm
            sidebarOpen={sidebarOpen}
            onSidebarClose={onSidebarClose}
            onSidebarOpen={onSidebarOpen}
          />
        </AcknowledgeProvider>
      </Route>
    </Switch>
  );
};

export default InterventionPage;

/* The localStorage or a memoryStorage fallback */
import MemoryStorage from "./memory-storage";

function storageOrFallback(storage: Storage): Storage {
  const x = "__storage_test__";
  try {
    storage.setItem(x, x);
    storage.removeItem(x);
    return storage;
  } catch (error) {
    console.warn("The requested Storage API is unavailable. Falling back to in-memory storage.");
    return new MemoryStorage();
  }
}

const STORAGE = storageOrFallback(window.localStorage);

const VER = "v1.0.0";
const KEY = "session";
const URL = "/api";

/* In-memory representation of the stored data */
const DATA: { [key: string]: unknown } = {};

export type StorageEventHandler = (
  changes: {
    created: { [key: string]: unknown };
    replaced: { [key: string]: unknown };
    deleted: { [key: string]: unknown };
  },
  appStorage: AppStorage
) => void;

/* Subscribed callbacks to be notifed of any changes */
const eventHandlers: StorageEventHandler[] = [];

/* Keys of changed values since the last save */
let CREATED: { [key: string]: unknown } = {};
let DELETED: { [key: string]: unknown } = {};
let REPLACED: { [key: string]: unknown } = {};

export interface AppStorage {
  reset(): AppStorage;
  load(): AppStorage;
  save(): AppStorage;
  get<T>(key: string): T | null;
  set(key: string, value: unknown): AppStorage;
  assign(obj: { [key: string]: unknown }): AppStorage;
  has(key: string): boolean;
  hasAll(keys: string[]): boolean;
  remove(key: string): AppStorage;
  removeAll(keys: string[]): AppStorage;
  on(handler: StorageEventHandler): AppStorage;
  off(handler: StorageEventHandler): AppStorage;
}

const appStorage: AppStorage = {
  reset() {
    return appStorage.removeAll(Object.keys(DATA)).assign({ ver: VER, url: URL }).save();
  },

  load() {
    try {
      appStorage.removeAll(Object.keys(DATA));
      const str = STORAGE.getItem(KEY);
      if (str) {
        appStorage.assign(JSON.parse(str));
        if (appStorage.get("ver") === VER && appStorage.get("url") === URL) {
          return sendEvents();
        }
      }
    } catch (error) {
      console.error(error);
    }
    return appStorage.reset();
  },

  save() {
    for (const key of Object.keys(DELETED)) {
      delete DATA[key];
    }

    STORAGE.setItem(KEY, JSON.stringify(DATA));
    return sendEvents();
  },

  get<T>(key: string) {
    if (!DELETED[key]) {
      return (DATA[key] as T) ?? null;
    }
    return null;
  },

  set(key, value) {
    if (DELETED[key]) {
      delete DELETED[key];
    }

    if (DATA[key] !== value) {
      if (appStorage.has(key)) {
        if (!CREATED[key]) {
          REPLACED[key] = true;
        }
      } else {
        CREATED[key] = true;
      }
    }

    DATA[key] = value;
    return appStorage;
  },

  assign(obj) {
    Object.keys(obj).forEach((key) => {
      appStorage.set(key, obj[key]);
    });
    return appStorage;
  },

  has(key) {
    return !DELETED[key] && DATA.hasOwnProperty(key);
  },

  hasAll(keys) {
    return keys.every(appStorage.has);
  },

  remove(key) {
    if (appStorage.has(key)) {
      if (CREATED[key]) {
        delete CREATED[key];
        delete DATA[key];
      } else {
        delete REPLACED[key];
        DELETED[key] = true;
      }
    }
    return appStorage;
  },

  removeAll(keys) {
    keys.forEach(appStorage.remove);
    return appStorage;
  },

  on(handler) {
    if (eventHandlers.indexOf(handler) === -1) {
      eventHandlers.push(handler);
    }
    return appStorage;
  },

  off(handler) {
    const i = eventHandlers.indexOf(handler);
    if (i !== -1) {
      eventHandlers.splice(i, 1);
    }
    return appStorage;
  },
};

let once = false;
(() => {
  if (once) return;
  once = true;

  appStorage.load();

  window.addEventListener("storage", (event) => {
    if (event.storageArea === STORAGE && event.key === KEY && URL !== null) {
      appStorage.load();
    }
  });
})();

function sendEvents() {
  if (Object.keys(CREATED).length || Object.keys(DELETED).length || Object.keys(REPLACED).length) {
    const changes = {
      created: CREATED,
      replaced: REPLACED,
      deleted: DELETED,
    };

    eventHandlers.forEach((handler) => {
      try {
        handler(changes, appStorage);
      } catch (error) {
        console.error(error);
      }
    });

    if (Object.keys(CREATED).length) {
      CREATED = {};
    }
    if (Object.keys(DELETED).length) {
      DELETED = {};
    }
    if (Object.keys(REPLACED).length) {
      REPLACED = {};
    }
  }
  return appStorage;
}

export default appStorage;

import CustomerDataWidget from "./CustomerDataWidget";
import MapWidget from "./MapWidget";
import { Intervention } from "./types";
import { SubPage } from "@mb-pro-ui/components";

const CustomerData = ({ intervention }: { intervention?: Intervention }) => (
  <SubPage
    innerSx={{
      display: "grid",
      gridTemplateColumns: "1fr 3fr",
      gridTemplateRows: "1fr",
      gap: (theme) => theme.spacing(1),
    }}
  >
    <CustomerDataWidget
      intervention={intervention}
      sx={{ overflow: "hidden" }}
    />
    <MapWidget intervention={intervention} />
  </SubPage>
);

export default CustomerData;

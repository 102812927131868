import { Box, Typography } from "@mui/material";

const ProgressMessage = ({ message }: { message: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        "@keyframes scaling": {
          "0%, 100%": {
            transform: "scale(0.5)",
          },
          "50%": {
            transform: "scale(1)",
          },
        },
      }}
      display="flex"
      justifyContent="center"
    >
      <Typography sx={{ marginRight: "3px" }}>{message}</Typography>
      <Dot delay={"0.2s"} />
      <Dot delay={"0.4s"} />
      <Dot delay={"0.6s"} />
    </Box>
  );
};

const Dot = ({ delay }: { delay: string }) => {
  return (
    <Box
      sx={{
        height: "3px",
        width: "3px",
        backgroundColor: "currentColor",
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: "3px",
        animation: "scaling 2.5s ease-in-out infinite",
        alignSelf: "flex-end",
        marginBottom: "5.5px",
        animationDelay: delay,
      }}
    />
  );
};

export default ProgressMessage;

import { useState, useCallback, useMemo } from "react";
import deburr from "lodash/deburr";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useIntl, defineMessage as $d, FormatDateOptions } from "react-intl";
import { Page, SubPage } from "@mb-pro-ui/components";
import GlobalFilter from "@mb-pro-ui/components/table/filters/GlobalFilter";
import { Filters, useGetAll } from "@mb-pro-ui/utils";
import { Customer as Customer_ } from "@mb-pro-ui/utils/types/alarm";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import Map, { defaultAlarmProfile, intToColor } from "../map/Map";
import {
  boolFilter,
  eqInNeqNinFilter,
  FilterOp,
  patternFilter,
} from "../utils/FilterUtils";
import { Filter as TableFilter } from "../components/table";
import { SimpleBooleanFilter } from "../components/table/filters";

import AdvancedFilters from "../components/table/AdvancedFilters/AdvancedFilters";
import CustomerGroupAutocompleteFilter from "../components/customer/filters/CustomerGroupAutocompleteFilter";
import ObjectTypeAutocompleteFilter from "../components/customer/filters/ObjectTypeAutocompleteFilter";
import FULL_TIMESTAMP from "../components/utils/FULL_TIMESTAMP";
import { AnyIcon } from "../map/icons";
import { Icon, MapProfile, MarkerInfo, MarkerProps } from "../map/types";
import RefreshControl from "../components/utils/RefreshControl";
import {
  CustomerModalActions,
  CustomerModalContents,
} from "../components/operator/cdec/CustomerModal";

const tableFilters = [
  {
    id: "active",
    Filter: SimpleBooleanFilter(
      $d({ defaultMessage: "Active" }),
      $d({ defaultMessage: "Inactive" })
    ),
  },
  { id: "group", Filter: CustomerGroupAutocompleteFilter },
  { id: "object-type", Filter: ObjectTypeAutocompleteFilter },
];

const filterSpec: { [id: string]: FilterOp } = {
  account: patternFilter,
  name: patternFilter,
  active: boolFilter,
  address: patternFilter,
  unitid: patternFilter,
  group: eqInNeqNinFilter,
  "object-type": eqInNeqNinFilter,
};

const mapToServerFilters = (filters: TableFilter[]): Filters => {
  const result: Filters = {};
  if (filters !== null) {
    filters.forEach((filter) => {
      if (filter.id in filterSpec) {
        result[filter.id] = filterSpec[filter.id](filter);
      }
    });
  }
  return result;
};

type Customer = Pick<
  Customer_,
  | "type"
  | "id"
  | "account"
  | "name"
  | "address"
  | "active"
  | "phone"
  | "mobile"
  | "email"
> & {
  mapprofile: Maybe<MapProfile>;
} & (
    | {
        "has-valid-base-coords": true;
        "base-latitude": number;
        "base-longitude": number;
      }
    | {
        "has-valid-base-coords": false;
        "base-latitude": null;
        "base-longitude": null;
      }
  ) &
  (
    | {
        "has-valid-move-coords": true;
        latitude: number;
        longitude: number;
        "coord-update-ts": string;
      }
    | {
        "has-valid-move-coords": false;
        latitude: null;
        longitude: null;
        "coord-update-ts": null;
      }
  );

type Poi = {
  id: ID;
  type: `${"alarm/" | ""}pois`;
  "localized-description": Maybe<string>;
  latitude: number;
  longitude: number;
  icon: Maybe<Icon>;
};

function createFilter(
  partial: string,
  { caseSensitive = false, deburr: shouldDeburr = true } = {}
) {
  partial = partial.trim();

  if (!partial) {
    return false;
  }

  if (!caseSensitive) {
    partial = partial.toLowerCase();
  }
  if (shouldDeburr) {
    partial = deburr(partial);
  }

  return function (full: string) {
    if (typeof full !== "string") {
      console.log("not a string", typeof full, full);
      return false;
    }
    if (!caseSensitive) {
      full = full.toLowerCase();
    }
    if (shouldDeburr) {
      full = deburr(full);
    }
    return full.includes(partial);
  };
}

interface MapObjectInfo {
  id: string;
  icon: Icon | null;
  mapProfile: MapProfile;
  primary: string;
  secondary?: string;
  customer: Customer;
}

const defaultFilters = {
  "has-valid-coords": { is: "true" },
} as const;

function customerLabel(customer: Customer) {
  return `${customer.account} - ${customer.name}`;
}

function customerMarkerToDialogListItem(
  m: MarkerInfo<Customer>,
  formatDate: (d: string, opts?: FormatDateOptions) => string
): MapObjectInfo {
  return {
    id: m.id,
    icon: m.icon,
    mapProfile: m.mapProfile,
    primary: customerLabel(m.obj),
    secondary: m.id.endsWith("@base@customer")
      ? "Honos koordináták"
      : formatDate(m.obj["coord-update-ts"] ?? "", FULL_TIMESTAMP),
    customer: m.obj,
  };
}

const MapTrackingPage = () => {
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [filters, setFilters] = useState<Omit<Filters, "or">>(defaultFilters);
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  const limit = 10000;
  const { formatDate, formatMessage } = useIntl();

  const {
    data: customers,
    status,
    refetch,
    dataUpdatedAt,
  } = useGetAll<Customer>("alarm/customers", {
    fields: {
      customers: [
        "account",
        "name",
        "address",
        "phone",
        "mobile",
        "email",
        "base-latitude",
        "base-longitude",
        "latitude",
        "longitude",
        "coord-update-ts",
        "active",
        "mapprofile",
        "has-valid-base-coords",
        "has-valid-move-coords",
      ],
      icons: ["raw-data", "media-type", "icon"],
    },
    include: {
      mapprofile: {
        icon: {},
        "track-icon": {},
        "alarm-icon": {},
        "base-icon": {},
      },
    },
    filter: {
      ...defaultFilters,
      ...filters,
    },
    page: { limit },
    keepPreviousData: true,
    refetchInterval: !!refetchInterval && refetchInterval * 1000,
  });

  const {
    data: pois,
    status: poiStatus,
    refetch: refetchPois,
    dataUpdatedAt: poiDataUpdatedAt,
  } = useGetAll<Poi>("alarm/pois", {
    fields: {
      pois: ["localized-description", "latitude", "longitude", "icon"],
      icons: ["raw-data", "media-type", "icon"],
    },
    include: {
      icon: {},
    },
    filter: {
      latitude: { null: "false" },
      longitude: { null: "false" },
      ...(filters["object-type"]
        ? { "object-type": filters["object-type"] }
        : {}),
    },
    page: { limit },
    keepPreviousData: true,
    refetchInterval: !!refetchInterval && refetchInterval * 1000,
  });

  const handleFilterChange = useCallback(
    (filters: TableFilter[]) => {
      setFilters(Object.assign(mapToServerFilters(filters), defaultFilters));
    },
    [setFilters]
  );

  const prefix = useMemo(() => {
    const message =
      status === "success" && poiStatus === "success"
        ? formatMessage(
            { defaultMessage: "Query Results: {count}{mark}" },
            {
              count: (customers?.length ?? 0) + (pois?.length ?? 0),
              mark:
                customers?.length === limit || pois?.length === limit
                  ? "+"
                  : "",
            }
          )
        : formatMessage(
            { defaultMessage: "Query Status: {status}" },
            { status }
          );
    return <Typography color="primary.contrastText">{message}</Typography>;
  }, [
    customers?.length,
    pois?.length,
    status,
    poiStatus,
    limit,
    formatMessage,
  ]);

  const matches = createFilter(globalFilter ?? "");

  const filteredCustomers = useMemo(() => {
    if (!Array.isArray(customers) || !matches) {
      return customers;
    }

    return customers.filter(
      (customer) =>
        matches(customer.account) ||
        matches(customer.name ?? "") ||
        matches(customer.address ?? "")
    );
  }, [customers, matches]);

  const filteredPois = useMemo(() => {
    if (!Array.isArray(pois) || !matches) {
      return pois;
    }

    return pois.filter((poi) => matches(poi["localized-description"] ?? ""));
  }, [pois, matches]);

  const markers = useMemo(() => {
    const ms: MarkerProps[] = [];
    if (!filteredCustomers) {
      return ms;
    }

    for (const customer of filteredCustomers) {
      if (customer["has-valid-base-coords"]) {
        ms.push({
          id: `${customer.id}@base@customer`,
          position: {
            lat: customer["base-latitude"],
            lng: customer["base-longitude"],
          },
          type: "alarm@base",
          label: `${customer.account} - ${customer.name}`,
          mapProfile: customer.mapprofile ?? defaultAlarmProfile,
          obj: customer,
        });
      }

      if (customer["has-valid-move-coords"]) {
        ms.push({
          id: `${customer.id}@customer`,
          position: {
            lat: customer.latitude as number,
            lng: customer.longitude as number,
          },
          type: "alarm@icon",
          label: `${customer.account} - ${customer.name} @ ${formatDate(
            customer["coord-update-ts"],
            FULL_TIMESTAMP
          )}`,
          mapProfile: customer.mapprofile ?? defaultAlarmProfile,
          obj: customer,
        });
      }
    }

    return ms;
  }, [filteredCustomers, formatDate]);

  const poiMarkers: MarkerProps[] = useMemo(() => {
    return (
      filteredPois?.map((poi) => ({
        id: `${poi.id}@poi`,
        position: {
          lat: poi.latitude,
          lng: poi.longitude,
        },
        type: "alarm@icon",
        label: poi["localized-description"],
        mapProfile: {
          ...defaultAlarmProfile,
          icon: poi.icon,
        },
        interactive: false,
        obj: poi,
      })) ?? []
    );
  }, [filteredPois]);

  const [groupList, setGroupList] = useState<MapObjectInfo[]>();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedCustomer, selectCustomer] = useState<Customer | null>(null);

  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const openDialog = useCallback((markers: MapObjectInfo[]) => {
    setGroupList(markers);
    selectCustomer(markers.length === 1 ? markers[0].customer : null);
    setShowDialog(true);
  }, []);

  return (
    <Page sx={{ padding: "12px" }}>
      <SubPage
        showHeader={true}
        prefix={
          <>
            {prefix}
            <GlobalFilter
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              placement="left"
            />
          </>
        }
        postfix={
          <RefreshControl
            onClick={() => {
              refetch();
              refetchPois();
            }}
            value={refetchInterval}
            onChange={setRefetchInterval}
            dataUpdatedAt={Math.min(dataUpdatedAt, poiDataUpdatedAt)}
          />
        }
        innerSx={{ display: "flex", flexDirection: "column" }}
      >
        <AdvancedFilters
          tableFilters={tableFilters}
          onFilterChange={handleFilterChange}
        />
        <Map
          unclusteredMarkers={poiMarkers}
          clusteredMarkers={markers}
          onGroupClick={(markers) => {
            openDialog(
              (markers as MarkerInfo<Customer>[])
                .sort((a, b) => a.obj.account.localeCompare(b.obj.account))
                .map((m) => customerMarkerToDialogListItem(m, formatDate))
            );
          }}
          onMapObjectClick={(marker) => {
            openDialog([
              customerMarkerToDialogListItem(
                marker as MarkerInfo<Customer>,
                formatDate
              ),
            ]);
          }}
        ></Map>
        <Dialog open={showDialog} onClose={closeDialog}>
          <DialogTitle
            sx={{
              padding: (theme) => theme.spacing(2),
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                maxWidth: "70%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selectedCustomer
                ? selectedCustomer.name ?? ""
                : "Csoportosított ikonok"}
            </span>
          </DialogTitle>
          {selectedCustomer && (groupList?.length ?? 0) > 1 && (
            <IconButton
              aria-label="back"
              sx={(theme) => ({
                position: "absolute",
                left: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: "unset",
                },
              })}
              onClick={() => selectCustomer(null)}
              disableRipple
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: "unset",
              },
            })}
            onClick={closeDialog}
            disableRipple
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={[
              selectedCustomer
                ? {
                    minWidth: "550px",
                  }
                : {
                    minWidth: "450px",
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
              {
                transition: (theme) =>
                  theme.transitions.create("minWidth", {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeIn,
                  }),
              },
            ]}
          >
            {selectedCustomer ? (
              <CustomerModalContents customer={selectedCustomer} />
            ) : (
              <List>
                {groupList?.map((obj) => (
                  <ListItem
                    button
                    key={obj.id}
                    onClick={() => selectCustomer(obj.customer)}
                  >
                    <ListItemIcon
                      sx={{ minWidth: "30px", marginRight: "10px" }}
                    >
                      {obj.icon && (
                        <AnyIcon
                          key={obj.id}
                          icon={obj.icon}
                          color={intToColor(obj.mapProfile["line-color"])}
                          sx={{ width: "40px", height: "40px" }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={obj.primary}
                      secondary={obj.secondary}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>
          {selectedCustomer && (
            <DialogActions>
              <CustomerModalActions customer={selectedCustomer} />
            </DialogActions>
          )}
        </Dialog>
      </SubPage>
    </Page>
  );
};

export default MapTrackingPage;

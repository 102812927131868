import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Schema } from "../jsonapi/types";
import { MBProSocketProvider } from "../socket.io";
import { useUserInfo } from "../state/user-info";
import { http, httpWithAuth, isHttpError } from "./http";

export type CategorySchemas = {
  [Type in string]: Schema<Type>;
};

export type BackendSchemas = {
  alarm: CategorySchemas;
  gps: CategorySchemas;
  admin: CategorySchemas;
  fridge: CategorySchemas;
};

type BackendSchemasValue = { waitFor: (timeout?: number) => Promise<BackendSchemas> };
// | { data: undefined; isLoading: true }
// | { data: BackendSchemas; isLoading: false };

const BackendSchemasContext = createContext<BackendSchemasValue | undefined>(undefined);

const BackendSchemasProvider = ({ baseUrl, children }: PropsWithChildren<{ baseUrl: string }>) => {
  const subscriptions = useRef<Array<(schemas: BackendSchemas) => void>>([]);

  const handleSuccess = useCallback((schemas: BackendSchemas) => {
    for (const fn of subscriptions.current) {
      try {
        fn(schemas);
      } catch (error) {
        console.error(error);
      }
    }
    subscriptions.current = [];
  }, []);

  const { data } = useQuery<BackendSchemas>(
    "schemas.json",
    () => http<BackendSchemas>(`${baseUrl}/schemas.json`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      onSuccess: handleSuccess,
    }
  );

  const waitFor = useCallback(
    async (timeout?: number) => {
      if (data) {
        return data;
      }

      return new Promise<BackendSchemas>((resolve, reject) => {
        let timer: ReturnType<typeof setTimeout> | undefined;

        const fn = (schemas: BackendSchemas) => {
          if (timer) {
            clearTimeout(timer);
          }

          resolve(schemas);
        };

        subscriptions.current.push(fn);

        if (timeout) {
          timer = setTimeout(() => {
            subscriptions.current.splice(subscriptions.current.indexOf(fn), 1);
            reject(new Error("Timeout"));
          }, timeout);
        }
      });
    },
    [data]
  );

  return (
    <BackendSchemasContext.Provider
      value={useMemo(() => ({ waitFor } as BackendSchemasValue), [waitFor])}
    >
      {children}
    </BackendSchemasContext.Provider>
  );
};

export const useBackendSchemas = () => {
  const value = useContext(BackendSchemasContext);
  if (value === undefined) {
    throw new Error("useBackendSchemas must be used within an BackendSchemasProvider");
  }
  return value;
};

export const useBackendSchema = <T extends string>(fullType: `${keyof BackendSchemas}/${T}`) => {
  const { waitFor } = useBackendSchemas();
  return {
    waitFor: useCallback(
      async (timeout?: number) => {
        const schemas = await waitFor(timeout);
        const [category, type] = fullType.split("/") as [keyof BackendSchemas, T];
        return schemas[category][type] as Schema<T>;
      },
      [waitFor, fullType]
    ),
  };
};

type BaseUrl = string;

const BaseUrlContext = createContext<BaseUrl | undefined>(undefined);

export const useBaseUrl = () => {
  const baseUrl = useContext(BaseUrlContext);
  if (baseUrl === undefined) {
    throw new Error("useBaseUrl must be used within an BaseUrlProvider");
  }
  return baseUrl;
};

type Api = <T>(path: string, options?: RequestInit) => Promise<T>;

const ApiContext = createContext<Api | undefined>(undefined);

export type ApiProvderProps = PropsWithChildren<{ baseUrl: string }>;

export const ApiProvider = ({ baseUrl, children }: ApiProvderProps) => {
  const [sid, setSid] = useUserInfo();
  const client = useRef(new QueryClient());

  const api = useMemo(() => {
    if (sid) {
      return async <T,>(path: string, options?: RequestInit) => {
        try {
          return await httpWithAuth<T>(sid, `${baseUrl}${path}`, options);
        } catch (error) {
          if (isHttpError(error) && error.status === 401) {
            setSid(null);
          }
          throw error;
        }
      };
    } else {
      return <T,>(path: string, options?: RequestInit) => http<T>(`${baseUrl}${path}`, options);
    }
  }, [sid, baseUrl, setSid]);

  return (
    <MBProSocketProvider baseUrl={baseUrl}>
      <QueryClientProvider client={client.current}>
        <BackendSchemasProvider baseUrl={baseUrl}>
          <BaseUrlContext.Provider value={baseUrl}>
            <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
          </BaseUrlContext.Provider>
        </BackendSchemasProvider>
      </QueryClientProvider>
    </MBProSocketProvider>
  );
};

export const useApi = () => {
  const api = useContext(ApiContext);
  if (api === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return api;
};

import { IntlShape, useIntl } from "react-intl";
import type { NumberFormatOptionsUnitDisplay } from "@formatjs/ecma402-abstract";

interface FormattedDurationProps {
  value: number;
  children?(value: string): React.ReactElement | null;
  unitDisplay?: NumberFormatOptionsUnitDisplay;
}

export const formatDuration = (
  intl: IntlShape,
  value: number,
  unitDisplay?: NumberFormatOptionsUnitDisplay
) => {
  const { formatNumber, formatList } = intl;

  const hours = Math.floor(value / 60 / 60);
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  const parts = [];

  if (hours) {
    parts.push(
      formatNumber(hours, {
        style: "unit",
        unit: "hour",
        unitDisplay,
      })
    );
  }

  if (minutes) {
    parts.push(
      formatNumber(minutes, {
        style: "unit",
        unit: "minute",
        unitDisplay,
      })
    );
  }

  if (seconds) {
    parts.push(
      formatNumber(seconds, {
        style: "unit",
        unit: "second",
        unitDisplay,
      })
    );
  }

  if (parts.length > 0) {
    return formatList(parts);
  }

  return formatNumber(0, {
    style: "unit",
    unit: "second",
    unitDisplay,
  });
};

const FormattedDuration = ({
  value,
  unitDisplay,
  children,
}: FormattedDurationProps) => {
  const intl = useIntl();
  const formattedDuration = formatDuration(intl, value, unitDisplay);

  if (typeof children === "function") {
    return children(formattedDuration);
  }

  if (intl.textComponent) {
    return <intl.textComponent>{formattedDuration}</intl.textComponent>;
  }

  return <>{formattedDuration}</>;
};

FormattedDuration.defaultProps = {
  value: 0,
  unitDisplay: "short",
};

export default FormattedDuration;

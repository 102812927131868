import { useState } from "react";

import {
  Divider,
  FormControlLabel,
  FormGroup,
  Popover,
  Switch,
  Typography,
  SvgIcon,
} from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";

import { ReactComponent as FilterListIcon } from "../../../../icons/FilterList.svg";

import { FormattedMessage, useIntl } from "react-intl";

import { FilterState } from "../CdecWindow";
import { styled } from "@mui/system";

interface FilterButtonProps {
  filterState: FilterState;
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(1),
}));

const StyledTypoGraphy = styled(Typography)({
  padding: "10px",
});

export const FilterButton = ({
  filterState,
  onFilterChange: handleFilterChange,
}: FilterButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage } = useIntl();

  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        title={formatMessage({
          defaultMessage: "Filter events",
          description: "Események táblázat esemény szűrés tooltip",
        })}
      >
        <IconButton
          onClick={handleClick}
          size="large"
          sx={{
            color: (theme) =>
              Object.entries(filterState).some((state) => Boolean(state[1]))
                ? theme.palette.error.main
                : theme.palette.common.white,
          }}
        >
          <SvgIcon component={FilterListIcon} color="inherit" />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock
      >
        <FormGroup
          sx={{
            maxWidth: "220px",
          }}
        >
          <StyledFormControlLabel
            control={
              <Switch
                checked={!!filterState.hideNonAlertEvents}
                onChange={handleFilterChange}
                color="primary"
                name="hideNonAlertEvents"
                disableRipple
                size="small"
              />
            }
            label={
              <StyledTypoGraphy>
                <FormattedMessage defaultMessage="Hide none alert events" />
              </StyledTypoGraphy>
            }
          />
          <Divider />
          <StyledFormControlLabel
            control={
              <Switch
                checked={!!filterState.hideInactiveEvents}
                onChange={handleFilterChange}
                color="primary"
                name="hideInactiveEvents"
                disableRipple
                size="small"
              />
            }
            label={
              <StyledTypoGraphy>
                <FormattedMessage
                  defaultMessage="Hide inactive events"
                  description="Események táblázat inaktív események elrejtése"
                />
              </StyledTypoGraphy>
            }
          />
          <Divider />
          <StyledFormControlLabel
            control={
              <Switch
                checked={!!filterState.hideUnknownCustomers}
                onChange={handleFilterChange}
                color="primary"
                name="hideUnknownCustomers"
                disableRipple
                size="small"
              />
            }
            label={
              <StyledTypoGraphy>
                <FormattedMessage
                  defaultMessage="Hide unknown customers"
                  description="Események táblázat ismeretlen ügyfelek elrejtése"
                />
              </StyledTypoGraphy>
            }
          />
          <Divider />
          <StyledFormControlLabel
            control={
              <Switch
                checked={!!filterState.hideUnknownEvents}
                onChange={handleFilterChange}
                color="primary"
                name="hideUnknownEvents"
                disableRipple
                size="small"
              />
            }
            label={
              <StyledTypoGraphy>
                <FormattedMessage
                  defaultMessage="Hide unknown events"
                  description="Események táblázat ismeretlen események elrejtése"
                />
              </StyledTypoGraphy>
            }
          />
        </FormGroup>
      </Popover>
    </>
  );
};

export default FilterButton;

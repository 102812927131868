import { SubPage } from "@mb-pro-ui/components";

import { Intervention } from "./types";
import { EventsWidget } from "./EventsWidget";
import { SubtasksWidget } from "./SubtasksWidget";
import { LogWidget } from "./LogWidget";
import { RemarksWidget } from "./RemarksWidget";

export const InterventionWorkSheet = ({
  intervention,
  id,
  isOwn,
  refetchIntervention,
}: {
  intervention?: Intervention;
  id: string;
  isOwn: boolean;
  refetchIntervention: () => Promise<any>;
}) => (
  <SubPage
    elevation={0}
    sx={{
      backgroundColor: "background.default",
      overflow: "auto",
    }}
    innerSx={{
      display: "grid",
      gridTemplateColumns:
        "[start] 2fr [line2] minmax(300px, 1.5fr) [line3] 2fr [end]",
      gridTemplateRows: "1.5fr 1fr",
      gap: 1,
      overflow: "hidden",
    }}
  >
    <SubtasksWidget
      intervention={intervention}
      isOwn={isOwn}
      refetchIntervention={refetchIntervention}
      sx={{
        gridRowStart: 1,
        gridRowEnd: 3,
      }}
    />
    <LogWidget
      id={id}
      isOwn={isOwn}
      intervention={intervention}
      refetchIntervention={refetchIntervention}
      sx={{
        gridRowStart: 2,
        gridRowEnd: 3,
      }}
    />
    <RemarksWidget intervention={intervention} />
    <EventsWidget
      key={id}
      isOwn={isOwn}
      intervention={intervention}
      refetchIntervention={refetchIntervention}
      sx={{
        gridColumnStart: "start",
        gridRowStart: 1,
        gridRowEnd: 3,
        height: "100%",
        width: "100%",
      }}
    />
  </SubPage>
);

import { useGetAll } from "@mb-pro-ui/utils";
import { ListItem, ListItemIcon, ListItemText, TableRowProps } from "@mui/material";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column, Row } from "react-table";
import moment from "moment";
import { useTheme } from "@mui/system";

import { Email } from "@mb-pro-ui/utils/types/admin";
import { EnhancedTable } from "../table";
import LinkButton from "../utils/LinkButton";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingIcon from '@mui/icons-material/Pending';
import BootstrapTooltip from "../utils/BootstrapTooltip";
import SentEmailDialog from "./SentEmailDialog";


interface EmailGroup {
  description: string;
  count: number;
}

type EmailListEntry = Email | EmailGroup;

const EmailTableRowProps = (row: Row<EmailListEntry>): Partial<TableRowProps> => {
  const g = row.original as EmailGroup;
  if (g && g.count) {
    return { selected: true, style: { fontSizeAdjust: 1.5 } };
  } else {
    return {};
  }
};

const SentEmailList = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [cutoff] = useState(() => {
    return moment().subtract(3, "month").startOf('day').toISOString();
  });

  const { data: emails, status:queryStatus } = useGetAll<Email>("admin/emails", {
    page: {
      limit: 10000,
    },
    sort: ["-created", "id"],
    include: {
        "user": {},
    },
    filter: {
      created: {
        gte: cutoff,
      },
      "options.type": {
          eq: "prepared-email"
      },
    },
  });

  const [showEmail, setShowEmail] = useState<Email>();
  const closeShowEmailDialog = () => {
    setShowEmail(undefined);
  }

  const groupedEmails = useMemo(() => {
    const result: EmailListEntry[] = [];

    let last: EmailGroup;
    emails?.forEach((e) => {
      const cd = moment(e.created).format(
        formatMessage({
          defaultMessage: "MM/DD/YYYY",
        })
      );

      if (last?.description !== cd) {
        const g: EmailGroup = {
          description: cd,
          count: 0,
        };
        result.push(g);
        last = g;
      }
      last.count++;
      result.push(e);
    });

    return result;
  }, [emails, formatMessage]);


  const columns = useMemo(
    (): Column<EmailListEntry>[] => [
      {
        id: "description",
        width: 500,
        Header: formatMessage({
          defaultMessage: "Description",
        }),
        accessor: (email) => email.description,
        Cell: ({ row, value }: Cell<EmailListEntry>) => {
          const group = row.original as EmailGroup;
          if (group?.count) {
            return (
              <b>
                {group.description} ({group.count})
              </b>
            );
          }
          const email = row.original as Email;

          return (
            <ListItem>
              <ListItemIcon sx={{minWidth: "20px"}}>
              {email.status === "success" && (
                  <CheckCircleOutlineOutlinedIcon
                    color="success"
                    fontSize="inherit"
                  />
                )}
              {(email.status === "pending" || email.status === "in_progress") && (
                  <PendingIcon
                    color="primary"
                    fontSize="inherit"
                  />
                )}
              {email.status === "failure" && (
                <BootstrapTooltip
                  title={formatMessage({
                    defaultMessage: "Email sending failed",
                    description: "Failed export message",
                  })}
                  backgroundColor={theme.palette.error.light}
                >
                  <ErrorOutlineOutlinedIcon color="error" fontSize="inherit" />
                </BootstrapTooltip>
              )}
              </ListItemIcon>
              <ListItemText>{value}</ListItemText>
            </ListItem>
          );
        },


      },
      {
        id: "created",
        width: 120,
        Header: formatMessage({
          defaultMessage: "Created",
        }),
        accessor: (email) => {
          const t = (email as Email)?.created;
          if (t) {
            return moment(t).format(
              formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
            );
          } else {
            return "";
          }
        },        
      },
    ],
    [formatMessage, theme]
  );

  return (
    <>
      <EnhancedTable
        data={groupedEmails}
        columns={columns}
        queryStatus={queryStatus}
        sx={{ height: "100%" }}
        emptyLineCount={1}
        getRowProps={EmailTableRowProps}

        action={(row, _url) => {  
          const email = row.original as Email;
          if (email && email.id) {
          return (<LinkButton
            row={row}
            message={<FormattedMessage defaultMessage={"View"} />}
            onClick={()=>setShowEmail(email)}
            />);
          } else {
            return null;
          }

        }}
        actionPosition="right"
        actionCellWidth={120}

      />
      {showEmail &&
        <SentEmailDialog open={!!showEmail} email={showEmail} onFormClose={closeShowEmailDialog} />
      }

    </>
  );
};

export default SentEmailList;

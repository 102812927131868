import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Route, Switch, useRouteMatch, useParams } from "react-router";
import { Redirect } from "react-router-dom";

import { useIntl } from "react-intl";

import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import {
  diff,
  useBackendSchemas,
  useGetAll,
  useGetOne,
  useBatchedChanges,
  isHttpError,
} from "@mb-pro-ui/utils";

import { Page } from "@mb-pro-ui/components";

import { Category, Customer as _Customer } from "@mb-pro-ui/utils/types/alarm";

import RfidForm from "../components/customer/Rfid";
import ZonesForm from "../components/customer/Zones";
import BasicForm from "../components/customer/Basic";
import UsersForm from "../components/customer/Users";
import ContractForm from "../components/customer/Contract";
import PositionForm from "../components/customer/Position";
import LifesignsForm from "../components/customer/Lifesigns";
import InstallersForm from "../components/customer/Installer";
import PartitionsForm from "../components/customer/Partitions";
import MapProfileForm from "../components/customer/MapProfile";
import EventRulesForm from "../components/customer/EventRules";
import NotifiablesForm from "../components/customer/Notifiables";
import IntervalRulesForm from "../components/customer/IntervalRules";
import EquipmentInfoForm from "../components/customer/EquipmentInfo";
import RegularReportsForm from "../components/customer/RegularReports";
import ExternalReceiverForm from "../components/customer/ExternalReceiver";
import EventCancellationForm from "../components/customer/EventCancellation";
import AutomaticNotificationForm from "../components/customer/AutomaticNotification";

import { Customer, FormValues } from "../components/customer/types";
import {
  apiResponseToForm,
  formValuesForSave,
} from "../components/customer/utils";
import { styled } from "@mui/system";
import { CircularProgress, Snackbar } from "@mui/material";

import CustomerFormHeader from "../components/customer/CustomerFormHeader";

import ProfileProvider, {
  Profile,
  useProfile,
} from "../components/customer/profile/ProfileProvider";

import { getMenuOptions } from "../components/customer/utils";
import ManualNotificationForm from "../components/customer/ManualNotifications";

const StyledForm = styled("form")(() => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  overflow: "visible",
  flex: 1,
}));

const Customers = () => {
  const readOnly = false;

  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);

  const { expertMode, profile, setProfile } = useProfile();

  const { formatMessage } = useIntl();

  const { data: categories, isLoading: areCategoriesAreLoading } =
    useGetAll<Category>("alarm/categories", {
      refetchOnWindowFocus: false,
      filter: {
        "intervention-needed": {
          is: "true",
        },
      },
    });

  const {
    data,
    isLoading: isCustomerLoading,
    error,
    refetch,
  } = useGetOne<Customer, [Customer, FormValues]>("alarm/customers", id, {
    include: {
      "action-groups": {
        color: {},
        rules: {},
        subactions: {
          authority: {},
          guard: {},
          installer: {},
          "notifiable-person": {},
        },
      },
      "area-rules": {
        area: {},
      },
      "base-sector": {},
      "battery-type": {},
      "code-table": {},
      commands: {},
      "complex-lifesign-para": {},
      "controllable-devices": {},
      "custom-fields": {},
      "customer-manager-profile": {},
      "date-code-overrides": {},
      "disabled-events": {},
      "equipment-type": {},
      "event-storno": {},
      firesignal: {},
      "gprs-lifesign-para": {},
      group: {},
      installer: {},
      "interval-rules": {
        "date-code": {},
        "event-category": {},
      },
      "lifesign-para": {},
      mapprofile: {},
      maps: {},
      "notifiable-persons": {
        notification: {},
      },
      "notification-config": {},
      notifications: {
        sms: {},
        email: {},
        "sms-recipients": {},
        "email-recipients": {},
      },
      "object-type": {},
      partitions: {},
      "rc-commands": {},
      "rfid-tokens": {},
      sector: {
        groundplan: {},
      },
      "sounder-type": {},
      users: {},
      zones: {
        sector: {},
        "sensor-type": {},
        "zone-type": {},
      },
    },
    fields: {
      "action-groups": ["+subactions", "+rules", "-customer"],
      "action-rules": ["-group"],
      "area-rules": ["-customer"],
      "code-tables": ["descr"],
      "controllable-devices": ["-customer"],
      "customer-commands": ["-customer"],
      "customer-groups": ["description"],
      "customer-notifications": ["-customer"],
      "customer-rc-commands": ["-customer"],
      customers: [
        "+zones",
        "+partitions",
        "+notifiable-persons",
        "+action-groups",
        "+event-storno",
        "+interval-rules",
        "+date-code-overrides",
        "+users",
        "+rfid-tokens",
        "+disabled-events",
        "+area-rules",
        "+maps",
        "+controllable-devices",
        "+commands",
        "+notifications",
        "+rc-commands",
      ],

      "date-code-overrides": ["-customer"],
      "disabled-events": ["-customer"],
      "event-storno": ["-customer"],
      "interval-rules": ["-customer"],
      "lifesign-params": ["descr"],
      maps: ["-customer"],
      "notifiable-persons": ["-customer"],
      "object-types": ["descr"],
      partitions: ["-customer"],
      "rfid-tokens": ["-customer"],
      subactions: ["-action-group"],
      users: ["-customer"],
      zones: ["-customer"],
    },
    select: useCallback(
      (customer: Customer): [Customer, FormValues] => [
        customer,
        apiResponseToForm(customer, categories as any),
      ],
      [categories]
    ),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!categories,
    structuralSharing: false,
  });

  const customer = data?.[0];
  const initialValue = data?.[1];
  const customerProfile = customer?.["customer-manager-profile"]?.settings;

  const options = useMemo(
    () => getMenuOptions(profile, expertMode, formatMessage),
    [expertMode, profile, formatMessage]
  );

  useEffect(() => {
    setProfile((customerProfile as any as Profile) ?? null);
  }, [customerProfile, setProfile]);

  const breadcrumbNameMap: { [key: string]: string } = useMemo(
    () => ({
      [`/alarm/customers`]: formatMessage({
        defaultMessage: "Customer",
        description: "Customer page breadcumb starter",
      }),
      [`/alarm/customers/${id}`]: initialValue?.account ?? "",
      [`/alarm/customers/${id}/installers`]: formatMessage({
        defaultMessage: "Installers",
      }),
      [`/alarm/customers/${id}/equipment-information`]: formatMessage({
        defaultMessage: "Technical informations",
      }),
      [`/alarm/customers/${id}/partitions`]: formatMessage({
        defaultMessage: "Partitions",
      }),
      [`/alarm/customers/${id}/zones`]: formatMessage({
        defaultMessage: "Zones",
      }),
      [`/alarm/customers/${id}/users`]: formatMessage({
        defaultMessage: "Users",
      }),
      [`/alarm/customers/${id}/external-receiver`]: formatMessage({
        defaultMessage: "External receiver",
      }),
      [`/alarm/customers/${id}/interval-rules`]: formatMessage({
        defaultMessage: "Interval rules",
      }),
      [`/alarm/customers/${id}/event-cancellation`]: formatMessage({
        defaultMessage: "Event cancellation",
      }),
      [`/alarm/customers/${id}/lifesigns`]: formatMessage({
        defaultMessage: "Lifesigns",
      }),
      [`/alarm/customers/${id}/regular-reports`]: formatMessage({
        defaultMessage: "Regular reports",
      }),
      [`/alarm/customers/${id}/map-profile`]: formatMessage({
        defaultMessage: "Map profile",
      }),
      [`/alarm/customers/${id}/position`]: formatMessage({
        defaultMessage: "Location",
      }),
      [`/alarm/customers/${id}/rfid-tokens`]: formatMessage({
        defaultMessage: "RFID",
      }),
      [`/alarm/customers/${id}/manual-notifications`]: formatMessage({
        defaultMessage: "Manual notifications",
      }),
      [`/alarm/customers/${id}/automatic-notifications`]: formatMessage({
        defaultMessage: "Automatic notifications",
      }),
      [`/alarm/customers/${id}/notifiables`]: formatMessage({
        defaultMessage: "Notifiables",
      }),
      [`/alarm/customers/${id}/event-rules`]: formatMessage({
        defaultMessage: "Event rules",
      }),
      [`/alarm/customers/${id}/contract`]: formatMessage({
        defaultMessage: "Contract",
      }),
    }),
    [formatMessage, id, initialValue]
  );

  const { waitFor } = useBackendSchemas();

  const { mutateAsync } = useBatchedChanges("alarm");

  const submitHandler = useCallback(
    async (values: any) => {
      const { alarm: schemas } = await waitFor(1000);

      console.log("Original:", customer);
      console.log("New:", formValuesForSave(values, categories as any));

      console.log(
        "Diff:",
        ...diff(
          "customers",
          customer as any,
          formValuesForSave(values, categories as any),
          {
            schemas,
          }
        )
      );

      try {
        await mutateAsync([
          ...diff(
            "customers",
            customer as any,
            formValuesForSave(values, categories as any),
            {
              schemas,
            }
          ),
        ]);
        refetch();
      } catch (e) {
        setSnackbarError(true);
        setSnackbarOpen(true);
      }
      setSnackbarOpen(true);
    },
    [customer, waitFor, mutateAsync, refetch, categories]
  );

  const snackbarOnClose = () => {
    setSnackbarOpen(false);
    setSnackbarError(false);
  };

  const formRef = useRef<HTMLFormElement>(null);

  if (isCustomerLoading || areCategoriesAreLoading) {
    return (
      <Page sx={{ alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </Page>
    );
  }

  if (isHttpError(error) && error.status === 404) {
    return <Redirect to="/alarm/customers" />;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={snackbarOnClose}
        message={
          snackbarError
            ? formatMessage({
                defaultMessage: "Unsuccessfull operation",
              })
            : formatMessage({
                defaultMessage: "Saved succesfully",
              })
        }
        sx={{
          "& > div": {
            backgroundColor: snackbarError ? "error.main" : "primary.main",
          },
        }}
      />
      {initialValue ? (
        <Form
          onSubmit={submitHandler}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValue}
          render={({ values, dirty, handleSubmit, valid }) => (
            <Page
              sidebarAnchor="left"
              sidebarOptions={options.map(({ id: _, ...restOpt }) => restOpt)}
              breadcrumbs
              breadcrumbNameMap={breadcrumbNameMap}
              postfix={
                <CustomerFormHeader
                  formRef={formRef}
                  id={initialValue.id}
                  account={initialValue.account}
                  formDirty={dirty}
                  formValid={valid}
                  readOnly={readOnly}
                  hasExpertMode={!!customer?.["customer-manager-profile"]}
                />
              }
              sx={{
                "& .MuiToolbar-root": {
                  marginLeft: 0,
                },
              }}
            >
              <StyledForm
                onSubmit={async (event) => {
                  if (dirty && valid) handleSubmit(event);
                }}
                ref={formRef}
              >
                <Switch>
                  <Route path={`${path}/lifesigns`}>
                    <LifesignsForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/regular-reports`}>
                    <RegularReportsForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/installers`}>
                    <InstallersForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/equipment-information`}>
                    <EquipmentInfoForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/manual-notifications`}>
                    <ManualNotificationForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/automatic-notifications`}>
                    <AutomaticNotificationForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/map-profile`}>
                    <MapProfileForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/position`}>
                    <PositionForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/partitions`}>
                    <PartitionsForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/zones`}>
                    <ZonesForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/event-cancellation`}>
                    <EventCancellationForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/users`}>
                    <UsersForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/external-receiver`}>
                    <ExternalReceiverForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/rfid-tokens`}>
                    <RfidForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/interval-rules`}>
                    <IntervalRulesForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/notifiables`}>
                    <NotifiablesForm readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/event-rules`}>
                    <EventRulesForm values={values} readOnly={readOnly} />
                  </Route>
                  <Route path={`${path}/contract`}>
                    <ContractForm readOnly={readOnly} />
                  </Route>
                  <Route path={path}>
                    <BasicForm readOnly={readOnly} />
                  </Route>
                </Switch>
                {/* <FormSpy onChange={(e) => console.log(e.values)} /> */}
              </StyledForm>
            </Page>
          )}
        />
      ) : null}
    </>
  );
};

const CustomerPage = () => {
  return (
    <ProfileProvider>
      <Customers />
    </ProfileProvider>
  );
};

export default CustomerPage;

import { useGetAll } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FilterViewProps } from "../../table";
import { ObjectType } from "../types";
import { AutocompleteFilter } from "../../table/filters";

import {
  idAsLabel,
  filterOptionsFromDescription,
  autoFilterOptions,
} from "../../../utils/FilterUtils";

type ApiType = Omit<ObjectType, "descr">;
type OptionType = { id: string; description: string | null };

const renderOption = (v: OptionType) => (
  <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
    {v.description}
  </Typography>
);

const ObjectTypeAutocompleteFilter = ({
  noNoType,
  ...props
}: FilterViewProps & { noNoType?: boolean }) => {
  const { formatMessage } = useIntl();

  const { data } = useGetAll<ApiType, OptionType[]>("alarm/object-types", {
    fields: { "object-types": ["localized-description"] },
    sort: ["localized-description"],
    refetchOnWindowFocus: false,
    select: (original) =>
      autoFilterOptions(
        original,
        ({ id, "localized-description": description }) => ({ id, description }),
        !noNoType && {
          description: formatMessage({
            defaultMessage: "No object type",
          }),
        }
      ),
  });

  return (
    <AutocompleteFilter
      {...props}
      options={data}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      noOptionsText={formatMessage({
        defaultMessage: "No such object type",
        description: "Autocomplete Filter",
      })}
      label={formatMessage({
        defaultMessage: "Object type",
      })}
      inputPlaceholder={formatMessage({
        defaultMessage: "Object types: Name",
        description: "Autocomplete Filter",
      })}
      virtualizedRowHeight={40}
    />
  );
};

export default ObjectTypeAutocompleteFilter;

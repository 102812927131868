import { Filters, useCreate, useEvents } from "@mb-pro-ui/utils";
import {
  Box,
  Button,
  IconButton,
  Link,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { DateRangeFilter } from "../table/filters";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ReportTable, { ReportQueryRef } from "./ReportTable";
import moment from "moment";
import Widget from "../utils/Widget";

const filter: Filters = {
  "report-type": {
    eq: "regular-report",
  },
};

const prefix = (
  <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
    <FormattedMessage defaultMessage="Regular reports" />
  </Typography>
);

const noop = () => {}


const RegularReports = () => {
  const { formatMessage } = useIntl();
  const [reportID, setReportID] = useState<string>();
  const [reportURL, setReportURL] = useState<string>();
  const [successIDs, setSuccessIDs] = useState<
    { id: string; result: string }[]
  >([]);
  const [failureIDs, setFailureIDs] = useState<string[]>([]);

  const [dateFilter, setDateFilter] = useState<any>();

  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);

  const queryRef = useRef<ReportQueryRef>();

  const { mutate: createReport } = useCreate("admin/reports", {
    onSuccess: (data) => {
      setReportID(data.id);
    },
    onError: () => {
        setSnackBarMessage(
        formatMessage({
          defaultMessage: "Export request failed",
          description: "Failed export request message",
        })
      );
    },
  });

  const handleNotification = useCallback((event: string, notification: any) => {
    if (event === "report") {
      if (notification.$channel === "UPDATE@public.reports") {
        if (queryRef.current) {
            queryRef.current.refetch?.();
        }
        if (notification.status === "success") {
          setSuccessIDs((previous) => [
            ...previous,
            { id: String(notification.id), result: notification.result },
          ]);
        } else if (notification.status === "failure") {
          setFailureIDs((previous) => [...previous, notification.id]);
        }
      }
    }
  }, []);

  useEvents(["report"], handleNotification);

  const handleSnackbarClose = (_event: Event | SyntheticEvent<any>, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "timeout") {
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Please try again",
          description: "Export try again message",
        })
      );
    } else {
      setSnackBarMessage(null);
    }
  };

  useEffect(() => {
    const successObj = successIDs.find((obj) => obj.id === reportID);
    if (successObj) {
      setReportURL(successObj.result);
      setSnackBarMessage(
        formatMessage({
          defaultMessage: "Export success",
          description: "Successful export message",
        })
      );
      setReportID(undefined);
    }
  }, [reportID, successIDs, formatMessage]);

  useEffect(() => {
    if (reportID) {
      if (failureIDs.indexOf(reportID) >= 0) {
        setSnackBarMessage(
          formatMessage({
            defaultMessage: "Export failed",
            description: "Failed export message",
          })
        );
      }
    }
  }, [reportID, failureIDs, formatMessage]);

  const action = (
    <>
      {reportURL && (
        <Link
          href={reportURL}
          target="_blank"
          rel="noopener"
          onClick={() => {
            setSnackBarMessage(null);
          }}
          sx={{ mr: 1 }}
        >
          {formatMessage({ defaultMessage: "Download" })}
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const reportTitle = (): string => {
    let title = formatMessage({ defaultMessage: "Regular report" });

    if (dateFilter) {      
      let from = "";
      let to = "";
      if (dateFilter[0]) {
        from = moment(dateFilter[0]).format(
          formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
        );
      }
      if (dateFilter[1]) {
        to = moment(dateFilter[1]).format(
          formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
        );
      }
      if (to || from) {
        title = `${title} (${from} - ${to})`;
      }
    }
    return title;
  };

  const setFilter = useCallback((_id: string, value: any, _reversed?: boolean) => {
    setDateFilter(value);
  }, [setDateFilter]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "8px",

          maxHeight: "100%",
          width: "100%",
        }}
      >
        <Widget sx={{ paddingTop: "8px", paddingLeft: "8px" }}>
          <Typography>
            <FormattedMessage
              defaultMessage="Regular reports contain the events in the specified interval in
            separate PDFs for each customer. You can change the included events on
            the Edit customer page: open / close events, or all events. If neither
            options is selected, then the customer will not appear in the regular
            reports."
            />
          </Typography>

          <Toolbar
            sx={{
              alignItems: "normal",
              display: "flex",
              columnGap: "15px",
              flexWrap: "wrap",
            }}
          >
            <DateRangeFilter
              filterId="date"
              filterValue={dateFilter}
              setFilter={setFilter}
              urlSearchParam={null}
              setUrlSearchParam={noop}
            />
            <Button
              onClick={() => {
                const options: { gte?: string; lte?: string } = {};
                if (dateFilter && dateFilter[0]) options.gte = dateFilter[0];
                if (dateFilter && dateFilter[1]) options.lte = dateFilter[1];

                setReportURL(undefined);
                createReport({
                  description: reportTitle(),
                  "report-type": "regular-report",
                  options,
                });
                setSnackBarMessage(
                  formatMessage({
                    defaultMessage: "Exporting...",
                    description: "Currently exporting snackbar message",
                  })
                );
              }}
              sx={{
                color: "primary",
              }}
            >
              <Tooltip
                title={formatMessage({
                  defaultMessage: "Export",
                  description: "Export button tooltip",
                })}
                placement="bottom-start"
              >
                <DownloadIcon fontSize="inherit" />
              </Tooltip>
              <FormattedMessage
                defaultMessage={"Begin"}
                description={"Begin running regular report"}
              />
            </Button>
          </Toolbar>
          <Snackbar
            open={!!snackBarMessage}
            onClose={handleSnackbarClose}
            autoHideDuration={reportURL ? null : 10000}
            message={snackBarMessage}
            action={action}
          />
        </Widget>
      </Box>

      <ReportTable prefix={prefix} filter={filter} queryRef={queryRef} />
    </>
  );
};

export default RegularReports;

import { Checkbox } from "@mb-pro-ui/components/form";

import Widget from "../utils/Widget";
import FormLayout from "../form/FormLayout";
import { useIntl } from "react-intl";
import HideableFormField from "./profile/HideableFormField";

interface RegularReportsFormProps {
  readOnly?: boolean;
}

const RegularReportsForm = ({ readOnly }: RegularReportsFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormLayout
      firstChild={
        <Widget
          title={formatMessage({
            defaultMessage: "Regular reports",
            description: "Customer editor regular reports card name",
          })}
          children={
            <>
              <HideableFormField name="open-close-list">
                <Checkbox
                  name="open-close-list"
                  label={formatMessage({
                    defaultMessage: "Open/Close list",
                  })}
                  readOnly={readOnly}
                />
              </HideableFormField>
              <HideableFormField name="full-list">
                <Checkbox
                  name="full-list"
                  label={formatMessage({
                    defaultMessage: "Full list",
                  })}
                  readOnly={readOnly}
                />
              </HideableFormField>
            </>
          }
        />
      }
    />
  );
};

export default RegularReportsForm;

import {
  useMemo,
} from "react";
import { FormDialog } from "@mb-pro-ui/components";
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Email } from "@mb-pro-ui/utils/types/admin";
import { EmailRecipient, EmailRecipients } from "@mb-pro-ui/utils/types/admin/emails";


function unifiedRecipients(r?:EmailRecipients) : EmailRecipient[] {
    if (!r) return [];
    if (Array.isArray(r)) {
        if (r.length > 0 && typeof(r[0]) === 'string') {
            return (r as string[]).map(address => ({address}) as EmailRecipient)
        } else {
            return r as EmailRecipient[];
        }
    }
    if (typeof(r) === 'string') return [{ address: r}];
    return [(r as any as EmailRecipient)];
}

export interface SentEmailDialogProps {
    email: Email;
    open: boolean;
    onFormClose: () => void;
  }

const SentEmailDialog = ({
  email,
  open,
  onFormClose,
}: SentEmailDialogProps) => {

  const { formatMessage } = useIntl();

  const [to, bcc] = useMemo(() => {
      return [unifiedRecipients(email.options.to), unifiedRecipients(email.options.bcc)];
  }, [email]);

  return (
    <FormDialog
      title="Email details"
      open={open}
      hasCancel={false}
      confirmLabel={formatMessage({
        defaultMessage: "OK",
      })}      
      onDialogAction={() => {
        onFormClose();
      }}
    >
      <List dense disablePadding>
        <ListItem>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Recipients" />}
            secondary={to.map((r) => (
              <Typography key={r.address}>
                  {r.name &&
                  `${r.name} <${r.address}>`}
                  {!r.name && r.address}
              </Typography>
            ))}
          />
        </ListItem>
        {bcc.length > 0 && (
          <ListItem>
            <ListItemText
              primary={<FormattedMessage defaultMessage="BCC recipients" />}
              secondary={bcc.map((r) => (
                <Typography key={r.address}>
                  {r.name &&
                  `${r.name} <${r.address}>`}
                  {!r.name && r.address}
                </Typography>
              ))}
            />
          </ListItem>
        )}
        <ListItem>
          <ListItemText
            primary={
              <>
                <FormattedMessage defaultMessage="Subject" />
                :&nbsp;
                <b>{email.options.subject}</b>
              </>
            }
            secondary={
              <>
                {email.options.html && (
                  <div
                    dangerouslySetInnerHTML={{ __html: email.options.html }}
                  ></div>
                )}
                {!email.options.html && email.options.text && (
                  <pre>{email.options.text}</pre>
                )}
              </>
            }
          />
        </ListItem>
        {email.options?.attachments && email.options.attachments.length > 0 &&
        <ListItem>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Attachments" />}
            secondary={
              <List dense disablePadding>
                {email.options?.attachments?.map((a) => (
                  <>
                    <ListItem>
                      <Link href={a.href}>{a.filename}</Link>
                    </ListItem>
                  </>
                ))}
              </List>
            }
          />
        </ListItem>
        }
      </List>
    </FormDialog>
  );
};

export default SentEmailDialog;

import { useGetAll } from "@mb-pro-ui/utils";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column } from "react-table";

import moment from "moment";
import { Customer, RegularReport, RegularReportInterval } from "@mb-pro-ui/utils/types/alarm";

import CreateSavedQueryForm from "./SavedQueryCreateModal";
import { AllEntities } from "../settings/utils";


const reportSchedule = (report:RegularReport) : string => {
  const s = typeof report.interval === 'string' ? report.interval : (report.interval as RegularReportInterval)["localized-description"];
  const t = report.offset;

  return `${s} (${t})`;
}


const SavedQueries = () => {
  const { formatMessage } = useIntl();
  
  /*const { data: reports = [], status: queryStatus, refetch } */
  const queryResult = useGetAll<RegularReport>(
    "alarm/regular-reports",
    {
      include: {
        customer: {},
        interval: {},
      },
      fields: {
        customers: ["account", "name"],
        "regular-reports": ["+needs-export"],
      },
      page: {
        limit: 1000,
      },
      sort: ["id"],
    }
  );

  const columns = useMemo(
    (): Column<RegularReport>[] => [
      {
        id: "customer-id",
        minWidth: 60,
        maxWidth: 80,
        Header: formatMessage({
          defaultMessage: "Customer id",
        }),
        accessor: (report) => typeof report.customer === "string" ? String(report.customer) : (report.customer as Customer).account,
      },
      {
        id: "customer-name",
        width: 200,
        Header: formatMessage({
          defaultMessage: "Customer name",
        }),
        accessor: (report) => typeof report.customer === "string" ? "" : (report.customer as Customer).name,
      },
      {
        id: "format",
        width: 100,
        Header: formatMessage({
          defaultMessage: "Format",
        }),
        accessor: (report) => report.format,
      },
      {
        id: "schedule",
        minWidth: 60,
        maxWidth: 200,
        Header: formatMessage({
          defaultMessage: "Schedule",
        }),
        accessor: reportSchedule
      },
      {
        id: "report-last-exported",
        Header: formatMessage({
          defaultMessage: "Last exported",
        }),
        accessor: (report) =>
          report["last-exported"]
            ? moment(report["last-exported"]).format(
                formatMessage({
                  defaultMessage: "MM/DD/YYYY HH:mm",
                })
              )
            : "",
        minWidth: 60,
        maxWidth: 120,
      },
    ],
    [formatMessage]
  );

  const title = <FormattedMessage defaultMessage="Saved queries" />;

  return (
    <AllEntities
      columns={columns}
      title={title}
      queryResult={queryResult}
      renderForm={({ isFormOpen, onFormClose, refetch, setSnackbarState }) => (
        <CreateSavedQueryForm
          open={isFormOpen}
          onFormClose={onFormClose}
          refetch={refetch}
          setSnackbarState={setSnackbarState}
        />
      )}
    />
  );

};

export default SavedQueries;

import Dialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";
import Button from "./Button";

export type FormDialogProps = PropsWithChildren<{
  title: string;
  contentText?: string;
  warning?: boolean;
  centered?: boolean;
  prefixButtons?: JSX.Element;
  DialogProps?: Partial<MuiDialogProps>;
  open: boolean;
  hasCancel?: boolean;
  hasConfirm?: boolean;
  confirmDisabled?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  onDialogAction?: (e: {}, action: "close" | "cancel" | "confirm") => void;
}>;

const FormDialog = ({
  warning = false,
  centered = false,
  title,
  contentText,
  children,
  open,
  prefixButtons,
  DialogProps,
  hasCancel = true,
  hasConfirm = true,
  cancelLabel = "Cancel",
  confirmDisabled,
  confirmLabel = "Confirm",
  onDialogAction,
}: FormDialogProps) => (
  <Dialog open={open} onClose={() => onDialogAction?.({}, "close")} {...DialogProps}>
    <DialogTitle
      sx={[
        {
          fontSize: 16,
          fontWeight: 600,
          letterSpacing: "0.02em",
          textTransform: "uppercase",
        },
        warning && {
          backgroundColor: "warning.main",
        },
      ]}
    >
      {title}
    </DialogTitle>
    <DialogContent>
      {contentText && <DialogContentText>{contentText}</DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions
      sx={[
        centered && {
          justifyContent: "center",
        },
      ]}
    >
      {prefixButtons && <Box sx={{ marginRight: "auto" }}>{prefixButtons}</Box>}
      {hasCancel && (
        <Button onClick={() => onDialogAction?.({}, "cancel")} mode="secondary">
          {cancelLabel}
        </Button>
      )}
      {hasConfirm && (
        <Button
          disabled={confirmDisabled}
          onClick={() => onDialogAction?.({}, "confirm")}
          mode={warning ? "warning" : "primary"}
        >
          {confirmLabel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default FormDialog;

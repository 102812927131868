import {
  InputAdornment,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { FilterViewProps } from "../../types";
import { useEffect, useRef, useState } from "react";
import { utils } from "@mb-pro-ui/utils";
import MbProFilterTextField from "../../../components/MbProFilterTextField";

const ClassedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({});

const TextFilter = ({
  filterValue,
  setFilter,
  urlSearchParam,
  setUrlSearchParam,
  label,
  helperText,
  filterId,
}: FilterViewProps) => {
  const [value, setValue] = useState<string>(urlSearchParam ?? "");
  const debouncedValue = utils.useDebounce<string>(value ?? "", 500);
  const didMountRef = useRef(false);

  useEffect(() => {
    setFilter(filterId, debouncedValue);
    setUrlSearchParam(filterId, debouncedValue);
  }, [debouncedValue, setFilter, filterId, setUrlSearchParam, urlSearchParam]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!filterValue) {
        setValue("");
      }
    }
  }, [filterValue]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return (
    <MbProFilterTextField
      size="small"
      label={label}
      value={value ?? ""}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClassedTooltip
              title={helperText ?? false}
              sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: filterValue ? "info.main" : "primary.main",
                },
              }}
            >
              <TextFieldsIcon />
            </ClassedTooltip>
          </InputAdornment>
        ),
      }}
      margin="normal"
      sx={{
        width: "200px",
      }}
      emphasize={!!filterValue}
    />
  );
};

export default TextFilter;

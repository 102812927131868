import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { CircularProgress } from "@mui/material";

import { useCreate } from "@mb-pro-ui/utils";
import { ID } from "@mb-pro-ui/utils/types";

import { Page } from "@mb-pro-ui/components";

import { useQueryParams } from "../hooks";

type Intervention = {
  type: string;
  id: ID;
};

const InterventionCreatePage = () => {
  const queryParams = useQueryParams();
  const histroy = useHistory();

  const {
    mutate: createIntervention,
    mutateAsync: createInterventionAsync,
  } = useCreate<Intervention>("alarm/interventions");

  const eventIDs = queryParams.get("start-events");

  const isMounted = useRef<boolean>();
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (eventIDs) {
      (async function () {
        try {
          const results = await Promise.allSettled(
            eventIDs?.split(",").map((id) =>
              createInterventionAsync({
                "start-event": { type: "cdec", id },
              })
            )
          );

          let firstSuccess: PromiseFulfilledResult<Intervention> | undefined;
          const errors: PromiseRejectedResult[] = [];

          for (const res of results) {
            if (res.status === "fulfilled") {
              if (!firstSuccess) {
                firstSuccess = res;
              }
            } else {
              errors.push(res);
            }
          }

          if (errors.length > 0) {
            // TODO
            console.error(
              "Create Intervention Error",
              ...errors.map((res) => res.reason)
            );
          }

          if (!isMounted.current) {
            return;
          }

          if (firstSuccess) {
            histroy.replace(
              `/alarm/${firstSuccess.value.type}/${firstSuccess.value.id}`
            );
          } else {
            histroy.replace(`/alarm/operator`);
          }
        } catch (error) {
          // TODO
          console.error("Unknown error", error);
          // collect and show errors?
        }
      })();
    }
  }, [eventIDs, createIntervention, createInterventionAsync, histroy]);

  return (
    <Page
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={80} />
    </Page>
  );
};

export default InterventionCreatePage;

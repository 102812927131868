import { TextField } from "@mb-pro-ui/components/form";
import { TextField as MbpTextField, Button } from "@mb-pro-ui/components/";
import { Divider, IconButton, lighten } from "@mui/material";

import { Box, useTheme } from "@mui/system";
import { FieldArray, useFieldArray } from "react-final-form-arrays";

import DeleteIcon from "@mui/icons-material/Delete";
import Widget from "../utils/Widget";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import exifr from "exifr";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-final-form";

interface ExifImageProps {
  image: string;
  readOnly?: boolean;
}

const ExifImage = ({ image, readOnly }: ExifImageProps) => {
  const isAttached = useRef(true);

  const { change } = useForm();

  const [coordinates, setCoordinates] = useState<null | {
    latitude: number;
    longitude: number;
  }>(null);

  const { formatMessage } = useIntl();

  useEffect(() => {
    return () => {
      isAttached.current = false;
    };
  }, []);

  const imageOnLoad = async (e: any) => {
    const gps = await exifr.gps(e.target);
    if (isAttached.current) {
      setCoordinates(gps);
    }
  };

  return (
    <>
      <Link to={{ pathname: image }} target="_blank" rel="noopener noreferrer">
        <img
          src={image}
          style={{ width: "calc(100% - 16px)", margin: "8px" }}
          alt=""
          onLoad={imageOnLoad}
        />
      </Link>
      {coordinates ? (
        <>
          <Box sx={{ display: "flex" }}>
            <MbpTextField
              value={coordinates.longitude.toFixed(6)}
              label={formatMessage({ defaultMessage: "Longitude" })}
            />
            <MbpTextField
              value={coordinates.latitude.toFixed(6)}
              label={formatMessage({ defaultMessage: "Latitude" })}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 1,
            }}
          >
            {readOnly ? null : (
              <Button
                onClick={() => {
                  change("base-latitude", coordinates.latitude);
                  change("base-longitude", coordinates.longitude);
                }}
              >
                {formatMessage({
                  defaultMessage: "Overwrite base coordinates",
                })}
              </Button>
            )}
          </Box>
        </>
      ) : null}
    </>
  );
};

interface ImageInputProps {
  name: string;
  readOnly?: boolean;
}

const ImageInput = ({ name, readOnly }: ImageInputProps) => {
  const { palette } = useTheme();

  const {
    fields: { push },
  } = useFieldArray(name);

  const { formatMessage } = useIntl();

  const onChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const data = (reader?.result as string)?.split(",");
      push({
        image: reader.result,
        description: file.name.replace(/(\.\w+)$/, ""),
        "raw-data": data[1],
        type: "maps",
      });
    };

    reader.readAsDataURL(file);
  };

  return (
    <FieldArray name={name}>
      {({ fields, meta: { dirty } }) => (
        <Widget
          title={formatMessage({
            defaultMessage: "Images",
            description: "Images input label",
          })}
          children={
            <>
              <MbpTextField type="file" value={""} onChange={onChange} />
              {fields.map((field, i) => {
                const fieldValue = fields.value[i];
                return (
                  <Box
                    key={field}
                    sx={
                      dirty
                        ? {
                            backgroundColor: lighten(
                              palette.warning.light,
                              0.6
                            ),
                          }
                        : {}
                    }
                  >
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <TextField
                        name={`${field}.description`}
                        label={formatMessage({
                          defaultMessage: "Description",
                        })}
                        sx={{ width: "100%" }}
                      />
                      {readOnly ? null : (
                        <IconButton onClick={() => fields.remove(i)}>
                          <DeleteIcon sx={{ color: "error.main" }} />
                        </IconButton>
                      )}
                    </Box>
                    <ExifImage image={fieldValue.image} readOnly={readOnly} />
                    <Divider />
                  </Box>
                );
              })}
            </>
          }
        />
      )}
    </FieldArray>
  );
};

export default ImageInput;

import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import React from "react";

export type ButtonProps<C extends React.ElementType> = MuiButtonProps<
  C,
  {
    mode?: "primary" | "secondary" | "warning" | "info";
    component?: C;
  }
>;

const Button = <C extends React.ElementType>({
  mode = "primary",
  sx = [],
  ...props
}: ButtonProps<C>) => (
  <MuiButton
    size="small"
    variant={mode === "secondary" ? "outlined" : "contained"}
    disableElevation
    sx={[
      mode === "warning" && {
        backgroundColor: "warning.main",
        color: "warning.contrastText",
        "&:hover": {
          backgroundColor: "warning.dark",
        },
      },
      mode === "info" && {
        backgroundColor: "info.main",
        color: "info.contrastText",
        "&:hover": {
          backgroundColor: "info.dark",
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default Button;

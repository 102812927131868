import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { formatDuration } from "../formatjs/FormattedDuration";

export interface RefreshControlProps {
  value: false | number;
  onChange: (value: false | number) => void;
  intervals?: readonly (false | number)[];
  onClick?: () => void;
  dataUpdatedAt?: number;
}

const defaultIntervals = [false, 10, 60, 120, 300, 900] as const;

const RefreshControl = ({
  intervals = defaultIntervals,
  value,
  onChange,
  onClick,
  dataUpdatedAt,
}: RefreshControlProps) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    if (value) {
      const interval = setInterval(() => setNow(Date.now()), 1000);
      return () => clearInterval(interval);
    }
  }, [value]);

  const progressRaw =
    value !== false && dataUpdatedAt !== undefined
      ? (value - (now - dataUpdatedAt) / 1000) / value
      : false;

  const progress =
    progressRaw !== false
      ? Math.min(100, Math.max(0, progressRaw * 100))
      : false;

  const options = useMemo(
    () =>
      intervals.map((value) => ({
        value,
        label: value
          ? formatDuration(intl, value, "long")
          : formatMessage({
              defaultMessage: "Off",
              description: "Disable auto refresh",
            }),
      })),
    [intervals, intl, formatMessage]
  );

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ position: "relative", marginRight: 1 }}>
        {progress !== false && (
          <CircularProgress
            value={progress}
            variant="determinate"
            thickness={2}
            sx={{ position: "absolute", color: "primary.contrastText" }}
          />
        )}
        <IconButton sx={{ color: "primary.contrastText" }} onClick={onClick}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Autocomplete
        options={options}
        value={options.find((opt) => opt.value === value) ?? options[0]}
        getOptionLabel={(option) => option.label}
        disableClearable
        freeSolo={false}
        multiple={false}
        onChange={(e, opt) => onChange(opt.value)}
        renderInput={(params) => (
          <TextField {...params} hiddenLabel size="small" variant="outlined" />
        )}
      />
    </Stack>
  );
};

export default RefreshControl;

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FilterViewProps } from "../../table";
import { AutocompleteFilter } from "../../table/filters";
import {
  EntityWithDescription,
  filterOptionsFromDescription,
  idAsLabel,
} from "../../../utils/FilterUtils";

const renderOption = (v: EntityWithDescription) => {
  return (
    <Typography noWrap padding={"0 10px"} maxWidth={"100%"}>
      {v.description}
    </Typography>
  );
};

const ActiveFilter = (props: FilterViewProps) => {
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      { id: "true", description: formatMessage({ defaultMessage: "Yes" }) },
      { id: "false", description: formatMessage({ defaultMessage: "No" }) },
    ],
    [formatMessage]
  );

  return (
    <AutocompleteFilter
      {...props}
      options={options}
      getOptionLabel={idAsLabel}
      renderOption={renderOption}
      filterOptions={filterOptionsFromDescription}
      label={formatMessage({ defaultMessage: "Active" })}
      virtualizedRowHeight={40}
    />
  );
};

export default ActiveFilter;

import { BatchCreateOp, BatchDeleteOp, BatchOp, BatchUpdateOp } from "../jsonapi";
import { Schema } from "../jsonapi/types";
import { CategorySchemas } from "../query/ApiProvider";
import type { ID } from "../types";
import isEqual from "./isEqual";

/*
Unhandled edge cases:
  With: readonly relationship with required back ref rel
    When: the related object is re-assigned to an other relationship
    Causes: possible data loss.
    How: generates an update and a delete query on the related object, that depending on the order of the operations may or may not commit successfully.


Relationship schema properties:
  readonly:
    The `item`.`field` cannot be updated.
    If this relationship has changed, the back-reference on the related object must be updated.

  required:
    The related object cannot exist without this item.
    If this item no longer references the related object, the related object must be deleted.

  manyToMany:
    The `item`.`field` is an array of IDs, that can be updated directly.
    Handled the same way as if readonly === false.
*/

export type DiffOptions = {
  // The schemas for the API object types
  schemas: CategorySchemas;

  // Custom field diff handlers
  // Should be used when the form's data models is different from the API's data model
  // Applies the necessery changes to the `op` argument, and yields other related change operations in case of relationships
  customHandlers?: {
    [type: string]: (
      // The object collecting the changes to be sent to the API
      op: BatchUpdateOp | BatchCreateOp,

      // The schema of the API object type
      schema: Schema,

      // The name of the field
      field: string,

      // The initial value of the field (can be null or undefined)
      initialValue: unknown,

      // The current value of the field (can be null)
      currentValue: unknown,

      options: DiffOptions
    ) => IterableIterator<BatchOp>;
  };

  // Can be used to return the original of an object that can be edited in the form, but might be missing from the `initialValues` object
  getOriginal?: (type: string, id: ID) => ({ id: ID } & Record<string, unknown>) | null;

  // Used internally to generate unique batch keys for newly created objects
  getBatchKey: (type: string) => string;
};

export function* diff(
  // The type of the root object
  type: string,

  // Initial values, null or undefined if this is a newly created object
  // Must contain the ID if this is an edited object
  initialValues: (Record<string, unknown> & { id?: ID }) | null | undefined,

  // The current values of the edited or created object
  // Must contain the ID if this is an edited object
  currentValues: Record<string, unknown> & { id?: ID },

  options: Omit<DiffOptions, "getBatchKey"> & { getBatchKey?: (t: string) => string }
): IterableIterator<BatchOp> {
  const schema = options.schemas[type];
  if (!schema) {
    throw new Error(`Unknown schema with type "${type}"`);
  }

  const getBatchKey = options.getBatchKey ?? createBatchKeyGenerator();

  const op: BatchCreateOp | BatchUpdateOp = currentValues.id
    ? {
        type,
        id: currentValues.id,
        meta: { op: "update" },
      }
    : {
        type,
        meta: { op: "create", "batch-key": getBatchKey(type) },
      };

  yield* diffPrivate(op, schema, initialValues, currentValues, {
    ...options,
    getBatchKey,
  });

  if (op.meta.op === "create" || Object.keys(op).length > 3) {
    yield op;
  }
}

// Applies the necessery changes to the `op` argument, and yields other related change operations in case of relationships
export function* diffField(
  // The object collecting the changes to be sent to the API
  op: BatchUpdateOp | BatchCreateOp,

  // The schema of the API object type
  schema: Schema,

  // The name of the field
  field: string,

  // The initial value of the field (can be null or undefined)
  initialValue: unknown,

  // The current value of the field (can be null)
  currentValue: unknown,

  options: DiffOptions
): IterableIterator<BatchOp> {
  if (isEqual(currentValue, initialValue)) {
    return;
  }

  // CASE 1: attribute
  if (schema.attributes.includes(field)) {
    if (initialValue !== undefined || op.meta.op === "create") {
      op[field] = currentValue;
    } else {
      // eslint-disable-next-line no-console
      console.debug(
        `skip updating "${schema.type}"."${field}" attribute because original value is undefined`
      );
    }
    return;
  }

  const rel = schema.relationships?.[field];

  if (!rel) {
    throw new Error(`Unknown field "${field}" on type "${schema.type}"`);
  }

  const relatedSchema = options.schemas[rel.type];
  if (!relatedSchema) {
    throw new Error(`Unknown related schema with type "${rel.type}"`);
  }

  // CASE 2: readonly relationship with back-ref
  if (rel.readonly) {
    // To edit a readonly relationship, it must have a writable back-reference relationship
    const [backRefField, backRefRel] = findBackRefRel(relatedSchema, schema.type, field);

    // Remove items no longer referenced by the readonly relationship
    {
      let initialRelatedItems;
      let isItemStillUsed;

      if (Array.isArray(initialValue)) {
        initialRelatedItems = initialValue;
        isItemStillUsed = (relatedItem: any) =>
          Array.isArray(currentValue) && currentValue.some((item) => isEqualID(item, relatedItem));
      } else {
        initialRelatedItems = initialValue ? [initialValue] : [];
        isItemStillUsed = (relatedItem: any) => isEqualID(relatedItem, currentValue);
      }

      for (const relatedItem of initialRelatedItems) {
        if (!isItemStillUsed(relatedItem)) {
          yield unrefItemFromReadonlyRel(relatedItem, rel.type, backRefField, backRefRel);
        }
      }
    }

    // Update still referenced items, and create the new ones
    {
      // Readonly relationships need a reference to the current object
      const parentRef = getParentRef(op);

      let relatedItems;
      let getInitialItem;

      if (Array.isArray(currentValue)) {
        relatedItems = currentValue;
        getInitialItem = Array.isArray(initialValue)
          ? (id: string) => initialValue.find((item) => isEqualID(item, id))
          : (_id: string) => {};
      } else {
        relatedItems = currentValue ? [currentValue] : [];
        getInitialItem = (id: string) =>
          initialValue && isEqualID(initialValue, id)
            ? (initialValue as ID | Record<string, unknown>)
            : undefined;
      }

      for (const relatedItem of relatedItems) {
        yield* processReadonlyRelItems(
          relatedItem,
          relatedSchema,
          backRefField,
          parentRef,
          getInitialItem,
          options
        );
      }
    }
  } else {
    let relatedItems;
    let initialRelatedItems;

    if (Array.isArray(currentValue)) {
      relatedItems = currentValue;
      initialRelatedItems = (initialValue as any[]) ?? [];
    } else {
      relatedItems = currentValue ? [currentValue] : [];
      initialRelatedItems = initialValue ? [initialValue] : [];
    }

    const relatedIDs = [];

    for (const relatedItem of relatedItems) {
      // The related item is an ID only
      if (typeof relatedItem === "string") {
        relatedIDs.push(relatedItem);
        continue;
      }

      const relatedOp: BatchCreateOp | BatchUpdateOp = relatedItem.id
        ? {
            type: rel.type,
            id: relatedItem.id,
            meta: { op: "update" },
          }
        : {
            type: rel.type,
            meta: { op: "create", "batch-key": options.getBatchKey(rel.type) },
          };

      if (relatedOp.meta.op === "update") {
        relatedIDs.push(relatedOp.id);
      } else {
        relatedIDs.push({ meta: { "batch-key": relatedOp.meta["batch-key"] } });
      }

      const originalItem = relatedItem.id
        ? options.getOriginal?.(rel.type, relatedItem.id) ??
          initialRelatedItems.find((item) => isEqualID(item, relatedItem))
        : null;

      if ((originalItem && typeof originalItem !== "string") || relatedOp.meta.op === "create") {
        yield* diffPrivate(
          relatedOp,
          relatedSchema,
          typeof originalItem === "string" ? null : originalItem,
          relatedItem,
          options
        );

        if (relatedOp.meta.op === "create" || Object.keys(relatedOp).length > 3) {
          yield relatedOp;
        }
      }
    }

    if (
      !isEqual(
        relatedIDs,
        initialRelatedItems.map((item) => (typeof item === "string" ? item : item.id))
      )
    ) {
      if (initialValue !== undefined || op.meta.op === "create") {
        if (relatedIDs.length === 0) {
          op[field] = null;
        } else if (!Array.isArray(currentValue) && !Array.isArray(initialValue)) {
          op[field] = relatedIDs[0];
        } else {
          op[field] = relatedIDs;
        }
      } else {
        // eslint-disable-next-line no-console
        console.debug(
          `skip updating "${schema.type}"."${field}" relationship because original value is undefined`
        );
      }
    }
  }
}

function* diffPrivate(
  op: BatchCreateOp | BatchUpdateOp,
  schema: Schema,
  initialValues: (Record<string, unknown> & { id?: ID }) | null | undefined,
  currentValues: Record<string, unknown> & { id?: ID },
  options: DiffOptions
): IterableIterator<BatchOp> {
  if (!isEqual(initialValues, currentValues)) {
    for (const [field, value] of Object.entries(currentValues)) {
      if (field === "type" || field === "id") {
        continue;
      }
      yield* diffFieldPrivate(op, schema, field, initialValues?.[field], value, options);
    }
  }
}

function* diffFieldPrivate(
  op: BatchUpdateOp | BatchCreateOp,
  schema: Schema,
  field: string,
  initialValue: unknown,
  currentValue: unknown,
  options: DiffOptions
): IterableIterator<BatchOp> {
  // Use the custom change handler always if it exists.
  // Simple custom change handlers could for example just convert the value types, and call the default diffField(...).
  const customHandler = options.customHandlers?.[schema.type];
  if (customHandler) {
    return yield* customHandler(op, schema, field, initialValue, currentValue, options);
  }

  return yield* diffField(op, schema, field, initialValue, currentValue, options);
}

function findBackRefRel(relatedSchmea: Schema, parentType: string, parentField: string) {
  // Identify the back reference relationship for readonly relationships (eg. "customers"."zones" <- "zones"."customer")
  const backRefRels = Object.entries(relatedSchmea.relationships ?? {}).filter(
    (backRefRel) => backRefRel[1].type === parentType
  );
  if (backRefRels.length !== 1) {
    throw new Error(
      `Couldn't identify the back reference relationship for the readonly relationship "${parentType}"."${parentField}"`
    );
  }

  return backRefRels[0];
}

function unrefItemFromReadonlyRel(
  relatedItem: ID | { id: ID },
  relatedType: string,
  backRefField: string,
  backRefRel: { required?: boolean }
): BatchDeleteOp | BatchUpdateOp {
  if (backRefRel.required) {
    return {
      type: relatedType,
      id: typeof relatedItem === "string" ? relatedItem : relatedItem.id,
      meta: { op: "delete" },
    };
  }

  return {
    type: relatedType,
    id: typeof relatedItem === "string" ? relatedItem : relatedItem.id,
    [backRefField]: null,
    meta: { op: "update" },
  };
}

function* processReadonlyRelItems(
  relatedItem: ID | { id?: ID },
  relatedSchema: Schema,
  backRefField: string,
  parentRef: ID | { meta: { "batch-key": ID } },
  getInitialItem: (id: ID) => ID | Record<string, unknown> | null | undefined,
  options: DiffOptions
): IterableIterator<BatchOp> {
  const relatedID: string | undefined =
    typeof relatedItem === "string" ? relatedItem : relatedItem.id;

  const initialItem = relatedID ? getInitialItem(relatedID) : null;
  const isReassignedID = !!relatedID && !initialItem;

  if (typeof relatedItem === "string" && !isReassignedID) {
    return;
  }

  const relatedOp: BatchCreateOp | BatchUpdateOp = relatedID
    ? {
        type: relatedSchema.type,
        id: relatedID,
        meta: { op: "update" },
      }
    : {
        type: relatedSchema.type,
        meta: { op: "create", "batch-key": options.getBatchKey(relatedSchema.type) },
      };

  if (isReassignedID || !relatedID) {
    relatedOp[backRefField] = parentRef;
  }

  if (typeof relatedItem === "string") {
    if (isReassignedID) {
      yield relatedOp;
    }
    return;
  }

  const originalItem = relatedID
    ? options.getOriginal?.(relatedSchema.type, relatedID) ?? initialItem
    : null;

  yield* diffPrivate(
    relatedOp,
    relatedSchema,
    typeof originalItem === "string" ? undefined : originalItem,
    relatedItem,
    options
  );

  if (relatedOp.meta.op === "create" || Object.keys(relatedOp).length > 3) {
    yield relatedOp;
  }
}

function getParentRef(op: BatchUpdateOp | BatchCreateOp): ID | { meta: { "batch-key": ID } } {
  if (op.meta.op === "create") {
    if (!op.meta["batch-key"]) {
      throw new Error(`Missing "meta"."batch-key" from "${op.type}" create op`);
    }
    return { meta: { "batch-key": op.meta["batch-key"] as ID } };
  } else if (!op.id) {
    throw new Error(`Missing "id" from "${op.type}" update op`);
  }
  return op.id as ID;
}

function isEqualID(a: any, b: any): boolean {
  return (a?.id ?? a) === (b?.id ?? b);
}

// Generates new temporary IDs, eg:
// "some-type": "some-type #1", "some-type #2", ...
export function createBatchKeyGenerator() {
  const counters: Record<string, number> = {};
  return (type: string) => {
    const id = counters[type] ?? 0;
    counters[type] = id + 1;
    return `${type} #${id}`;
  };
}

import { FormDialog } from "@mb-pro-ui/components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Location, Action } from "history";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";

interface FormPromptProps {
  title: string;
  when: boolean;
  whenForward?: (location: Location<unknown>, action: Action) => boolean;
  onConfirm?: () => boolean;
  onCancel?: () => boolean;
  confirmLabel?: string;
  cancelLabel?: string;
}

const FormPrompt = ({
  when,
  whenForward,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  title,
}: FormPromptProps) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);

  const unblock = useRef<any>();

  const { formatMessage } = useIntl();

  const navigate = useCallback(
    (location: Location<unknown> | null, action: Action | null) => {
      switch (action) {
        case "PUSH":
          if (location) history.push(location.pathname);
          break;
        case "REPLACE":
          if (location) history.replace(location.pathname);
          break;
        case "POP":
          history.goBack();
          break;
        default:
          if (location) history.push(location.pathname);
          break;
      }
    },
    [history]
  );

  useEffect(() => {
    if (when) {
      unblock.current = history.block((prompt, action) => {
        setCurrentLocation(prompt);
        setCurrentAction(action);
        setShowPrompt(true);

        if (whenForward && whenForward(prompt, action)) {
          setShowPrompt(false);
          unblock.current();
          navigate(prompt, action);
        }
        return "true";
      });
    }
    return () => {
      unblock.current && unblock.current();
    };
  }, [history, when, whenForward, currentAction, currentLocation, navigate]);

  const handleConfirm = useCallback(async () => {
    const canRoute = onConfirm ? await Promise.resolve(onConfirm()) : true;
    if (canRoute) {
      setShowPrompt(false);
      history.block(() => {});
      navigate(currentLocation, currentAction);
    }
  }, [currentLocation, history, onConfirm, currentAction, navigate]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      await Promise.resolve(onCancel());
    }

    setShowPrompt(false);
  }, [onCancel]);

  const onDialogAction = async (
    _: {},
    action: "close" | "cancel" | "confirm"
  ) => {
    switch (action) {
      case "cancel":
        handleCancel();
        break;
      case "close":
        handleCancel();
        break;
      case "confirm":
        handleConfirm();
        break;
    }
  };

  return showPrompt ? (
    <FormDialog
      open={showPrompt}
      title={title}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      onDialogAction={onDialogAction}
    >
      <Typography>
        {formatMessage({
          defaultMessage:
            "There are unsaved changes. Are you sure want to leave this page?",
        })}
      </Typography>
    </FormDialog>
  ) : null;
};

export default FormPrompt;

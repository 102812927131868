import AppBar, { AppBarProps } from "@mui/material/AppBar";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar, { ToolbarProps } from "@mui/material/Toolbar";

export interface HeaderProps extends AppBarProps {
  ToolbarProps?: ToolbarProps;
  isLoading?: boolean;
}

const Header = ({
  position = "fixed",
  children,
  ToolbarProps: _ToolbarProps,
  isLoading,
  sx,
  ...props
}: HeaderProps) => (
  <>
    <AppBar
      {...props}
      position={position}
      sx={{
        ...sx,
        backgroundColor: "primary.main",
        color: "primary.contrastText",
      }}
    >
      <Toolbar
        {..._ToolbarProps}
        disableGutters
        sx={{
          alignItems: "stretch",
        }}
      >
        {children}
      </Toolbar>
      {isLoading ? (
        <LinearProgress sx={{ position: "absolute", bottom: 0, width: "100%" }} />
      ) : null}
    </AppBar>
    {position === "fixed" && <Toolbar />}
  </>
);

export default Header;

import Widget from "../utils/Widget";
import { useIntl } from "react-intl";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Intervention, Customer } from "./types";
import moment from "moment";
import { styled } from "@mui/system";

const StyledMapContainer = styled(MapContainer)({ flex: 1 });

function getPosition(customer: Customer) {
  if (customer["has-valid-move-coords"]) {
    if (
      !customer["has-valid-base-coords"] ||
      moment(customer["base-coord-update-ts"]).isBefore(
        customer["coord-update-ts"]
      )
    ) {
      return {
        position: [customer.latitude, customer.longitude] as [number, number],
        locationName: customer["location-name"],
      };
    }
  }
  if (customer["has-valid-base-coords"]) {
    return {
      position: [customer["base-latitude"], customer["base-longitude"]] as [
        number,
        number
      ],
      locationName: null,
    };
  }
  return null;
}

const MapWidget = ({ intervention }: { intervention?: Intervention }) => {
  const { formatMessage } = useIntl();

  const locationInfo = intervention?.customer
    ? getPosition(intervention.customer)
    : null;

  return (
    <Widget
      title={formatMessage({
        defaultMessage: "Map",
        description: "Intervention page Map widget title",
      })}
      placeholderMessage={formatMessage({
        defaultMessage: "There is no known location",
        description:
          "Intervention Page Customer Data Map Widget unkonwn location",
      })}
    >
      {locationInfo && (
        <StyledMapContainer
          center={locationInfo.position}
          zoom={16}
          scrollWheelZoom={"center"}
          zoomAnimation={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={locationInfo.position}>
            {locationInfo.locationName && (
              <Popup>{locationInfo.locationName}</Popup>
            )}
          </Marker>
        </StyledMapContainer>
      )}
    </Widget>
  );
};

export default MapWidget;

import { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import { useIntl } from "react-intl";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

export interface AutoplayPolicybarProps {
  audioContextState: string;
}

const AutoplayPolicybar = ({ audioContextState }: AutoplayPolicybarProps) => {
  const [open, setOpen] = useState(audioContextState === "suspended");
  const { formatMessage } = useIntl();

  useEffect(() => {
    setOpen(audioContextState === "suspended");
  }, [audioContextState]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      onClose={handleClose}
      message={formatMessage({
        defaultMessage: "Click anywhere to enable sound",
        description: "Autoplay policy snackbar üzenet",
      })}
      sx={{
        "& > div": {
          backgroundColor: "error.main",
        },
      }}
      action={<VolumeOffIcon />}
    />
  );
};

export default AutoplayPolicybar;

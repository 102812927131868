import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ID } from "@mb-pro-ui/utils/types";

type Cdec = { id: ID };

type Acknowledge = [Cdec[], Dispatch<SetStateAction<Cdec[]>>];

const AcknowledgeContext = createContext<Acknowledge | undefined>(undefined);

const AcknowledgeProvider = ({ children }: PropsWithChildren<{}>) => {
  const acknowledgeState = useState<Cdec[]>([]);
  return (
    <AcknowledgeContext.Provider children={children} value={acknowledgeState} />
  );
};

const useAcknowledge = () => {
  const context = useContext(AcknowledgeContext);
  if (context === undefined) {
    throw Error(
      "Acknowledge context must be used within an AcknowledgeProvider"
    );
  }

  return context;
};

export { AcknowledgeProvider, useAcknowledge };

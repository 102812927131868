import { CircularProgress, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import { useIntl } from "react-intl";
import ProgressMessage from "../../utils/ProgressMessage";
import { ReactNode } from "react";
import { Button } from "@mb-pro-ui/components";

interface UpdateFormActionsProps {
  isLoading?: boolean;
  isDeleting?: boolean;
  isSaving?: boolean;
  onDelete?: () => void;
  onReset?: () => void;
  onSave?: () => void;
  disabledDelete?: boolean;
  disabledReset?: boolean;
  disabledSave?: boolean;
  prefix?: ReactNode;
  postfix?: ReactNode;
}

const UpdateFormActions = ({
  isLoading,
  isDeleting,
  isSaving,
  onDelete,
  onReset,
  onSave,
  disabledDelete,
  disabledReset,
  disabledSave,
  prefix,
  postfix,
}: UpdateFormActionsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        m: 1,
        mt: 3,
      }}
    >
      {isLoading ? (
        <CircularProgress
          sx={{ color: "white", marginRight: "5px" }}
          size={"17px"}
        />
      ) : (
        <>
          {prefix}
          {onDelete && (
            <Button
              startIcon={
                isDeleting ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: "error.contrastText",
                    }}
                  />
                ) : (
                  <DeleteOutlineIcon />
                )
              }
              onClick={onDelete}
              size="small"
              disabled={disabledDelete}
              color="error"
            >
              {formatMessage({ defaultMessage: "Delete" })}
            </Button>
          )}
          <Box sx={{ flex: 1 }}></Box>
          {onReset && (
            <Button
              startIcon={<ReplayIcon />}
              variant="outlined"
              onClick={onReset}
              size="small"
              disabled={disabledReset}
              sx={{ mx: 2 }}
            >
              {formatMessage({ defaultMessage: "Reset" })}
            </Button>
          )}
          {onSave && (
            <Button
              startIcon={
                isSaving ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: "primary.contrastText",
                    }}
                  />
                ) : (
                  <SaveOutlinedIcon />
                )
              }
              onClick={onSave}
              size="small"
              disabled={disabledSave}
            >
              {formatMessage({ defaultMessage: "Save" })}
            </Button>
          )}
          {postfix}
        </>
      )}
    </Box>
  );
};

export default UpdateFormActions;

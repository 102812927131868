import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { Typography } from "@mui/material";
import { EnhancedTable } from "@mb-pro-ui/components/table";

import { Intervention, Partition } from "./types";

const CustomerPartitions = ({
  intervention,
}: {
  intervention?: Intervention;
}) => {
  const { formatMessage } = useIntl();

  return (
    <EnhancedTable
      columns={useMemo(
        (): Column<Partition>[] => [
          {
            id: "number",
            Header: formatMessage({
              defaultMessage: "Number",
              description:
                "Intervention page customer partitions table number header",
            }),
            accessor: "number",
          },
          {
            id: "description",
            Header: formatMessage({
              defaultMessage: "Description",
              description:
                "Intervention page customer partitions table description header",
            }),
            accessor: "description",
          },
        ],
        [formatMessage]
      )}
      data={intervention?.customer?.partitions ?? []}
      prefix={
        <Typography color="primary.contrastText">
          {formatMessage({
            defaultMessage: "Customer partitions",
            description: "Intervention page customer partitions subpage header",
          })}
        </Typography>
      }
    />
  );
};

export default CustomerPartitions;

import { useCreate } from "@mb-pro-ui/utils";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { ModalFormProps } from "../settings/types";

import { CustomerGroup } from "@mb-pro-ui/utils/types/alarm";
import EmailRecipientForm, { EmailRecipientFormValues } from "./EmailRecipientForm";

const initialValues:EmailRecipientFormValues = {
    active: true,
};

const EmailRecipientCreateModal = ({
    open,
    onFormClose,
    refetch,
    setSnackbarState,
  }: ModalFormProps) => {

    const { mutate: createEmailRecipient } = useCreate("alarm/email-recipients", {
        onSuccess: () => {
          setSnackbarState({
            message: formatMessage({
              defaultMessage: "Saved succesfully",
            }),
          });
          refetch();
          onFormClose();
        },
        onError: () => {
            setSnackbarState({
                message: formatMessage({
                  defaultMessage: "Unsuccessful operation",
                }),
                error: true,
              });
        },
      });

    const { formatMessage } = useIntl();
    const onSubmit = (values:EmailRecipientFormValues) => {
        const cg = values["customer-group"] as CustomerGroup;
        createEmailRecipient({
          "customer-group": cg?.id,
          name: values.name || "",
          email: values.email || "",
          active: !!values.active,
        });
      };

    return (
    <Dialog open={open}>
      <DialogTitle>
        {formatMessage({ defaultMessage: "Create email recipient" })}
      </DialogTitle>

      <Form initialValues={initialValues} onSubmit={onSubmit} render={(props) => { 
          return (
            <form onSubmit={props.handleSubmit}>
              <DialogContent
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  minWidth: "400px",
                }}
              >
                <EmailRecipientForm {...props} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={onFormClose}
                  disabled={props.submitting}
                >
                  {formatMessage({ defaultMessage: "Cancel" })}
                </Button>
                <Button
                  type="submit"
                  disabled={props.submitting || props.pristine}
                >
                  {formatMessage({ defaultMessage: "Create" })}
                </Button>
              </DialogActions>
            </form>
          );
          
      }} />      
    </Dialog>
    );

  };

  
export default EmailRecipientCreateModal;